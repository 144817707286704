import React, {useCallback} from "react";
import {SpinnerButton} from "../../../core/frontend/elements/SpinnerButton";
import SchemaIcon from '@mui/icons-material/Schema';
import {useNavigate} from "react-router-dom";
import "./GeneratedContentViewer.scss";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

export default function GeneratedContentViewer({generatedContent}) {
    const navigate = useNavigate();

    const handleViewChartClicked = useCallback(() => {
        navigate(`/single_chart_evaluation/${generatedContent.single_chart_evaluation_id}`);
    }, [generatedContent, navigate]);

    return <div className={"generated-content-viewer"}>
        <div className={"accordian-wrapper"}>
            {
                (generatedContent.posts ?? []).map((post, postIndex) => {
                    return <Accordion key={postIndex}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}>
                            Post {postIndex + 1}
                        </AccordionSummary>
                        <AccordionDetails>
                            <pre>{post}</pre>
                        </AccordionDetails>
                    </Accordion>
                })
            }
        </div>
        <div className={"buttons-bar"}>
            <SpinnerButton variant="contained" color="primary" type="submit" onClick={handleViewChartClicked}
                           startIcon={<SchemaIcon/>}>
                View Prompt Chart
            </SpinnerButton>
        </div>
    </div>
}
