import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import "./PhoneNumberConfigurationMethodSelector.scss";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";



/**
 * This is used to select the method of purchasing a phone number. The user is presented two options,
 * either to purchase a new phone number or to port an existing phone number from another provider.
 *
 * @returns {Element}
 * @constructor
 */
export const PhoneNumberConfigurationMethodSelector = ({onConfigurationModeSelected}) => {
    const handleMethodSelected = (event) => {
        onConfigurationModeSelected(event.target.value);
    };

    return (
        <div className={"phone-number-purchase-method-selector"}>
            <FormControl>
                <FormLabel id="phone-number-purchase-method-label">
                    <LocalTranslatedText language={"en"} text="How would you like to set up your new phone number?" />
                </FormLabel>
                <RadioGroup
                    aria-labelledby="phone-number-purchase-method-label"
                    name="phone-number-purchase-method"
                    onChange={handleMethodSelected}
                >
                    <FormControlLabel value="purchase" control={<Radio />} label={<LocalTranslatedText language={"en"} text="Purchase New Number" />} />
                    <FormControlLabel value="port" control={<Radio />} label={<LocalTranslatedText language={"en"} text="Port Existing Number" />}/>
                    {/*<FormControlLabel value="test_number" control={<Radio />} label={<LocalTranslatedText language={"en"} text="Attach to a Testing Number (dev-only)"/>}/>*/}
                </RadioGroup>
            </FormControl>

        </div>
    );
};
