import React, {useContext} from "react";


const TemplateSampleValuesCache = React.createContext(null);

export const TemplateSampleValuesCacheProvider = ({children}) => {
    const [cache, setCache] = React.useState({});

    return <TemplateSampleValuesCache.Provider value={cache}>
        {children}
    </TemplateSampleValuesCache.Provider>;
};

export const useTemplateSampleValuesCache = useContext.bind(null, TemplateSampleValuesCache);

