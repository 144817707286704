import FormControl from "@mui/material/FormControl";
import React, {useCallback} from "react";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import supportedLanguages from "../../backend/data/supported_languages.json";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";

// Filter for only the deepgram supported languages
supportedLanguages.languages = supportedLanguages.languages.filter(language => language.deepgram_language_code);

// Sort the standard list of languages
supportedLanguages.languages.sort((a, b) => a.name.localeCompare(b.name));


export function BasicLanguageSelector({label, fieldName, placeholder, emptyLabel, disabled, onChange, value}) {
    const options = supportedLanguages.languages.map(language => language.id);
    const optionLabels = supportedLanguages.languages.map(language => language.name);

    placeholder = placeholder ?? "Select a language";

    const handleChange = useCallback((event) => {
        onChange(event.target.value);
    }, [fieldName, onChange]);

    let valueLocal = value ?? '';

    return <div className={"form-input dropdown-input"}>
        {
            label ? <Typography variant={'label'}><LocalTranslatedText language={"en"} text={label} /></Typography> : null
        }
        <FormControl>
            <Select
                value={disabled ? "" : valueLocal}
                name={fieldName}
                onChange={handleChange}
                disabled={disabled}
                displayEmpty={true}
                renderValue={(value) => value ?
                    <LocalTranslatedText language={"en"} text={optionLabels[options.indexOf(value)]} /> :
                    <span className={"placeholder"}><LocalTranslatedText language={"en"} text={placeholder} /></span>}
            >
                <MenuItem value={""}>{emptyLabel ?? "None"}</MenuItem>
                {
                    options.map((optionStr, optionIndex) =>
                        <MenuItem value={optionStr} key={optionStr}>
                            <LocalTranslatedText language={"en"} text={optionLabels[optionIndex]} />
                        </MenuItem>
                    )
                }
            </Select>
        </FormControl>
    </div>;
}

export default BasicLanguageSelector;

