import "./DefaultOutboundRulesPage.scss";
import React, { useCallback, useEffect, useState } from "react";
import { PageWrapperWithMenu } from "../../frontend/elements/PageWrapperWithMenu";
import { PageHeader } from "../../frontend/elements/PageHeader";
import { SpinnerButton } from "../../frontend/elements/SpinnerButton";
import { useAPI } from "../../frontend/components/APIProvider";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";
import OutboundRulesEditor from "./OutboundRulesEditor";
import { useFrontendConfiguration } from "../../frontend/components/FrontendConfigurationProvider";

export const DefaultOutboundRulesPage = () => {
  const config = useFrontendConfiguration();

  const api = useAPI();
  const [formData, setFormData] = useState({});

  useEffect(() => {
    api.getDefaultOutboundRules().then((data) => {
      setFormData(data);
    });
  }, [api]);

  const handleChange = useCallback(
    (newOutboundRules) => {
      const newFormData = {
        ...formData,
        outbound_rules: newOutboundRules,
      };
      setFormData(newFormData);
    },
    [formData]
  );

  const saveDefaultOutboundRules = useCallback(
    (e) => {
      e.preventDefault();
      return api.saveDefaultOutboundRules(formData);
    },
    [api, formData]
  );

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={`${config.frontend.default_outbound_rules_word} Rules`}
      />
      <div className='default-outbound-rules-page'>
        <div className={"buttons-bar"}>
          <OutboundRulesEditor
            value={formData.outbound_rules ?? []}
            onChange={handleChange}
            dayRelativityMode={"before"}
            outboundWord={config.frontend.default_outbound_rules_word}
            enableRepeat={
              config.frontend.enable_repeat_on_default_outbound_rules
            }
            enableOutreachPrompt={
              config.frontend.enable_prompt_on_default_outbound_rules
            }
          />

          <SpinnerButton
            variant='contained'
            color='primary'
            type='submit'
            onClick={saveDefaultOutboundRules}
          >
            <LocalTranslatedText language={"en"} text='Save' />
          </SpinnerButton>
        </div>
      </div>
    </PageWrapperWithMenu>
  );
};

export default DefaultOutboundRulesPage;
