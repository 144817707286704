import React, {useState, useCallback, useMemo} from 'react';
import {Select, MenuItem, TextField} from '@mui/material';
import {StoryFormWidgetContainer} from "./StoryFormWidgetContainer";
import "./StoryEnvironmentSelector.scss";
import _ from "lodash";

const allowedOptions = [
    'Spooky Forest',
    'Medieval Castle',
    'Bustling City',
    'Relaxing Farm',
    'Space Station',
    'Haunted House',
    'Desert Oasis',
    'Underwater Kingdom',
    'Arctic Village',
    'Jungle Ruins',
    'Other'
];

export const StoryEnvironmentSelector = ({value, onChange}) => {
    let initialSelected = value;
    let initialCustom = "";
    // Fill in a default value if none is provided
    if (!initialSelected) {
        initialSelected = 'Scary Forest';
    }
    // If the value isn't in the list of allowed values, we set environment
    // to "Other" allowing the user to type in their own value
    if (!allowedOptions.includes(initialSelected)) {
        initialSelected = 'Other';
        initialCustom = value;
    }

    const [selectedEnvironment, setSelectedEnvironment] = useState(initialSelected);
    const [customEnvironment, setCustomEnvironment] = useState(initialCustom);

    const onChangeDebounced = useMemo(() => _.debounce(onChange, 500), [onChange]);

    const handleSelectChange = useCallback((event) => {
        setSelectedEnvironment(event.target.value);
        onChange(event.target.value);
    }, [onChange]);

    const handleTextChange = useCallback((event) => {
        setCustomEnvironment(event.target.value);
        onChangeDebounced(event.target.value);
    }, [onChangeDebounced]);

    return (
        <StoryFormWidgetContainer label={"Environment"} className={"story-environment-selector"}>
            <Select
                labelId="environment-label"
                id="environment-select"
                value={selectedEnvironment}
                onChange={handleSelectChange}
            >
                {
                    allowedOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))
                }
            </Select>
            {selectedEnvironment === 'Other' && (
                <TextField
                    id="other-environment"
                    label="Custom Environment"
                    onChange={handleTextChange}
                    value={customEnvironment}
                    placeholde={"Describe in one sentence"}
                    fullWidth={true}
                />
            )}
        </StoryFormWidgetContainer>
    );
};