import React from "react";
import { useAPI } from "../../../frontend/components/APIProvider";
import { useNavigate } from "react-router-dom";
import { TableView } from "../../../frontend/elements/TableView";

export default function IntakeTable() {
  const api = useAPI();
  const navigate = useNavigate();

  const handleFilledIntakeRowClicked = (filledIntake) => {
    navigate(`/filled_intakes/${filledIntake.id}`);
  };

  return (
    <TableView
      schemaAPI={api.getFilledIntakeSchema}
      fetchAPI={api.getFilledIntakes}
      onRowClicked={handleFilledIntakeRowClicked}
    />
  );
}
