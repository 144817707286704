import "./OutboundStateViewer.scss";
import React from "react";
import Typography from "@mui/material/Typography";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";
import { OutboundAttemptRecordsList } from "./OutboundAttemptRecordsList";
import { ScheduledOutboundList } from "./ScheduledOutboundList";

export const OutboundStateViewer = ({ outboundState, outboundWord }) => {
  if (!outboundState) {
    return null;
  }

  if (!outboundWord) {
    outboundWord = "Outbound";
  }

  return (
    <div className='outbound-state-view'>
      <Typography variant={"p"}>
        <strong>
          <LocalTranslatedText
            language={"en"}
            text={`Next Scheduled ${outboundWord}s`}
          />
        </strong>
        &nbsp;
      </Typography>

      <ScheduledOutboundList
        outboundState={outboundState}
        outboundWord={outboundWord}
      />

      <Typography variant={"p"}>
        <strong>
          <LocalTranslatedText
            language={"en"}
            text={`Past ${outboundWord} Attempts`}
          />
        </strong>
        &nbsp;
      </Typography>

      <OutboundAttemptRecordsList
        outboundState={outboundState}
        outboundWord={outboundWord}
      />
    </div>
  );
};

export default OutboundStateViewer;
