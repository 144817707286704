import {useCallback, useEffect, useMemo} from "react";
import StarterKit from "@tiptap/starter-kit";
import Mention from "@tiptap/extension-mention";
import createNodeSuggesterForNode from "./NodeSuggester";
import useChartStore from "../ChartState";
import {EditorContent, useEditor} from "@tiptap/react";
import * as React from "react";
import "./TextEditorWithNodeSuggester.scss";
import Placeholder from "@tiptap/extension-placeholder";


export function TextEditorWithNodeSuggester(props) {
    const nodeId = props.nodeId;
    const onChange = props.onChange;
    const onBlur = props.onBlur;
    const value = props.value;
    const focus = props.focus;

    const {node, getNode} = useChartStore((state) => {
        return {
            node: state.getNode(nodeId),
            getNode: state.getNode,
        }
    });

    const handleOnBlur = useCallback(function handleOnBlur({ editor }) {
        if (onBlur) {
            if (editor.getText().trim()) {
                setTimeout(() => {
                    onBlur();
                }, 200);
            }
        }
    }, [onBlur]);

    const handleOnUpdate = useCallback(function handleOnUpdate({ editor }) {
        if (onChange) {
            onChange(editor.getHTML());
        }
    }, [onChange]);

    const editorConfig = useMemo(() => ({
        editorProps: {
            attributes: {
                class: 'nodrag',
            },
        },
        extensions: [
            StarterKit,
            Mention.configure({
                HTMLAttributes: {
                    class: 'mention',
                },
                suggestion:  createNodeSuggesterForNode(node),
                char: "@",
                renderLabel({ options, node }) {
                    const nodeData = getNode(node.attrs.id);
                    return "@" + nodeData?.displayTitle ?? "Unknown Node";
                }
            }),
            Placeholder.configure({
                placeholder: props.placeholder,
            })
        ],
        content: value,
        editable: true,
        onUpdate: handleOnUpdate,
        onBlur: handleOnBlur
    }), [node, getNode, value, props.placeholder, handleOnBlur, handleOnUpdate]);

    const editor = useEditor(editorConfig);

    useEffect(() => {
        if (editor) {
            if (editor.getHTML() !== value) {
                editor.commands.setContent(value)
            }
        }
    }, [editor, value]);

    useEffect(() => {
        if (editor && focus) {
            // Set the editor to be focused as soon as its created
            editor.commands.focus('end');
        }
    }, [editor, focus]);

    if (!node) {
        return;
    }

    if (!editor) {
        return null
    }

    return (
        <EditorContent
            id={props.id}
            editor={editor}
            className={`${props.className ?? " "} text-editor-with-node-suggester nodrag always-show-caret`}
        />
    );
}