import {NodeResizer} from "reactflow";
import * as React from "react";
import "./NodeHandle.scss";
import "./TransparentNodeResizer.scss";

export function TransparentNodeResizer({nodeId}) {
    return <NodeResizer
        nodeId={nodeId}
        minWidth={400}
        minHeight={250}
        lineClassName={"transparent-node-resizer-line"}
        handleClassName={"transparent-node-resizer-handle"}
    />
}
