import * as React from 'react';
import {TableView} from "../../../frontend/elements/TableView";
import {useAPI} from "../../../frontend/components/APIProvider";
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

export function CustomDataSchemaTable() {
    const api = useAPI();
    const navigate = useNavigate();

    const handleCustomDataSchemaRowClicked = useCallback((customDataSchema) => {
        navigate(`/custom_data_schema/${customDataSchema.id}`);
    }, [navigate]);


    return (
        <TableView
            schemaAPI={api.getCustomDataSchemaSchema}
            fetchAPI={api.getCustomDataSchemas}
            onRowClicked={handleCustomDataSchemaRowClicked}
        />
    );
}
