import React, { useCallback } from "react";
import FormInput from "../../../frontend/elements/form/FormInput";
import FormRoot from "../../../frontend/elements/form/FormRoot";
import FormSection from "../../../frontend/elements/form/FormSection";
import { SpinnerButton } from "../../../frontend/elements/SpinnerButton";
import FormSubFieldRoot from "../../../frontend/elements/form/FormSubFieldRoot";
import "./GeneratedDocumentForm.scss";

export default function GeneratedDocumentForm({
  initialGeneratedDocument,
  onGeneratedDocumentSave,
  onDeletedClicked,
  onRenderClicked,
  saveButtonText,
}) {
  const handleGeneratedDocumentSaved = useCallback(
    (newGeneratedDocumentData) => {
      if (onGeneratedDocumentSave) {
        return onGeneratedDocumentSave(newGeneratedDocumentData);
      }
    },
    [onGeneratedDocumentSave]
  );

  return (
    <div className={"generated-document-form"}>
      <FormRoot
        initialValue={initialGeneratedDocument}
        saveButtonText={saveButtonText ?? "Save Generated Document"}
        onSave={handleGeneratedDocumentSaved}
        extraButtons={
          <>
            {onDeletedClicked ? (
              <SpinnerButton
                variant='contained'
                color='error'
                className={"delete-button"}
                onClick={onDeletedClicked}
              >
                Delete
              </SpinnerButton>
            ) : null}
            {onRenderClicked ? (
              <SpinnerButton
                variant='contained'
                color='success'
                className={"render-button"}
                onClick={onRenderClicked}
              >
                Render PDF
              </SpinnerButton>
            ) : null}
          </>
        }
      >
        <FormSection
          title={"Contact"}
          subtitle={
            "Contact information for the recipient of this generated document."
          }
        >
          <FormInput type={"text"} label={"Name"} fieldName={"name"} />

          <FormInput
            type={"text"}
            label={"Phone Number"}
            fieldName={"phone_number"}
          />

          <FormInput type={"text"} label={"Email"} fieldName={"email"} />
        </FormSection>

        <FormSection
          title={"General"}
          subtitle={"Metadata and titles for the generated documents"}
        >
          <FormInput type={"text"} label={"Title"} fieldName={"title"} />

          <FormInput
            type={"text"}
            label={"Conversation ID"}
            fieldName={"conversation_id"}
          />

          <FormInput
            type={"text"}
            label={"Document Template ID"}
            fieldName={"document_template_id"}
          />
        </FormSection>

        <FormSection
          title={"Contents"}
          subtitle={"Content for the generated document"}
        >
          <FormSubFieldRoot field={"content_variables"}>
            {Object.keys(
              initialGeneratedDocument?.content_variables ?? {}
            )?.map((variable, index) => {
              return (
                <FormInput
                  type={"text"}
                  label={titleCaseFieldName(variable)}
                  fieldName={variable}
                  key={variable}
                  multiline={true}
                  autoSize={true}
                />
              );
            })}
            {Object.keys(initialGeneratedDocument?.content_variables ?? {})
              .length === 0 ? (
              <div>
                <em>No content found</em>
              </div>
            ) : null}
          </FormSubFieldRoot>
        </FormSection>
      </FormRoot>
    </div>
  );
}

function titleCaseFieldName(fieldName) {
  return fieldName
    .split("_")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}
