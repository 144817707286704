import React, { useCallback, useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useChangeFormData, useFormData, useFormOptions } from "../FormRoot";
import moment from "moment/moment";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import LocalTranslatedText from "../../../../translation/frontend/components/LocalTranslatedText";

export function DateTimeInput({ label, example, fieldName, prefillValue }) {
  const formData = useFormData();
  const changeFormData = useChangeFormData();
  const formOptions = useFormOptions();
  const [localValue, setLocalValue] = useState(
    moment.utc(formData[fieldName], "YYYY-MM-DDTHH:mm:ssZ").local()
  );

  const onChangeFormDataDebounced = useMemo(
    () => _.debounce(changeFormData, 500),
    [changeFormData]
  );

  const handleDateChange = useCallback(
    (newDateTime) => {
      setLocalValue(newDateTime);
      onChangeFormDataDebounced(fieldName, newDateTime);
    },
    [fieldName, onChangeFormDataDebounced]
  );

  const defaultValueIfAvailable = useMemo(() => {
    if (prefillValue) {
      return moment.utc(prefillValue, "YYYY-MM-DDTHH:mm:ssZ").local();
    }
    return null;
  }, [prefillValue]);

  const assignedValue = localValue?.isValid()
    ? localValue
    : defaultValueIfAvailable;

  return (
    <div className={"form-input"}>
      <Typography variant={"label"}>
        <LocalTranslatedText language={"en"} text={label} />
      </Typography>

      <div>
        <DateTimePicker
          name={fieldName}
          placeholder={example}
          value={assignedValue}
          openTo='day'
          onChange={handleDateChange}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          disabled={!formOptions.editable}
        />
      </div>
    </div>
  );
}

export default DateTimeInput;
