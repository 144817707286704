import React from "react";
import FormInput from "../../elements/form/FormInput";
import FormSection from "../../elements/form/FormSection";
import FormSubFieldRoot from "../../elements/form/FormSubFieldRoot";

export default function AMQPConfigurationForm() {
    return <FormSection
        title={"AMQP"}
        subtitle={"Configuration for the AMQP server connection for this tenant"}
        collapsable={true}
    >
        <FormSubFieldRoot
            field={"amqp"}
        >
            <FormInput
                type={"text"}
                label={"Host"}
                fieldName={"host"}
                placeholder={"The host of the AMQP server"}
            />

            <FormInput
                type={"number"}
                label={"Port"}
                fieldName={"port"}
                placeholder={"The port to connect to the AMQP server"}
            />

            <FormInput
                type={"text"}
                label={"Username"}
                fieldName={"username"}
                placeholder={"The username to connect to the AMQP server"}
            />

            <FormInput
                type={"text"}
                label={"Password"}
                fieldName={"password"}
                placeholder={"The password to connect to the AMQP server"}
            />

            <FormInput
                type={"text"}
                label={"Virtual Host"}
                fieldName={"vhost"}
                placeholder={"Which virtual host on the AMQP server will be used. All tenants must have a unique vhost, even if they share an AMQP server."}
            />
        </FormSubFieldRoot>
    </FormSection>
}