import * as React from 'react';
import {TableView} from "../../core/frontend/elements/TableView";
import {useAPI} from "../../core/frontend/components/APIProvider";
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

export function ContactsTable() {
    const api = useAPI();
    const navigate = useNavigate();

    const handleContactRowClicked = useCallback((contact) => {
        navigate(`/contacts/${contact.id}`);
    }, [navigate]);


    return (
        <TableView
            schemaAPI={api.getContactSchema}
            fetchAPI={api.getContacts}
            onRowClicked={handleContactRowClicked}
        />
    );
}
