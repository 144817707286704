import * as React from "react";
import { TableView } from "../../../core/frontend/elements/TableView";
import { useAPI } from "../../../core/frontend/components/APIProvider";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export function CustomersTable() {
  const api = useAPI();
  const navigate = useNavigate();

  const handleCustomerRowClicked = useCallback(
    (customer) => {
      navigate(`/customers/${customer.id}`);
    },
    [navigate]
  );

  return (
    <TableView
      schemaAPI={api.getCustomerSchema}
      fetchAPI={api.getCustomers}
      onRowClicked={handleCustomerRowClicked}
    />
  );
}
