import axios from "axios";
import { axiosETAGCache } from "axios-etag-cache";
import { ensureMinimumPromiseResolveTime } from "../../frontend/utils/time";

let globalSmartChainSchema = null;
let globalPromptContextSchema = null;
let globalSmartchainStepTypes = null;
let globalSmartChainBindingSchema = null;

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);
const api = {
  async getPromptContextSchema() {
    if (globalPromptContextSchema) {
      return globalPromptContextSchema;
    }
    const response = await axiosWithETAGCache.get(
      `/smart_chain_engine/prompt_context_schema`
    );
    globalPromptContextSchema = response.data;
    return response.data;
  },
  async getSmartChainStepTypes() {
    if (globalSmartchainStepTypes) {
      return globalSmartchainStepTypes;
    }
    const response = await axiosWithETAGCache.get(
      `/smart_chain_engine/step_types`
    );
    globalSmartchainStepTypes = response.data;
    return response.data;
  },
  async getSmartChainSchema() {
    if (globalSmartChainSchema) {
      return globalSmartChainSchema;
    }
    const response = await axiosWithETAGCache.get(`/smart_chain/schema`);
    globalSmartChainSchema = response.data;
    return response.data;
  },
  async getSmartChains(tableQuery) {
    const queryParams = {
      query: JSON.stringify(tableQuery),
    };

    const response = await axiosWithETAGCache.get(`/smart_chain`, {
      params: queryParams,
    });

    return response.data;
  },
  async getSmartChainSampleContexts(chainName, stepId, orderMode, limit) {
    const queryParams = {
      chain_name: chainName,
      step_id: stepId,
      order_mode: orderMode,
      limit: limit,
    };

    const response = await axiosWithETAGCache.get(
      `/smart_chain_sample_contexts`,
      {
        params: queryParams,
      }
    );

    return response.data;
  },
  async getSmartChainRecentErrors(
    bindingName,
    publishedSmartChainVersionId,
    chainName,
    stepId,
    limit
  ) {
    const queryParams = {
      limit: limit,
    };

    if (bindingName) {
      queryParams.binding_name = bindingName;
    }

    if (publishedSmartChainVersionId) {
      queryParams.published_smart_chain_version_id =
        publishedSmartChainVersionId;
    }

    if (chainName) {
      queryParams.chain_name = chainName;
    }

    if (stepId) {
      queryParams.step_id = stepId;
    }

    const response = await axiosWithETAGCache.get(
      `/smart_chain_recent_errors`,
      {
        params: queryParams,
      }
    );

    return response.data;
  },
  async createNewSmartChain(newSmartChainData) {
    const response = await axios.post(`/smart_chain`, newSmartChainData);

    return response.data;
  },

  async publishSmartChain(chain_name) {
    const response = await ensureMinimumPromiseResolveTime(
      axios.post(`/published_smart_chain_version`, { chain_name }),
      1500
    );

    return response.data;
  },
  async getSmartChain(documentId) {
    const queryParams = {};

    const response = await axiosWithETAGCache.get(
      `/smart_chain/${documentId}`,
      {
        params: queryParams,
      }
    );

    return response.data;
  },
  async saveSmartChain(document) {
    const response = await axios.put(`/smart_chain/${document.id}`, document);
    return response.data;
  },
  async deleteSmartChain(documentId) {
    const response = await axios.delete(`/smart_chain/${documentId}`);

    return response.data;
  },
  async refreshSmartChain(smartChain) {
    const response = await axios.post(`/smart_chain/refresh`, smartChain);
    return response.data;
  },
  async executeSmartChain(smartChain, inputData) {
    const data = {
      smart_chain: smartChain,
      input_data: inputData,
    };

    const response = await axios.post(`/smart_chain/execute`, data);
    return response.data;
  },
  async executeSmartChainStep(smartChainStep, inputData) {
    const data = {
      smart_chain_step: smartChainStep,
      input_data: inputData,
    };

    const response = await axios.post(`/smart_chain_step/execute`, data);
    return response.data;
  },
  async getSmartChainBindingSchema() {
    if (globalSmartChainBindingSchema) {
      return globalSmartChainBindingSchema;
    }
    const response = await axiosWithETAGCache.get(
      `/smart_chain_binding/schema`
    );
    globalSmartChainBindingSchema = response.data;
    return response.data;
  },
  async getSmartChainBindings(tableQuery) {
    const queryParams = {
      query: JSON.stringify(tableQuery),
    };

    const response = await axiosWithETAGCache.get(`/smart_chain_binding`, {
      params: queryParams,
    });

    return response.data;
  },
  async createNewSmartChainBinding(newSmartChainBindingData) {
    const response = await axios.post(
      `/smart_chain_binding`,
      newSmartChainBindingData
    );

    return response.data;
  },
  async getSmartChainBinding(smartChainBindingId) {
    const queryParams = {};

    const response = await axiosWithETAGCache.get(
      `/smart_chain_binding/${smartChainBindingId}`,
      {
        params: queryParams,
      }
    );

    return response.data;
  },
  async saveSmartChainBinding(smartChainBinding) {
    const response = await axios.put(
      `/smart_chain_binding/${smartChainBinding.id}`,
      smartChainBinding
    );
    return response.data;
  },
  async deleteSmartChainBinding(smartChainBindingId) {
    const response = await axios.delete(
      `/smart_chain_binding/${smartChainBindingId}`
    );

    return response.data;
  },
  async getPublishedSmartChainVersions(chain_name) {
    const queryParams = { chain_name };

    const response = await axiosWithETAGCache.get(
      `/published_smart_chain_version`,
      {
        params: queryParams,
      }
    );

    return response.data;
  },
};

export default api;
