import * as React from "react";
import {isFunction} from "lodash";



/**
 * This hook is used to delay the state change of a component for a certain amount of time.
 *
 * @param targetState
 * @param stateChangeDelayTime The amount of time that the state change will be delayed before
 *                            the component actually changes state.
 *
 *                            This can also be a function. If it is a function, it will be of
 *                            the form: (previousState, targetState) => delayTimeMs, allowing
 *                            the caller to customize how much delay there is depending on
 *                            what state transition is occurring.
 * @returns An object containing multiple variables.
 *              - state: The current display state of the component
 *              - lastStateChangeTime: The time that the last state change occurred.
 */
export function useDelayedStateChange(targetState, stateChangeDelayTime) {
    const [state, setState] = React.useState({
        currentState: targetState,
        previousState: targetState,
        lastStateChangeTime: null,
    });
    const [, setLastUpdateDate] = React.useState(null);

    const {
        currentState,
        previousState,
        lastStateChangeTime,
    } = state;

    let nextStateChangeDelayTimeMs = 0;
    let lastStateChangeDelayTimeMs = 0;
    if (isFunction(stateChangeDelayTime)) {
        nextStateChangeDelayTimeMs = stateChangeDelayTime(previousState, targetState);
        lastStateChangeDelayTimeMs = stateChangeDelayTime(previousState, currentState);
    } else {
        nextStateChangeDelayTimeMs = stateChangeDelayTime;
        lastStateChangeDelayTimeMs = stateChangeDelayTime;
    }

    const forceRerender = () => {
        // This just forces the widget to rerender by updating a state variable,
        // even if it isn't used for anything.
        setLastUpdateDate(new Date());
    };

    const timeElapsed = lastStateChangeTime === null ? nextStateChangeDelayTimeMs + 1 : ((new Date()).getTime() - lastStateChangeTime.getTime());

    if (targetState !== currentState) {
        if (timeElapsed > lastStateChangeDelayTimeMs) {
            // We have a state change
            setState({
                currentState: targetState,
                previousState: currentState,
                lastStateChangeTime: new Date(),
            });

            setTimeout(() => {
                forceRerender();
            }, nextStateChangeDelayTimeMs + 1);
        } else {
            // We have a new current state, but previous state stays the same.
            setState({
                currentState: targetState,
                previousState: previousState,
                lastStateChangeTime: new Date(),
            });

            setTimeout(() => {
                forceRerender();
            }, nextStateChangeDelayTimeMs + 1);
        }
    } else if (targetState === currentState) {
        if (currentState !== previousState) {
            if (timeElapsed > nextStateChangeDelayTimeMs) {
                // We have a new current state, but previous state stays the same.
                setState({
                    currentState: targetState,
                    previousState: currentState,
                    lastStateChangeTime: lastStateChangeTime,
                });
            }
        }
    }

    let displayState;
    if (timeElapsed > lastStateChangeDelayTimeMs) {
        displayState = currentState;
    } else {
        displayState = previousState;
    }

    return {
        state: displayState,
        lastStateChangeTime: lastStateChangeTime === null ? new Date() : lastStateChangeTime
    };
}