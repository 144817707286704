import "./FormSection.scss";
import Typography from "@mui/material/Typography";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import React from "react";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";

export function FormSection({
  title,
  subtitle,
  children,
  collapsable = false,
  noPadding = false,
}) {
  const [open, setOpen] = React.useState(!collapsable);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <div className={"form-section"}>
      {title || subtitle ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "left",
            verticalAlign: "top",
          }}
        >
          {title ? (
            <h2>
              <LocalTranslatedText language={"en"} text={title} />
            </h2>
          ) : null}
          {collapsable ? (
            <Button onClick={handleClick}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </Button>
          ) : null}
        </div>
      ) : null}
      {subtitle ? (
        <Typography variant='subtitle1'>
          <LocalTranslatedText language={"en"} text={subtitle} />
        </Typography>
      ) : null}
      {
        <Collapse in={open} timeout='auto' unmountOnExit>
          {children}
        </Collapse>
      }
    </div>
  );
}

export default FormSection;
