import React, {useState, useCallback, useMemo} from 'react';
import {RadioGroup, FormControlLabel, Radio, TextField} from '@mui/material';
import {StoryFormWidgetContainer} from "./StoryFormWidgetContainer";
import "./MainCharacterNameSelector.scss";
import _ from "lodash";

export const MainCharacterTypeSelector = ({value, onChange}) => {
    const [selectedOption, setSelectedOption] = useState(value);

    const handleRadioChange = useCallback((event) => {
        setSelectedOption(event.target.value);
        onChange(event.target.value);
    }, [onChange]);

    return (
        <StoryFormWidgetContainer label="Main Character" className={"main-character-type-selector"}>
            <RadioGroup
                aria-label="main-character-type"
                name="main-character-type"
                value={selectedOption}
                onChange={handleRadioChange}
            >
                <FormControlLabel value="your kid" control={<Radio/>} label="Your Kid"/>
                <FormControlLabel value="your pet" control={<Radio/>} label="Your Pet"/>
                <FormControlLabel value="generate randomly" control={<Radio/>} label="Generate Randomly"/>
            </RadioGroup>
        </StoryFormWidgetContainer>
    );
};