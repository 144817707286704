import {parsePhoneNumberFromString} from 'libphonenumber-js'

export function formatPhoneNumberHumanReadable(phoneNumber) {
    if (!phoneNumber) {
        return '';
    }

    const parsed = parsePhoneNumberFromString(phoneNumber, 'US');
    if (!parsed) {
        return phoneNumber;
    } else {
        return parsed.formatInternational();
    }
}

