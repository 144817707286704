import React from "react";
import FormInput from "../../elements/form/FormInput";
import FormSection from "../../elements/form/FormSection";
import FormSubFieldRoot from "../../elements/form/FormSubFieldRoot";

export default function StripeConfigurationForm() {
    return (
        <FormSection
            title={"Stripe Integration"}
            subtitle={"Configuration for the Stripe API Integration for this tenant"}
            collapsable={true}
        >
            <FormSubFieldRoot field={"stripe"}>
                <FormInput
                    type={"text"}
                    label={"API Key"}
                    fieldName={"api_key"}
                    placeholder={"The API key used to connect to the Stripe API"}
                />
                <FormInput
                    type={"string_list"}
                    label={"Main Subscription Usage Price Lookup Keys"}
                    fieldName={"main_subscription_usage_price_lookup_keys"}
                    placeholder={"A list of keys that should be used to look up the prices to charge based on various types of usage."}
                    sx={{ width: '60%' }}
                />
                <FormInput
                    type={"string_list"}
                    label={"Main Subscription Per Seat Price Lookup Keys"}
                    fieldName={"main_subscription_per_seat_price_lookup_keys"}
                    placeholder={"A list of keys that should be used to look up the prices to charge based on the number of users."}
                    sx={{ width: '60%' }}
                />
                <FormInput
                    type={"text"}
                    label={"Billing Mode"}
                    fieldName={"billing_mode"}
                    placeholder={"The billing mode that should be used for the main subscription. Options are 'subscription' or 'usage'."}
                />
                <FormInput
                    type={"text"}
                    label={"Webhook Secret"}
                    fieldName={"webhook_secret"}
                    placeholder={"The secret that should be used to verify incoming webhooks from Stripe."}
                />
                <FormInput
                    type={"text"}
                    label={"Billing Portal Return URL"}
                    fieldName={"billing_portal_return_url"}
                    placeholder={"The return url for the billing portal"}
                />
            </FormSubFieldRoot>
        </FormSection>
    );
}