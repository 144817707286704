import {PageWrapperWithMenu} from "../../core/frontend/elements/PageWrapperWithMenu";
import React from "react";
import "../../core/frontend/pages/HomePage.scss";
import {useNavigate} from "react-router-dom";
import {useAPI} from "../../core/frontend/components/APIProvider";
import {ContactsTable} from "./ContactsTable";
import "./ContactListPage.scss";
import {PageHeader} from "../../core/frontend/elements/PageHeader";
import {WhiteButton} from "../../core/frontend/elements/WhiteButton";
import Paper from "@mui/material/Paper";
import {useCallback} from "react";


export default function ContactListPage() {
    const api = useAPI();
    const navigate = useNavigate();

    const handleNewContactClicked = useCallback(async () => {
        const contact = await api.createNewContact();
        navigate(`/contacts/${contact.id}`);
    }, [api, navigate]);


    return <PageWrapperWithMenu>
        <PageHeader title={"Contacts"}>
            <WhiteButton onClick={handleNewContactClicked}>
                Add New Contact
            </WhiteButton>
        </PageHeader>
        <div className={"contact-list-page"}>
            <div className={"contact-table-wrapper"}>
                <Paper className={"main-page-contents"}>
                    <ContactsTable/>
                </Paper>
            </div>
        </div>
    </PageWrapperWithMenu>
}

