import {useEffect, useState} from "react";
import {useAPI} from "../../../frontend/components/APIProvider";
import CircularProgress from "@mui/material/CircularProgress";
import "./SmartChainStepInputFieldList.scss";
import {SchemaFieldList} from "./SchemaFieldList";
import {recursivelyModifySchemaForFrontend} from "./schema_utils";


export default function SmartChainStepInputFieldList({smartChain, selectedSmartChainStepIndex}) {
  const api = useAPI();
  const [promptContextSchema, setPromptContextSchema] = useState(null);
  const [refreshedCumulativePriorOutput, setRefreshedCumulativePriorOutput] = useState(null);
  const step = smartChain.steps[selectedSmartChainStepIndex];

  useEffect(() => {
    async function getSchema() {
      // Refresh the whole smart chain, then find this one step in the refreshed result
      const newSmartChain = await api.refreshSmartChain(smartChain);
      const refreshedStepInfo = newSmartChain.steps.find(s => s.step_id === step.step_id);

      // Determine if there is a prior step
      if (refreshedStepInfo.step_index > 0) {
        // Fetch the prior step
        const priorStep = newSmartChain.steps[refreshedStepInfo.step_index - 1];
        const priorStepOutputSchema = priorStep.cumulative_output_schema;
        priorStepOutputSchema.title = "Cumulative Prior Output";
        recursivelyModifySchemaForFrontend(priorStepOutputSchema);
        setRefreshedCumulativePriorOutput(priorStepOutputSchema);
      } else {
        setRefreshedCumulativePriorOutput(null);
      }
    }

    getSchema();
  }, [step, api, smartChain]);

  useEffect(() => {
    if (!promptContextSchema) {
      api.getPromptContextSchema().then((promptContextSchema) => {
        setPromptContextSchema(promptContextSchema);
      });
    }
  }, [promptContextSchema, api]);

  if (!promptContextSchema) {
    return <div>
      <CircularProgress/>
    </div>
  }

  return <div className={"smart-chain-step-input-field-list"}>
    <h5>Prompt Context</h5>
    {
      promptContextSchema ?
        <SchemaFieldList
          schema={promptContextSchema}
          isOpenByDefault={true}
        />
        : null
    }
    <br/>
    {
      refreshedCumulativePriorOutput ? <>
        <h5>From Previous Steps</h5>
        <SchemaFieldList
          key={step.step_id}
          schema={refreshedCumulativePriorOutput}
          hideFirstLayer={true}
        />
      </> : null
    }
  </div>;
}
