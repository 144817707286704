import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import "./CleaningStrategySelector.scss";
import {useCallback, useMemo} from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const knownStrategies = [
    {
        title: "Remove wrapping quotes",
        value: "remove_wrapping_quotes",
    },
    {
        title: "Remove first line",
        value: "remove_first_line",
    },
    {
        title: "Remove bullet points",
        value: "remove_bullet_points",
    },
    {
        title: "Remove line heading",
        value: "remove_line_heading",
    }
];

export default function CleaningStrategySelector({value, onChange}) {
    if (!value) {
        value = "";
    }

    const handleChange = useCallback((event) => {
        if (event.target.value === "default") {
            onChange("");
        } else {
            onChange(event.target.value.join(","));
        }
    }, [onChange]);

    let valuesSplit = useMemo(() => {
        if (value === "") {
            return [];
        }
        return value.split(",")
    }, [value]);

    return (
        <div className={"cleaning-strategy-selector nodrag"}>
            <Select
                multiple
                value={valuesSplit}
                onChange={handleChange}
                input={<OutlinedInput label="Tag"/>}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
            >
                {knownStrategies.map((cleaningStrategyObject) => (
                    <MenuItem key={cleaningStrategyObject.name} value={cleaningStrategyObject.value}>
                        <Checkbox checked={value.indexOf(cleaningStrategyObject.value) > -1}/>
                        <ListItemText primary={cleaningStrategyObject.title}/>
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
}
