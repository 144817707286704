import React from "react";
import _ from "lodash";
import {
  formDataProvider,
  changeMultipleFormDataProvider,
  changeFormDataProvider,
  useFormData,
  useChangeFormData,
} from "./FormRoot";
import "./FormArrayItemRoot.scss";

export function FormArrayItemRoot({
  editable,
  field,
  itemIndex,
  children,
  defaultItemValue,
}) {
  editable = editable ?? true;

  const arrayData = useFormData()[field] ?? [];
  const itemData = arrayData[itemIndex] ?? _.clone(defaultItemValue ?? {});

  const changeFormDataOriginal = useChangeFormData();

  const changeFormData = React.useCallback(
    (key, newValue) => {
      if (editable) {
        const newData = {
          ...itemData,
          [key]: newValue,
        };
        const newArray = [
          ...arrayData.slice(0, itemIndex),
          newData,
          ...arrayData.slice(itemIndex + 1),
        ];

        changeFormDataOriginal(field, newArray);
      }
    },
    [editable, arrayData, changeFormDataOriginal, itemData]
  );

  const changeMultipleFormData = React.useCallback(
    (changes) => {
      if (editable) {
        const newData = {
          ...itemData,
          ...changes,
        };

        const newArray = [
          ...arrayData.slice(0, itemIndex),
          newData,
          ...arrayData.slice(itemIndex + 1),
        ];

        changeFormDataOriginal(field, newArray);
      }
    },
    [editable, itemData, arrayData, changeFormDataOriginal, field, itemIndex]
  );

  return (
    <div className={"form-array-item-root"}>
      <formDataProvider.Provider value={itemData}>
        <changeFormDataProvider.Provider value={changeFormData}>
          <changeMultipleFormDataProvider.Provider
            value={changeMultipleFormData}
          >
            {children}
          </changeMultipleFormDataProvider.Provider>
        </changeFormDataProvider.Provider>
      </formDataProvider.Provider>
    </div>
  );
}

export default FormArrayItemRoot;
