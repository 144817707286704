import React, {useCallback, useEffect} from "react";
import "@grapesjs/studio-sdk/style";
import "./DocumentTemplateSettingsTab.scss";
import {useAPI} from "../../../frontend/components/APIProvider";
import {JSONSchemaForm} from "../../../frontend/elements/JSONSchemaForm";
import Paper from "@mui/material/Paper";
import {PageHeader} from "../../../frontend/elements/PageHeader";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";
import TableElementLookupSelector from "../../../frontend/elements/TableElementLookupSelector";
import {TextField} from "@mui/material";
import _ from "lodash";


export default function DocumentTemplateSettingsTab({documentTemplateId}) {
  const [documentTemplate, setDocumentTemplate] = React.useState(null);
  const api = useAPI();

  const saveDocumentTemplate = useCallback(async (newTemplateData) => {
    await api.saveDocumentTemplate(documentTemplateId, newTemplateData);
  }, [api]);

  const saveDocumentTemplateDebounced = useCallback(_.debounce(saveDocumentTemplate, 1000), [saveDocumentTemplate]);

  useEffect(() => {
    api.getDocumentTemplate(documentTemplateId).then((documentTemplate) => {
      setDocumentTemplate(documentTemplate);
    });
  }, [api, documentTemplateId]);

  const handleSubmit = useCallback(async ({formData}) => {
    const pdfData = await api.renderedDocumentTemplate(documentTemplateId, formData);

    // Now we need to show the pdfData to the user
    const blob = new Blob([pdfData], {type: 'application/pdf'});
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `generated-document.pdf`;
    a.target = "_blank";
    a.click();
    URL.revokeObjectURL(url);

  }, [api, documentTemplateId]);

  const handleChangeSmartChainBinding = useCallback(async (newSmartChainBindingName) => {
    const newDocumentTemplateData = {
      ...documentTemplate,
      smart_chain_binding_name: newSmartChainBindingName
    }

    setDocumentTemplate(newDocumentTemplateData);
    saveDocumentTemplateDebounced(newDocumentTemplateData);
  }, [documentTemplate, saveDocumentTemplateDebounced]);

  const handleTitleChange = useCallback((newTitle) => {
    const newDocumentTemplateData = {
      ...documentTemplate,
      title: newTitle
    }

    setDocumentTemplate(newDocumentTemplateData);
    saveDocumentTemplateDebounced(newDocumentTemplateData);
  }, [documentTemplate, saveDocumentTemplateDebounced]);

  if (!documentTemplate) {
    return null;
  }

  return (
    <>
      <PageHeader
        title={
          <LocalTranslatedText language={"en"} text='Document Template Settings'/>
        }
      />
      <div className='document-template-settings-page'>
        <Paper elevation={3}>
          <h2>Title</h2>

          <TextField
            value={documentTemplate.title}
            onChange={(e) => handleTitleChange(e.target.value)}
          />

          <h2>Smart Chain Binding</h2>
          <TableElementLookupSelector
            label={"Smart chain binding used to generate document Data from a conversation"}
            getSuggestions={api.getSmartChainBindings}
            getSingleItem={api.getSmartChainBinding}
            idFieldName={'binding_name'}
            searchFieldName={'binding_name'}
            displayFieldName={'binding_name'}
            value={documentTemplate.smart_chain_binding_name}
            onChange={handleChangeSmartChainBinding}
            multiple={false}
          />
        </Paper>

        <Paper elevation={3}>
          <h2>Test Document Generator</h2>
          <JSONSchemaForm
            schema={documentTemplate.input_schema}
            onSubmit={handleSubmit}
            submitText={"Generate"}
          />
        </Paper>
      </div>
    </>
  );
}