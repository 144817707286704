import "./DefaultIntakeConfigurationPage.scss";
import React, {useCallback, useEffect, useState} from "react";
import {PageWrapperWithMenu} from "../../../frontend/elements/PageWrapperWithMenu";
import {PageHeader} from "../../../frontend/elements/PageHeader";
import {SpinnerButton} from "../../../frontend/elements/SpinnerButton";
import {useAPI} from "../../../frontend/components/APIProvider";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";
import TableElementLookupSelector from "../../../frontend/elements/TableElementLookupSelector";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";

export const DefaultIntakeConfigurationPage = () => {
  const api = useAPI();
  const [defaultIntakeConfiguration, setDefaultIntakeConfiguration] = useState(null);

  useEffect(() => {
    api.getDefaultIntakeConfiguration().then((data) => {
      setDefaultIntakeConfiguration(data);
    });
  }, [api]);

  const handleChange = useCallback(
    (newDefaultIntakeConfigurationId) => {
      const newFormData = {
        ...defaultIntakeConfiguration,
        default_intake_id: newDefaultIntakeConfigurationId,
      };
      setDefaultIntakeConfiguration(newFormData);
    },
    [defaultIntakeConfiguration]
  );

  const saveDefaultIntakeConfiguration = useCallback(
    (e) => {
      e.preventDefault();
      return api.saveDefaultIntakeConfiguration(defaultIntakeConfiguration);
    },
    [api, defaultIntakeConfiguration]
  );

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={`Intake Configuration`}
      />
      <div className='default-intake-configuration-page'>
        {
          !defaultIntakeConfiguration ?
            <CircularProgress/>
            : null
        }
        {
          defaultIntakeConfiguration ?
            <Paper elevation={4}>
              <TableElementLookupSelector
                label={"Default Intake"}
                getSuggestions={api.getIntakes}
                getSingleItem={api.getIntake}
                idFieldName={'id'}
                searchFieldName={'title'}
                displayFieldName={'title'}
                value={defaultIntakeConfiguration?.default_intake_id}
                onChange={handleChange}
                multiple={false}
              />

              <SpinnerButton
                variant='contained'
                color='primary'
                type='submit'
                onClick={saveDefaultIntakeConfiguration}
              >
                <LocalTranslatedText language={"en"} text='Save'/>
              </SpinnerButton>
            </Paper>
            : null
        }
      </div>
    </PageWrapperWithMenu>
  )
    ;
};

export default DefaultIntakeConfigurationPage;
