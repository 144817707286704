import { PageWrapperWithMenu } from "../../../core/frontend/elements/PageWrapperWithMenu";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAPI } from "../../../core/frontend/components/APIProvider";
import { ContentSampleTable } from "./ContentSampleTable";
import "./ContentSampleListPage.scss";
import { PageHeader } from "../../../core/frontend/elements/PageHeader";
import { WhiteButton } from "../../../core/frontend/elements/WhiteButton";
import Paper from "@mui/material/Paper";
import { useCallback } from "react";

export default function ContentSampleListPage() {
  const api = useAPI();
  const navigate = useNavigate();

  const handleNewContentSampleClicked = useCallback(async () => {
    navigate(`/content_samples/new`);
  }, [navigate]);

  return (
    <PageWrapperWithMenu>
      <PageHeader title={"Content Samples"}>
        <WhiteButton onClick={handleNewContentSampleClicked}>
          Add New Content Sample
        </WhiteButton>
      </PageHeader>
      <div className={"content-sample-list-page"}>
        <div className={"content-sample-table-wrapper"}>
          <Paper className={"main-page-contents"}>
            <ContentSampleTable />
          </Paper>
        </div>
      </div>
    </PageWrapperWithMenu>
  );
}
