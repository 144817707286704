import "./NodeFilePreview.scss";
import * as React from "react";
import BehaviorTracker from "../../../frontend/components/BehaviorTracker";
import CircularProgress from "@mui/material/CircularProgress";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import useChartStore from "../ChartState";
import { Button } from "@mui/material";
import { apiAccessTokenContext } from "../../../frontend/components/api";
import { useContext } from "react";
import {useFrontendConfiguration} from "../../../frontend/components/FrontendConfigurationProvider";

function NodeFilePreview({ nodeId }) {
  const config = useFrontendConfiguration();

  const { node, isFileUploadingForNode, triggerFileUploadFlowForNode } =
    useChartStore((state) => {
      return {
        node: state.getNode(nodeId),
        isFileUploadingForNode: state.isFileUploadingForNode(nodeId),
        triggerFileUploadFlowForNode: state.triggerFileUploadFlowForNode,
      };
    });

  const accessToken = useContext(apiAccessTokenContext);

  const onUploadClick = function onUploadClick() {
    BehaviorTracker.trackInteraction({
      id: "upload-file-file-preview",
      mixpanel: "upload-file-file-preview",
    });

    return triggerFileUploadFlowForNode(nodeId);
  };

  let imageUrl = `${config.frontend.backend_api_url}file/${node.data.fileId}/preview?`;

  if (accessToken) {
    imageUrl += `token=${accessToken}`;
  }

  if (!node) {
    return null;
  }

  return (
    <div className={"node-file-preview"}>
      {node.data.fileId ? (
        <img src={imageUrl} alt={`File Preview for: ${node.fileName}`} />
      ) : null}

      {!node.data.fileId ? (
        <div className={"no-file-text-wrapper"}>
          <span>No file uploaded...</span>
        </div>
      ) : null}
      <div
        className={`file-upload-button-wrapper ${
          isFileUploadingForNode ? "uploading" : ""
        }`}
        onClick={() => onUploadClick()}
      >
        {isFileUploadingForNode ? <CircularProgress size={36} /> : null}
        {!isFileUploadingForNode ? (
          <Button
            className={"nodrag file-upload-button"}
            variant='contained'
            endIcon={<UploadFileIcon />}
          >
            Upload
          </Button>
        ) : null}
      </div>
    </div>
  );
}

export default NodeFilePreview;
