import React, {useEffect} from "react";
import {api} from "./api";
import ApplicationBootingPage from "../pages/ApplicationBootingPage";


/**
 * The wakeup wrapper calls the home endpoint of the API as soon as the application starts
 * up to ensure the backend is alive and responding to requests.
 *
 * It's meant to trigger the app to start cold-booting if it needs to, and will also
 * reset the connect if you have switched in-between tenants with the same public IP
 * (this is a bit of a hack fix for the backend miscorrectly identifying the tenant
 * for a connection when the public IP is the same).
 */
export const BackendWakeupWrapper = ({children}) => {
    const [isBooted, setIsBooted] = React.useState(false);

    useEffect(() => {
        if (!isBooted) {
            api.home().then((data) => {
                setIsBooted(true);
            }).catch(() => {
                // Ignore errors
            });
        }
    }, [isBooted]);

    if (!isBooted) {
        return <ApplicationBootingPage />
    } else {
        return <>
            {children}
        </>;
    }
};

