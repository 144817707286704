import {PageWrapperWithMenu} from "../elements/PageWrapperWithMenu";
import React, {useCallback, useState} from "react";
import Paper from "@mui/material/Paper";
import {PageHeader} from "../elements/PageHeader";
import {TextField} from "@mui/material";
import axios from "axios";
import "./ImpersonatePage.scss";
import {useAPI} from "../components/APIProvider";
import {useNavigate} from "react-router-dom";
import {SpinnerButton} from "../elements/SpinnerButton";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";

export default function ImpersonatePage() {
  const api = useAPI();
  const navigate = useNavigate();
  const [userId, setUserId] = useState('');

  const handleUserIdChange = useCallback((event) => {
    setUserId(event.target.value);
  }, [setUserId]);

  const handleImpersonateClicked = useCallback(() => {
    axios.defaults.headers.common['X-User-Id'] = userId;
    api.resetCachedUserDetails();
    navigate('/');
  }, [userId]);

  return <PageWrapperWithMenu>
    <PageHeader title={"Impersonate"} />
    <div className="impersonate-page">
      <Paper className={"main-page-contents"}>

        <TextField
          id="outlined-basic"
          label="User Id"
          variant="outlined"
          value={userId}
          fullWidth={true}
          onChange={handleUserIdChange}
        />

        <SpinnerButton
          variant="contained"
          color="primary"
          onClick={handleImpersonateClicked}
        >
          <LocalTranslatedText
            language={"en"}
            text={"Impersonate"}
          />
        </SpinnerButton>
      </Paper>
    </div>
  </PageWrapperWithMenu>
}

