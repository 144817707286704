import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import SaveIcon from "@mui/icons-material/Save";
import PublishIcon from "@mui/icons-material/Publish";
import "./SmartChainEditorTopBar.scss";
import SmartChainStepTypeSelector from "./SmartChainStepTypeSelector";
import { SpinnerIconButton } from "../../../frontend/elements/SpinnerIconButton";
import { IconButton } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import InputIcon from "@mui/icons-material/Input";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import Icon from "@mui/material/Icon";
import { useAPI } from "../../../frontend/components/APIProvider";
import { useCallback, useEffect } from "react";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import OutputIcon from "@mui/icons-material/Output";
import PublishSmartChainDialog from "./PublishSmartChainDialog";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import HardwareIcon from "@mui/icons-material/Hardware";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import ExportSmartChainDialog from "./ExportSmartChainDialog";
import HistoryIcon from "@mui/icons-material/History";

const FieldWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const FieldIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export default function SmartChainEditorTopBar({
  smartChain,
  selectedSmartChainStep,
  onSaveClick,
  onChainNameChanged,
  onStepTypeChange,
  onOpenPromptContextClicked,
  onOpenChainStepsClicked,
  isContextOpen,
  isChainStepsOpen,
  onSwitchLightDarkModeClick,
  isDarkMode,
  hasUnsavedChanges,
  isShowingPromptOutput,
  onShowPromptOutputClicked,
  onModelListClicked,
  onActionListClicked,
  handleShowPublishedSmartChainDialog,
}) {
  const api = useAPI();
  const [stepTypes, setStepTypes] = React.useState({});
  const [isPublishDialogOpen, setIsPublishDialogOpen] = React.useState(false);
  const [isExportDialogOpen, setIsExportDialogOpen] = React.useState(false);

  useEffect(() => {
    api.getSmartChainStepTypes().then((types) => {
      const typesDict = {};
      types.forEach((type) => {
        typesDict[type.name] = type;
      });
      setStepTypes(typesDict);
    });
  }, [api]);

  const handleTogglePublishDialog = useCallback(() => {
    setIsPublishDialogOpen(!isPublishDialogOpen);
  }, [isPublishDialogOpen]);

  const handleToggleExportDialog = useCallback(() => {
    setIsExportDialogOpen(!isExportDialogOpen);
  }, [isExportDialogOpen]);

  const handlePublishSmartChain = useCallback(() => {
    return api.publishSmartChain(smartChain.chain_name).then(() => {
      setIsPublishDialogOpen(false);
    });
  }, [api, smartChain]);

  return (
    <div className={"smart-chain-editor-top-bar"}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position='static' color={"transparent"}>
          <Toolbar>
            <div className={"top-bar-group"}>
              <IconButton
                size='large'
                aria-label='Open Chain Steps'
                title={"Open Chain Steps"}
                color='inherit'
                onClick={onOpenChainStepsClicked}
              >
                <FormatListNumberedIcon />
              </IconButton>
              <IconButton
                size='large'
                aria-label='Open Context'
                title={"Open Context"}
                color='inherit'
                onClick={onOpenPromptContextClicked}
              >
                <InputIcon />
              </IconButton>
              {selectedSmartChainStep ? (
                <FieldWrapper>
                  <FieldIconWrapper>
                    <Icon>
                      {stepTypes[selectedSmartChainStep?.step_type]?.icon}
                    </Icon>
                  </FieldIconWrapper>
                  <SmartChainStepTypeSelector
                    placeholder='Step Type'
                    value={selectedSmartChainStep?.step_type ?? ""}
                    onChange={onStepTypeChange}
                    select={true}
                  />
                </FieldWrapper>
              ) : null}
              {/*<FieldWrapper>*/}
              {/*    <FieldIconWrapper>*/}
              {/*        <TextFieldsIcon/>*/}
              {/*    </FieldIconWrapper>*/}
              {/*    <StyledInputBase*/}
              {/*        placeholder="Chain Name…"*/}
              {/*        value={smartChain?.chain_name}*/}
              {/*        onChange={handleChainNameChanged}*/}
              {/*    />*/}
              {/*</FieldWrapper>*/}
            </div>
            <div className={"top-bar-group"}>
              {hasUnsavedChanges ? (
                <SpinnerIconButton
                  size='large'
                  aria-label='Unsaved Changes'
                  title={"Unsaved Changes"}
                  color='inherit'
                >
                  <WarningIcon />
                </SpinnerIconButton>
              ) : null}
              <IconButton
                size='large'
                aria-label='Change Theme'
                title={
                  isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"
                }
                color='inherit'
                onClick={onSwitchLightDarkModeClick}
              >
                {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
              </IconButton>
              <SpinnerIconButton
                size='large'
                aria-label='Built In Agent Actions'
                title={"Built In Agent Actions"}
                color='inherit'
                onClick={onActionListClicked}
              >
                <HardwareIcon />
              </SpinnerIconButton>
              <SpinnerIconButton
                size='large'
                aria-label='LLM Models'
                title={"LLM Models"}
                color='inherit'
                onClick={onModelListClicked}
              >
                <ModelTrainingIcon />
              </SpinnerIconButton>
              <SpinnerIconButton
                size='large'
                aria-label='Save'
                title={"Save"}
                color='inherit'
                onClick={onSaveClick}
              >
                <SaveIcon />
              </SpinnerIconButton>
              <SpinnerIconButton
                size='large'
                aria-label='Publish'
                title='Publish'
                color='inherit'
                onClick={handleTogglePublishDialog}
              >
                <PublishIcon />
              </SpinnerIconButton>
              <IconButton
                size='large'
                aria-label='View Previously Published Versions'
                color='inherit'
                title='View Previously Published Versions'
                onClick={handleShowPublishedSmartChainDialog}
              >
                <HistoryIcon />
              </IconButton>
              <SpinnerIconButton
                size='large'
                aria-label='Export'
                title='Export'
                color='inherit'
                onClick={handleToggleExportDialog}
              >
                <ImportExportIcon />
              </SpinnerIconButton>
              <IconButton
                size='large'
                aria-label={
                  isShowingPromptOutput
                    ? "Hide Prompt Output"
                    : "Show Prompt Output"
                }
                title={
                  isShowingPromptOutput
                    ? "Hide Prompt Output"
                    : "Show Prompt Output"
                }
                color='inherit'
                onClick={onShowPromptOutputClicked}
              >
                <OutputIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </Box>
      <PublishSmartChainDialog
        open={isPublishDialogOpen}
        handleClose={handleTogglePublishDialog}
        handleConfirm={handlePublishSmartChain}
      />
      <ExportSmartChainDialog
        open={isExportDialogOpen}
        handleClose={handleToggleExportDialog}
        smartChain={smartChain}
      />
    </div>
  );
}
