import * as React from 'react';
import {TableView} from "../../../core/frontend/elements/TableView";
import {useAPI} from "../../../core/frontend/components/APIProvider";
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

export function ContentSampleTable() {
    const api = useAPI();
    const navigate = useNavigate();

    const handleContentSampleRowClicked = useCallback((contentSample) => {
        navigate(`/content_samples/${contentSample.id}`);
    }, [navigate]);


    return (
        <TableView
            schemaAPI={api.getContentSampleSchema}
            fetchAPI={api.getContentSamples}
            onRowClicked={handleContentSampleRowClicked}
        />
    );
}
