import React from "react";
import FormInput from "../../elements/form/FormInput";
import FormSection from "../../elements/form/FormSection";
import FormSubFieldRoot from "../../elements/form/FormSubFieldRoot";

export default function OpenAIConfigurationForm() {
    return (
        <FormSection
            title={"OpenAI Integration"}
            subtitle={"Configuration for the OpenAI API Integration for this tenant"}
            collapsable={true}
        >
            <FormSubFieldRoot field={"openai"}>
                <FormInput
                    type={"text"}
                    label={"API Key"}
                    fieldName={"key"}
                    placeholder={"The API key used to connect to the OpenAI API"}
                />

                <FormSubFieldRoot field={"completion_rate_limit_tokens_per_minute"}>
                    <FormInput
                        type={"number"}
                        label={"Rate Limit For: gpt-4o"}
                        fieldName={"gpt-4o"}
                        placeholder={"The rate limit applied to outbound requests made with the gpt-4o model in terms of tokens per minute."}
                    />
                    <FormInput
                        type={"number"}
                        label={"Rate Limit For: gpt-4o-mini"}
                        fieldName={"gpt-4o-mini"}
                        placeholder={"The rate limit applied to outbound requests made with the gpt-4o-mini model in terms of tokens per minute."}
                    />
                    <FormInput
                        type={"number"}
                        label={"Rate Limit For: gpt-3.5-turbo"}
                        fieldName={"gpt-3.5-turbo"}
                        placeholder={"The rate limit applied to outbound requests made with the gpt-3.5-turbo model in terms of tokens per minute."}
                    />
                    <FormInput
                        type={"number"}
                        label={"Rate Limit For: gpt-4"}
                        fieldName={"gpt-4"}
                        placeholder={"The rate limit applied to outbound requests made with the gpt-4 model in terms of tokens per minute."}
                    />
                    <FormInput
                        type={"number"}
                        label={"Rate Limit For: gpt-4-turbo"}
                        fieldName={"gpt-4-turbo"}
                        placeholder={"The rate limit applied to outbound requests made with the gpt-4-turbo model in terms of tokens per minute."}
                    />
                    <FormInput
                        type={"number"}
                        label={"Rate Limit For: other"}
                        fieldName={"other"}
                        placeholder={"The rate limit applied to outbound requests made with any other models that didn't have a specific rate limit set, in terms of tokens per minute."}
                    />
                </FormSubFieldRoot>
                <FormInput
                    type={"number"}
                    label={"Image Rate Limit Images Per Minute"}
                    fieldName={"image_rate_limit_images_per_minute"}
                    placeholder={"The maximum number of images that can be generated using DALL-E 3 per minute. This should be retrieved directly from the rate limits page on the OpenAI account."}
                />
                <FormInput
                    type={"number"}
                    label={"Embedding Rate Limit Tokens Per Minute"}
                    fieldName={"embedding_rate_limit_tokens_per_minute"}
                    placeholder={"The maximum number of tokens that can be processed using the embedding endpoint per minute. This should be set based on what's in the OpenAI account."}
                />
            </FormSubFieldRoot>
        </FormSection>
    );
}