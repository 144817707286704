import {create} from 'zustand';
import {addEdge, applyNodeChanges, applyEdgeChanges} from 'reactflow';
import {api} from "../../../core/frontend/components/api";
import PromptManipulator from "./components/PromptManipulator";
import _, {isString} from "lodash";
import { textInputNodeTypes } from "../../../core/frontend/components/constants";
import {
    fileInputNodeHeight,
    fileInputNodeWidth, outputNodeInitialHeight, outputNodeWidth,
    promptNodeInitialHeight, promptNodeWidth,
    textInputNodeHeight,
    textInputNodeWidth
} from "../../../core/frontend/theme/sizes";
import BehaviorTracker from "../../../core/frontend/components/BehaviorTracker";
import {defaultInputGroupNode, defaultOutputNode, defaultTemplateInputNode} from "./builtincharts/shared";
import {createAnonymousHomeChart} from "./builtincharts/anonymous-home";
import * as rfc6902 from "rfc6902";
import * as promptTemplates from "./components/PromptTemplates";
import {createTestingChart} from "./builtincharts/testing-chart";

const builtInChartIds = [
    'default',
    'test'
];

let lastSavedChartData = null;

const targetOriginX = 100;
// We set a large target origin y, because of a reactflow glitch involving certain coordinates
// position values. This is a workaround to ensure that the chart is displayed correctly.
const targetOriginY = -100;

// this is our useStore hook that we can use in our components to get parts of the store and call actions
const useChartStore = create((set, get) => ({
        // UI state
        is_local: true,
        is_loading_new_chart: false,

        is_recomputing_chart: false,
        needs_another_chart_recompute: false,
        recompute_promise: null,
        next_recompute_promise: null,
        next_recompute_promise_resolver: null,


        is_saving_chart: false,
        needs_another_chart_save: false,
        save_promise: null,
        next_save_promise: null,
        next_save_promise_resolver: null,

        streaming_nodes: {},

        viewport: {
            x: 0,
            y: 0,
            zoom: 0.65,
        },
        is_uploading_file_for_node: {

        },


        // Chart data
        id: null,
        id_counter: 1,
        title: "",
        user_id: "",
        nodes: {},
        edges: {},
        template: null,
        allowPublicViewing: false,
        created_at: null,

        // Actions
        loadChart: async (chartId, local, singleChartEvaluation) => {
            function setNewChartAfterLoading(chart) {
                get().changeChart(chart);
                // This is done in this weird order to ensure that the
                // changes to x,y coordinates for nodes by resetNodeCoordinatesOnChart
                // will get saved on the next call to saveChart()
                lastSavedChartData = get().serializeChart();
                get().fixNodeSizeValueTypes(chart);
                get().resetNodeCoordinatesOnChart(chart);
                get().ensureInputOutputNodesExistOnChart(chart);
                get().changeChart(chart);

                get().recomputePositionsForInputNodes();
                get().recalculateSizeForInputGroupNode();
                if (singleChartEvaluation && singleChartEvaluation?.output_node_datas) {
                    get().loadValuesFromSingleChartEvaluation(singleChartEvaluation);
                }

                set({
                    is_loading_new_chart: false,
                    is_local: local,
                });
            }

            set({
                is_loading_new_chart: true
            });

            try {
                if (local) {
                    let chart = JSON.parse(localStorage.getItem(`chart-${chartId}`));
                    // If the local version is too old, ignore it.
                    if (!chart?.created_at) {
                        chart = null;
                    } else {
                        const timeSinceLastSave = new Date().getTime() - new Date(chart.created_at).getTime();
                        const sevenDaysTime = 7 * 24 * 60 * 60 * 1000;
                        if (timeSinceLastSave > sevenDaysTime) {
                            chart = null;
                        }
                    }



                    if (chart) {
                        setNewChartAfterLoading(chart);
                    } else if (builtInChartIds.includes(chartId)) {
                        let defaultChart;
                        if (chartId === 'test') {
                            defaultChart = createTestingChart();
                        } else {
                            defaultChart = createAnonymousHomeChart();
                        }

                        defaultChart.id = chartId;
                        setNewChartAfterLoading(defaultChart);
                        setTimeout(() => {
                            get().recomputeChartDebounced();
                        }, 500);
                    } else {
                        // Load the chart, but we keep it as our own local copy.
                        // This allows us to modify it without affecting the original.
                        const chart = await api.getChart(chartId);
                        // Set id to _id for compatibility
                        chart.id = chart._id;
                        setNewChartAfterLoading(chart);
                    }
                    lastSavedChartData = null;
                } else {
                    const chart = await api.getChart(chartId);
                    // Set id,
                    chart.id = chart._id;
                    setNewChartAfterLoading(chart);
                }
            } catch (err) {
                console.error(err);
                set({
                    is_loading_new_chart: false
                });
                throw err;
            }
        },
        fixNodeSizeValueTypes(chart) {
            for (let node of Object.values(chart.nodes)) {
                if (isString(node.style.width)) {
                    try {
                        node.style.width = parseFloat(node.style.width);
                    } catch (e) {
                        node.style.width = 500;
                    }
                }
                if (isString(node.style.height)) {
                    try {
                        node.style.height = parseFloat(node.style.height);
                    } catch (e) {
                        node.style.height = 500;
                    }
                }
            }
        },
        resetNodeCoordinatesOnChart(chart) {
            // First compute which node has the minimum aggregate X and Y coordinates.
            let minDistScore = Infinity;
            let minNode = null;
            for (let node of Object.values(chart.nodes)) {
                if (!get().isInputNode(node)) {
                    const distScore = Math.sign(node.position.x) * Math.pow(node.position.x, 2)
                                        + Math.sign(node.position.y) * Math.pow(node.position.y, 2);

                    if (distScore < minDistScore) {
                        minDistScore = distScore;
                        minNode = node;
                    }
                }
            }

            if (minNode !== null) {
                const adjustmentX = -minNode.position.x + targetOriginX;
                const adjustmentY = -minNode.position.y + targetOriginY;

                chart.nodes = _.mapValues(chart.nodes, (node) => {
                    if (node.parentNode) {
                        // Don't reposition any sub nodes, only root nodes.
                        return node;
                    }

                    return {
                        ...node,
                        position: {
                            ...node.position,
                            x: node.position.x + adjustmentX,
                            y: node.position.y + adjustmentY,
                        }
                    }
                });
            }
        },
        createPromptNode: (prompt, currentValues, x, y) => {
            const newNode = get().addNode({
                id: "",
                data: {
                    title: "",
                    prompt: prompt ?? "",
                    currentValues: currentValues ?? [],
                    filled_prompts: [],
                    raw_completion_results: [],
                    stale: false,
                    method: null,
                    templateId: null,
                    templateInsertionValues: {},
                    enable_ai_completion: true,
                    temperature: 0.8,
                    merge_strategy: null,
                    deduplication_strategy: null,
                    deduplication_threshold: 0.7,
                    splitting_strategy: null,
                    cleaning_strategies: null,
                    cache_variants_per_identical_prompt: 25,
                },
                position: {x, y},
                style: {width: 500, height: 300},
                type: 'promptNode',
            });

            return newNode;
        },
        createTextInputNode: (prompt, currentValues) => {
            const newNode = get().addNode({
                id: "",
                data: {
                    title: "",
                    prompt: prompt ?? "",
                    currentValues: currentValues ?? [],
                    filled_prompts: [],
                    raw_completion_results: [],
                    stale: false,
                    method: null,
                    templateId: null,
                    templateInsertionValues: {},
                    enable_ai_completion: false,
                    merge_strategy: null,
                    deduplication_strategy: null,
                    deduplication_threshold: 0.7,
                    splitting_strategy: null,
                    cleaning_strategies: null,
                    cache_variants_per_identical_prompt: 25,
                },
                position: get().calculatePositionForNewInputNode(),
                type: 'textInputNode',
                parentNode: 'input',
            });

            get().recalculateSizeForInputGroupNode();

            return newNode;
        },
        createFileInputNode: (prompt, currentValues) => {
            const newNode = get().addNode({
                id: "",
                data: {
                    title: "",
                    prompt: prompt ?? "",
                    currentValues: currentValues ?? [],
                    filled_prompts: [],
                    raw_completion_results: [],
                    stale: false,
                    method: null,
                    templateId: null,
                    templateInsertionValues: {},
                    enable_ai_completion: false,
                    merge_strategy: null,
                    deduplication_strategy: null,
                    deduplication_threshold: 0.7,
                    cache_variants_per_identical_prompt: 25,
                    splitting_strategy: null,
                    cleaning_strategies: null,
                },
                position: get().calculatePositionForNewInputNode(),
                type: 'fileInputNode',
                parentNode: 'input',
            });

            get().recalculateSizeForInputGroupNode();

            return newNode;
        },
        calculatePositionForNewInputNode() {
            // Find the highest coordinates for all the input nodes.
            const inputNodes = Object.values(get().nodes).filter((node) => get().isInputNode(node));
            const lowestYPosition = inputNodes.reduce((lowestPosition, node) => {
                const bottom = node.position.y + get().calculateHeightForNode(node);

                if (bottom > lowestPosition) {
                    return bottom;
                } else {
                    return lowestPosition;
                }
            }, 0);

            return {
                x: 0,
                y: Math.max(0, lowestYPosition)
            }
        },
        isInputNode: (node) => {
          return textInputNodeTypes.includes(node.type)
        },
        addNode: (node) => {
            node.id = `${get().id_counter}`;
            set({
                id_counter: get().id_counter + 1,
                nodes: {
                    ...get().nodes,
                    [node.id]: node,
                }
            });

            get().saveChartDebounced();

            return node;
        },
        computeDisplayTitleForNode(id) {
            const node = get().nodes[id];

            if (node) {
                if (node.type === "input") {
                    return "Input";
                } else if (node.type === "output") {
                    return "Output";
                } else if (node.type === "textInputNode" || node.type === "fileInputNode") {
                    return node.data.title || node.data.generatedTitle || `Input #${node.id}`;
                } else {
                    return node.data.title?.toString()?.trim() || node.data.generatedTitle?.toString()?.trim() || `Prompt #${node.id}`;
                }
            }
            return null;
        },

        getNode: (id) => {
            const node = get().nodes[id];

            if (node) {
                node.displayTitle = get().computeDisplayTitleForNode(id);
            }

            return node;
        },
        changeNodeData: (id, changedData) => {
            // Finds the node in the nodes list with the same 'id' field as updatedNodeInfo,
            // and replaces its contents with whatever is in newNode
            const newNodes = _.mapValues(get().nodes, (node) => {
                if (node.id === id) {
                    node.data = {
                        ...node.data,
                        ...changedData
                    };

                    const prompt = new PromptManipulator(node.data.prompt)

                    // Get a list of all references made within this prompt
                    let references = prompt.getAllNodeReferencesInPrompt();

                    // Get a list of all edges leading into this node.
                    const edges = Object.values(get().edges).filter((edge) => edge.target === node.id);

                    // Get all the nodes for each of the edges
                    const sourceNodes = edges.map((edge) => get().getNode(edge.source)).filter((node) => node);

                    // For each reference, check if it's in the list of nodes that lead into this node.
                    // If it's not, we create a new edge from the reference into this node.
                    for (let reference of references) {
                        let found = false;
                        for (let sourceNode of sourceNodes) {
                            if (sourceNode.id === reference) {
                                found = true;
                                break;
                            }
                        }

                        if (!found) {
                            const sourceNode = get().nodes[reference]

                            // Double check that the referenced node exists.
                            // In rare cases it might have been deleted
                            // at the same time this code needs to run.
                            if (sourceNode) {
                                get().addEdge(sourceNode.id, node.id);

                                BehaviorTracker.trackInteraction({
                                    id: "reference-node-using-at",
                                    mixpanel: null
                                });
                            } else {
                                // This reference is pointing to a node that doesn't exist.
                                // So lets remove it from the prompt.
                                prompt.removeNodeReferencesFromPrompt(reference);
                                node.data.prompt = prompt.toString();
                                references = prompt.getAllNodeReferencesInPrompt();
                            }
                        }
                    }

                    // Next lets check if there are any edges that we no longer have the corresponding reference for.
                    for (let edge of edges) {
                        let found = false;
                        for (let reference of references) {
                            if (reference === edge.source) {
                                found = true;
                                break;
                            }
                        }

                        if (!found || !get().getNode(edge.source)) {
                            get().removeEdge(edge.source, edge.target);
                        }
                    }

                }


                return node;
            });

            set({
                nodes: newNodes
            });

            get().deleteOrphanEdges();

            get().saveChartDebounced();
        },
        convertTemplateNodeToCustom: (nodeId) => {
            const node = get().getNode(nodeId);
            const template = promptTemplates.findTemplateById(node.data.templateId);
            const insertionValues = node.data.templateInsertionValues ?? {};

            get().changeNodeData(
                nodeId,
                {
                    method: "custom",
                    templateId: null,
                    templateInsertionValues: null,
                    prompt: template.constructPrompt(insertionValues),
                    stale: true,
                }
            )
        },
        deleteNode: (id) => {
            const newNodes = _.omit(get().nodes, id);

            set({
                nodes: newNodes
            });

            get().deleteOrphanEdges();

            get().recomputePositionsForInputNodes();

            get().recalculateSizeForInputGroupNode();

            get().recomputeChart();
        },
        deleteEdge: (id) => {
            const edge = get().edges[id];

            const newEdges = _.omit(get().edges, id);

            get().deleteReferencesForEdge(edge);

            set({
                edges: newEdges
            });

            get().recomputeChart();
        },
        changeChart(chart) {
            set({
                id: chart.id,
                id_counter: chart.id_counter,
                title: chart.title,
                user_id: chart.user_id,
                nodes: chart.nodes,
                edges: chart.edges,
                template: chart.template,
                allowPublicViewing: chart.allowPublicViewing,
                created_at: chart.created_at,
            });

            get().saveChartDebounced();
        },
        /** These three functions are used by the ReactFlow component to update the chart. */
        onNodesChange: (changes) => {
            let shouldRecomputeInputNodePositions = false;

            const modifiedChanges = changes.map((change) => {
                if (change.type === "position") {
                    const node = get().getNode(change.id);
                    if (!node) {
                        // This node no longer exists, but it was being dragged. This can happen
                        // inside the fuzzer. We can safely ignore the position change.
                        return null;
                    } else if (get().isInputNode(node)) {
                        if (change.dragging) {
                            // Instead, we reposition the input group node based on
                            // these changes, and leave the input node untouched.
                            // This means any attempt to change the position of an
                            // input node actually changes the input group node.
                            const diffX = change.position.x - node.position.x;
                            const diffY = change.position.y - node.position.y;

                            const inputNode = get().getNode("input");
                            const newPosX = inputNode.position.x + diffX;
                            const newPosY = inputNode.position.y + diffY;

                            const newPosAbsoluteX = change.positionAbsolute.x - change.position.x + newPosX;
                            const newPosAbsoluteY = change.positionAbsolute.y - change.position.y + newPosY;

                            return {
                                "type": "position",
                                "id": "input",
                                "position": {
                                    "x": newPosX,
                                    "y": newPosY
                                },
                                "positionAbsolute": {
                                    "x": newPosAbsoluteX,
                                    "y": newPosAbsoluteY
                                }
                            };
                        } else {
                            return {
                                ...change,
                                id: "input"
                            }
                        }
                    }

                    if (change.dragging) {
                        BehaviorTracker.trackInteraction({
                            id: 'drag-node',
                            mixpanel: null,
                        });
                    }

                } else if (change.type === "remove") {
                    const node = get().getNode(change.id);
                    if (get().isInputNode(node)) {
                        shouldRecomputeInputNodePositions = true;
                    }

                    // Don't allow deletion on the input or output nodes.
                    if (change.id === "input" || change.id === "output") {
                        return null;
                    } else {
                        BehaviorTracker.trackInteraction({
                            id: "delete-node-keyboard",
                            mixpanel: null
                        });
                    }
                } else if (change.type === "dimensions") {
                    if (change.resizing) {
                        BehaviorTracker.trackInteraction({
                            id: 'resize-node',
                            mixpanel: null,
                        });
                    }
                }

                // By default just return the change unmodified.
                return change;
            }).filter(change => change)

            let newNodes = applyNodeChanges(modifiedChanges, Object.values(get().nodes));

            set({
                nodes: _.fromPairs(newNodes.map((node) => [node.id, node]))
            });

            const containsNodeDimensionUpdates = changes.some((change) => {
                return change.type === 'dimensions'
            });

            if (containsNodeDimensionUpdates) {
                setTimeout(() => {
                    get().recalculateSizeForInputGroupNode();
                });
            } else {
                get().recalculateSizeForInputGroupNode();
            }

            if (shouldRecomputeInputNodePositions) {
                get().recomputePositionsForInputNodes();
            }

            get().deleteOrphanEdges();

            get().saveChartDebounced();
        },
        deleteReferencesForEdge(edge) {
            const target = get().getNode(edge.target);

            if (target) {
                if (target.data.prompt) {
                    const prompt = new PromptManipulator(target.data.prompt);
                    prompt.removeNodeReferencesFromPrompt(edge.source);
                    if (prompt.toString() !== target.data.prompt) {
                        get().changeNodeData(target.id, {prompt: prompt.toString(), stale: true});
                    }
                }
            }
        },
        onEdgesChange: (changes) => {
            for (let change of changes) {
                if (change.type === 'remove') {
                    const edgeId = change.id;
                    const edge = get().edges[edgeId];
                    if (edge) {
                        get().deleteReferencesForEdge(edge);

                        BehaviorTracker.trackInteraction({
                            id: "delete-connection-keyboard",
                            mixpanel: null
                        });
                    }
                }
            }

            const newEdgeList = applyEdgeChanges(changes, Object.values(get().edges));

            set({
                edges: _.fromPairs(newEdgeList.map((edge) => [edge.id, edge]))
            });

            get().saveChartDebounced();
        },
        onConnect: (connection) => {
            const newEdges = addEdge(connection, Object.values(get().edges));

            set({
                edges: _.fromPairs(newEdges.map((edge) => [edge.id, edge]))
            });

            const source = get().getNode(connection.source);
            const target = get().getNode(connection.target);
            if (!source) {
                console.warn(`Warning in onConnect: source node with id ${connection.source} not found`);
                return;
            }
            if (!target) {
                console.warn(`Warning in onConnect: target node with id ${connection.source} not found`);
                return;
            }

            if (target.type === "promptNode" || target.type === "outputNode") {
                const prompt = new PromptManipulator(target.data.prompt);
                const references = prompt.getAllNodeReferencesInPrompt();

                if (!references.includes(source.id)) {
                    prompt.addNodeReferenceToStartOfPrompt(source.id, source.data.title);
                    const changes = {prompt: prompt.toString()};
                    if (!prompt.onlyHasReferences()) {
                        changes.stale = true;
                    }
                    get().changeNodeData(target.id, changes);
                }
            }

            get().recomputeChart();
        },
        addEdge(source, target) {
            const newEdge = {
                source: source,
                target: target,
                sourceHandle: "out",
                targetHandle: "in"
            };

            get().onConnect(newEdge);

            get().saveChartDebounced();
        },
        removeEdge(source, target) {
            const matchingEdge = Object.values(get().edges).find((edge) => {
                return edge.source === source && edge.target === target;
            });

            get().deleteReferencesForEdge(matchingEdge);

            const newEdges = _.omit(get().edges, matchingEdge.id);

            set({
                edges: newEdges,
            });

            get().saveChartDebounced();
        },
        serializeChart() {
            return {
                id: get().id,
                id_counter: get().id_counter,
                user_id: get().user_id,
                title: get().title,
                allowPublicViewing: get().allowPublicViewing,
                template: get().template,
                created_at: get().created_at,
                nodes: _.mapValues(get().nodes, (node) => {
                    return {
                        id: node.id,
                        data: node.data,
                        position: node.position,
                        style: node.style,
                        type: node.type,
                        parentNode: node.parentNode,
                        extent: node.extent,
                    }
                }),
                edges: _.mapValues(get().edges, (edge) => {
                    return {
                        id: edge.id,
                        source: edge.source,
                        target: edge.target,
                        sourceHandle: edge.sourceHandle,
                        targetHandle: edge.targetHandle,
                    }
                })
            };
        },
        recomputeChart:  async function recomputeChart() {
            // Don't update again in parallel.
            if (get().is_recomputing_chart) {
                set({
                    needs_another_chart_recompute: true
                });

                // Make an HTTP request to trigger cancellation
                await api.cancelRecomputeChart(get().id);

                return get().next_recompute_promise;
            }

            if (get().next_save_promise) {
                await get().next_save_promise;
            }

            const recomputePromise = new Promise(async (resolve, reject) => {
                try {
                    const recomputeChartData = _.cloneDeep(get().serializeChart());
                    const staleNodes = Object.values(recomputeChartData.nodes).filter((node) => node.data.stale);

                    // There is a stale node, so we need to recompute
                    if (staleNodes.length > 0) {
                        const savePatch = rfc6902.createPatch(lastSavedChartData, recomputeChartData)

                        function scrollNodeToBottom(nodeId) {
                            const currentValueScrollWrapperId = "current-value-scroll-wrapper-" + nodeId;
                            const currentValueScrollWrapper = document.getElementById(currentValueScrollWrapperId);
                            if (currentValueScrollWrapper) {
                                currentValueScrollWrapper.scrollTop = currentValueScrollWrapper.scrollHeight;
                            }
                        }

                        function scrollNodeToTop(nodeId) {
                            const currentValueScrollWrapperId = "current-value-scroll-wrapper-" + nodeId;
                            const currentValueScrollWrapper = document.getElementById(currentValueScrollWrapperId);
                            if (currentValueScrollWrapper) {
                                currentValueScrollWrapper.scrollTop = 0;
                            }
                        }

                        function isNodeScrolledToBottom(nodeId) {
                            const currentValueScrollWrapperId = "current-value-scroll-wrapper-" + nodeId;
                            const currentValueScrollWrapper = document.getElementById(currentValueScrollWrapperId);
                            if (currentValueScrollWrapper) {
                                return currentValueScrollWrapper.scrollTop >= (currentValueScrollWrapper.scrollHeight - currentValueScrollWrapper.clientHeight - 5);
                            } else {
                                return true;
                            }
                        }

                        let currentValueLineByNodeIdAndValueIndex = {};
                        let wordsToAddByNodeId = {};
                        let finalValuesByNodeId = {};
                        let didWithdrawValueByNodeId = {};
                        let localValueCountByNodeId = {};

                        function flushWordsToAdd(nodeId) {
                            // For efficiencies sake, we have to bypass react
                            // and add html elements directly to the root within
                            // the current value container
                            const currentValueContainerId = "current-value-streaming-container-" + nodeId;
                            const currentValueContainer = document.getElementById(currentValueContainerId);

                            if (currentValueContainer) {
                                const wasScrolledToBottom = isNodeScrolledToBottom(nodeId);

                                while(wordsToAddByNodeId[nodeId].length > 0) {
                                    const {word, valueIndex} = wordsToAddByNodeId[nodeId].shift();

                                    let currentValueGroup = currentValueContainer.querySelectorAll(".current-value-group[data-value-index='" + valueIndex + "']")[0];
                                    if (!currentValueGroup) {
                                        // Remap to a local numbering, because the value indexes can come
                                        // from the server out of order, and that looks weird when displayed on the interface.
                                        if (!localValueCountByNodeId[nodeId]) {
                                            localValueCountByNodeId[nodeId] = 0;
                                        }
                                        localValueCountByNodeId[nodeId] += 1;
                                        const localValueIndex = localValueCountByNodeId[nodeId];

                                        currentValueGroup = document.createElement("div");
                                        currentValueGroup.className = "current-value-group";
                                        currentValueGroup.setAttribute("data-value-index", valueIndex.toString());
                                        currentValueContainer.appendChild(currentValueGroup);
                                        if (localValueIndex > 1) {
                                            const pageDividerElement = document.createElement("div");
                                            pageDividerElement.className = "current-value-page-divider";
                                            pageDividerElement.setAttribute("data-value-index", valueIndex.toString());
                                            const dottedPart1 = document.createElement("div");
                                            dottedPart1.className = "current-value-divider-dotted-part";
                                            dottedPart1.setAttribute("data-value-index", valueIndex.toString());
                                            const pageNumberElement = document.createElement("div");
                                            pageNumberElement.className = "current-value-divider-page-number";
                                            pageNumberElement.innerText = `#${localValueCountByNodeId[nodeId] - 1}`;
                                            pageNumberElement.setAttribute("data-value-index", valueIndex.toString());
                                            const dottedPart2 = document.createElement("div");
                                            dottedPart2.className = "current-value-divider-dotted-part";
                                            dottedPart2.setAttribute("data-value-index", valueIndex.toString());
                                            pageDividerElement.appendChild(dottedPart1);
                                            pageDividerElement.appendChild(pageNumberElement);
                                            pageDividerElement.appendChild(dottedPart2);
                                            currentValueGroup.appendChild(pageDividerElement);
                                        }
                                    }

                                    let currentValueLine = currentValueLineByNodeIdAndValueIndex[nodeId]?.[valueIndex] ?? null;
                                    if (word !== null) {
                                        // Split the word based on new line characters.
                                        const lineSections = word.split("\n");
                                        for (let lineSectionIndex = 0; lineSectionIndex < lineSections.length; lineSectionIndex += 1) {
                                            const linePart = lineSections[lineSectionIndex];

                                            if (currentValueLine === null || lineSectionIndex > 0) {
                                                if (currentValueLine !== null) {
                                                    // Add a br tag to the end of the previous line
                                                    currentValueLine.appendChild(document.createElement("br"));
                                                }

                                                currentValueLine = document.createElement("span");
                                                currentValueLine.className = "current-value-line";
                                                currentValueLine.setAttribute("data-value-index", valueIndex.toString());
                                                if (!currentValueLineByNodeIdAndValueIndex[nodeId]) {
                                                    currentValueLineByNodeIdAndValueIndex[nodeId] = {};
                                                }
                                                currentValueLineByNodeIdAndValueIndex[nodeId][valueIndex] = currentValueLine;
                                                currentValueGroup.appendChild(currentValueLine);
                                            }

                                            // Add a new span tag containing the word
                                            const span = document.createElement("span");
                                            span.innerText = linePart;
                                            span.className = "current-value-word";
                                            span.setAttribute("data-value-index", valueIndex.toString());
                                            currentValueLine.appendChild(span);
                                        }
                                    }
                                }

                                // Ensure that the scroll wrapper is scrolled down to the bottom
                                if (wasScrolledToBottom) {
                                    scrollNodeToBottom(nodeId);
                                }
                            }
                        }

                        let eventStreamer;
                        if (get().is_local) {
                            eventStreamer = await api.anonymousRecomputeChartStreaming(recomputeChartData);
                        } else {
                            eventStreamer = await api.recomputeChartStreaming(recomputeChartData.id, savePatch);
                        }

                        eventStreamer.addEventListener("error", (e) => {
                            reject(e);
                        });

                        eventStreamer.addEventListener("start_node_value", (e) => {
                            const data = JSON.parse(e.data);
                            const parts = data.split("|");
                            const nodeId = parts[0];
                            // const valueIndex = parts[1];
                            get().setNodeStreaming(nodeId, true);
                        });

                        eventStreamer.addEventListener("word", (e) => {
                            const data = JSON.parse(e.data);
                            const parts = data.split("|");
                            const nodeId = parts[0];
                            const valueIndex = parts[1];
                            const word = parts.slice(2).join("|");

                            if (!wordsToAddByNodeId[nodeId]) {
                                wordsToAddByNodeId[nodeId] = [];
                            }

                            wordsToAddByNodeId[nodeId].push({word, valueIndex});

                            // We have to queue up and flush the words (rather than just adding them directly
                            // on every event) because the current-value-streaming-container might not exist
                            // right at the start of the request as the browser is updating. Therefore
                            // we might miss a few words at the beginning.
                            flushWordsToAdd(nodeId);
                        });
                        eventStreamer.addEventListener("finish_node_value", (e) => {
                            const data = JSON.parse(e.data);
                            const parts = data.split("|");
                            const nodeId = parts[0];
                            const valueIndex = parts[1];

                            if (!finalValuesByNodeId[nodeId]) {
                                finalValuesByNodeId[nodeId] = [];
                            }

                            const finishedCurrentValue = parts.slice(2).join("|");
                            finalValuesByNodeId[nodeId].push(finishedCurrentValue);

                            if (!wordsToAddByNodeId[nodeId]) {
                                wordsToAddByNodeId[nodeId] = [];
                            }
                            wordsToAddByNodeId[nodeId].push({word: null, valueIndex: valueIndex});

                            // Don't to anything
                            flushWordsToAdd(nodeId);
                        });

                        eventStreamer.addEventListener("withdraw_node_value", (e) => {
                            const data = JSON.parse(e.data);
                            const parts = data.split("|");
                            const nodeId = parts[0];
                            const valueIndex = parts[1];

                            // Find all DOM nodes where the data-value-index matches the valueIndex
                            const currentValueContainerId = "current-value-streaming-container-" + nodeId;
                            const currentValueContainer = document.getElementById(currentValueContainerId);
                            if (currentValueContainer) {
                                const currentValueTaggedElements = currentValueContainer.querySelectorAll(`[data-value-index="${valueIndex}"]`);

                                for (const elem of currentValueTaggedElements) {
                                    elem.className += " current-value-withdrawn";
                                }
                            }

                            if (!didWithdrawValueByNodeId[nodeId]) {
                                didWithdrawValueByNodeId[nodeId] = {};
                            }

                            didWithdrawValueByNodeId[nodeId][valueIndex] = true;
                        });

                        eventStreamer.addEventListener("finish_all_values_for_node", (e) => {
                            const data = JSON.parse(e.data);
                            const parts = data.split("|");
                            const nodeId = parts[0];

                            scrollNodeToTop(nodeId);
                        });

                        eventStreamer.addEventListener("patch", (e) => {
                            for (const nodeId of Object.keys(finalValuesByNodeId)) {
                                get().setNodeStreaming(nodeId, false);
                            }

                            let resultPatch = JSON.parse(e.data);

                            const newChart = get().serializeChart();
                            resultPatch = resultPatch.map((patch) => {
                                if (!patch.path.includes("currentValues/") &&
                                    !patch.path.includes("filled_prompts/") &&
                                    !patch.path.includes("raw_completion_results/"))
                                {
                                    return {
                                        ...patch,
                                        op: (patch.op === "replace" ? "add" : patch.op)
                                    };
                                } else {
                                    return patch;
                                }
                            })

                            const results = rfc6902.applyPatch(newChart, resultPatch);
                            for (let result of results) {
                                if (result) {
                                    console.warn(`Patch operation error: `, result);
                                }
                            }
                            if (lastSavedChartData) {
                                rfc6902.applyPatch(lastSavedChartData, savePatch);
                                rfc6902.applyPatch(lastSavedChartData, resultPatch);
                            }

                            get().changeChart(newChart);

                            eventStreamer.close();

                            resolve();
                        });
                    } else {
                        resolve();
                    }
                } catch (err) {
                    reject(err);
                }
            });

            const next_recompute_promise = new Promise((resolve, reject) => {
                set({
                    next_recompute_promise_resolver: {resolve, reject}
                })
            });

            set({
                is_recomputing_chart: true,
                recompute_promise: recomputePromise,
                next_recompute_promise,
            });

            await recomputePromise;

            set({
                is_recomputing_chart: false,
                recompute_promise: null
            });

            // Find out if there are still stale nodes in the chart, and if so, we need
            // to recompute them as well
            const staleNodes = Object.values(get().nodes).filter((node) => node.data.stale);
            if (staleNodes.length > 0 || get().needs_another_chart_recompute) {
                if (get().needs_another_chart_recompute) {
                    set({needs_another_chart_recompute: false});
                }

                get().next_recompute_promise_resolver.resolve(get().recomputeChart());
            } else {
                get().next_recompute_promise_resolver.resolve();

                // Ignore the promise on this, so it just executes in the background.
                get().saveChart();
            }
        },
        recomputeChartDebounced: _.debounce(() => get().recomputeChart(), 2500),
        saveChart: async function saveChart() {
            // Never attempt to save a local chart through the API.
            // It will just be stored locally on local disk.
            if (get().is_local) {
                if (process.env.REACT_APP_PROMPT_CHART_ENABLE_LOCAL_SAVING === "true") {
                    localStorage.setItem(`chart-${get().id}`, JSON.stringify(get().serializeChart()));
                }
            } else {
                if (get().is_saving_chart) {
                    set({
                        needs_another_chart_save: true
                    });
                    return await get().next_save_promise;
                }

                if (get().next_recompute_promise) {
                    await get().next_recompute_promise;
                }

                const savePromise = new Promise((resolve, reject) => {
                    try {
                        const newSaveData = _.cloneDeep(get().serializeChart());
                        const patch = rfc6902.createPatch(lastSavedChartData, newSaveData)

                        if (patch.length > 0) {
                            // const response = await api.saveChart(newSaveData);
                            resolve(api.patchChart(get().id, patch).then(() => {
                                lastSavedChartData = newSaveData;
                            }))
                        } else {
                            resolve();
                        }
                    } catch (err) {
                        reject(err);
                    }
                });

                const next_save_promise = new Promise((resolve, reject) => {
                    set({
                        next_save_promise_resolver: {resolve, reject}
                    })
                });

                set({
                    is_saving_chart: true,
                    save_promise: savePromise,
                    next_save_promise,
                });

                await savePromise;

                set({
                    is_saving_chart: false,
                    save_promise: null,
                });

                if (get().needs_another_chart_save) {
                    get().next_save_promise_resolver.resolve(get().saveChart());

                    set({needs_another_chart_save: false});
                } else {
                    get().next_save_promise_resolver.resolve();
                }
            }
        },
        saveChartDebounced: _.debounce(() => get().saveChart(), 1500),
        getViewport() {
            return get().viewport;
        },
        setViewport(newViewport) {
            set({
                viewport: newViewport
            });
        },
        recalculateSizeForInputGroupNode() {
            const allInputNodes = Object.values(get().nodes).filter((node) => get().isInputNode(node));

            // Find maximum Y + height and maximum X + width for all input nodes, which are supposed to be contained
            // within the input group Node
            let maxY = -Infinity;
            let maxX = -Infinity;
            for (let i = 0; i < allInputNodes.length; i++) {
                const node = allInputNodes[i];

                const height = get().calculateHeightForNode(node);
                const width = get().calculateWidthForNode(node);

                if ((node.position.y + height) > maxY) {
                    maxY = node.position.y + height;
                }

                if ((node.position.x + width) > maxX) {
                    maxX = node.position.x + width;
                }
            }

            if (maxY === -Infinity) {
                maxY = 0;
            }

            if (maxX === -Infinity) {
                maxX = 0;
            }

            const extraVerticalSpace = 100;
            const extraHorizontalSpace = 10;
            const minimumHeight = 200;
            const minimumWidth = 500;

            // Now the height of this input group node is the extent from the top to the bottom
            // of all the input nodes.
            let height = extraVerticalSpace + maxY;
            let width = extraHorizontalSpace + maxX;

            // Apply the minimum dimensions
            height = Math.max(minimumHeight, height);
            width = Math.max(minimumWidth, width);

            const newNodes = _.mapValues(get().nodes, (node) => {
                if (node.id === "input") {
                    return {
                        ...node,
                        style: {
                            ...(node.style ?? {}),
                            width: width,
                            height: height
                        }
                    }
                } else {
                    return node;
                }
            });

            set({
                nodes: newNodes
            })
        },
        calculateHeightForNode(node) {
            if (node.style && node.style.height) {
                return node.style.height;
            }

            let height;
            if (node.type === 'textInputNode') {
                height = textInputNodeHeight;
            } else if (node.type === 'fileInputNode') {
                height = fileInputNodeHeight;
            } else if (node.type === 'promptNode') {
                //TODO: This doesn't handle the varying heights in prompt nodes
                height = promptNodeInitialHeight;
            } else if (node.type === 'outputNode') {
                //TODO: This doesn't handle the varying heights in prompt nodes
                height = outputNodeInitialHeight;
            } else {
                throw new Error(`Unable to calculate height for node type ${node.type}`);
            }

            return height;
        },
        calculateWidthForNode: (node) => {
            if (node.style && node.style.width) {
                return node.style.width;
            }

            let width;
            if (node.type === 'textInputNode') {
                width = textInputNodeWidth;
            } else if (node.type === 'fileInputNode') {
                width = fileInputNodeWidth;
            } else if (node.type === 'promptNode') {
                //TODO: This doesn't handle the varying heights in prompt nodes
                width = promptNodeWidth;
            } else if (node.type === 'outputNode') {
                //TODO: This doesn't handle the varying heights in prompt nodes
                width = outputNodeWidth;
            } else {
                throw new Error(`Unable to calculate width for node type ${node.type}`);
            }

            return width;
        },
        deleteOrphanEdges: () => {
            const edgesToDelete = Object.values(get().edges).filter((edge) => {
                return !get().nodes[edge.source] || !get().nodes[edge.target];
            });

            for (const edge of edgesToDelete) {
                get().deleteReferencesForEdge(edge);
            }

            const newEdgeList = Object.values(get().edges).filter((edge) => {
                return get().nodes[edge.source] && get().nodes[edge.target];
            });

            const newEdgeObject = _.fromPairs(newEdgeList.map((edge) => [edge.id, edge]));

            set({
                edges: newEdgeObject
            });
        },
        recomputePositionsForInputNodes() {
            let currentYPosition = 100;
            const newNodes = _.mapValues(get().nodes, (node) => {
                if (get().isInputNode(node)) {
                    const newNode = {
                        ...node,
                        position: {
                            x: 0,
                            y: currentYPosition
                        }
                    }

                    currentYPosition = currentYPosition + get().calculateHeightForNode(node);

                    return newNode;
                } else {
                    return node;
                }
            });

            set({
                nodes: newNodes
            });

            get().saveChartDebounced();
        },
        ensureInputOutputNodesExistOnChart(chart) {
            /* This only needs to be done if the chart has a template */
            if (!chart.template) {
                return;
            }

            let has_input_group_node = false;
            for (let node of Object.values(chart.nodes)) {
                if (node.id === "input") {
                    has_input_group_node = true;
                }
            }

            if (!has_input_group_node) {
                chart.nodes['input'] = _.cloneDeep(defaultInputGroupNode);
            }

            // We also create various text input nodes for each of the fields
            // that are present in the input group node system.
            for (let inputField of chart.template.input_fields) {
                const nodeId = 'input_' + inputField.field_name;

                if (!chart.nodes[nodeId]) {
                    chart.nodes[nodeId] = _.cloneDeep({
                        id: nodeId,
                        data: {
                            title: inputField.title,
                            prompt: "",
                            currentValues: [],
                            filled_prompts: [],
                            raw_completion_results: [],
                            stale: false,
                            method: null,
                            templateId: null,
                            templateInsertionValues: {},
                            enable_ai_completion: false,
                        },
                        position: get().calculatePositionForNewInputNode(),
                        type: 'textInputNode',
                        parentNode: 'input',
                    });
                }
            }

            get().recalculateSizeForInputGroupNode();

            // We also create various text input nodes for each of the fields
            // that are present in the input group node system.
            let outputFieldIndex = 0;
            for (let outputField of chart.template.output_fields) {
                const nodeId = 'output_' + outputField.field_name;

                outputFieldIndex += 1;

                if (!chart.nodes[nodeId]) {
                    chart.nodes[nodeId] = _.cloneDeep({
                        id: nodeId,
                        data: {
                            title: outputField.title,
                            prompt: "",
                            currentValues: [],
                            filled_prompts: [],
                            raw_completion_results: [],
                            stale: false,
                            method: null,
                            templateId: null,
                            templateInsertionValues: {},
                            enable_ai_completion: false,
                        },
                        position: {
                            x: 750 + targetOriginX,
                            y: outputFieldIndex * (outputNodeInitialHeight + 50) - (chart.template.output_fields.length) * ((outputNodeInitialHeight + 50) / 2) + targetOriginY,
                        },
                        type: 'outputNode'
                    });
                }
            }

            // Now check if there are any input nodes that need to be deleted because they are no longer present
            // on the template
            const inputNodeIds = Object.keys(chart.nodes).filter((nodeId) => nodeId.startsWith("input_"));
            for (let inputNodeId of inputNodeIds) {
                const fieldName = inputNodeId.substring(6);
                const field = chart.template.input_fields.find((field) => field.field_name === fieldName);
                if (!field) {
                    delete chart.nodes[inputNodeId];
                }
            }

            // Lastly, check if there are any output nodes that need to be deleted because they are no longer present
            // on the template
            const outputNodeIds = Object.keys(chart.nodes).filter((nodeId) => nodeId.startsWith("output_"));
            for (let outputNodeId of outputNodeIds) {
                const fieldName = outputNodeId.substring(7);
                const field = chart.template.output_fields.find((field) => field.field_name === fieldName);
                if (!field) {
                    delete chart.nodes[outputNodeId];
                }
            }
        },
        getEdgesGoingIntoNode(nodeId) {
            return Object.values(get().edges).filter((edge) => edge.target === nodeId);
        },
        getEdgesGoingFromNode(nodeId) {
            return Object.values(get().edges).filter((edge) => edge.source === nodeId);
        },
        setTitle(newTitle) {
            set({
                title: newTitle
            });

            get().saveChartDebounced();
        },
        setNodeStreaming(nodeId, isStreaming) {
            const newStreamingNodes = {
                ...get().streaming_nodes,
                [nodeId]: isStreaming,
            };

            set({
                streaming_nodes: newStreamingNodes
            });
        },
        isNodeBeingStreamed(nodeId) {
            return get().streaming_nodes[nodeId];
        },
        setUploadingFileForNode(nodeId, isUploading) {
            const newUploadingNodes = {
                ...get().is_uploading_file_for_node,
                [nodeId]: isUploading,
            }

            set({
                is_uploading_file_for_node: newUploadingNodes
            });
        },
        isFileUploadingForNode(nodeId) {
            return get().is_uploading_file_for_node[nodeId];
        },
        triggerFileUploadFlowForNode(nodeId) {
            const fileUploadPromise = new Promise((resolve, reject) => {
                // Gets the user to select a file.
                const fileSelector = document.createElement('input');
                fileSelector.setAttribute('type', 'file');
                // Don't allow multiple for now.
                // fileSelector.setAttribute('multiple', 'multiple');

                // Setup an event handler for after the user selects a file.
                fileSelector.onchange = async function onFileSelected(evt) {
                    const files = evt.target.files;
                    if (!files) {
                        return;
                    }

                    get().setUploadingFileForNode(nodeId, true);

                    const file = files[0];
                    const fileData = await api.uploadFile(file);
                    get().changeNodeData(nodeId, {
                        prompt: "",
                        currentValues: [],
                        filled_prompts: [],
                        raw_completion_results: [],
                        fileName: file.name,
                        fileId: fileData._id,
                        stale: true,
                        enable_ai_completion: false,
                    });

                    get().recomputeChart();

                    resolve();
                }

                fileSelector.oncancel = function (evt) {
                    // Just resolve without any fuss.
                    resolve();
                }

                fileSelector.onclose = function (evt) {
                    // Just resolve without any fuss.
                    resolve();
                }

                fileSelector.onabort = function (evt) {
                    // Just resolve without any fuss.
                    resolve();
                }

                fileSelector.click();
            });

            fileUploadPromise.then((response) => {
                get().setUploadingFileForNode(nodeId, false);
                return response;
            }).catch((error) => {
                get().setUploadingFileForNode(nodeId, false);
                throw error;
            });

            return fileUploadPromise;
        },
        loadValuesFromSingleChartEvaluation(singleChartEvaluation) {
            // Go through each of the nodes in the chart, and check to see if they
            // Exist on the chart evaluation. If so, we load in the current values
            // the chart evaluation.
            const nodes = get().nodes;
            for (let node of Object.values(nodes)) {
                if (singleChartEvaluation.output_node_datas[node.id]) {
                    get().changeNodeData(node.id, {
                        currentValues: singleChartEvaluation.output_node_datas[node.id].currentValues,
                        filled_prompts: singleChartEvaluation.output_node_datas[node.id].filled_prompts,
                        raw_completion_results: singleChartEvaluation.output_node_datas[node.id].raw_completion_results,
                    });

                    if (node.type === "textInputNode") {
                        get().changeNodeData(node.id, {
                            prompt: singleChartEvaluation.output_node_datas[node.id].prompt
                        });
                    }
                }
            }
        }
    })
);

export default useChartStore;
