import {PageWrapperWithMenu} from "../../../core/frontend/elements/PageWrapperWithMenu";
import React, {useCallback, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Card, CardContent} from "@mui/material";
import "./TranscriptProcessorConfigurationEditPage.scss";
import {useAPI} from "../../../core/frontend/components/APIProvider";
import {PageHeader} from "../../../core/frontend/elements/PageHeader";
import TranscriptProcessorConfigurationForm from "./TranscriptProcessorConfigurationForm";
import CircularProgress from "@mui/material/CircularProgress";


export default function TranscriptProcessorConfigurationEditPage(props) {
    const api = useAPI();
    const params = useParams();

    const navigate = useNavigate();
    const [transcriptProcessorConfiguration, setTranscriptProcessorConfiguration] = useState(null);

    const handleTranscriptProcessorConfigurationSave = useCallback(async (newTranscriptProcessorConfiguration) => {
        return await api.saveTranscriptProcessorConfiguration(newTranscriptProcessorConfiguration);
    }, [api]);

    const handleDeleteClicked = useCallback(async () => {
        return await api.deleteTranscriptProcessorConfiguration(transcriptProcessorConfiguration.id).then(() => {
            navigate(`/transcript_processor_configurations`);
        })
    }, [navigate, api, transcriptProcessorConfiguration?._id]);

    React.useEffect(() => {
        if (!transcriptProcessorConfiguration) {
            api.getTranscriptProcessorConfiguration(params?.transcriptProcessorConfigurationId).then((transcriptProcessorConfiguration) => {
                setTranscriptProcessorConfiguration(transcriptProcessorConfiguration);
            });
        }
    }, [handleTranscriptProcessorConfigurationSave, transcriptProcessorConfiguration, params?.transcriptProcessorConfigurationId, api]);

    if (!transcriptProcessorConfiguration) {
        return <PageWrapperWithMenu>
            <PageHeader title={`Edit Transcript Processor Configuration`}/>
            <div className="transcript-processor-configuration-edit-page">
                <CircularProgress/>
            </div>
        </PageWrapperWithMenu>
    }

    return <PageWrapperWithMenu>
        {/*<PageHeader title={`Edit Transcript Processor Configuration`}/>*/}
        {/*<div className="transcript-processor-configuration-edit-page">*/}
        {/*    <Card className="transcript-processor-configuration-edit-form-card">*/}
        {/*        <CardContent>*/}
                    <TranscriptProcessorConfigurationForm
                        initialTranscriptProcessorConfiguration={transcriptProcessorConfiguration}
                        onTranscriptProcessorConfigurationSave={handleTranscriptProcessorConfigurationSave}
                        onDeletedClicked={handleDeleteClicked}
                    />
                {/*</CardContent>x*/}
            {/*</Card>*/}
        {/*</div>*/}

    </PageWrapperWithMenu>
}