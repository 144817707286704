/**
 * Note! Contents of this file are commented out until Mixpanel is integrated
 */
class BehaviorTrackerImpl {
    constructor() {
        const interactionCountsData = localStorage.getItem("interaction-counts");
        if (interactionCountsData) {
            this.interactionCounts = JSON.parse(interactionCountsData);
        } else {
            this.interactionCounts = {};
        }

        this.lastInteractionTimes = {};
    }

    setUserId(userId) {

    }

    /**
     * Tracks an interaction within the application.
     *
     * @param config - The configuration object describing and categorizing the interaction.
     *                 It should contain the following properties:
     *                 - id, which is a string that globally uniquely identifies the interaction.
     *                 - mixpanel - this is the id that indicates how this interaction should be
     *                              tracked in mixpanel. Defaults to null which will not be tracked
     *                              in mixpanel.
     *                 - throttleMs - how long in ms to wait before tracking this interaction again. Defaults
     *                              to 3000 which means that this interaction will only be tracked once
     *                              every 3 seconds.
     */
    trackInteraction(config) {
        // See if we have done this interaction already recently to prevent throttling
        const throttleMs = config.throttleMs || 3000;
        if (this.lastInteractionTimes[config.id]) {
            const timeElapsed = Date.now() - this.lastInteractionTimes[config.id];
            // Record the latest interaction time.
            this.lastInteractionTimes[config.id] = Date.now();
            if (timeElapsed < throttleMs) {
                return;
            }
        }

        // Records this interaction as having been performed
        if (this.interactionCounts[config.id]) {
            this.interactionCounts[config.id] += 1;
        } else {
            this.interactionCounts[config.id] = 1;
        }
        // Save this to local storage
        localStorage.setItem("interaction-counts", JSON.stringify(this.interactionCounts));
    }

    /**
     * Returns whether the interaction with the given id has been performed at least once.
     */
    hasPerformedInteraction(id) {
        return this.interactionCounts[id] && this.interactionCounts[id] > 0;
    }
}


const BehaviorTracker = new BehaviorTrackerImpl();
export default BehaviorTracker;