import { TopBar } from "../menu/TopBar";
import React from "react";
import "./PageWrapperWithoutMenu.scss";

export const PageWrapperWithoutMenu = ({ children, padding, showTopBar }) => {
  if (padding === undefined) {
    padding = true;
  }

  if (showTopBar === undefined) {
    showTopBar = true;
  }

  const mainContentAreaClassName = `main-content-area ${
    padding ? "padding" : "no-padding"
  }`;

  return (
    <div className='page-wrapper-without-menu'>
      {showTopBar ? <TopBar /> : null}
      <div className={`below-top-bar ${showTopBar ? '' : 'no-top-bar'}`}>
        <div className={mainContentAreaClassName}>{children ?? null}</div>
      </div>
    </div>
  );
};
