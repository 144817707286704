import React, {useCallback, useMemo} from 'react';
import {TextField} from '@mui/material';
import {StoryFormWidgetContainer} from "./StoryFormWidgetContainer";
import "./MainCharacterNameSelector.scss"
import _ from "lodash";


export const MainCharacterNameSelector = ({value, onChange, mainCharacterType}) => {
    const [localValue, setLocalValue] = React.useState(value);

    const onChangeDebounced = useMemo(() => _.debounce(onChange, 500), [onChange]);

    const handleTextChange = useCallback((event) => {
        onChangeDebounced(event.target.value);
        setLocalValue(event.target.value);
    }, [onChangeDebounced]);

    let label = "Main Character Name";
    if (mainCharacterType === "your pet") {
        label = "Your Pets Name";
    } else if (mainCharacterType === "your kid") {
        label = "Your Kid's Name";
    }

    return (
        <StoryFormWidgetContainer label={label}
                                  className={"main-character-name-selector"}>

            <TextField
                id="other-feature"
                label="Name"
                onChange={handleTextChange}
                value={localValue}
                placeholder={"Jennifer Johnson, Bob, Fluffy, etc..."}
            />
        </StoryFormWidgetContainer>
    );
};