import "./BottomTabs.scss"
import {createContext, useContext} from "react";


export const tabsContext = createContext(null);

export function BottomTabs(props) {
    const tabClicked = (tabName) => {
        if(props.onChange) {
            props.onChange(tabName);
        }
    }

    const contextValue = {
        selectedTab: props.value,
        tabClicked: tabClicked,
    }

    return <div className={"bottom-tabs"}>
        <tabsContext.Provider value={contextValue}>
            {props.children}
        </tabsContext.Provider>
    </div>
}


export function BottomTab(props) {
    const tabContext = useContext(tabsContext);
    const isSelected = tabContext.selectedTab === props.name;
    const tabClass = `bottom-tab nodrag ${isSelected ? "selected" : ""}`;

    return <div className={tabClass} onClick={() => tabContext.tabClicked(props.name)}>
        {props.children}
    </div>
}