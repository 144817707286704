import OutboundIcon from "@mui/icons-material/Outbound";
import MessageIcon from "@mui/icons-material/Message";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import moment from "moment";
import {List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import React, {useMemo} from "react";
import {NavLink} from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
import "./OutboundAttemptRecordsList.scss";


export const OutboundAttemptListItem = ({outboundAttempt}) => {
    const rule = outboundAttempt.rule;

    let icon = <OutboundIcon/>;
    if (rule.communication_channel === 'sms') {
        icon = <MessageIcon/>;
    } else if (rule.communication_channel === 'phone_call') {
        icon = <PhoneIcon/>;
    } else if (rule.communication_channel === 'email') {
        icon = <EmailIcon/>;
    }


    if (outboundAttempt.conversation_id) {
        const dateString = moment.utc(outboundAttempt.actual_outbound_start_time_utc).local().format("MMM DD, YYYY");
        const timeString = moment.utc(outboundAttempt.actual_outbound_start_time_utc).local().format("h:mm a");

        return <NavLink to={`/conversations/${outboundAttempt.conversation_id}`} className={"outbound-attempt-record-item"}>
            <ListItemButton>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText
                    primary={`${dateString} at ${timeString} - ${rule.prompt || "No specific instructions provided"}`}
                />
            </ListItemButton>
        </NavLink>
    } else {
        const dateString = moment.utc(outboundAttempt.scheduled_outbound_date).format("MMM DD, YYYY");

        return <ListItem className={"outbound-attempt-record-item"}>
            <ListItemIcon>
                <CancelIcon />
            </ListItemIcon>
            <ListItemText
                primary={`${dateString} - Skipped - ${rule.prompt || "No specific instructions provided"}`}
            />
        </ListItem>
    }
};



export const OutboundAttemptRecordsList = ({outboundState, outboundWord}) => {
    // Combine together the outbound attempts with the skipped outbounds
    const combinedList = useMemo(() => {
        return (outboundState.outbound_attempt_records ?? []).concat(outboundState.skipped_outbound_attempts ?? []);
    }, [outboundState.outbound_attempt_records, outboundState.skipped_outbound_attempts]);

    // Sort the list based on the scheduled outbound date
    combinedList.sort((a, b) => {
        return new Date(a.scheduled_outbound_date) - new Date(b.scheduled_outbound_date);
    });

    return <List className={"outbound-attempt-records-list"}>
        {
            combinedList.map((outboundAttempt, index) => (
                <OutboundAttemptListItem
                    key={`${outboundAttempt.conversation_id}-${outboundAttempt.index_in_sequence}`}
                    outboundAttempt={outboundAttempt}
                />
            ))
        }
        {
            combinedList.length === 0 ?
                <ListItem>
                    <ListItemText primary={`No ${outboundWord.toLowerCase()} attempts made or skipped`}/>
                </ListItem>
                : null
        }
    </List>
}
