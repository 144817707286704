import React, {useState, useCallback, useMemo} from 'react';
import {RadioGroup, FormControlLabel, Radio, TextField} from '@mui/material';
import {StoryFormWidgetContainer} from "./StoryFormWidgetContainer";
import _ from "lodash";
import "./StoryThemeSelector.scss";

const allowedOptions = [
    'Funny & Amusing',
    'Inspiring / Life Lesson',
    'Informative / Educational',
    'Custom'
];

export const StoryThemeSelector = ({value, onChange}) => {

    let initialSelected = value;
    let initialCustom = "";
    // Fill in a default value if none is provided
    if (!initialSelected) {
        initialSelected = 'Funny & Amusing';
    }
    // If the value isn't in the list of allowed values, we set environment
    // to "Other" allowing the user to type in their own value
    if (!allowedOptions.includes(initialSelected)) {
        initialSelected = 'Custom';
        initialCustom = value;
    }

    const [selectedTheme, setSelectedTheme] = useState(initialSelected);
    const [customTheme, setCustomTheme] = useState(initialCustom);

    const onChangeDebounced = useMemo(() => _.debounce(onChange, 500), [onChange]);

    const handleThemeOptionRadioChange = useCallback((event) => {
        setSelectedTheme(event.target.value);
        onChange(event.target.value);
    }, [onChange]);

    const handleTextChange = useCallback((event) => {
        setCustomTheme(event.target.value);
        onChangeDebounced(event.target.value);
    }, [onChangeDebounced]);

    return (
        <StoryFormWidgetContainer label="Story Theme">
            <RadioGroup
                aria-label="story-theme"
                name="story-theme"
                className={"story-theme-selector"}
                value={selectedTheme}
                onChange={handleThemeOptionRadioChange}
            >
                {
                    allowedOptions.map((option) => (
                        <FormControlLabel key={option} value={option} control={<Radio/>} label={option}/>
                    ))
                }
                {selectedTheme === 'Custom' &&
                    <TextField
                        name="custom-theme"
                        label="Custom Theme"
                        onChange={handleTextChange}
                        value={customTheme}
                        multiline
                        fullWidth
                        minRows={6}
                        placeholder={"Describe a theme in the style of your favorite children's author"}
                    />}
            </RadioGroup>
        </StoryFormWidgetContainer>
    );
};
