import React, { useCallback } from "react";
import "./WeeklyAvailabilityWidget.scss";
import Checkbox from "@mui/material/Checkbox";
import { TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";

function convertTimeStringToMinutes(timeString) {
  if (!timeString) {
    return null;
  }

  const [hours, minutes] = timeString.split(/: /);
  const hasPm = timeString.toLowerCase().includes("p");

  let hoursInt = parseInt(hours);
  if (isNaN(hoursInt)) {
    hoursInt = 0;
  }
  let minutesInt = parseInt(minutes);
  if (isNaN(minutesInt)) {
    minutesInt = 0;
  }
  return hoursInt * 60 + minutesInt + (hasPm && hoursInt !== 12 ? 12 * 60 : 0);
}

function formatMinutesAsTimeString(minutes) {
  if (minutes === null || minutes === "") {
    return "";
  }

  const hours = Math.floor(minutes / 60);
  const minutesPart = minutes % 60;
  // Format in 24 hour
  return `${hours.toString().padStart(2, "0")}:${minutesPart
    .toString()
    .padStart(2, "0")}`;
}

function AvailabilitySegment({
  day_of_week_name,
  day_of_week_index,
  available_hours_segment,
  disabled,
  handleAddNewSegment,
  handleDeleteSegment,
  handleSegmentChanged,
  allowDeletion,
}) {
  const onAddClicked = () => {
    handleAddNewSegment(day_of_week_index);
  };

  const onDeleteClicked = () => {
    handleDeleteSegment(day_of_week_index, available_hours_segment);
  };

  const onStartHourChanged = React.useCallback(
    (e) => {
      let newStartMinute = convertTimeStringToMinutes(e.target.value);

      // Make sure that the start minute is at or before 11:00PM
      newStartMinute = Math.min(newStartMinute, 23 * 60 + 59);

      // Determine if we need to adjust the end_minute. The end_minute
      // Should be atleast 1 hour after the start_minute.
      let newEndMinute =
        available_hours_segment?.end_minute ?? newStartMinute + 60;
      if (newEndMinute < newStartMinute + 60) {
        newEndMinute = newStartMinute + 60;
      }

      handleSegmentChanged(day_of_week_index, available_hours_segment, {
        start_minute: newStartMinute,
        end_minute: newEndMinute,
      });
    },
    [day_of_week_index, available_hours_segment, handleSegmentChanged]
  );

  const onEndHourChanged = useCallback(
    (e) => {
      let newEndMinute = convertTimeStringToMinutes(e.target.value);

      // Make sure that the end minute after at-least 1am
      newEndMinute = Math.max(newEndMinute, 60);

      // Determine if we need to adjust the start_minute. The start_minute
      // Should be at-least 1 hour before the end_minute.
      let newStartMinute =
        available_hours_segment?.start_minute ?? newEndMinute - 60;
      if (newStartMinute > newEndMinute - 60) {
        newStartMinute = newEndMinute - 60;
      }

      handleSegmentChanged(day_of_week_index, available_hours_segment, {
        start_minute: newStartMinute,
        end_minute: newEndMinute,
      });
    },
    [day_of_week_index, available_hours_segment, handleSegmentChanged]
  );

  const deleteHiddenClass = available_hours_segment ? "" : "hidden";

  return (
    <div className={"availability-segment"}>
      <TextField
        name='start_hour'
        value={formatMinutesAsTimeString(
          available_hours_segment?.start_minute ?? ""
        )}
        onChange={onStartHourChanged}
        margin='normal'
        type='time'
        className={"start_hour"}
        disabled={disabled}
      />
      <span>-</span>
      <TextField
        name='end_hour'
        value={formatMinutesAsTimeString(
          available_hours_segment?.end_minute ?? ""
        )}
        onChange={onEndHourChanged}
        margin='normal'
        type='time'
        className={"end_hour"}
        disabled={disabled}
      />
      <div className={"end-buttons"}>
        {allowDeletion ? (
          <IconButton
            aria-label='delete'
            className={`delete_button ${deleteHiddenClass}`}
            color={"error"}
            onClick={onDeleteClicked}
          >
            <RemoveCircleIcon />
          </IconButton>
        ) : (
          <div />
        )}
        <IconButton
          aria-label='add'
          className={"add_button"}
          onClick={onAddClicked}
        >
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
}

export const DayOfWeekAvailability = ({
  day_of_week_name,
  day_of_week_index,
  available_hours,
  handleAddNewSegment,
  handleDeleteSegment,
  handleSegmentChanged,
  handleDeleteAllSegments,
  allowEmptyDays,
}) => {
  const label = day_of_week_name;

  // Check to see if there is at-least one segment matching this day of the week
  const segments_for_day = available_hours.filter((segment) => {
    return segment.day_of_week === day_of_week_index;
  });

  const onCheckboxChanged = (e) => {
    if (e.target.checked) {
      handleAddNewSegment(day_of_week_index);
    } else {
      handleDeleteAllSegments(day_of_week_index);
    }
  };

  return (
    <div className={"day-of-week-availability"}>
      <div className={"day-of-week-checkbox-section"}>
        {allowEmptyDays ? (
          <Checkbox
            {...label}
            color={"success"}
            className={"day_of_week_is_enabled"}
            checked={segments_for_day.length > 0}
            onChange={onCheckboxChanged}
          />
        ) : null}

        <span className={"day_of_week_text"}>
          <LocalTranslatedText language={"en"} text={day_of_week_name} />
        </span>
      </div>

      <div className={"day-of-week-availability-segments-list"}>
        {segments_for_day.length > 0
          ? segments_for_day.map((segment, index) => {
              return (
                <AvailabilitySegment
                  key={index}
                  day_of_week_name={day_of_week_name}
                  day_of_week_index={day_of_week_index}
                  available_hours_segment={segment}
                  handleAddNewSegment={handleAddNewSegment}
                  handleDeleteSegment={handleDeleteSegment}
                  handleSegmentChanged={handleSegmentChanged}
                  allowDeletion={segments_for_day.length > 1}
                />
              );
            })
          : null}
        {segments_for_day.length === 0 ? (
          <AvailabilitySegment
            day_of_week_name={day_of_week_name}
            day_of_week_index={day_of_week_index}
            available_hours_segment={null}
            disabled={true}
            handleAddNewSegment={handleAddNewSegment}
            handleDeleteSegment={handleDeleteSegment}
            handleSegmentChanged={handleSegmentChanged}
            allowDeletion={false}
          />
        ) : null}
      </div>
    </div>
  );
};

export const WeeklyAvailabilityWidget = ({
  value,
  onChange,
  allowEmptyDays,
}) => {
  if (allowEmptyDays === undefined) {
    allowEmptyDays = true;
  }

  const handleAddNewSegment = (day_of_week_index) => {
    const newAvailableHours = [
      ...value,
      {
        day_of_week: day_of_week_index,
        start_minute: 540, // 9:00 AM
        end_minute: 1020, // 5:00 PM
      },
    ];
    if (onChange) {
      onChange(newAvailableHours);
    }
  };

  const handleDeleteSegment = (day_of_week_index, segment) => {
    const newAvailableHours = value.filter((item) => {
      return item !== segment;
    });
    if (onChange) {
      onChange(newAvailableHours);
    }
  };

  const handleDeleteAllSegments = (day_of_week_index) => {
    const newAvailableHours = value.filter((item) => {
      return item.day_of_week !== day_of_week_index;
    });
    if (onChange) {
      onChange(newAvailableHours);
    }
  };

  const handleSegmentChanged = (day_of_week_index, segment, newSegment) => {
    const newAvailableHours = value.map((item) => {
      if (item === segment) {
        return { ...item, ...newSegment };
      }
      return item;
    });
    if (onChange) {
      onChange(newAvailableHours);
    }
  };

  return (
    <div className={"weekly-availability-widget"}>
      <span className={"title-text"}>
        <LocalTranslatedText language={"en"} text='Weekly hours' />
      </span>

      <DayOfWeekAvailability
        day_of_week_name='Monday'
        day_of_week_index={0}
        available_hours={value}
        handleAddNewSegment={handleAddNewSegment}
        handleDeleteSegment={handleDeleteSegment}
        handleDeleteAllSegments={handleDeleteAllSegments}
        handleSegmentChanged={handleSegmentChanged}
        allowEmptyDays={allowEmptyDays}
      />

      <DayOfWeekAvailability
        day_of_week_name='Tuesday'
        day_of_week_index={1}
        available_hours={value}
        handleAddNewSegment={handleAddNewSegment}
        handleDeleteSegment={handleDeleteSegment}
        handleDeleteAllSegments={handleDeleteAllSegments}
        handleSegmentChanged={handleSegmentChanged}
        allowEmptyDays={allowEmptyDays}
      />

      <DayOfWeekAvailability
        day_of_week_name='Wednesday'
        day_of_week_index={2}
        available_hours={value}
        handleAddNewSegment={handleAddNewSegment}
        handleDeleteSegment={handleDeleteSegment}
        handleDeleteAllSegments={handleDeleteAllSegments}
        handleSegmentChanged={handleSegmentChanged}
        allowEmptyDays={allowEmptyDays}
      />

      <DayOfWeekAvailability
        day_of_week_name='Thursday'
        day_of_week_index={3}
        available_hours={value}
        handleAddNewSegment={handleAddNewSegment}
        handleDeleteSegment={handleDeleteSegment}
        handleDeleteAllSegments={handleDeleteAllSegments}
        handleSegmentChanged={handleSegmentChanged}
        allowEmptyDays={allowEmptyDays}
      />

      <DayOfWeekAvailability
        day_of_week_name='Friday'
        day_of_week_index={4}
        available_hours={value}
        handleAddNewSegment={handleAddNewSegment}
        handleDeleteSegment={handleDeleteSegment}
        handleDeleteAllSegments={handleDeleteAllSegments}
        handleSegmentChanged={handleSegmentChanged}
        allowEmptyDays={allowEmptyDays}
      />

      <DayOfWeekAvailability
        day_of_week_name='Saturday'
        day_of_week_index={5}
        available_hours={value}
        handleAddNewSegment={handleAddNewSegment}
        handleDeleteSegment={handleDeleteSegment}
        handleDeleteAllSegments={handleDeleteAllSegments}
        handleSegmentChanged={handleSegmentChanged}
        allowEmptyDays={allowEmptyDays}
      />

      <DayOfWeekAvailability
        day_of_week_name='Sunday'
        day_of_week_index={6}
        available_hours={value}
        handleAddNewSegment={handleAddNewSegment}
        handleDeleteSegment={handleDeleteSegment}
        handleDeleteAllSegments={handleDeleteAllSegments}
        handleSegmentChanged={handleSegmentChanged}
        allowEmptyDays={allowEmptyDays}
      />
    </div>
  );
};
