import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardContent } from "@mui/material";
import "./ImportedWebpageEditPage.scss";
import { useAPI } from "../../../frontend/components/APIProvider";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import ImportedWebpageForm from "./ImportedWebpageForm";
import CircularProgress from "@mui/material/CircularProgress";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";

export default function ImportedWebpageEditPage(props) {
  const api = useAPI();
  const params = useParams();

  const navigate = useNavigate();
  const [importedWebpage, setImportedWebpage] = useState(null);

  const handleImportedWebpageSave = useCallback(
    async (newImportedWebpage) => {
      return await api.saveImportedWebpage(newImportedWebpage);
    },
    [api]
  );

  const handleDeleteClicked = useCallback(async () => {
    return await api.deleteImportedWebpage(importedWebpage.id).then(() => {
      navigate(`/imported_webpages`);
    });
  }, [navigate, api, importedWebpage?.id]);

  React.useEffect(() => {
    if (!importedWebpage) {
      api
        .getImportedWebpage(params?.importedWebpageId)
        .then((importedWebpage) => {
          setImportedWebpage(importedWebpage);
        });
    }
  }, [
    handleImportedWebpageSave,
    importedWebpage,
    params?.importedWebpageId,
    api,
  ]);

  if (!importedWebpage) {
    return (
      <PageWrapperWithMenu>
        <PageHeader
          title={
            <LocalTranslatedText language={"en"} text='Edit Imported Webpage' />
          }
        />
        <div className='imported-webpage-edit-page'>
          <CircularProgress />
        </div>
      </PageWrapperWithMenu>
    );
  }

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={
          <LocalTranslatedText language={"en"} text='Edit Imported Webpage' />
        }
      />
      <div className='imported-webpage-edit-page'>
        <Card className='imported-webpage-edit-form-card'>
          <CardContent>
            <ImportedWebpageForm
              initialImportedWebpage={importedWebpage}
              onImportedWebpageSave={handleImportedWebpageSave}
              onDeletedClicked={handleDeleteClicked}
            />
          </CardContent>
        </Card>
      </div>
    </PageWrapperWithMenu>
  );
}
