import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Alert, Card, CardContent } from "@mui/material";
import { useAPI } from "../../../frontend/components/APIProvider";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ArticleIcon from "@mui/icons-material/Article";
import "./BulkDocumentUploadPage.scss";

export default function BulkDocumentUploadPage(props) {
  const api = useAPI();
  const [filesBeingUploaded, setFilesBeingUploaded] = useState({});
  const queueRef = useRef([]);
  const isUploadingRef = useRef(false);

  const processQueue = useCallback(async () => {
    if (isUploadingRef.current || queueRef.current.length === 0) return;

    isUploadingRef.current = true;
    while (queueRef.current.length > 0) {
      const fileToUpload = queueRef.current.shift();
      const { file, onUploadProgress } = fileToUpload;

      try {
        await api.createNewImportedDocumentWithFileUpload(
          file,
          onUploadProgress
        );
      } catch (error) {
        console.error(`Error uploading file ${file.name}:`, error);
      }
    }
    isUploadingRef.current = false;
  }, [api]);

  const handleFileUploadButtonPressed = useCallback(() => {
    const fileSelector = document.createElement("input");
    fileSelector.setAttribute("type", "file");
    fileSelector.setAttribute("multiple", "multiple");

    fileSelector.onchange = async (evt) => {
      const files = evt.target.files;

      for (let file of files) {
        const onUploadProgress = (progress) => {
          setFilesBeingUploaded((prevState) => ({
            ...prevState,
            [file.name]: {
              ...prevState[file.name],
              progress: progress,
            },
          }));
        };

        setFilesBeingUploaded((prevState) => ({
          ...prevState,
          [file.name]: {
            file,
            progress: 0,
          },
        }));

        queueRef.current.push({ file, onUploadProgress });
      }
      processQueue();
    };

    fileSelector.click();
  }, [processQueue]);

  useEffect(() => {
    if (!isUploadingRef.current && queueRef.current.length > 0) {
      processQueue();
    }
  }, [filesBeingUploaded, processQueue]);

  return (
    <PageWrapperWithMenu>
      <PageHeader title={`Bulk Document Upload Page`} />
      <div className='bulk-document-upload-page'>
        <Card className='document-edit-form-card'>
          <CardContent>
            <div className='bulk-document-upload-page-content'>
              <Alert severity='warning'>
                <strong>Note:</strong> Ongoing uploads will be canceled if you
                navigate away from this page or reload this page. Please stay on
                this page until all files are uploaded.
              </Alert>
              <div
                className='upload-button-box'
                onClick={handleFileUploadButtonPressed}
                role='button'
                tabIndex={0}
              >
                <Button
                  variant='contained'
                  color='primary'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFileUploadButtonPressed(e);
                  }}
                >
                  Upload Files
                </Button>
              </div>

              <List dense={false}>
                {Object.keys(filesBeingUploaded).map((fileName) => {
                  const progress = filesBeingUploaded[fileName].progress * 100;
                  return (
                    <ListItem key={fileName}>
                      <ListItemAvatar>
                        <Avatar>
                          <ArticleIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={fileName}
                        secondary={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <LinearProgress
                              variant='determinate'
                              value={progress}
                              style={{ flex: 1, marginRight: "8px" }}
                            />
                            <span>{`${Math.round(progress)}%`}</span>
                          </div>
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            </div>
          </CardContent>
        </Card>
      </div>
    </PageWrapperWithMenu>
  );
}
