import { PageWrapperWithMenu } from "../../../core/frontend/elements/PageWrapperWithMenu";
import React, { useCallback } from "react";
import "../../../core/frontend/pages/HomePage.scss";
import { Card, CardContent } from "@mui/material";
import "./CustomerEditPage.scss";
import { useAPI } from "../../../core/frontend/components/APIProvider";
import { PageHeader } from "../../../core/frontend/elements/PageHeader";
import CustomerForm from "./CustomerForm";
import { useNavigate } from "react-router-dom";

export default function NewCustomerPage(props) {
  const api = useAPI();
  const navigate = useNavigate();

  const handleCreateCustomer = useCallback(
    async (customerData) => {
      return await api.createNewCustomer(customerData).then((newCustomer) => {
        navigate(`/customers/${newCustomer.id}`);
      });
    },
    [api, navigate]
  );

  return (
    <PageWrapperWithMenu>
      <PageHeader title={`New Customer`} />
      <div className='customer-edit-page'>
        <Card className='customer-edit-form-card'>
          <CardContent>
            <CustomerForm
              initialCustomer={{}}
              onCustomerSave={handleCreateCustomer}
              saveButtonText={"Create New Customer"}
            />
          </CardContent>
        </Card>
      </div>
    </PageWrapperWithMenu>
  );
}
