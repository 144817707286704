import React from "react";
import {PageWrapperWithMenu} from "../../../core/frontend/elements/PageWrapperWithMenu";
import {ChartEditor} from "./ChartEditor";
import {useParams} from "react-router-dom";


export default function ChartEditPage() {
    const params = useParams();

    const chartId = params.chartId;

    return <PageWrapperWithMenu padding={false}>
        <ChartEditor
            chartId={chartId}
            local={false}
        />
    </PageWrapperWithMenu>
}

