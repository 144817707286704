import React, {useCallback, useEffect} from 'react';
import "./StoryPDFViewer.scss"
import {useAPI} from "../../core/frontend/components/APIProvider";
import CircularProgress from "@mui/material/CircularProgress";

export const StoryPDFViewer = ({story}) => {
    const [pdfUrl, setPdfUrl] = React.useState(null);
    const [isGeneratingPDF, setIsGeneratingPDF] = React.useState(false);
    const api = useAPI();

    const generatePDF = useCallback(() => {
        setIsGeneratingPDF(true);
        return api.downloadPDFForStory(story).then((pdfData) => {

            const blob = new Blob([pdfData], {type: 'application/pdf'});
            const url = URL.createObjectURL(blob);
            setPdfUrl(url);
            setIsGeneratingPDF(false);
        });
    }, [api, story]);

    useEffect(() => {
        if (!pdfUrl && !isGeneratingPDF) {
            generatePDF();
        }
    }, [pdfUrl, generatePDF, isGeneratingPDF]);

    return (
        <div className={"story-pdf-viewer"}>
            {
                pdfUrl ?
                    <iframe src={pdfUrl}/>
                    : <div>
                        <p>Generating PDF...</p>
                        <CircularProgress/>
                    </div>
            }

        </div>
    );
};
