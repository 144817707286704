import {PageWrapperWithMenu} from "../../../core/frontend/elements/PageWrapperWithMenu";
import React from "react";
import "../../../core/frontend/pages/HomePage.scss";
import {useNavigate} from "react-router-dom";
import {useAPI} from "../../../core/frontend/components/APIProvider";
import {InsuranceAgentsTable} from "./InsuranceAgentsTable";
import "./InsuranceAgentsListPage.scss";
import {PageHeader} from "../../../core/frontend/elements/PageHeader";
import {WhiteButton} from "../../../core/frontend/elements/WhiteButton";
import Paper from "@mui/material/Paper";
import {useCallback} from "react";


export default function InsuranceAgentsListPage() {
    const navigate = useNavigate();

    const handleNewInsuranceAgentClicked = useCallback(async () => {
        navigate(`/insurance_agents/new`);
    }, [navigate]);


    return <PageWrapperWithMenu>
        <PageHeader title={"Insurance Agents"}>
            <WhiteButton onClick={handleNewInsuranceAgentClicked}>
                Add New Insurance Agent
            </WhiteButton>
        </PageHeader>
        <div className={"insurance_agent-list-page"}>
            <div className={"insurance_agent-table-wrapper"}>
                <Paper className={"main-page-contents"}>
                    <InsuranceAgentsTable/>
                </Paper>
            </div>
        </div>
    </PageWrapperWithMenu>
}

