import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardContent } from "@mui/material";
import "./ImportedDocumentEditPage.scss";
import { useAPI } from "../../../frontend/components/APIProvider";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import ImportedDocumentForm from "./ImportedDocumentForm";
import CircularProgress from "@mui/material/CircularProgress";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";

export default function ImportedDocumentEditPage(props) {
  const api = useAPI();
  const params = useParams();

  const navigate = useNavigate();
  const [document, setDocument] = useState(null);

  const handleDocumentSave = useCallback(
    async (newDocument) => {
      return await api.saveImportedDocument(newDocument);
    },
    [api]
  );

  const handleDeleteClicked = useCallback(async () => {
    return await api.deleteImportedDocument(document.id).then(() => {
      navigate(`/imported_documents`);
    });
  }, [navigate, api, document?.id]);

  React.useEffect(() => {
    if (!document) {
      api.getImportedDocument(params?.documentId).then((document) => {
        setDocument(document);
      });
    }
  }, [handleDocumentSave, document, params?.documentId, api]);

  if (!document) {
    return (
      <PageWrapperWithMenu>
        <PageHeader
          title={<LocalTranslatedText language={"en"} text='Edit Document' />}
        />
        <div className='document-edit-page'>
          <CircularProgress />
        </div>
      </PageWrapperWithMenu>
    );
  }

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={<LocalTranslatedText language={"en"} text='Edit Document' />}
      />
      <div className='document-edit-page'>
        <Card className='document-edit-form-card'>
          <CardContent>
            <ImportedDocumentForm
              initialDocument={document}
              onDocumentSave={handleDocumentSave}
              onDeletedClicked={handleDeleteClicked}
            />
          </CardContent>
        </Card>
      </div>
    </PageWrapperWithMenu>
  );
}
