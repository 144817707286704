import React, {useCallback, useEffect, useMemo} from 'react';
import "./VoiceSelector.scss";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Radio from "@mui/material/Radio";
import {useAPI} from "../../../core/frontend/components/APIProvider";
import {useFrontendConfiguration} from "../../frontend/components/FrontendConfigurationProvider";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";


const VoiceOptionList = ({voiceOptions, selectedVoiceId, handleVoiceOptionClicked}) => {
    return <List dense sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
        {voiceOptions.map((voiceOption) => {
            const labelId = `phone-number-selector-${voiceOption.voiceId}`;

            const voiceDescriptionText = `${voiceOption.name}`

            return (
                <ListItem
                    key={voiceOption.voiceId}
                    secondaryAction={
                        <Radio
                            edge="end"
                            checked={selectedVoiceId === voiceOption.voiceId}
                            onChange={() => handleVoiceOptionClicked(voiceOption)}
                            inputProps={{'aria-labelledby': labelId}}
                        />
                    }
                    onClick={() => handleVoiceOptionClicked(voiceOption)}
                    disablePadding
                >
                    <ListItemButton>
                        <ListItemText id={labelId} primary={voiceDescriptionText}/>
                    </ListItemButton>
                </ListItem>
            )
        })
        }
    </List>
}


let globalCurrentlyPlayingAudioClip = null;

export const VoiceSelector = ({value, onChange}) => {
    const [voiceOptions, setVoiceOptions] = React.useState(null);
    const api = useAPI();
    const features = useFrontendConfiguration();

    useEffect(() => {
        api.getAvailableVoices().then((response) => {
            console.log(response);
            const voiceOptions = response.available_voices;
            voiceOptions.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            }, []);

            setVoiceOptions(voiceOptions);
        });
    }, [api]);

    const handleVoiceOptionClicked = useCallback((voiceOption) => {
        if (onChange) {
            onChange(voiceOption.voiceId);
        }

        if (globalCurrentlyPlayingAudioClip) {
            if (!globalCurrentlyPlayingAudioClip.src.endsWith(voiceOption.sampleUrl)) {
                globalCurrentlyPlayingAudioClip.src = voiceOption.sampleUrl;
                globalCurrentlyPlayingAudioClip.play();
            }
        } else {
            globalCurrentlyPlayingAudioClip = new Audio(voiceOption.sampleUrl);
            globalCurrentlyPlayingAudioClip.play();
        }
    }, [onChange]);

    /**
     * Split the list of voice options into three columns
     */
    const voiceOptionsColumns = useMemo(() => {
        const columns = [[], [], [], []]
        if (!voiceOptions) {
            return columns;
        }

        voiceOptions.forEach((voiceOption, index) => {
            columns[(index % 4)].push(voiceOption);
        });
        return columns;
    }, [voiceOptions]);

    return (
        <div className={"voice-selector"}>
            <div className="voice-selector-columns">
                <div className={"voice-selector-generation-group columns-4"}>
                    <div className={"voice-selector-generation-group-columns"}>
                        <VoiceOptionList
                            voiceOptions={voiceOptionsColumns[0]}
                            selectedVoiceId={value}
                            handleVoiceOptionClicked={handleVoiceOptionClicked}
                        />
                        <VoiceOptionList
                            voiceOptions={voiceOptionsColumns[1]}
                            selectedVoiceId={value}
                            handleVoiceOptionClicked={handleVoiceOptionClicked}
                        />
                        <VoiceOptionList
                            voiceOptions={voiceOptionsColumns[2]}
                            selectedVoiceId={value}
                            handleVoiceOptionClicked={handleVoiceOptionClicked}
                        />
                        <VoiceOptionList
                            voiceOptions={voiceOptionsColumns[3]}
                            selectedVoiceId={value}
                            handleVoiceOptionClicked={handleVoiceOptionClicked}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
