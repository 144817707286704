import React from "react";
import { TextInput } from "./TextInput";
import { formatPhoneNumberHumanReadable } from "../../../utils/phone";
import { isPossiblePhoneNumber } from "libphonenumber-js";

const isPhoneValid = (phone_number) => {
  return isPossiblePhoneNumber(phone_number, "US");
};

export function PhoneNumberInput({ label, fieldName, size }) {
  return (
    <TextInput
      fieldName={fieldName}
      size={size || "medium"}
      label={label}
      example={"+1 (555) 555-5555"}
      formatter={formatPhoneNumberHumanReadable}
      validator={isPhoneValid}
      errorHelperText={"Invalid phone number"}
    />
  );
}

export default PhoneNumberInput;
