import * as React from 'react';
import {TableView} from "../../../core/frontend/elements/TableView";
import {useAPI} from "../../../core/frontend/components/APIProvider";
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

export function TopicExtractorPromptTable() {
    const api = useAPI();
    const navigate = useNavigate();

    const handleTopicExtractorPromptRowClicked = useCallback((topicExtractorPrompt) => {
        navigate(`/topic_extractor_prompts/${topicExtractorPrompt.id}`);
    }, [navigate]);


    return (
        <TableView
            schemaAPI={api.getTopicExtractorPromptSchema}
            fetchAPI={api.getTopicExtractorPrompts}
            onRowClicked={handleTopicExtractorPromptRowClicked}
        />
    );
}
