/**
 * This file just contains a list of hard coded prompt templates, along with all their details
 */

import FindInPageIcon from '@mui/icons-material/FindInPage';
import StarsIcon from '@mui/icons-material/Stars';
import CreateIcon from '@mui/icons-material/Create';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import CategoryIcon from '@mui/icons-material/Category';
import ReviewsIcon from '@mui/icons-material/Reviews';
import GradingIcon from '@mui/icons-material/Grading';
import ChecklistIcon from '@mui/icons-material/Checklist';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import SubjectIcon from '@mui/icons-material/Subject';
import * as React from "react";
import PromptManipulator from "./PromptManipulator";


export class PromptTemplate {
    static piecesMemoizedSymbol = Symbol("piecesMemoized");

    constructor(data) {
        this.title = data.title;
        this.id = data.id;
        this.icon = data.icon;
        this.template = data.template;
    }

    getPieces() {
        if (this[PromptTemplate.piecesMemoizedSymbol]) {
            return this[PromptTemplate.piecesMemoizedSymbol];
        }

        const pieces = [];

        let remainingTemplateString = this.template;
        while (remainingTemplateString.includes("[") || remainingTemplateString.includes("\n")) {
            const newLineIndex = remainingTemplateString.indexOf("\n");
            const bracketStartIndex = remainingTemplateString.indexOf("[");

            if (newLineIndex !== -1 && (newLineIndex < bracketStartIndex || bracketStartIndex === -1)) {
                const text = remainingTemplateString.substring(0, newLineIndex);
                pieces.push({
                    "type": "text",
                    "text": text,
                    "index": pieces.length,
                });
                pieces.push({
                    "type": "newline",
                    "text": "\n",
                    "index": pieces.length,
                });
                remainingTemplateString = remainingTemplateString.substring(newLineIndex + 1);
            } else if (bracketStartIndex !== -1) {
                const bracketEndIndex = remainingTemplateString.indexOf("]");
                const insertionData = remainingTemplateString.substring(bracketStartIndex + 1, bracketEndIndex);
                // Get all the text that lead up to the square bracket
                const text = remainingTemplateString.substring(0, bracketStartIndex);

                let insertionPrefix = "";
                let insertionName = "";
                let insertionSuffix = "";
                const insertionParts = insertionData.split("|");
                if (insertionParts.length === 1) {
                    insertionName = insertionParts[0];
                } else if (insertionParts.length === 2) {
                    insertionPrefix = insertionParts[0];
                    insertionName = insertionParts[1];
                } else if (insertionParts.length === 2) {
                    insertionPrefix = insertionParts[0];
                    insertionName = insertionParts[1];
                    insertionSuffix = insertionParts[2];
                }

                pieces.push({
                    "type": "text",
                    "text": text,
                    "index": pieces.length,
                });
                pieces.push({
                    "type": "insertion",
                    "insertionName": insertionName,
                    "insertionPrefix": insertionPrefix,
                    "insertionSuffix": insertionSuffix,
                    "index": pieces.length,
                });

                remainingTemplateString = remainingTemplateString.substring(bracketEndIndex + 1);
            }
        }
        if (remainingTemplateString) {
            pieces.push({
                "type": "text",
                "text": remainingTemplateString,
                "index": pieces.length,
            });
        }

        this[PromptTemplate.piecesMemoizedSymbol] = pieces;

        return pieces;
    }

    constructPrompt(templateInsertionValues) {
        const pieces = this.getPieces();

        let finalPrompt = "";
        for (let piece of pieces) {
            if (piece.type === "text") {
                if (piece.text.trim()) {
                    finalPrompt += `<span>${piece.text}</span>`;
                }
            } else if (piece.type === "insertion") {
                const prefix = piece.insertionPrefix ?? "";
                const suffix = piece.insertionSuffix ?? "";
                const value = (templateInsertionValues[piece.insertionName] ?? "").trim();
                const subPrompt = new PromptManipulator(value);
                subPrompt.simplifyPromptStructureToOnlyMentions();
                if (!subPrompt.isPromptEmpty()) {
                    const insertion = `${prefix}${subPrompt.toString()}${suffix}`;
                    finalPrompt += insertion;
                }
            } else if (piece.type === "newline") {
                // Don't add a bunch of consecutive newlines to the prompt. Max two consecutive newlines
                if (!finalPrompt.endsWith("<br/><br/>")) {
                    finalPrompt += "<br/>";
                }
            }
        }

        return finalPrompt;
    }

    getInsertionPoints() {
        const pieces = this.getPieces();

        const insertionPoints = [];
        for (let piece of pieces) {
            if (piece.type === "insertion") {
                insertionPoints.push(piece.insertionName);
            }
        }

        return insertionPoints;
    }
}


export const templateGroups = [
    {
        title: "Find & Extract Information",
        id: "find-extract",
        icon: <FindInPageIcon/>,
        explanation: "Use these prompts when you have large documents with a lot of irrelevant information. The AI works best when you only give it just the relevant details."
    },
    {
        title: "Categorize & Group",
        id: "categorize-group",
        icon: <GroupWorkIcon/>,
        explanation: "These prompts come in handy when you want to categorize information them into one of several groups."
    },
    {
        title: "Rank & Analyze",
        id: "rank-analyze",
        icon: <StarsIcon/>,
        explanation: "With these prompts, you can take your data and rank if from bad to good, or determine if the data meets some criteria."
    },
    {
        title: "Write & Summarize",
        id: "write-summarize",
        icon: <CreateIcon/>,
        explanation: "These prompts are useful useful when you need the AI to write for you, such as composing an email or summarizing a document"
    }
];

export const templatesByGroup = {
    'find-extract': [
        new PromptTemplate({
            title: "Extract a large block of data",
            id: "extract-large-block",
            icon: <FormatAlignJustifyIcon/>,
            template: "The following information is a single page from a larger document. " +
                "If the below information contains [Description of data to extract], then please display that information exactly word for word.\n" +
                "Otherwise, show 'No information found'.\n" +
                "[Input Data]\n" +
                "The above information was a single page from a larger document. " +
                "If the above information contains [Description of data to extract], then please display that information exactly word for word.\n" +
                "Otherwise, show 'No information found'."
        }),
        new PromptTemplate({
            title: "Find a small data point",
            id: "find-small-data-point",
            icon: <ManageSearchIcon />,
            template: "Given the following information: [Input Data]\n" +
                "Extract [Description of data to extract] from the above text.\n" +
                "Just display the extracted data with nothing else.\n" +
                "If you do not see the data in the document, show 'Nothing found'."
        })
    ],
    'categorize-group': [
        new PromptTemplate({
            title: "Categorize text into one of a few categories",
            id: "categorize-text",
            icon: <CategoryIcon/>,
            template: "Given the following information: [Input Data]\n" +
                "Categorize the given information into one of the following categories:\n" +
                "[    - |Category 1 Name][: |Optional Category 1 Description]\n" +
                "[    - |Category 2 Name][: |Optional Category 2 Description]\n" +
                "[    - |Category 3 Name][: |Optional Category 3 Description]\n" +
                "[    - |Category 4 Name][: |Optional Category 4 Description]\n" +
                "[    - |Category 5 Name][: |Optional Category 5 Description]\n" +
                "[    - |Category 6 Name][: |Optional Category 6 Description]\n" +
                "[    - |Category 7 Name][: |Optional Category 7 Description]\n" +
                "[    - |Category 8 Name][: |Optional Category 8 Description]\n" +
                "[    - |Category 9 Name][: |Optional Category 9 Description]\n" +
                "[    - |Category 10 Name][: |Optional Category 10 Description]\n" +
                "First provide a short, one sentence reason for your categorization, a blank line, and then your final categorization.\n" +
                "If the data doesn't fit into any of the categories, show \"Not Found\""
        })
    ],
    'rank-analyze': [
        new PromptTemplate({
            title: "Rank text good/bad along a single dimension",
            id: "rank-good-bad-single",
            icon: <ReviewsIcon/>,
            template: "Analyze the following information with the given criteria: [Input Data]\n" +
                "Rate the given information as being bad or good along using the following criteria:\n" +
                "[Description of how you want the text rated]\n" +
                "Use one of the following descriptors for your rating\n" +
                " - Terrible (1)\n" +
                " - Poor (2)\n" +
                " - Mediocre (3)\n" +
                " - Average (4)\n" +
                " - Fair (5)\n" +
                " - Good (7)\n" +
                " - Excellent (8)\n" +
                "First provide a short, one sentence reason for your rating, a blank line, and then your final rating.\n" +
                "Provide both the qualitative rating word, followed by its number, in your answer.\n"
        }),
        new PromptTemplate({
            title: "Rank text good/bad along several dimensions",
            id: "rank-good-bad-multiple",
            icon: <GradingIcon/>,
            template: "Analyze the following information with the given criteria: [Input Data]\n" +
                "Rate the given information as being bad or good along each of the following dimensions:\n" +
                "[    - |Criteria 1 Name][: |Optional Criteria 1 Description]\n" +
                "[    - |Criteria 2 Name][: |Optional Criteria 2 Description]\n" +
                "[    - |Criteria 3 Name][: |Optional Criteria 3 Description]\n" +
                "[    - |Criteria 4 Name][: |Optional Criteria 4 Description]\n" +
                "[    - |Criteria 5 Name][: |Optional Criteria 5 Description]\n" +
                "[    - |Criteria 6 Name][: |Optional Criteria 6 Description]\n" +
                "[    - |Criteria 7 Name][: |Optional Criteria 7 Description]\n" +
                "[    - |Criteria 8 Name][: |Optional Criteria 8 Description]\n" +
                "[    - |Criteria 9 Name][: |Optional Criteria 9 Description]\n" +
                "[    - |Criteria 10 Name][: |Optional Criteria 10 Description]\n" +
                "For each of the criteria, use the following descriptors for your rating.\n" +
                " - Terrible (1)\n" +
                " - Poor (2)\n" +
                " - Mediocre (3)\n" +
                " - Average (4)\n" +
                " - Fair (5)\n" +
                " - Good (7)\n" +
                " - Excellent (8)\n" +
                "For each criteria, provide the criteria name, a short, one sentence reason for your rating, and lastly your final rating at the end.\n" +
                "Provide both the qualitative rating word, followed by its number, in your answer.\n"
        }),
        new PromptTemplate({
            title: "Determine if text meets criteria",
            id: "check-criteria",
            icon: <ChecklistIcon/>,
            template: "Analyze the following information in order to determine if it meets the given criteria: [Input Data]\n" +
                "Determine if the given information meets the following criteria:\n" +
                "[    - |Criteria 1 Name][: |Optional Criteria 1 Description]\n" +
                "[    - |Criteria 2 Name][: |Optional Criteria 2 Description]\n" +
                "[    - |Criteria 3 Name][: |Optional Criteria 3 Description]\n" +
                "[    - |Criteria 4 Name][: |Optional Criteria 4 Description]\n" +
                "[    - |Criteria 5 Name][: |Optional Criteria 5 Description]\n" +
                "[    - |Criteria 6 Name][: |Optional Criteria 6 Description]\n" +
                "[    - |Criteria 7 Name][: |Optional Criteria 7 Description]\n" +
                "[    - |Criteria 8 Name][: |Optional Criteria 8 Description]\n" +
                "[    - |Criteria 9 Name][: |Optional Criteria 9 Description]\n" +
                "[    - |Criteria 10 Name][: |Optional Criteria 10 Description]\n" +
                "Use either Yes, No, or Maybe to indicate if the criteria is met.\n" +
                "For each criteria, provide the criteria name, then provide a short, one sentence reason for your decision. Then lastly, give your final decision on whether that criteria is met or not.\n" +
                "At the end, output the number of criteria met and True/False whether all the criteria were met.\n"
        }),
        new PromptTemplate({
            title: "Create a Pros/Cons list",
            id: "pros-cons",
            icon: <ThumbsUpDownIcon/>,
            template: "Come up with a Pros/Cons list for the following information: [Input Data]\n" +
                "Provide the pros, rewards, benefits, and advantages first.\n" +
                "Then provide the cons, costs, downsides, and disadvantages second.\n"
        }),
    ],
    'write-summarize': [
        new PromptTemplate({
            title: "Summarize the given information",
            id: "summarize",
            icon: <SubjectIcon/>,
            template: "Please summarize the given information: [Input Data]\n" +
                "In particular, try to pull out the following most important details if they are present:\n" +
                "[    - |Important Detail 1 Name][: |Optional Important Detail 1 Description]\n" +
                "[    - |Important Detail 2 Name][: |Optional Important Detail 2 Description]\n" +
                "[    - |Important Detail 3 Name][: |Optional Important Detail 3 Description]\n"

        }),
        new PromptTemplate({
            title: "Write an email, article, document, or other long-form text",
            id: "write",
            icon: <CreateIcon/>,
            template: "Base on the following information: [Input Data]\n" +
                "Please write me a [Type of Document]\n" +
                "Write in the voice of a [Job title of person who typically writes this type of document]\n" +
                "Use the following style in your writing: [The style or tone you want to evoke]\n"
        }),
    ],
}

export function findTemplateById(id) {
    for (let group of templateGroups) {
        for (let template of templatesByGroup[group.id]) {
            if (template.id === id) {
                return template;
            }
        }
    }
    return null;
}
