import React, {useCallback} from 'react';
import "./StoryPromptConfigurationForm.scss";
import PromptChartBindingWidget from "../../core/prompt_chart/frontend/PromptChartBindingWidget";
import {MainStoryTemplate, MainPageTemplate} from "./story_prompt_chart_templates";


export const StoryPromptConfigurationForm = ({storyPromptConfiguration, onChange}) => {
    const handleChangeStoryPromptChart = useCallback((value) => {
        onChange({...storyPromptConfiguration, story_prompt_chart_id: value});
    }, [storyPromptConfiguration, onChange]);

    const handleChangePagePromptChart = useCallback((value) => {
        onChange({...storyPromptConfiguration, page_prompt_chart_id: value});
    }, [storyPromptConfiguration, onChange]);

    return (
        <div className={"story-prompt-configuration-form"}>
            <PromptChartBindingWidget
                template={MainStoryTemplate}
                label={"Story Prompt Chart"}
                name={"story_prompt_chart_id"}
                value={storyPromptConfiguration.story_prompt_chart_id}
                onChange={handleChangeStoryPromptChart}
            />
            <PromptChartBindingWidget
                template={MainPageTemplate}
                label={"Page Prompt Chart"}
                name={"page_prompt_chart_id"}
                value={storyPromptConfiguration.page_prompt_chart_id}
                onChange={handleChangePagePromptChart}
            />
        </div>
    );
};
