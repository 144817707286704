import React, { useEffect, useRef } from "react";
import { FormProvider as Form, useWatch } from "react-hook-form";
import useDebounce from "../../../hooks/useDebounce";
import { areDateRangesOverlapping } from "../../../utils/string-helper";
import { useSnackbar } from "../../../components/SnackbarProvider";

export default function FormProvider({
  methods,
  onSubmit,
  children,
  onAutoSave,
  isResume = false,
}) {
  const formData = useWatch({ control: methods.control });
  const debouncedFormData = useDebounce(formData, 500);
  const snackbar = useSnackbar();

  // Ref to store the previous form data
  const prevFormData = useRef(formData);

  const checkDateOverlapping = () => {
    const workHistory = formData?.work_history;
    if (areDateRangesOverlapping(workHistory)) return true;
    return false;
  };

  useEffect(() => {
    if (onAutoSave) {
      if (
        JSON.stringify(debouncedFormData) !==
        JSON.stringify(prevFormData?.current)
      ) {
        if (isResume && checkDateOverlapping()) {
          snackbar.toast({
            message:
              "One or more work history date ranges are overlapping. Please correct them.",
            severity: "error",
            autoHideMs: 5000,
          });
        }
        onAutoSave(debouncedFormData);
        prevFormData.current = debouncedFormData;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFormData, onAutoSave]);

  return (
    <Form {...methods}>
      <form onSubmit={onSubmit}>{children}</form>
    </Form>
  );
}
