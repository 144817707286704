import React, { useCallback } from "react";
import FormInput from "../../core/frontend/elements/form/FormInput";
import FormRoot from "../../core/frontend/elements/form/FormRoot";
import FormSection from "../../core/frontend/elements/form/FormSection";
import { SpinnerButton } from "../../core/frontend/elements/SpinnerButton";
import TranscriptProcessorConfigurationSelector from "./transcript_processor_configuration/TranscriptProcessorConfigurationSelector";
import "./ProcessTranscriptForm.scss";

export default function ProcessTranscriptForm({
  initialData,
  onGenerateClicked,
  onDeletedClicked,
}) {
  const handleSaveClicked = useCallback(
    (newData) => {
      if (onGenerateClicked) {
        return onGenerateClicked(newData);
      }
    },
    [onGenerateClicked]
  );

  return (
    <div className='process-transcript-form'>
      <FormRoot
        initialValue={initialData}
        saveButtonText={"Process Transcript"}
        onSave={handleSaveClicked}
        extraButtons={
          <>
            {onDeletedClicked ? (
              <SpinnerButton
                variant='contained'
                color='error'
                className={"delete-button"}
                onClick={onDeletedClicked}
              >
                Delete
              </SpinnerButton>
            ) : null}
          </>
        }
      >
        <FormSection
          title={"Config"}
          subtitle={
            "Select which processor configuration you would like to use"
          }
        >
          <TranscriptProcessorConfigurationSelector
            label={"Transcript Processor Configuration"}
            fieldName={"transcript_processor_configuration_id"}
          />
        </FormSection>

        <FormSection
          title={"Transcript"}
          subtitle={"Paste in the transcript you would like to process"}
        >
          <FormInput
            type={"text"}
            label={"Transcript"}
            fieldName={"transcript"}
            multiline={true}
            rows={8}
          />
        </FormSection>
      </FormRoot>
    </div>
  );
}
