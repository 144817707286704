import {PageWrapperWithMenu} from "../../../core/frontend/elements/PageWrapperWithMenu";
import React, {useCallback} from "react";
import {useNavigate} from "react-router-dom";
import {ConversationsTable} from "./ConversationsTable";
import Paper from "@mui/material/Paper";
import "./ConversationsListPage.scss";
import {PageHeader} from "../../../core/frontend/elements/PageHeader";


export default function ConversationsListPage() {
    const navigate = useNavigate();

    const handleConversationClicked = useCallback((conversation) => {
        navigate(`/conversations/${conversation.id}`);
    }, [navigate]);


    return <PageWrapperWithMenu>
        <PageHeader title={"Conversations"} />
        <div className="conversation-list-page">
            <Paper className={"main-page-contents"}>
                <ConversationsTable onConversationClicked={handleConversationClicked}/>
            </Paper>
        </div>
    </PageWrapperWithMenu>
}

