import {List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import React from "react";
import OutboundIcon from "@mui/icons-material/Outbound";
import MessageIcon from "@mui/icons-material/Message";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import moment from "moment";
import ListItemIcon from "@mui/material/ListItemIcon";

export const ScheduledOutboundListItem = ({scheduledOutbound}) => {
    let icon = <OutboundIcon/>;
    if (scheduledOutbound.rule.communication_channel === 'sms') {
        icon = <MessageIcon/>;
    } else if (scheduledOutbound.rule.communication_channel === 'phone_call') {
        icon = <PhoneIcon/>;
    } else if (scheduledOutbound.rule.communication_channel === 'email') {
        icon = <EmailIcon/>;
    }

    const dateString = moment.utc(scheduledOutbound.scheduled_outbound_datetime_utc).local().format("MMM DD, YYYY");
    const timeString = moment.utc(scheduledOutbound.scheduled_outbound_datetime_utc).local().format("h:mm a");

    return <ListItem>
        <ListItemIcon>
            {icon}
        </ListItemIcon>
        <ListItemText
            primary={`${dateString} after ${timeString} - ${scheduledOutbound.rule.prompt || "No specific instructions provided"}`}
        />
    </ListItem>
}
export const ScheduledOutboundList = ({outboundState, outboundWord}) => {

    return <List>
        {
            outboundState.next_ten_scheduled_outbounds.map((scheduledOutbound, index) => (
                <ScheduledOutboundListItem key={index} scheduledOutbound={scheduledOutbound}/>
            ))
        }
        {
            outboundState.next_ten_scheduled_outbounds.length === 0 ?
                <ListItem>
                    <ListItemText primary={`No future ${outboundWord.toLowerCase()}s are scheduled`}/>
                </ListItem>
                : null
        }
    </List>
}
