import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import "./FileInputNode.scss";
import useChartStore from "../../ChartState";
import NodeTitleEditor from "../../components/NodeTitleEditor";
import {NodeOutputHandle} from "../../components/NodeHandle";
import CardActions from "@mui/material/CardActions";
import DeleteNodeActionButton from "../../components/DeleteNodeActionButton";
import {FileInputNodeIcon} from "../../components/icons";
import {ShrinkingCardHeader} from "../../components/ShrinkingCardHeader";
import NodeFilePreview from "../../components/NodeFilePreview";

function FileInputNode({id: nodeId, isConnectable}) {
    const {node} = useChartStore((state) => {
        return {
            node: state.getNode(nodeId),
        }
    });

    if (!node) {
        return null;
    }

    return (
        <div className="file-input-node" title={"File Input"}>
            <Card>
                <ShrinkingCardHeader
                    node={node}
                    avatar={<FileInputNodeIcon/>}
                    title={<NodeTitleEditor
                    nodeId={nodeId}/>}
                />
                <CardContent>
                    <NodeFilePreview nodeId={nodeId} />
                </CardContent>
                <CardActions disableSpacing className={"file-input-node-action-bar"}>
                    <DeleteNodeActionButton nodeId={nodeId}/>
                </CardActions>
            </Card>
            <NodeOutputHandle isConnectable={isConnectable} nodeId={nodeId}/>
        </div>
    );
}

export default FileInputNode;
