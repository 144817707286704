import axios from "axios";
import {ensureMinimumPromiseResolveTime} from "../../../core/frontend/utils/time";
import {axiosETAGCache} from "axios-etag-cache";

let globalDocumentTemplateSchema = null;
let globalGeneratedDocumentSchema = null;

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);
const api = {
    async getDocumentTemplateSchema() {
        if (globalDocumentTemplateSchema) {
            return globalDocumentTemplateSchema;
        }
        const response = await axiosWithETAGCache.get(`/document_template/schema`);
        globalDocumentTemplateSchema = response.data;
        return response.data;
    },
    async createNewDocumentTemplate(newDocumentTemplate) {
        const body = newDocumentTemplate;

        const response = await ensureMinimumPromiseResolveTime(axios.post(`/document_template`, body), 1000);

        return response.data;
    },
    async getDocumentTemplateTable(tableQuery) {
        const query = {
            query: JSON.stringify(tableQuery)
        };

        const response = await axiosWithETAGCache.get(`/document_template_table`, {params: query});

        return response.data;
    },
    async getDocumentTemplate(documentTemplateId) {
        const body = {}

        const response = await axiosWithETAGCache.get(`/document_template/${documentTemplateId}`, body);

        return response.data;
    },
    async saveDocumentTemplate(templateId, changes) {
        const response = await axios.put(`/document_template/${templateId}`, changes);

        return response.data;
    },
    async deleteDocumentTemplate(documentTemplateId) {
        const response = await ensureMinimumPromiseResolveTime(axios.delete(`/document_template/${documentTemplateId}`), 1000);

        return response.data;
    },
    async renderedDocumentTemplate(documentTemplateId, data) {
        const response = await axios.post(`/document_template/${documentTemplateId}/render`,  data,{responseType: "blob"});
        return response.data;
    },
    async getGeneratedDocumentSchema() {
        if (globalGeneratedDocumentSchema) {
            return globalGeneratedDocumentSchema;
        }
        const response = await axiosWithETAGCache.get(`/generated_document/schema`);
        globalGeneratedDocumentSchema = response.data;
        return response.data;
    },
    async createNewGeneratedDocument(newGeneratedDocument) {
        const body = newGeneratedDocument;

        const response = await ensureMinimumPromiseResolveTime(axios.post(`/generated_document`, body), 1000);

        return response.data;
    },
    async getGeneratedDocumentTable(tableQuery) {
        const query = {
            query: JSON.stringify(tableQuery)
        };

        const response = await axiosWithETAGCache.get(`/generated_document_table`, {params: query});

        return response.data;
    },
    async getGeneratedDocument(generatedDocumentId) {
        const body = {}

        const response = await axiosWithETAGCache.get(`/generated_document/${generatedDocumentId}`, body);

        return response.data;
    },
    async saveGeneratedDocument(generatedDocument) {
        const response = await axios.put(`/generated_document/${generatedDocument.id}`, generatedDocument);

        return response.data;
    },
    async deleteGeneratedDocument(generatedDocumentId) {
        const response = await ensureMinimumPromiseResolveTime(axios.delete(`/generated_document/${generatedDocumentId}`), 1000);

        return response.data;
    },
    async renderGeneratedDocument(generatedDocumentId) {
        const response = await axios.get(`/generated_document/${generatedDocumentId}/render`, {responseType: "blob"})
        return response.data;
    },
}

export default api;