import React, { useState } from "react";
import { TextField, Chip } from "@mui/material";
import "./CommaSeparatedInput.scss";

const CommaSeparatedInput = ({ onChange, values, size, ...rest }) => {
  const [value, setValue] = useState("");

  const handleInputChange = (e) => {
    setValue(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();

      const newItems = value
        .split(",")
        .map((item) => item.trim())
        .filter((item) => item && !values.includes(item));

      onChange([...values, ...newItems]);
      setValue("");
    }
  };

  const handleDelete = (itemToDelete) => {
    onChange(values.filter((item) => item !== itemToDelete));
  };

  return (
    <div className='comma-separated-input'>
      <TextField
        value={value}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        fullWidth
        size={size || "medium"}
        {...rest}
      />
      <div className='chips-container'>
        {values.map((item, index) => (
          <Chip key={index} label={item} onDelete={() => handleDelete(item)} />
        ))}
      </div>
    </div>
  );
};

export default CommaSeparatedInput;
