import React, { useCallback } from "react";
import "./InsuranceAgentEditPage.scss";
import FormInput from "../../../core/frontend/elements/form/FormInput";
import FormRoot from "../../../core/frontend/elements/form/FormRoot";
import FormSection from "../../../core/frontend/elements/form/FormSection";
import { SpinnerButton } from "../../../core/frontend/elements/SpinnerButton";
import "./InsuranceAgentForm.scss";

export default function InsuranceAgentForm({
  initialInsuranceAgent,
  onInsuranceAgentSave,
  onDeletedClicked,
  saveButtonText,
}) {
  // const [insurance_agent, setInsuranceAgent] = React.useState(null);

  const handleInsuranceAgentSaved = useCallback(
    (newInsuranceAgentData) => {
      if (onInsuranceAgentSave) {
        return onInsuranceAgentSave(newInsuranceAgentData);
      }
    },
    [onInsuranceAgentSave]
  );

  return (
    <div className={"insurance-agent-form"}>
      <FormRoot
        initialValue={initialInsuranceAgent}
        saveButtonText={saveButtonText ?? "Save"}
        // onChange={setInsuranceAgent}
        onSave={handleInsuranceAgentSaved}
        extraButtons={
          <>
            {onDeletedClicked ? (
              <SpinnerButton
                variant='contained'
                color='error'
                className={"delete-button"}
                onClick={onDeletedClicked}
              >
                Delete
              </SpinnerButton>
            ) : null}
          </>
        }
      >
        <FormSection
          title={"Insurance Agent Details"}
          subtitle={"General information about the insurance agent"}
        >
          <FormInput
            type={"full_name"}
            label={"Full Name"}
            fieldName={"full_name"}
          />

          <FormInput
            type={"phone"}
            label={"Work Phone Number"}
            fieldName={"phone_number"}
          />

          <FormInput
            type={"email"}
            label={"Email"}
            fieldName={"email_address"}
          />

          <FormInput
            type={"email"}
            label={"Secondary Email"}
            fieldName={"secondary_email_address"}
            placeholder={
              "This email will receive a copy of all communications sent to the agent."
            }
          />
        </FormSection>
      </FormRoot>
    </div>
  );
}
