import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import styles from "./HookForm.module.scss";

export default function RHFCheckboxField({
  name,
  label,
  helperText,
  ...props
}) {
  const { control } = useFormContext();
  return (
    <div className={styles.formGroup}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormControl fullWidth error={!!error} {...props}>
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  checked={field.value}
                  onChange={field.onChange}
                />
              }
              {...props}
              label={label}
            />

            {(!!error || helperText) && (
              <FormHelperText error={!!error}>
                {error ? error?.message : helperText}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </div>
  );
}
