import React from 'react';
import "./StoryFormWidgetContainer.scss";
import Typography from "@mui/material/Typography";

export const StoryFormWidgetContainer = ({children, label, className}) => {
    if (!className) {
        className = "";
    }

    return (
        <div className={"story-form-widget-container"}>
            <div className={"story-form-widget-field-title-container"}>
                <Typography
                    variant="h6"
                    component="h6"
                    gutterBottom>
                    {label}
                </Typography>
            </div>
            <div className={`story-form-widget-field-container ${className}`}>
                {children}
            </div>
        </div>
    );
};
