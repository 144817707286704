import React, {useCallback, useMemo} from 'react';
import {TextField} from '@mui/material';
import {StoryFormWidgetContainer} from "./StoryFormWidgetContainer";
import "./MainCharacterVisualDescriptionSelector.scss"
import _ from "lodash";


export const MainCharacterVisualDescriptionSelector = ({value, onChange, mainCharacterType}) => {
    const [localValue, setLocalValue] = React.useState(value);

    const onChangeDebounced = useMemo(() => _.debounce(onChange, 500), [onChange]);

    const handleTextChange = useCallback((event) => {
        onChangeDebounced(event.target.value);
        setLocalValue(event.target.value);
    }, [onChangeDebounced]);

    let label = "Main Character Visual Description";
    if (mainCharacterType === "your pet") {
        label = "Your Pets Visual Description";
    } else if (mainCharacterType === "your kid") {
        label = "Your Kid's Visual Description";
    }

    return (
        <StoryFormWidgetContainer label={label}
                                  className={"main-character-visual-description-selector"}>

            <TextField
                id="other-feature"
                label="Visual Description"
                onChange={handleTextChange}
                value={localValue}
                placeholder={"Please describe everything about their appearance. Eye Color, Hair Color, Clothing, Height, Age, Geographic Ancestry, Complexion, etc..."}
            />
        </StoryFormWidgetContainer>
    );
};