import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import {useChangeFormData, useFormData} from "../FormRoot";
import {useCallback, useEffect, useMemo} from "react";
import TableElementLookupSelector from "../../TableElementLookupSelector";
import Typography from "@mui/material/Typography";
import LocalTranslatedText from "../../../../translation/frontend/components/LocalTranslatedText";

const globalCachedItems = {};

export default function TableElementLookupSelectorInput({
                                                            id,
                                                            label,
                                                            fieldName,
                                                            searchFieldName,
                                                            displayFieldName,
                                                            getSuggestions,
                                                            getSingleItem,
                                                            multiple,
                                                        }) {
    const formData = useFormData();
    const changeFormData = useChangeFormData();

    const handleChange = useCallback((newValue) => {
        changeFormData(fieldName, newValue);
    }, [fieldName, changeFormData, multiple]);

    return (
      <div className={"form-input"}>
        <Typography variant={"label"}>
          <LocalTranslatedText language={"en"} text={label} />
        </Typography>

        <TableElementLookupSelector
            value={formData[fieldName]}
            onChange={handleChange}
            id={id}
            searchFieldName={searchFieldName}
            displayFieldName={displayFieldName}
            getSuggestions={getSuggestions}
            getSingleItem={getSingleItem}
            multiple={multiple}
        />
      </div>
    );
}