import "./NodeHandle.scss";
import * as React from "react";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import useChartStore from "../ChartState";
import {Handle, Position} from "reactflow";

export function NodeInputHandle({nodeId, isConnectable}) {
    const {getEdgesGoingIntoNode} = useChartStore((state) => {
        return {
            getEdgesGoingIntoNode: state.getEdgesGoingIntoNode,
        }
    });

    let handleClasses = 'node-handle override override2 input-handle';
    let circle = <div className={"handle-circle"} />;
    const countEdges = getEdgesGoingIntoNode(nodeId).length;
    if (countEdges === 0) {
        circle = <AddCircleIcon
            className={"add-circle-icon"} />;
        handleClasses += ' large';
    } else {
        handleClasses += ' small';
    }

    return <Handle
        type="target"
        position={Position.Left}
        id="in"
        isConnectable={isConnectable}
        className={handleClasses}
        title={"Connect to a previous node to bring in data from it"}
    >
        {circle}
    </Handle>
}

export function NodeOutputHandle({nodeId, isConnectable}) {
    const {nodes, getEdgesGoingFromNode} = useChartStore((state) => {
        return {
            nodes: state.nodes,
            getEdgesGoingIntoNode: state.getEdgesGoingIntoNode,
            getEdgesGoingFromNode: state.getEdgesGoingFromNode,
        }
    });

    let handleClasses = 'node-handle override override2 output-handle';
    let circle = <div className={"handle-circle"} />;
    const countEdges = getEdgesGoingFromNode(nodeId).length;
    if (countEdges === 0) {
        circle = <AddCircleIcon
            className={"add-circle-icon"} />;
        handleClasses += ' large';
    } else {
        handleClasses += ' small';
    }

    const promptNodes = Object.values(nodes).filter((node) => {
        return node.type === 'promptNode';
    });

    if (promptNodes.length === 0) {
        handleClasses += " empty-chart";
    }

    return <Handle
        type="source"
        position={Position.Right}
        id="out"
        isConnectable={isConnectable}
        className={handleClasses}
        title={"Connect to another node to send this data to it"}
    >
        {circle}
    </Handle>
}
