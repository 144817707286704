import React, {useCallback} from "react";
import {useAPI} from "../../core/frontend/components/APIProvider";
import {SpinnerButton} from "../../core/frontend/elements/SpinnerButton";
import DownloadIcon from '@mui/icons-material/Download';


export default function DownloadStoryPdfButton({story}) {
    const api = useAPI();

    const handleDownloadPDFClicked = useCallback((e) => {
        e.preventDefault();
        return api.downloadPDFForStory(story).then((pdfData) => {
            const blob = new Blob([pdfData], {type: 'application/pdf'});
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${story.book_title}.pdf`;
            a.click();
            URL.revokeObjectURL(url);
        }, () => {
            console.error("Error downloading PDF");
        });
    }, [api, story]);

    return <SpinnerButton variant="contained" color="primary" type="submit" onClick={handleDownloadPDFClicked} startIcon={<DownloadIcon />}>
        Download PDF
    </SpinnerButton>
}

