import "./RadioButtonsInput.scss";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import React, { useCallback } from "react";
import Typography from "@mui/material/Typography";
import { useChangeFormData, useFormData, useFormOptions } from "../FormRoot";
import LocalTranslatedText from "../../../../translation/frontend/components/LocalTranslatedText";

export function RadioButtonsInput({ label, fieldName, options, optionLabels }) {
  const formData = useFormData();
  const changeFormData = useChangeFormData();
  const formOptions = useFormOptions();

  const handleChange = useCallback(
    (event) => {
      changeFormData(fieldName, event.target.value);
    },
    [fieldName, changeFormData]
  );

  return (
    <div className={"form-input radio-buttons-input"}>
      {label ? (
        <Typography variant={"label"}>
          <LocalTranslatedText language={"en"} text={label} />
        </Typography>
      ) : null}

      <FormControl>
        <RadioGroup
          value={formData[fieldName]}
          name={fieldName}
          onChange={handleChange}
          disabled={!formOptions.editable}
        >
          {options.map((optionStr, optionIndex) => (
            <FormControlLabel
              key={optionStr}
              value={optionStr}
              control={<Radio />}
              label={optionLabels[optionIndex]}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
}

export default RadioButtonsInput;
