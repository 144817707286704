import React from "react";
import { TextInput } from "./TextInput";

export function URLInput({ label, fieldName, size }) {
  return (
    <TextInput
      fieldName={fieldName}
      label={label}
      example={"https://bradleyarsenault.me/"}
      size={size}
    />
  );
}

export default URLInput;
