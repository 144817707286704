import React, { useState } from "react";
import "./SchemaLayout.scss";
import { isNotEmptyObject } from "./utils/schema-utils";
import {
  Button,
  Collapse,
  Grid,
  IconButton,
  List,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SchemaMetadataEditor from "./components/SchemaMetadataEditor";
import KeyField from "./components/KeyField";
import RenderPrimitiveFields from "./components/RenderPrimitiveFields";

export default function SchemaLayout({
  mode,
  schema,
  handleSchemaMetadataChange,
  handleAddField,
  handleRemoveField,
  handleFieldUpdate,
  handleKeyRename,
  disabled,
  options,
}) {
  const [openSections, setOpenSections] = useState({});
  const [editingKeys, setEditingKeys] = useState({});

  const toggleSection = (nodeId) => {
    setOpenSections((prev) => ({
      ...prev,
      [nodeId]: !prev[nodeId],
    }));
  };

  const renderField = (key, value, parentPath) => {
    const nodeId = [...parentPath, key].join(".");

    const currentEditingKey = editingKeys[nodeId] ?? key;

    const onKeyChange = (newText) => {
      setEditingKeys((prev) => ({
        ...prev,
        [nodeId]: newText,
      }));
    };

    const onKeyBlur = () => {
      handleKeyRename(parentPath, key, editingKeys[nodeId]);
    };

    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleKeyRename(parentPath, key, editingKeys[nodeId]);
      }
    };

    return (
      <div key={nodeId}>
        <KeyField
          currentEditingKey={currentEditingKey}
          handleKeyDown={handleKeyDown}
          handleRemoveField={() => handleRemoveField(parentPath, key)}
          nodeId={nodeId}
          onKeyBlur={onKeyBlur}
          onKeyChange={onKeyChange}
          openSections={openSections}
          toggleSection={toggleSection}
          value={value}
          disabled={disabled}
        />
        <div className='field-list-item-content'>
          <Collapse in={openSections[nodeId]} timeout='auto' unmountOnExit>
            <div className='field-content'>
              {value.type === "object" &&
                renderObjectFields(value, [...parentPath, key])}
              {value.type === "array" &&
                renderArrayFields(value, [...parentPath, key])}
              {value.type &&
                !["object", "array"].includes(value.type) &&
                renderPrimitiveFields(key, value, parentPath)}
            </div>
          </Collapse>
        </div>
      </div>
    );
  };

  const renderObjectFields = (objValue, path) => {
    const fields = Object.entries(objValue.properties || {});

    return (
      <>
        {renderPrimitiveFields(null, objValue, path)}

        <List disablePadding>
          {fields.map(([childKey, childValue]) => {
            return renderField(childKey, childValue, [...path, "properties"]);
          })}
        </List>

        <div className='add-field-button-container'>
          <Button
            variant='text'
            onClick={() => handleAddField([...path, "properties"])}
            size='small'
            startIcon={<AddCircleOutlineIcon />}
            disabled={disabled}
          >
            Add Sub-Field
          </Button>
        </div>
      </>
    );
  };

  const renderArrayFields = (arrayValue, path) => {
    const arrayPrimitiveUI = renderPrimitiveFields(null, arrayValue, path);
    const itemsValue = arrayValue.items || {};
    const itemsNodeId = [...path, "items"].join(".");

    return (
      <>
        {arrayPrimitiveUI}
        <div className='array-items-schema-container'>
          <button
            className='array-items-schema-header'
            onClick={() => toggleSection(itemsNodeId)}
          >
            <Typography variant='caption' className='header-text'>
              Items Schema
            </Typography>
            <div className='array-items-schema-header-actions'>
              <IconButton size='small' className='expand-button'>
                {openSections[itemsNodeId] ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
            </div>
          </button>

          <Collapse in={openSections[itemsNodeId]} timeout='auto' unmountOnExit>
            <div className='field-content double-indent'>
              {itemsValue.type === "object" &&
                renderObjectFields(itemsValue, [...path, "items"])}
              {itemsValue.type === "array" &&
                renderArrayFields(itemsValue, [...path, "items"])}
              {itemsValue.type &&
                !["object", "array"].includes(itemsValue.type) &&
                renderPrimitiveFields(null, itemsValue, [...path, "items"])}
            </div>
          </Collapse>
        </div>
      </>
    );
  };

  const renderPrimitiveFields = (key, value, parentPath) => {
    const fullPath = key ? [...parentPath, key] : parentPath;
    const canChangeType = value.type ? true : false;

    return (
      <RenderPrimitiveFields
        canChangeType={canChangeType}
        fullPath={fullPath}
        handleFieldUpdate={handleFieldUpdate}
        mode={mode}
        value={value}
        disabled={disabled}
      />
    );
  };

  const renderRoot = () => {
    if (schema.type === "object") {
      const rootFields = Object.entries(schema.properties || {});
      return (
        <List disablePadding>
          {rootFields.map(([key, val]) => {
            return renderField(key, val, ["properties"]);
          })}
        </List>
      );
    } else if (schema.type === "array") {
      return (
        <div>
          {renderPrimitiveFields(null, schema, [])}
          <Typography variant='subtitle1' sx={{ mt: 2 }}>
            Root Array Items
          </Typography>
          <Collapse in={true} timeout='auto' unmountOnExit>
            {schema.items?.type === "object" &&
              renderObjectFields(schema.items, ["items"])}
            {schema.items?.type === "array" &&
              renderArrayFields(schema.items, ["items"])}
            {schema.items?.type &&
              !["object", "array"].includes(schema.items?.type) &&
              renderPrimitiveFields(null, schema.items, ["items"])}
          </Collapse>
        </div>
      );
    } else {
      return renderPrimitiveFields(null, schema, []);
    }
  };

  return (
    <div className='schema-layout-container'>
      {isNotEmptyObject(options) &&
      options?.metadataComponent === false ? null : (
        <SchemaMetadataEditor
          schema={schema}
          onMetadataChange={handleSchemaMetadataChange}
          disabled={disabled}
        />
      )}
      {isNotEmptyObject(schema.properties) && (
        <div className='schema-fields-container'>{renderRoot()}</div>
      )}
      <Grid item xs={12}>
        <Button
          variant='contained'
          onClick={() => handleAddField(["properties"])}
          size='small'
          className='add-field-button'
          disabled={disabled}
        >
          Add Schema Field
        </Button>
      </Grid>
    </div>
  );
}
