import { useEffect, useState, useMemo, useRef } from "react";
import globalTranslationManager from "./translation_manager";
import _ from "lodash";
import "./LocalTranslatedText.scss";
import Tooltip from "@mui/material/Tooltip";
import { useFrontendConfiguration } from "../../../frontend/components/FrontendConfigurationProvider";

function trimSymbolsAndWhitespace(text) {
  const result = /^[\s\W]*(.*?)[\s\W]*$/.exec(text);
  if (result === null) {
    return text;
  } else {
    return result[1];
  }
}

const LocalTranslatedText = ({
  text,
  language,
  showOptionToViewOriginalLanguage,
}) => {
  const config = useFrontendConfiguration();

  const [translated, setTranslated] = useState(null);
  const spanRef = useRef(null);

  // Get the language of the browser.
  const localLanguage = globalTranslationManager.getLocalLanguage();
  const localLanguageCode = globalTranslationManager.getLocalLanguageCode();

  useEffect(() => {
    if (_.isString(text) === false) {
      return;
    }

    if (config.frontend.enable_automatic_frontend_translation) {
      if (
        !language ||
        (language !== localLanguage && language !== localLanguageCode)
      ) {
        globalTranslationManager
          .fetchTranslation(text, localLanguage)
          .then((translation) => {
            setTranslated(translation);
          });
      }
    }
  }, [
    text,
    language,
    localLanguage,
    localLanguageCode,
    config.frontend.enable_automatic_frontend_translation,
  ]);

  let textWithTranslation = useMemo(() => {
    if (_.isString(text) === false) {
      return text;
    }

    if (translated) {
      // Trip any symbols and whitespace from the boundaries of the text
      const trimmedTranslated = trimSymbolsAndWhitespace(translated);
      const trimmedText = trimSymbolsAndWhitespace(text);

      return `${trimmedTranslated} - ${trimmedText}`;
    } else {
      return text;
    }
  }, [text, translated]);

  if (_.isString(text) === false) {
    return text;
  }

  if (showOptionToViewOriginalLanguage) {
    if (translated && translated !== text) {
      return (
        <Tooltip
          title={`Original Language: ${text}`}
          placement='top-start'
          classes={{ tooltip: "local-translated-text-original-text-tooltip" }}
          leaveDelay={500}
        >
          <span className={"local-translated-text-with-original"} ref={spanRef}>
            {translated || text}
          </span>
        </Tooltip>
      );
    } else {
      return (
        <span className={"local-translated-text-without-original"}>
          {translated || text}
        </span>
      );
    }
  } else {
    if (translated && translated !== text) {
      return (
        <span
          className={"local-translated-text-without-original"}
          title={textWithTranslation}
        >
          {translated || text}
        </span>
      );
    } else {
      return (
        <span className={"local-translated-text-without-original"}>
          {translated || text}
        </span>
      );
    }
  }
};

export default LocalTranslatedText;
