


export const DraggableItemTypes = {
    SECTION: 'section'
};


export const textInputNodeTypes = [
    'textInputNode',
    'fileInputNode',
]
