import "./NewSmartChainBindingPage.scss";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";
import React, {useCallback, useState} from "react";
import {Card, CardContent, TextField} from "@mui/material";
import {PageHeader} from "../../../frontend/elements/PageHeader";
import {PageWrapperWithMenu} from "../../../frontend/elements/PageWrapperWithMenu";
import {SpinnerButton} from "../../../frontend/elements/SpinnerButton";
import {useAPI} from "../../../frontend/components/APIProvider";
import {useNavigate} from "react-router-dom";


export default function NewSmartChainBindingPage(props) {
    const api = useAPI();
    const navigate = useNavigate();
    const [bindingName, setBindingName] = useState(null);
    const [templateName, setTemplateName] = useState(null);
    const [templateVersionId, setTemplateVersionId] = useState(null);

    const handleBindingNameChange = useCallback((evt) => {
        setBindingName(evt.target.value);
    }, [setTemplateName]);

    const handleTemplateNameChange = useCallback((evt) => {
        setTemplateName(evt.target.value);
    }, [setTemplateName]);

    const handleTemplateVersionIdChange = useCallback((evt) => {
        setTemplateVersionId(evt.target.value);
    }, [setTemplateVersionId]);

    const handleCreateNewSmartChainBinding = useCallback(async (result) => {
        const newBinding = await api.createNewSmartChainBinding({
            binding_name: bindingName,
            chain_name: templateName,
            template_version_id: templateVersionId,
        });
        navigate(`/smart_chain_binding`);
    }, [api, navigate, templateName]);

    return <PageWrapperWithMenu>
        <PageHeader title={"New Smart Chain Binding"}/>
        <div className="new-smart-chain-binding-page">
            <Card className="new-smart-chain-binding-card">
                <CardContent>
                    <TextField
                        label={"Binding Name"}
                        value={bindingName}
                        onChange={handleBindingNameChange}
                    />
                    <TextField
                        label={"Template Name"}
                        value={templateName}
                        onChange={handleTemplateNameChange}
                    />
                    <TextField
                        label={"Template Version ID"}
                        value={templateVersionId}
                        onChange={handleTemplateVersionIdChange}
                    />

                    <SpinnerButton
                        variant="contained"
                        color="primary"
                        onClick={handleCreateNewSmartChainBinding}
                    >
                        <LocalTranslatedText
                            text={"Create"}
                            language={"en"}
                        />
                    </SpinnerButton>
                </CardContent>
            </Card>
        </div>

    </PageWrapperWithMenu>
}