import * as React from 'react';
import {TableView} from "../../../frontend/elements/TableView";
import {useAPI} from "../../../frontend/components/APIProvider";
import {useCallback, useEffect} from "react";
import {useNavigate} from "react-router-dom";

export function CustomDataObjectTable({customSchemaId}) {
    const api = useAPI();
    const navigate = useNavigate();

    const [customDataSchema, setCustomDataSchema] = React.useState(null);
    const handleCustomDataObjectRowClicked = useCallback((customDataObject) => {
        navigate(`/c/${customSchemaId}/${customDataObject.id}`);
    }, [navigate]);

    useEffect(() => {
        if (customDataSchema === null) {
            api.getCustomDataSchema(customSchemaId).then((data) => {
                setCustomDataSchema(data);
            });
        }
    }, [customDataSchema]);

    const getCustomDataSchema = useCallback(async () => {
        if (customDataSchema) {
            return modifySchemaForTableView(customDataSchema.json_schema);
        }
    }, [customDataSchema]);

    const fetchCustomDataObjects = useCallback(async (tableQuery) => {
        tableQuery = {...tableQuery};

        if (!tableQuery.fields) {
            tableQuery.fields = [];
        }

        tableQuery.fields = ['data'];

        tableQuery.sortModel = tableQuery.sortModel.map((sort) => {
            return {
                colId: `data.${sort.colId}`,
                sort: sort.sort
            }
        })

        const newFilterModel = {
            "custom_data_schema_id": {
                "filterType": "text",
                "type": "equals",
                "filter": customDataSchema.id
            }
        };

        Object.keys(tableQuery.filterModel).forEach((key) => {
            newFilterModel[`data.${key}`] = tableQuery.filterModel[key];
        })

        tableQuery.filterModel = newFilterModel;

        const results = await api.getCustomDataObjects(tableQuery);

        // Grab the 'data' field within each object.
        const transformed = results.map((result) => ({...result, ...result.data}));
        return transformed;
    }, [customDataSchema]);

    if (!customDataSchema) {
        return null;
    }

    return (
        <TableView
            schemaAPI={getCustomDataSchema}
            fetchAPI={fetchCustomDataObjects}
            onRowClicked={handleCustomDataObjectRowClicked}
        />
    );
}


const modifySchemaForTableView = (schema) => {
    if (schema.type === "object") {
        let newProperties = {};
        Object.keys(schema.properties).forEach((key) => {
            newProperties[key] = modifySchemaForTableView(schema.properties[key]);
        });
        // console.log("newProperties", newProperties)
        return {
            ...schema,
            properties: newProperties
        };
    } else {
        return schema;
    }
}