import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import CodeEditor from "../CodeEditor";
import _ from "lodash";
import { Alert } from "@mui/material";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";

function isValidJSON(jsonString) {
  try {
    JSON.parse(jsonString);
    return true;
  } catch (error) {
    return false;
  }
}

export default function CodeLayout({ schema, setSchema, readOnly, onChange }) {
  const [jsonContent, setJsonContent] = useState(
    JSON.stringify(schema, null, 2)
  );

  const isValid = isValidJSON(jsonContent);

  const onDebouncedData = useMemo(
    () =>
      _.debounce((value) => {
        if (isValidJSON(value)) {
          setSchema(JSON.parse(value));
          onChange && onChange(JSON.parse(value));
        }
      }, 500),
    [onChange, setSchema]
  );

  const handleEditorChange = (value) => {
    setJsonContent(value);
    onDebouncedData(value);
  };

  return (
    <div className='code-layout-container'>
      <CodeEditor
        height={"50vh"}
        defaultLanguage='json'
        defaultValue={jsonContent}
        onChange={handleEditorChange}
        options={{
          readOnly: readOnly,
          minimap: { enabled: false },
          automaticLayout: true,
        }}
        value={jsonContent}
      />
      {!isValid ? (
        <Alert severity='error'>
          <LocalTranslatedText language={"en"} text={"The JSON is not valid"} />
        </Alert>
      ) : null}
    </div>
  );
}

CodeLayout.propTypes = {
  schema: PropTypes.object.isRequired,
  setSchema: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};
