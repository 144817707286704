import "./EditBookingPage.scss";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import React, { useCallback, useEffect, useState } from "react";
import { BookingForm } from "./BookingForm";
import { PageHeader } from "../../../../core/frontend/elements/PageHeader";
import { PageWrapperWithMenu } from "../../../../core/frontend/elements/PageWrapperWithMenu";
import { useAPI } from "../../../../core/frontend/components/APIProvider";
import { useNavigate, useParams } from "react-router-dom";

export default function EditBookingPage() {
  const api = useAPI();
  const params = useParams();
  const navigate = useNavigate();

  const [booking, setBooking] = useState(null);

  useEffect(() => {
    api.getBooking(params.bookingId).then((data) => {
      setBooking(data);
    });
  }, [params.bookingId, api]);

  const handleSaveBookingClicked = useCallback(async () => {
    await api.updateBooking(booking);
  }, [booking, api]);

  const handleDeleteBookingClicked = useCallback(async () => {
    await api.deleteBooking(booking.id);
    navigate("/bookings");
  }, [booking, navigate, api]);

  return (
    <PageWrapperWithMenu>
      <PageHeader title={"Edit Booking"} />
      <div className='edit-booking-page'>
        <Paper className={"main-page-contents"}>
          {booking ? (
            <BookingForm
              booking={booking}
              onChange={setBooking}
              onSaveClicked={handleSaveBookingClicked}
              showDeleteButton={true}
              onDeleteClicked={handleDeleteBookingClicked}
            />
          ) : null}
          {!booking ? <CircularProgress /> : null}
        </Paper>
      </div>
    </PageWrapperWithMenu>
  );
}
