import * as React from 'react';
import {TableView} from "../../../core/frontend/elements/TableView";
import {useAPI} from "../../../core/frontend/components/APIProvider";
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

export function GeneratedContentTable() {
    const api = useAPI();
    const navigate = useNavigate();

    const handleGeneratedContentRowClicked = useCallback((generatedContent) => {
        navigate(`/generated_content/${generatedContent.id}`);
    }, [navigate]);


    return (
        <TableView
            schemaAPI={api.getGeneratedContentSchema}
            fetchAPI={api.getGeneratedContents}
            onRowClicked={handleGeneratedContentRowClicked}
        />
    );
}
