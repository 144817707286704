import React from "react";
import FormSection from "../../elements/form/FormSection";
import FormSubFieldRoot from "../../elements/form/FormSubFieldRoot";
import CheckboxesInput from "../../elements/form/inputs/CheckboxesInput";

const globalModules = [
    "Bot",
    "Voice",
    "Web Chat",
    "SMS",
    "Email",
    "WhatsApp",
    "Business Info",
    "Scheduling",
    "Document Generator",
    "Prompt Charts",
    "Knowledge Base",
    "Outbound",
    "Intake",
    "Billing",
    "Word Factory",
    "Legacy Reminder Tap",
    "Legacy Networking Bot",
    "Legacy MyBookRobot",
];

const globalFieldNames = [
    "bot", "voice", "web_chat", "sms", "email", "whatsapp", "business_info", "scheduling",
    "document_generator", "prompt_chart", "knowledge_base", "outbound", "intake", "billing",
    "legacy_wordfactory", "legacy_remindertap",
    "legacy_networkingbot", "legacy_mybookrobot"
];

export default function ModulesConfigurationForm() {
    return <FormSection
        title={"Modules"}
        subtitle={"Choose which modules you would like enabled in your system"}
        collapsable={false}
    >
        <FormSubFieldRoot
            field={"modules"}
        >
            <CheckboxesInput
                type={"checkboxes"}
                options={globalModules}
                fieldName={globalFieldNames}
            />
        </FormSubFieldRoot>
    </FormSection>
}
