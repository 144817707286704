import React from "react";
import FormInput from "../../elements/form/FormInput";
import FormSection from "../../elements/form/FormSection";
import FormSubFieldRoot from "../../elements/form/FormSubFieldRoot";


export default function BotConfigurationForm() {
    return <FormSection
        title={"Bot"}
        subtitle={"Configuration for the default behavior of the bot"}
        collapsable={true}
    >
        <FormSubFieldRoot
            field={"bot"}
        >
            <FormInput
                type={"text"}
                label={"Default Smart Chain Binding"}
                fieldName={"default_smart_chain"}
                placeholder={"The default name of the binding for the smart chain to use when no other smart chain has been supplied for the conversation."}
            />
        </FormSubFieldRoot>
    </FormSection>
}
