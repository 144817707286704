import axios from "axios";
import { axiosETAGCache } from "axios-etag-cache";

let globalIntakeSchema = null;

let globalFilledIntakeSchema = null;

const axiosWithETAGCache = axiosETAGCache(axios);
const api = {
  async getIntakeSchema() {
    if (globalIntakeSchema) {
      return globalIntakeSchema;
    }
    const response = await axiosWithETAGCache.get(`/intake/schema`);
    globalIntakeSchema = response.data;
    return response.data;
  },

  async getIntakes(tableQuery) {
    const queryParams = {
      query: JSON.stringify(tableQuery),
    };

    const response = await axiosWithETAGCache.get(`/intake`, {
      params: queryParams,
    });

    return response.data;
  },

  async createNewIntake() {
    const newIntake = {};

    const response = await axios.post(`/intake`, newIntake);

    return response.data;
  },

  async getIntake(intakeId) {
    const queryParams = {};

    const response = await axiosWithETAGCache.get(`/intake/${intakeId}`, {
      params: queryParams,
    });

    return response.data;
  },

  async updateIntake(intake) {
    const response = await axios.put(`/intake/${intake._id}`, intake);
    return response.data;
  },

  async deleteIntake(intakeId) {
    const response = await axios.delete(`/intake/${intakeId}`);

    return response.data;
  },

  async startIntakeChatConversation(data) {
    const response = await axios.post(`/intake_chat/conversation`, data);

    return response.data;
  },

  async getFilledIntakeSchema() {
    if (globalFilledIntakeSchema) {
      return globalFilledIntakeSchema;
    }
    const response = await axiosWithETAGCache.get(`/filled_intake/schema`);
    globalFilledIntakeSchema = response.data;
    return response.data;
  },

  async getFilledIntakes(tableQuery) {
    const queryParams = {
      query: JSON.stringify(tableQuery),
    };

    const response = await axiosWithETAGCache.get(`/filled_intake`, {
      params: queryParams,
    });

    return response.data;
  },

  async createNewFilledIntake() {
    const newFilledIntake = {};

    const response = await axios.post(`/filled_intake`, newFilledIntake);

    return response.data;
  },

  async getFilledIntake(intakeId) {
    const queryParams = {};

    const response = await axiosWithETAGCache.get(
      `/filled_intake/${intakeId}`,
      {
        params: queryParams,
      }
    );

    return response.data;
  },

  async updateFilledIntake(intake) {
    const response = await axios.put(`/filled_intake/${intake._id}`, intake);
    return response.data;
  },

  async deleteFilledIntake(intakeId) {
    const response = await axios.delete(`/filled_intake/${intakeId}`);

    return response.data;
  },
  async getDefaultIntakeConfiguration() {
      const queryParams = {}

      const response = await axiosWithETAGCache.get(`/default_intake_configuration`, {
          params: queryParams
      });

      return response.data;
  },
  async saveDefaultIntakeConfiguration(defaultOutboundRules) {
      const response = await axios.put(`/default_intake_configuration`, defaultOutboundRules);
      return response.data;
  },
};

export default api;
