import React from "react";
import FormInput from "../../elements/form/FormInput";
import FormSection from "../../elements/form/FormSection";
import FormSubFieldRoot from "../../elements/form/FormSubFieldRoot";

export default function FrontendConfigurationForm() {
  return (
    <FormSection
      title={"Frontend Configuration"}
      subtitle={"Configuration for the frontend URLs for this tenant"}
      collapsable={true}
    >
      <FormSubFieldRoot field={"frontend"}>
        <FormInput
          type={"text"}
          label={"Main Root URL"}
          fieldName={"main_root"}
          placeholder={"The root URL for the main frontend interface"}
        />
        <FormInput
          type={"text"}
          label={"Plugin Root URL"}
          fieldName={"plugin_root"}
          placeholder={"The root URL to access the plugin on the frontend"}
        />
        <FormInput
          type={"text"}
          label={"Internal Root URL"}
          fieldName={"internal_root"}
          placeholder={
            "The root URL used to access the internal-only build of the frontend"
          }
        />

        <FormInput
          type={"text"}
          label={"Health Check Username"}
          fieldName={"health_check_username"}
          placeholder={
            "The username used to log into the frontend for health checks"
          }
        />

        <FormInput
          type={"text"}
          label={"Health Check Password"}
          fieldName={"health_check_password"}
          placeholder={
            "The password used to log into the frontend for health checks"
          }
        />

        <FormInput
          type={"text"}
          label={"Backend API URL"}
          fieldName={"backend_api_url"}
          placeholder={"The URL used to access the backend API"}
        />

        <FormInput
          type={"text"}
          label={"Auth0 Domain"}
          fieldName={"auth0_domain"}
          placeholder={"The domain used for Auth0 authentication"}
        />

        <FormInput
          type={"text"}
          label={"Auth0 Audience"}
          fieldName={"auth0_audience"}
          placeholder={"The audience used for Auth0 authentication"}
        />

        <FormInput
          type={"text"}
          label={"Auth0 Client ID"}
          fieldName={"auth0_client_id"}
          placeholder={"The client ID used for Auth0 authentication"}
        />

        <FormInput
          type={"text"}
          label={"Environment Title"}
          fieldName={"environment_title"}
          placeholder={"The title of the environment"}
        />

        <FormInput
          type={"text"}
          label={"Top Bar Title"}
          fieldName={"top_bar_title"}
          placeholder={"The title of the top bar"}
        />

        <FormInput
          type={"text"}
          label={"Router Basename"}
          fieldName={"router_basename"}
          placeholder={"The basename for the router"}
        />

        <FormInput
          type={"text"}
          label={"Privacy Policy URL"}
          fieldName={"privacy_policy_url"}
          placeholder={"The URL for the privacy policy"}
        />

        <FormInput
          type={"text"}
          label={"Terms and Conditions URL"}
          fieldName={"terms_and_conditions_url"}
          placeholder={"The URL for the terms and conditions"}
        />

        <FormInput
          type={"text"}
          label={"Logo URL"}
          fieldName={"logo_url"}
          placeholder={"The URL for the logo"}
        />

        <FormInput
          type={"text"}
          label={"Home Page Redirect"}
          fieldName={"home_page_redirect"}
          placeholder={"The URL to redirect to on the home page"}
        />

        <FormInput
          type={"text"}
          label={"GCP Error Reporting API Key"}
          fieldName={"gcp_error_reporting_api_key"}
          placeholder={"The API key for Google Cloud Platform error reporting"}
        />

        <FormInput
          type={"text"}
          label={"Google Cloud Project"}
          fieldName={"google_cloud_project"}
          placeholder={"The Google Cloud project"}
        />

        <FormInput
          type={"text"}
          label={"Google Maps API Key"}
          fieldName={"google_maps_api_key"}
          placeholder={"The Google Maps API key"}
        />

        <FormInput
          type={"text"}
          label={"Default Outbound Rules Word"}
          fieldName={"default_outbound_rules_word"}
          placeholder={"The default word for outbound rules"}
        />

        <FormInput
          type={"checkboxes"}
          options={[
            "Enable Automatic Frontend Translation",
            "Overwrite Backend API URL with Current Origin",
            "Enable Environment Menu",
            "Enable Repeat on Default Outbound Rules",
            "Enable Prompt on Default Outbound Rules",
            "Enable Billing in Account Menu",
            "Enable Onboarding",
            "Show Detailed Business Information Fields",
          ]}
          fieldName={[
            "enable_automatic_frontend_translation",
            "overwrite_backend_api_url_with_current_origin",
            "enable_environment_menu",
            "enable_repeat_on_default_outbound_rules",
            "enable_prompt_on_default_outbound_rules",
            "enable_billing_in_account_menu",
            "enable_onboarding",
            "show_detailed_business_information_fields",
          ]}
        />
      </FormSubFieldRoot>
    </FormSection>
  );
}
