import "../nodes/PromptNode/PromptNode.scss";
import "./ActionButton.scss";
import * as React from 'react';
import BehaviorTracker from "../../../../core/frontend/components/BehaviorTracker";
import IconButton from '@mui/material/IconButton';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import useChartStore from "../ChartState";
import {useCallback,} from 'react';

export function FileUploadActionButton({nodeId}) {
    const {node, triggerFileUploadFlowForNode} = useChartStore((state) => {
        return {
            node: state.getNode(nodeId),
            triggerFileUploadFlowForNode: state.triggerFileUploadFlowForNode,
        }
    });

    const handleFileUploadClicked = useCallback(() => {
        BehaviorTracker.trackInteraction({
            id: 'upload-file-action-button',
            mixpanel: 'upload-file-action-button',
        });

        return triggerFileUploadFlowForNode(nodeId);
    }, [nodeId, triggerFileUploadFlowForNode]);


    if (!node) {
        return;
    }

    return (
        <IconButton
            aria-label="File Upload"
            onClick={handleFileUploadClicked}
            className={'nodrag actionbutton'}
            title={"Upload File"}
        >
            <UploadFileIcon />
        </IconButton>
    )
}

export default FileUploadActionButton;
