import './NodeMentionList.scss'

import React, {
    forwardRef, useEffect, useImperativeHandle,
    useState,
} from 'react'

export default forwardRef((props, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0)

    const selectItem = index => {
        const item = props.items[index]

        if (item) {
            props.command({ id: item.id })
        }
    }

    const upHandler = () => {
        setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length)
    }

    const downHandler = () => {
        setSelectedIndex((selectedIndex + 1) % props.items.length)
    }

    const enterHandler = () => {
        selectItem(selectedIndex)
    }

    useEffect(() => setSelectedIndex(0), [props.items])

    useImperativeHandle(ref, () => ({
        onKeyDown: ({ event }) => {
            if (event.key === 'ArrowUp') {
                upHandler()
                return true
            }

            if (event.key === 'ArrowDown') {
                downHandler()
                return true
            }

            if (event.key === 'Enter') {
                enterHandler()
                return true
            }

            return false
        },
    }))

    const onClick = (evt, index) => {
      evt.stopPropagation();
        selectItem(index);
    };

    return (
        <div className="node-mention-list-items">
            {props.items.length
                ? props.items.map((item, index) => (
                    <button
                        className={`node-mention-list-item ${index === selectedIndex ? 'is-selected' : ''}`}
                        key={index}
                        onClick={(evt) => onClick(evt, index)}
                    >
                        {item.label || "Untitled Node"}
                    </button>
                ))
                : <div className="node-mention-list-item">No matching prompts</div>
            }
        </div>
    )
})
