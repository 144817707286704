import "./SmartChainErrorList.scss";
import * as React from 'react';
import {useCallback, useEffect} from "react";
import {useAPI} from "../../../frontend/components/APIProvider";
import {CustomJSONView} from "../../../frontend/elements/CustomJSONView";
import Paper from "@mui/material/Paper";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import moment from "moment";
import {SpinnerButton} from "../../../frontend/elements/SpinnerButton";

export default function SmartChainErrorList({smartChain, selectedStepIndex}) {
    const api = useAPI();
    const [smartChainErrors, setSmartChainErrors] = React.useState(null);

    useEffect(() => {
        async function getRecentErrors() {
            const retrievedErrors = await api.getSmartChainRecentErrors(
              null,
              null,
              smartChain.chain_name,
              selectedStepIndex ? smartChain.steps[selectedStepIndex].step_id : null,
              25)
            setSmartChainErrors(retrievedErrors);
        }

        getRecentErrors();
    }, [api, smartChain, selectedStepIndex]);

    const retrySmartChainStep = useCallback(async (smartChainError, smartChainErrorIndex) => {
        if (!selectedStepIndex) {
            alert("Cannot reprocess a whole chain errors for now. Please select an individual step and check its errors.");
            return;
        }

        const step = smartChain.steps[selectedStepIndex];
        const newResult = await api.executeSmartChainStep(step, smartChainError.most_recent_occurrence_context_data);
        const newErrorObject = {
            ...smartChainError,
            reprocessed_result_data: newResult
        }
        const newSmartChainErrors = [...smartChainErrors];
        newSmartChainErrors[smartChainErrorIndex] = newErrorObject;
        setSmartChainErrors(newSmartChainErrors);
    }, [api, smartChain, selectedStepIndex, smartChainErrors]);

    if (!smartChainErrors) {
        return null;
    }

    return (
        <div className={"smart-chain-error-list"}>
            {
                smartChainErrors.map((smartChainError, testSampleIndex) => {
                    return <Paper elevation={3} className={"smart-chain-error-row"} key={smartChainError.id ?? smartChainError._id ?? testSampleIndex}>
                      <div className={"smart-chain-error-header"}>
                        <span className={"sample-date"}>Most Recent Occurrence: {moment.utc(smartChainError.most_recent_occurrence_time).local().format("MMM Do, h:mm:ss a")}</span>
                        <span className={"sample-count"}>Error Count: {smartChainError.count}</span>
                      </div>
                        <div className={"smart-chain-error-body"}>
                        <div className={"smart-chain-error-column smart-chain-error-context"}>
                        <CustomJSONView
                                data={smartChainError.most_recent_occurrence_context_data ?? {}}
                                field_name={"Input"}
                            />
                        </div>
                        <div className={"smart-chain-error-actions"}>
                            {
                                selectedStepIndex ?
                                    <SpinnerButton
                                        label={"Reprocess"}
                                        onClick={() => retrySmartChainStep(smartChainError, testSampleIndex)}
                                    >
                                        <PlayCircleIcon/>
                                    </SpinnerButton>
                                    : null
                            }
                        </div>
                        <div className={"smart-chain-error-column smart-chain-error-result"}>
                            <CustomJSONView
                                data={smartChainError.reprocessed_result_data ?? smartChainError.stacktrace ?? {}}
                                field_name={"Result"}
                            />
                        </div>
                      </div>
                    </Paper>
                })
            }
            {
                smartChainErrors.length === 0 ?
                  <Paper elevation={3} className={"smart-chain-error-row"}>
                    <div className={"no-errors"}>All good so far. No recent errors found.</div>
                  </Paper>
                  : null
            }
        </div>
    );
}
