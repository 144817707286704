import {api} from "../../../core/frontend/components/api";


/**
 * This class is responsible for managing the connection to the web chat event service,
 * in order to receive an uninterrupted continuous flow of events from the server.
 */
class WebChatEventConnectionManager {
    static startEventStreamerCloseReconnectTimeMs = 500;
    static startEventStreamerErrorReconnectTimeMs = 5000;
    static maxEventStreamerCloseReconnectTimeMs = 30000;
    static maxEventStreamerErrorReconnectTimeMs = 60000;
    static maxReconnectAttempts = 100;

    constructor() {
        this.state = "disconnected";
        this.conversationId = null;
        this.eventStreamer = null;
        this.eventHandler = null;
        this.reconnectHandle = null;
        this.eventStreamerCloseReconnectTimeMs = WebChatEventConnectionManager.startEventStreamerCloseReconnectTimeMs;
        this.eventStreamerErrorReconnectTimeMs = WebChatEventConnectionManager.startEventStreamerErrorReconnectTimeMs;
        this.currentReconnectAttemptCount = 0;
    }

    updateEventHandle(eventHandler) {
        this.eventHandler = eventHandler;
    }

    subscribeToEvents(conversationId, conversationToken, mostRecentEventTime, eventHandler) {
        this.eventHandler = eventHandler;

        if (conversationId !== this.conversationId) {
            this.conversationId = conversationId;
            this.eventStreamerCloseReconnectTimeMs = WebChatEventConnectionManager.startEventStreamerCloseReconnectTimeMs;
            this.eventStreamerErrorReconnectTimeMs = WebChatEventConnectionManager.startEventStreamerErrorReconnectTimeMs;
            this.currentReconnectAttemptCount = 0;
            this.recreateEventStreamer(conversationId, conversationToken, mostRecentEventTime);
        }
    }

    disconnect() {
        if (this.eventStreamer) {
            this.eventStreamer.close();
            this.eventStreamer = null;
            this.conversationId = null;
            this.reconnectHandle = null;
        }
    }


    recreateEventStreamer(conversationId, conversationToken, mostRecentEventTime) {
        if (this.eventStreamer) {
            this.eventStreamer.close();
            this.eventStreamer = null;
        }

        let localMostRecentEventTime = mostRecentEventTime;

        let localEventStreamer = api.streamChatEvents(conversationId, conversationToken, mostRecentEventTime);

        this.eventStreamer = localEventStreamer;
        this.eventStreamer.addEventListener("conversation_event", (e) => {
            const messageData = JSON.parse(e.data);
            localMostRecentEventTime = messageData.created_at;
            if (messageData.conversation_id === this.conversationId) {
                if (this.eventHandler) {
                    this.eventHandler(messageData);
                }
            }
        });

        this.eventStreamer.addEventListener("error", (e) => {
            if (this.currentReconnectAttemptCount >= WebChatEventConnectionManager.maxReconnectAttempts) {
                this.disconnect();
                return;
            } else {
                this.currentReconnectAttemptCount += 1;
            }

            this.reconnectAfterWait(localEventStreamer, conversationId, conversationToken, localMostRecentEventTime, this.eventStreamerErrorReconnectTimeMs);

            this.eventStreamerErrorReconnectTimeMs = Math.min(
                this.eventStreamerErrorReconnectTimeMs * 1.5,
                WebChatEventConnectionManager.maxEventStreamerErrorReconnectTimeMs
            )
        });

        this.eventStreamer.addEventListener("close", (e) => {
            if (this.currentReconnectAttemptCount >= WebChatEventConnectionManager.maxReconnectAttempts) {
                this.disconnect();
                return;
            } else {
                this.currentReconnectAttemptCount += 1;
            }

            this.reconnectAfterWait(localEventStreamer, conversationId, conversationToken, localMostRecentEventTime, this.eventStreamerCloseReconnectTimeMs);

            this.eventStreamerCloseReconnectTimeMs = Math.min(
                this.eventStreamerCloseReconnectTimeMs * 1.5,
                WebChatEventConnectionManager.maxEventStreamerCloseReconnectTimeMs
            )
        });
    }

    reconnectAfterWait(localEventStreamer, conversationId, conversationToken, localMostRecentEventTime, waitTimeMS) {
        if (this.reconnectHandle) {
            clearTimeout(this.reconnectHandle);
            this.reconnectHandle = null;
        }

        this.reconnectHandle = setTimeout(() => {
            this.reconnectHandle = null;
            if (this.eventStreamer === localEventStreamer && this.conversationId === conversationId) {
                this.recreateEventStreamer(conversationId, conversationToken, localMostRecentEventTime);
            }
        }, waitTimeMS);
    }
}

export const globalWebChatEventConnectionManager = new WebChatEventConnectionManager();
export default globalWebChatEventConnectionManager;
