import React, {useCallback} from 'react';
import {SpinnerButton} from "../../core/frontend/elements/SpinnerButton";
import "./StoryConfigurationForm.scss";
import {StoryThemeSelector} from "./form_widgets/StoryThemeSelector";
import {StoryEnvironmentSelector} from "./form_widgets/StoryEnvironmentSelector";
import {StoryMacguffinSelector} from "./form_widgets/StoryMacGuffinSelector";
import {MainCharacterNameSelector} from "./form_widgets/MainCharacterNameSelector";
import {MainCharacterGenderSelector} from "./form_widgets/MainCharacterGenderSelector";
import {MainCharacterVisualDescriptionSelector} from "./form_widgets/MainCharacterVisualDescriptionSelector";
import {MainCharacterPersonalitySelector} from "./form_widgets/MainCharacterPersonalitySelector";
import {TextStyleSelector} from "./form_widgets/TextStyleSelector";
import {StoryVillainSelector} from "./form_widgets/StoryVillainSelector";
import {MainCharacterTypeSelector} from "./form_widgets/MainCharacterType";


export const StoryConfigurationForm = ({storyConfiguration, onChange, onGenerateClicked}) => {
    const handleGenerateClicked = useCallback((e) => {
        e.preventDefault();

        let finalStoryConfiguration = storyConfiguration;

        if (storyConfiguration.main_character_type === "generate randomly") {
            // If you chose to generate your main character randomly, we will
            // overwrite a bunch of values at the end here.
            finalStoryConfiguration = {
                ...storyConfiguration,
                main_character_type: "generate randomly",
                general_character_appearance: "Humans",
                main_character_name: "generate randomly",
                main_character_personality: "generate randomly",
                main_character_gender: "generate randomly",
                main_character_visual_description: "generate randomly",
            };
        } else if (storyConfiguration.main_character_type === "your pet") {
            finalStoryConfiguration = {
                ...storyConfiguration,
                general_character_appearance: "Animals",
            };
        } else if (storyConfiguration.main_character_type === "your kid") {
            finalStoryConfiguration = {
                ...storyConfiguration,
                general_character_appearance: "Humans",
            };
        }

        return onGenerateClicked(finalStoryConfiguration);
    }, [storyConfiguration, onGenerateClicked]);

    const handleFieldChange = useCallback((fieldName, newValue) => {
        const newStoryConfiguration = {
            ...storyConfiguration,
            [fieldName]: newValue
        };

        onChange(newStoryConfiguration);
    }, [storyConfiguration, onChange]);

    const handleThemeChange = useCallback((newValue) => {
        handleFieldChange("theme", newValue);
    }, [handleFieldChange]);

    const handleEnvironmentChange = useCallback((newValue) => {
        handleFieldChange("environment", newValue);
    }, [handleFieldChange]);

    const handleMacguffinChange = useCallback((newValue) => {
        handleFieldChange("macguffin", newValue);
    }, [handleFieldChange]);

    const handleMainCharacterTypeChange = useCallback((newValue) => {
        handleFieldChange("main_character_type", newValue);
    }, [storyConfiguration]);

    const handleMainCharacterNameChange = useCallback((newValue) => {
        handleFieldChange("main_character_name", newValue);
    }, [handleFieldChange]);

    const handleMainCharacterGenderChange = useCallback((newValue) => {
        handleFieldChange("main_character_gender", newValue);
    }, [handleFieldChange]);

    const handleMainCharacterVisualDescriptionChange = useCallback((newValue) => {
        handleFieldChange("main_character_visual_description", newValue);
    }, [handleFieldChange]);

    const handleMainCharacterPersonalityChange = useCallback((newValue) => {
        handleFieldChange("main_character_personality", newValue);
    }, [handleFieldChange]);

    const handleTextStyleChange = useCallback((newValue) => {
        handleFieldChange("text_style", newValue);
    }, [handleFieldChange]);

    const handleStoryVillainChange = useCallback((newValue) => {
        handleFieldChange("story_villain", newValue);
    }, [handleFieldChange]);

    return (
        <form className={"story-configuration-form"}>
            <StoryThemeSelector value={storyConfiguration.theme} onChange={handleThemeChange} />
            <StoryEnvironmentSelector value={storyConfiguration.environment} onChange={handleEnvironmentChange} />
            <StoryMacguffinSelector value={storyConfiguration.macguffin} onChange={handleMacguffinChange} />
            <MainCharacterTypeSelector value={storyConfiguration.main_character_type} onChange={handleMainCharacterTypeChange} />
            {
                storyConfiguration.main_character_type !== "generate randomly" ? (
                    <>
                        <MainCharacterNameSelector value={storyConfiguration.main_character_name} onChange={handleMainCharacterNameChange} mainCharacterType={storyConfiguration.main_character_type} />
                        <MainCharacterGenderSelector value={storyConfiguration.main_character_gender} onChange={handleMainCharacterGenderChange} mainCharacterType={storyConfiguration.main_character_type} />
                        <MainCharacterVisualDescriptionSelector value={storyConfiguration.main_character_visual_description} onChange={handleMainCharacterVisualDescriptionChange} mainCharacterType={storyConfiguration.main_character_type} />
                        <MainCharacterPersonalitySelector value={storyConfiguration.main_character_personality} onChange={handleMainCharacterPersonalityChange} mainCharacterType={storyConfiguration.main_character_type} />
                    </>
                ) : null
            }
            <StoryVillainSelector value={storyConfiguration.story_villain} onChange={handleStoryVillainChange} />
            <TextStyleSelector value={storyConfiguration.text_style} onChange={handleTextStyleChange} />

            <div className={"buttons-bar"}>
                <SpinnerButton variant="contained" color="primary" type="submit" onClick={handleGenerateClicked}>
                    Generate
                </SpinnerButton>
            </div>
        </form>
    );
};
