import React, { useCallback } from "react";
import FormRoot from "../../../core/frontend/elements/form/FormRoot";
import { SpinnerButton } from "../../../core/frontend/elements/SpinnerButton";
import "./TranscriptProcessorConfigurationForm.scss";
import FormSection from "../../../core/frontend/elements/form/FormSection";
import FormInput from "../../../core/frontend/elements/form/FormInput";
import ContentSampleSelector from "../content_sample/ContentSampleSelector";
import TopicExtractorPromptSelector from "../topic_extractor_prompt/TopicExtractorPromptSelector";
import PromptChartSelectorInput from "../../../core/frontend/elements/form/inputs/PromptChartSelectorInput";
import { MainTranscriptProcessorTemplate } from "./transcript_processor_chart_template";

export default function TranscriptProcessorConfigurationForm({
  initialTranscriptProcessorConfiguration,
  onTranscriptProcessorConfigurationSave,
  onDeletedClicked,
  saveButtonText,
}) {
  const handleTranscriptProcessorConfigurationSaved = useCallback(
    (newTranscriptProcessorConfigurationData) => {
      if (onTranscriptProcessorConfigurationSave) {
        return onTranscriptProcessorConfigurationSave(
          newTranscriptProcessorConfigurationData
        );
      }
    },
    [onTranscriptProcessorConfigurationSave]
  );

  return (
    <div className={"transcript-processor-configuration-form"}>
      <FormRoot
        initialValue={initialTranscriptProcessorConfiguration}
        saveButtonText={
          saveButtonText ?? "Save Transcript Processor Configuration"
        }
        onSave={handleTranscriptProcessorConfigurationSaved}
        extraButtons={
          <>
            {onDeletedClicked ? (
              <SpinnerButton
                variant='contained'
                color='error'
                className={"delete-button"}
                onClick={onDeletedClicked}
              >
                Delete
              </SpinnerButton>
            ) : null}
          </>
        }
      >
        {/*<Paper className={"step-contents-wrapper"}>*/}
        {/*   <Routes>*/}
        {/*     <Route index path="" element={<Navigate to="flow" />} />*/}
        {/*     <Route path="flow/*" element={<ChooseFlowStep />} />*/}
        {/*     <Route path="raw-materials/*" element={<ChooseRawMaterialsStep />} />*/}
        {/*     <Route path="finished-product/*" element={<ChooseFinishedProductStep />} />*/}
        {/*   </Routes>*/}

        {/*    <Outlet />*/}
        {/*</Paper>*/}

        {/*<Paper className={"stepper-element-wrapper"}>*/}
        {/*    <TranscriptProcessorStepper />*/}
        {/*</Paper>*/}

        <FormSection
          title={"General"}
          subtitle={
            "Metadata that can be used to find this content sample in the DB"
          }
        >
          <FormInput type={"text"} label={"Title"} fieldName={"title"} />

          <FormInput type={"text"} label={"Tags"} fieldName={"tags"} />

          <FormInput
            type={"text"}
            label={"Description"}
            fieldName={"description"}
          />
        </FormSection>

        <FormSection
          title={"Inputs"}
          subtitle={
            "This section is used to configure the inputs used for content generation"
          }
        >
          <ContentSampleSelector
            label={"Content Samples"}
            fieldName={"content_sample_ids"}
          />

          <TopicExtractorPromptSelector
            label={"Topic Extracting Prompts"}
            fieldName={"topic_extractor_prompt_ids"}
          />

          <PromptChartSelectorInput
            label={"Transcript Processing Prompt Chart"}
            fieldName={"transcript_processor_prompt_chart_id"}
            template={MainTranscriptProcessorTemplate}
          />
        </FormSection>
      </FormRoot>
    </div>
  );
}
