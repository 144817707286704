import "./ConversationDetails.scss";
import React from "react";
import Typography from "@mui/material/Typography";
import moment from "moment";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";

function titleCaseFieldName(fieldName) {
    return fieldName.split("_").map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(" ");
}

export default function ConversationDetails({conversation}) {
    return <div className={"conversation-details"}>
        <Typography variant={"p"}><strong><LocalTranslatedText language={"en"} text="Conversation ID:" /></strong> {conversation._id}</Typography>
        <Typography variant={"p"}><strong><LocalTranslatedText language={"en"} text="Status:"/></strong> {conversation.status}</Typography>
        {
            conversation.communication_channel ?
                <Typography variant={"p"}><strong><LocalTranslatedText language={"en"} text="Communication Channel:"/></strong> {conversation.communication_channel}</Typography>
                : null
        }
        {
            conversation.phone_number ?
                <Typography variant={"p"}><strong><LocalTranslatedText language={"en"} text="Phone Number:"/></strong> {conversation.phone_number}</Typography>
                : null
        }
        {
            conversation.bot_number ?
                <Typography variant={"p"}><strong><LocalTranslatedText language={"en"} text="Receiver Number:"/></strong> {conversation.bot_number}</Typography>
                : null
        }
        {
            conversation.language_id ?
                <Typography variant={"p"}><strong><LocalTranslatedText language={"en"} text="Language:"/></strong> {conversation.language_id}</Typography>
                : null
        }
        {
            conversation.agent_smart_chain_binding_name ?
                <Typography variant={"p"}><strong><LocalTranslatedText language={"en"} text="Agent Name:"/></strong> {conversation.agent_smart_chain_binding_name}</Typography>
                : null
        }
        {
            conversation.conversation_reference_id ?
                <Typography variant={"p"}><strong><LocalTranslatedText language={"en"} text="Reference ID:"/></strong> {conversation.conversation_reference_id}</Typography>
                : null
        }
        <Typography variant={"p"}><strong><LocalTranslatedText language={"en"} text="Timezone:"/></strong> {conversation.timezone ?? "N/A"}</Typography>
        {
            conversation.metadata ?
                Object.keys(conversation.metadata).map((key) => {
                    return <Typography variant={"p"} key={key}><strong><LocalTranslatedText language={"en"} text={titleCaseFieldName(key)}/>:</strong> {String(conversation.metadata[key])}</Typography>
                }) : null
        }
        {
            conversation.created_at ?
                <Typography variant={"p"}><strong><LocalTranslatedText language={"en"} text="Created At:"/></strong> {moment.utc(conversation.created_at).local().format("MMM DD, YYYY hh:mm A")}</Typography>
                : null
        }
    </div>
}
