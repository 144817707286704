import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import {useCallback, useState,} from 'react';
import "./ActionButton.scss";
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import {Popover} from "@mui/material";
import Typography from "@mui/material/Typography";
import "./PromptWritingTipsActionButton.scss";
import BehaviorTracker from "../../../../core/frontend/components/BehaviorTracker";


export function PromptWritingTipsActionButton() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mouseDownPosition, setMouseDownPosition] = useState({x: 0, y: 0});

    const handleOpenTipsClicked = useCallback((evt) => {
        setAnchorEl(evt.currentTarget);

        BehaviorTracker.trackInteraction({
            id: "open-prompt-writing-tips-action-button",
            mixpanel: null
        });
    }, []);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePopoverContentsMouseDown = useCallback((evt) => {
        setMouseDownPosition({x: evt.clientX, y: evt.clientY})
    }, []);

    const handlePopoverContentsMouseUp = useCallback((evt) => {
        const deltaX = evt.clientX - mouseDownPosition.x;
        const deltaY = evt.clientY - mouseDownPosition.y;
        const delta = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

        // User basically clicked in the same spot without dragging.
        if (delta < 5) {
            handleClose();
        }
    }, [mouseDownPosition]);

    return (
        <>
        <IconButton
            aria-label="Prompt Writing Tips"
            onClick={handleOpenTipsClicked}
            className={'nodrag actionbutton'}
            title={"Tips for Writing Prompts"}
        >
            <LightbulbIcon />
        </IconButton>
        <Popover
            id={"prompt-writing-tips-popover"}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorPosition={{
                left: 0,
                top: 0,
            }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            className={"prompt-writing-tips-popover"}
        >
            <div
                className={"tips-contents"}
                onMouseDown={handlePopoverContentsMouseDown}
                onMouseUp={handlePopoverContentsMouseUp}
            >
                <Typography sx={{ p: 2 }} className={"tips-header"}>
                    Tips for Writing Prompts
                </Typography>
                <ol className={"tips-list"}>
                    <li>Use @ key to reference prior prompts and include their data</li>
                    <li>Write detailed instructions. More details, better results.</li>
                    <li>Use templates to get premade prompts that you can customize</li>
                    <li>Provide examples on what you want the AI to do</li>
                    <li>Breakdown the steps required to complete a task</li>
                    <li>Give context - your industry, background, situation, job title, etc...</li>
                    <li>Break apart complex tasks into multiple simpler tasks</li>
                    <li>Improve results by asking the AI to provide its reasoning before its final answer</li>
                    <li>Provide all the information required to make a decision in your prompt</li>
                </ol>
                <br/>
                <a
                    href={"https://platform.openai.com/docs/guides/gpt-best-practices"}
                    target={"_blank"}
                    rel={"noreferrer"}
                    className={"learn-more-link"}
                >
                    Learn more prompt writing tips from OpenAI
                </a>
            </div>
        </Popover>
    </>);
}

export default PromptWritingTipsActionButton;
