import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import "./ContextMenu.scss";

export default function OutputNodeContextMenu({contextMenu, handleClose, handleCopyContentsClicked}) {
    return (
        <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
                contextMenu !== null
                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                    : undefined
            }
        >
            <MenuItem onClick={handleCopyContentsClicked} className={"context-menu-action-item"}>
                <ContentPasteIcon/>
                <span>Copy the Contents</span>
            </MenuItem>
        </Menu>
    );
}
