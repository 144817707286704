import React, {useCallback, useEffect, useState} from "react";
import {PageWrapperWithMenu} from "../../core/frontend/elements/PageWrapperWithMenu";
import {PageHeader} from "../../core/frontend/elements/PageHeader";
import "./GenerateNewStoryPage.scss";
import {useAPI} from "../../core/frontend/components/APIProvider";
import {StoryConfigurationForm} from "./StoryConfigurationForm";
import {PageSection} from "../../core/frontend/elements/PageSection";
import Button from "@mui/material/Button";
import IndeterminateLoadingBar from "../../core/frontend/elements/IndeterminateLoadingBar";
import CircularProgress from "@mui/material/CircularProgress";
import FadingText from "../../core/frontend/elements/FadingText";
import {StoryPDFViewer} from "./StoryPDFViewer";
import DownloadStoryPdfButton from "./DownloadStoryPdfButton";


export default function GenerateNewStoryPage() {
    const api = useAPI();
    const [story, setStory] = useState(null);
    const [isGenerating, setIsGenerating] = useState(false);
    const generatingTexts = [
        "Please wait, making magic can take up to a few minutes",
        "Story generation can take up to a couple of minutes... Please hold on tight",
        "Just one second, you will be getting closer with your kids in under a couple minutes",
        "Your story is on the way, please hold on tight for another couple minutes"
    ]

    useEffect(() => {
        // Call the home endpoint, forcing server to start if it's off.
        // Helps reduce impact of cold boots.
        api.home();
    }, [api]);

    // See if a default story configuration can be found in localStory. If so, we load that
    const localStory = localStorage.getItem("storyConfiguration");
    let defaultStoryConfiguration = {
        theme: "Funny & Amusing",
        environment: "Bustling City",
        macguffin: "Toy",
        childs_age: "8",
        main_character_type: "generate randomly",
        main_character_name: "",
        main_character_gender: "",
        main_character_visual_description: "",
        main_character_personality: "",
        general_character_appearance: "Humans",
        text_style: "Regular",
        story_villain: true,
        artistic_style: "3D Animated Cartoon"
    };
    if (localStory) {
        try {
            defaultStoryConfiguration = JSON.parse(localStory);
        } catch (e) {
            console.error("Error parsing local story configuration", e);
        }
    }
    const [storyConfiguration, setStoryConfiguration] = React.useState(defaultStoryConfiguration);

    const handleStoryConfigurationChanged = useCallback((newStoryConfiguration) => {
        setStoryConfiguration(newStoryConfiguration);
        // Save to local storage as well, so that the same settings automatically show up again
        // if you come back to the same form.
        localStorage.setItem("storyConfiguration", JSON.stringify(newStoryConfiguration));
    }, [setStoryConfiguration]);

    const handleGenerateClicked = useCallback((finalStoryConfiguration) => {
        setIsGenerating(true);
        setStoryConfiguration(finalStoryConfiguration);
        // Call the home endpoint before attempting to generate a story, to make sure
        // the server is fully booted.
        return api.home().then(() => {
            return api.generateStory(finalStoryConfiguration).then((story) => {
                setIsGenerating(false);
                setStory(story);
            }, () => {
                setIsGenerating(false);
            })
        }, () => {
            setIsGenerating(false);
        })
    }, [api, storyConfiguration]);

    return <PageWrapperWithMenu>
        <PageHeader className={"generate-new-story-header"}/>
        <div className={"generate-new-story-page"}>
            {
                !story && !isGenerating ?
                    <PageSection
                        title={"Story Details"}
                    >
                        <StoryConfigurationForm
                            storyConfiguration={storyConfiguration}
                            onChange={handleStoryConfigurationChanged}
                            onGenerateClicked={handleGenerateClicked}
                        />
                    </PageSection> : null
            }
            {
                isGenerating ? <PageSection>
                    <div className={"generating-story-box"}>

                        <CircularProgress/>
                        <IndeterminateLoadingBar halfLifeSeconds={180}/>
                        <FadingText texts={generatingTexts}/>
                    </div>
                </PageSection> : null
            }
            {
                story ? <PageSection
                        // title={story.book_title || "Book Title"}
                    >
                        <div className={"buttons-bar"}>
                            <Button
                                className={"generate-new-story-button"}
                                variant={"contained"}
                                color={"primary"}
                                onClick={() => setStory(null)}
                            >Generate New Story
                            </Button>
                            <DownloadStoryPdfButton story={story}/>
                        </div>

                        <StoryPDFViewer story={story}/>
                    </PageSection>
                    : null
            }
        </div>
    </PageWrapperWithMenu>
}

