import React, { useState, useCallback } from "react";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import "./AvatarSelector.scss";
import { ConversationAvatar } from "../../conversations/frontend/ConversationAvatar";

let iconAvatars = [
  {
    id: "face",
    name: "Person Face",
    url: "icon://face",
  },
  {
    id: "person",
    name: "Person",
    url: "icon://person",
  },
  {
    id: "bot",
    name: "Bot",
    url: "icon://smart_toy",
  },
  {
    id: "chip",
    name: "Computer Chip",
    url: "icon://memory",
  },
  {
    id: "lightbulb",
    name: "Lightbulb",
    url: "icon://lightbulb",
  },
  {
    id: "smile",
    name: "Happy Face",
    url: "icon://tag_faces",
  },
];

let avatarUrlPrefix = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/`;

let realisticAvatars = [
  {
    id: "black-woman",
    name: "Lakisha",
    url: `${avatarUrlPrefix}avatars/black-woman.jpeg`,
  },
  {
    id: "indian-woman",
    name: "Kavita",
    url: `${avatarUrlPrefix}avatars/indian-woman.jpeg`,
  },
  {
    id: "asian-man",
    name: "Chan",
    url: `${avatarUrlPrefix}avatars/asian-man.jpeg`,
  },
  {
    id: "android-man",
    name: "Droider",
    url: `${avatarUrlPrefix}avatars/android-man.jpeg`,
  },
  {
    id: "latino-woman",
    name: "Sofia",
    url: `${avatarUrlPrefix}avatars/latino-woman.jpeg`,
  },
  {
    id: "natam-man",
    name: "Takoda",
    url: `${avatarUrlPrefix}avatars/natam-man.jpeg`,
  },
  {
    id: "latino-man",
    name: "Rodriguez",
    url: `${avatarUrlPrefix}avatars/latino-man.jpeg`,
  },
  {
    id: "white-man",
    name: "James",
    url: `${avatarUrlPrefix}avatars/white-man.jpeg`,
  },
  {
    id: "asian-woman",
    name: "Vivian",
    url: `${avatarUrlPrefix}avatars/asian-woman.jpeg`,
  },
  {
    id: "white-woman",
    name: "Jennifer",
    url: `${avatarUrlPrefix}avatars/white-woman.jpeg`,
  },
  {
    id: "android-woman",
    name: "Zenobia",
    url: `${avatarUrlPrefix}avatars/android-woman.jpeg`,
  },
  {
    id: "indian-man",
    name: "Deepak",
    url: `${avatarUrlPrefix}avatars/indian-man.jpeg`,
  },
  {
    id: "natam-woman",
    name: "Sakari",
    url: `${avatarUrlPrefix}avatars/natam-woman.jpeg`,
  },
  {
    id: "black-man",
    name: "Raymond",
    url: `${avatarUrlPrefix}avatars/black-man.jpeg`,
  },
];

let animatedAvatars = [
  {
    id: "zoe",
    name: "Zoe",
    url: `${avatarUrlPrefix}avatars/zoe.jpeg`,
  },
  {
    id: "robot-brad",
    name: "Robot Brad",
    url: `${avatarUrlPrefix}avatars/robot-brad.jpeg`,
  },
];

const allAllowedOptions = iconAvatars
  .concat(realisticAvatars)
  .concat(animatedAvatars);

export const AvatarSelector = ({ value, onChange }) => {
  let initialSelected = value;
  if (!initialSelected) {
    initialSelected = "";
  }

  const [selectedStyle, setSelectedStyle] = useState(initialSelected);

  const handleRadioChange = useCallback(
    (event) => {
      setSelectedStyle(event.target.value);
      if (onChange) {
        onChange(event.target.value);
      }
    },
    [onChange]
  );

  return (
    <RadioGroup
      aria-label='avatar-selector'
      name='avatar-selector'
      className={"avatar-selector"}
      value={selectedStyle}
      onChange={handleRadioChange}
    >
      {allAllowedOptions.map((option) => (
        <FormControlLabel
          key={option.id}
          value={option.url}
          control={<Radio />}
          label={
            <div className={"avatar-option"}>
              <ConversationAvatar
                avatarUrl={option.url}
                role={"assistant"}
                moduleId={"user_interactor"}
              />
              <span>{option.name}</span>
            </div>
          }
        />
      ))}
    </RadioGroup>
  );
};

export default AvatarSelector;
