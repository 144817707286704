import React, { useCallback, useMemo, useRef } from "react";
import { PageWrapperWithMenu } from "../../../core/frontend/elements/PageWrapperWithMenu";
import { useEffect } from "react";
import "./WebChatConfigurationPage.scss";
import { useAPI } from "../../../core/frontend/components/APIProvider";
import { PageHeader } from "../../../core/frontend/elements/PageHeader";
import { PageSection } from "../../../core/frontend/elements/PageSection";
import { CircularProgress } from "@mui/material";
import AvatarSelector from "./AvatarSelector";
import { useParams } from "react-router-dom";
import {useFrontendConfiguration} from "../../frontend/components/FrontendConfigurationProvider";

function generateCodeSnippedForClientToken(clientToken, frontendConfig) {
  const frontendUrlBase = frontendConfig.frontend.plugin_root.replace(/\/$/, "");
  const pluginSourceUrl = `${frontendUrlBase}/static/js/plugin.js`;
  let clientTokenCode = `<!-- Start of plugin code -->\n`;
  clientTokenCode += `<script>\n`;
  clientTokenCode += `    (function() {\n`;
  clientTokenCode += `        var w = window;\n`;
  clientTokenCode += `        var d = document;\n`;
  clientTokenCode += `        var s = d.createElement('script');\n`;
  clientTokenCode += `        s.src = '${pluginSourceUrl}';\n`;
  clientTokenCode += `        s.onload = function() {\n`;
  clientTokenCode += `            w.WebChat.init({\n`;
  clientTokenCode += `                clientToken: '${clientToken}'\n`;
  clientTokenCode += `            });\n`;
  clientTokenCode += `        };\n`;
  clientTokenCode += `        d.body.appendChild(s);\n`;
  clientTokenCode += `    })();\n`;
  clientTokenCode += `</script>\n`;
  clientTokenCode += `<!-- End of plugin code -->\n`;
  return clientTokenCode;
}

const generateCodeSnippedForIntakeToken = (intakeToken, frontendConfig) => {
  const frontendUrlBase = frontendConfig.frontend.plugin_root.replace(/\/$/, "");
  const pluginSourceUrl = `${frontendUrlBase}/static/js/plugin.js`;
  let intakeTokenCode = `<!-- Start of plugin code -->\n`;
  intakeTokenCode += `<script>\n`;
  intakeTokenCode += `    (function() {\n`;
  intakeTokenCode += `        var w = window;\n`;
  intakeTokenCode += `        var d = document;\n`;
  intakeTokenCode += `        var s = d.createElement('script');\n`;
  intakeTokenCode += `        s.src = '${pluginSourceUrl}';\n`;
  intakeTokenCode += `        s.onload = function() {\n`;
  intakeTokenCode += `            w.IntakeChat.init({\n`;
  intakeTokenCode += `                intakeToken: '${intakeToken}'\n`;
  intakeTokenCode += `            });\n`;
  intakeTokenCode += `        };\n`;
  intakeTokenCode += `        d.body.appendChild(s);\n`;
  intakeTokenCode += `    })();\n`;
  intakeTokenCode += `</script>\n`;
  intakeTokenCode += `<!-- End of plugin code -->\n`;
  return intakeTokenCode;
};

export const WebChatConfigurationPage = () => {
  const api = useAPI();
  const [webChatConfiguration, setWebChatConfiguration] = React.useState(null);
  const frontendConfiguration = useFrontendConfiguration();
  const [isLoading, setIsLoading] = React.useState(false);
  const codeSnippetRef = useRef(null);
  const params = useParams();
  const intakeToken = params?.intakeToken || "";

  const loadWebChatConfiguration = useCallback(() => {
    setIsLoading(true);
    api.getWebChatConfiguration().then(
      (data) => {
        setIsLoading(false);
        setWebChatConfiguration(data);
      },
      (error) => {
        setIsLoading(false);
      }
    );
  }, [api]);

  useEffect(() => {
    loadWebChatConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const codeSnippet = useMemo(() => {
    if (webChatConfiguration) {
      return intakeToken
        ? generateCodeSnippedForIntakeToken(intakeToken, frontendConfiguration)
        : generateCodeSnippedForClientToken(webChatConfiguration.client_token, frontendConfiguration);
    }
    return null;
  }, [webChatConfiguration, intakeToken]);

  const botLandingPageURL = useMemo(() => {
    if (webChatConfiguration) {
      const frontendUrlBase = frontendConfiguration.frontend.plugin_root.replace(/\/$/, "");
      const botLandingURL = intakeToken
        ? `${frontendUrlBase}/full-screen/intake/${intakeToken}`
        : `${frontendUrlBase}/full-screen/${webChatConfiguration.client_token}`;
      return botLandingURL;
    }
    return null;
  }, [webChatConfiguration, intakeToken]);

  const handleChangedBotAvatar = useCallback(
    (avatar_url) => {
      const newWebChatConfiguration = {
        ...webChatConfiguration,
        avatar_url: avatar_url,
      };
      setWebChatConfiguration(newWebChatConfiguration);
      api.saveWebChatConfiguration(newWebChatConfiguration);
    },
    [api, webChatConfiguration]
  );

  return (
    <PageWrapperWithMenu>
      <PageHeader title={`Web Chat Configuration`} />
      <div className='web-chat-configuration-page'>
        {isLoading ? <CircularProgress /> : null}
        {webChatConfiguration ? (
          <>
            <PageSection
              title={"Web Chat Code Snippet"}
              subheading={
                "Please put this code snippet into your website to enable your chat-bot."
              }
            >
              <pre className='code-snippet' ref={codeSnippetRef}>
                {codeSnippet}
              </pre>
            </PageSection>
            <PageSection
              title={"Bot Landing Page URL"}
              subheading={
                "You can share this link with other people, and they will be able to chat directly with your bot!"
              }
            >
              <pre className='code-snippet' ref={codeSnippetRef}>
                {botLandingPageURL}
              </pre>
            </PageSection>
            <PageSection
              title={"Avatar"}
              subheading={
                "Change the icon used for your bot when it appears in the chat window."
              }
            >
              <AvatarSelector
                value={webChatConfiguration.avatar_url}
                onChange={handleChangedBotAvatar}
              />
            </PageSection>
          </>
        ) : null}
      </div>
    </PageWrapperWithMenu>
  );
};

export default WebChatConfigurationPage;
