import * as React from 'react';
import {useAPI} from "../../../core/frontend/components/APIProvider";
import {useNavigate} from "react-router-dom";
import {useCallback} from "react";
import {TableView} from "../../../core/frontend/elements/TableView";

export function InsuranceAgentsTable() {
    const api = useAPI();
    const navigate = useNavigate();

    const handleInsuranceAgentClicked = useCallback((insurance_agent) => {
        navigate(`/insurance_agents/${insurance_agent.id}`);
    }, [navigate]);

    return (
        <TableView
            schemaAPI={api.getInsuranceAgentSchema}
            fetchAPI={api.getInsuranceAgents}
            onRowClicked={handleInsuranceAgentClicked}
        />
    );
}