import "./NewCalendarPage.scss";
import React, {useCallback, useState} from "react";
import {CalendarForm} from "./CalendarForm";
import {PageHeader} from "../../../../core/frontend/elements/PageHeader";
import {PageSection} from "../../../../core/frontend/elements/PageSection";
import {PageWrapperWithMenu} from "../../../../core/frontend/elements/PageWrapperWithMenu";
import {useAPI} from "../../../../core/frontend/components/APIProvider";
import {useNavigate} from "react-router-dom";


export default function NewCalendarPage() {
    const api = useAPI();
    const navigate = useNavigate();

    const [newCalendarData, setNewCalendarData] = useState({
        'person_name': null,
    });

    const handleNewCalendarClicked = useCallback(async () => {
        const calendar = await api.createNewCalendar(newCalendarData);
        navigate(`/calendars/${calendar.id}`);
    }, [api, navigate, newCalendarData]);

    return <PageWrapperWithMenu>
        <PageHeader title={"New Calendar"} />
        <div className="new-calendar-page">
            <PageSection className={"main-page-contents"}>
                <CalendarForm
                    calendar={newCalendarData}
                    onChange={setNewCalendarData}
                    onSaveClicked={handleNewCalendarClicked}
                />
            </PageSection>
        </div>
    </PageWrapperWithMenu>
}

