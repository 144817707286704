import "./OutboundRulesEditor.scss";
import React, {useCallback, useMemo, useState} from 'react';
import {Collapse, List, ListItemButton, ListItemText, ListItem, TextField, MenuItem} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import AddBoxIcon from '@mui/icons-material/AddBox';
import OutboundIcon from "@mui/icons-material/Outbound";
import Select from "@mui/material/Select";
import PhoneIcon from "@mui/icons-material/Phone";
import MessageIcon from "@mui/icons-material/Message";
import EmailIcon from "@mui/icons-material/Email";
import DeleteIcon from "@mui/icons-material/Delete";
import {randomString} from "../../frontend/utils/random";
import {useFrontendConfiguration} from "../../frontend/components/FrontendConfigurationProvider";
import {debounce} from "lodash";

const maximumRelativeDays = 14;

export const OutboundAttemptRuleListItem = ({rule, onChange, onDeleteClicked, relativeDaysUsedInOtherRules}) => {
    const [open, setOpen] = useState(false);
    const [localPrompt, setLocalPrompt] = useState(rule.prompt);
    const features = useFrontendConfiguration();

    const handleClick = useCallback(() => {
        setOpen(!open);
    }, [open]);

    const onChangeDebounced = useMemo(() => debounce((newRule) => {
        onChange(newRule);
    }, 500), [onChange]);

    const handlePromptChange = useCallback((e) => {
        setLocalPrompt(e.target.value);
        onChangeDebounced({...rule, prompt: e.target.value});
    }, [rule, onChange, onChangeDebounced]);

    const handleCommunicationChannelChange = useCallback((e) => {
        onChange({...rule, communication_channel: e.target.value});
    }, [rule, onChange]);

    const handleRelativeDayChange = useCallback((e) => {
        onChange({...rule, relative_day: e.target.value});
    }, [rule, onChange]);

    let icon = <OutboundIcon/>;
    if (rule.communication_channel === 'sms') {
        icon = <MessageIcon/>;
    } else if (rule.communication_channel === 'phone_call') {
        icon = <PhoneIcon/>;
    } else if (rule.communication_channel === 'email') {
        icon = <EmailIcon/>;
    }

    const handleDelete = useCallback(() => {
        if (onDeleteClicked) {
            onDeleteClicked();
        }
    }, [onDeleteClicked]);

    const relativeDayOptions = useMemo(() => {
        const options = [];
        for (let i = -maximumRelativeDays; i <= maximumRelativeDays; i++) {
            options.push({
                value: i,
                title: i === 0 ? "On the Day" : `${Math.abs(i)} days ${i < 0 ? "before" : "after"}`,
                isUsed: relativeDaysUsedInOtherRules.includes(i)
            });
        }
        return options;
    }, [relativeDaysUsedInOtherRules]);

    return (<>
            <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                {
                    rule.relative_day < -1 ?
                        <ListItemText primary={`${-rule.relative_day} days before`}/>
                        : null
                }
                {
                    rule.relative_day === -1 ?
                        <ListItemText primary={`1 day before`}/>
                        : null
                }
                {
                    rule.relative_day === 0 ?
                        <ListItemText primary={`On the day`}/>
                        : null
                }
                {
                    rule.relative_day === 1 ?
                        <ListItemText primary={`1 day after`}/>
                        : null
                }
                {
                    rule.relative_day > 1 ?
                        <ListItemText primary={`${(rule.relative_day ?? 0)} days after`}/>
                        : null
                }
                {open ? <ExpandLess/> : <ExpandMore/>}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem sx={{pl: 4}} className={"outbound-rule-editor-area-list-item"}>
                        <Select
                            value={rule.relative_day}
                            title="Relative Date"
                            onChange={handleRelativeDayChange}
                        >
                            {
                                relativeDayOptions.map((option) => (
                                    <MenuItem value={option.value} disabled={option.isUsed}>{option.title}</MenuItem>
                                ))
                            }
                        </Select>
                    </ListItem>
                    <ListItem sx={{pl: 4}} className={"outbound-rule-editor-area-list-item"}>
                        <Select
                            value={rule.communication_channel}
                            title="Communication Channel"
                            onChange={handleCommunicationChannelChange}
                        >
                            {
                                features.modules.sms ?
                                    <MenuItem value={'sms'}>SMS</MenuItem>
                                    : null
                            }
                            {
                                features.modules.voice ?
                                    <MenuItem value={'phone_call'}>Phone Call</MenuItem>
                                    : null
                            }
                            {
                                features.modules.email ?
                                    <MenuItem value={'email'}>Email</MenuItem>
                                    : null
                            }
                        </Select>
                    </ListItem>
                    <ListItem sx={{pl: 4}} className={"outbound-rule-editor-area-list-item"}>
                        <TextField
                            value={localPrompt}
                            onChange={handlePromptChange}
                            fullWidth={true}
                            multiline={true}
                            placeholder={"Outreach Prompt: Describe what you want the bot to do during its outreach with the user in a few sentences"}
                            rows={4}
                        />
                    </ListItem>
                    <ListItemButton sx={{pl: 4}} onClick={handleDelete}>
                        <ListItemIcon>
                            <DeleteIcon color="error"/>
                        </ListItemIcon>
                        <ListItemText primary="Delete"/>
                    </ListItemButton>
                </List>
            </Collapse>
        </>
    );
}

export const OutboundSequenceEditor = ({value, onChange}) => {
    const config = useFrontendConfiguration();

    const handleAttemptSequenceItemChange = useCallback((index, newRule) => {
        const newValue = [...value];
        newValue[index] = newRule;
        onChange(newValue);
    }, [value, onChange]);

    const handleAddAttemptSequenceItem = useCallback(() => {
        // Find a value for relative day that isn't take
        let chosenRelativeDay = 0;
        for (let potentialRelativeDay = 0; potentialRelativeDay <= maximumRelativeDays; potentialRelativeDay++) {
            if (!value.map((r) => -r.relative_day).includes(potentialRelativeDay)) {
                chosenRelativeDay = -potentialRelativeDay;
                break;
            }
            if (!value.map((r) => r.relative_day).includes(potentialRelativeDay)) {
                chosenRelativeDay = potentialRelativeDay;
                break;
            }
        }

        const newValue = [...value];
        if (config.modules.voice) {
            newValue.push({
                rule_id: randomString(24),
                relative_day: chosenRelativeDay,
                communication_channel: 'phone_call',
                prompt: ''
            });
        } else if (config.modules.sms) {
            newValue.push({
                rule_id: randomString(24),
                relative_day: chosenRelativeDay,
                communication_channel: 'sms',
                prompt: ''
            });
        } else if (config.modules.email) {
            newValue.push({
                rule_id: randomString(24),
                relative_day: chosenRelativeDay,
                communication_channel: 'email',
                prompt: ''
            });
        } else {
            throw new Error("No communication channels are enabled");
        }

        onChange(newValue);
    }, [value, onChange]);

    const handleDeleteAttemptSequenceItem = useCallback((index) => {
        const newValue = [...value];
        newValue.splice(index, 1);
        onChange(newValue);
    }, [value, onChange]);

    const attemptRuleListItems = useMemo(() => {
        return (value ?? []).map((rule, index) => (
            <OutboundAttemptRuleListItem
                key={rule.rule_id}
                rule={rule}
                onChange={(newRule) => handleAttemptSequenceItemChange(index, newRule)}
                onDeleteClicked={() => handleDeleteAttemptSequenceItem(index)}
                relativeDaysUsedInOtherRules={value.map((r) => r.relative_day).filter((r) => r !== rule.relative_day)}
            />
        ));
    }, [value, handleAttemptSequenceItemChange, handleDeleteAttemptSequenceItem]);

    return (
        <List
            sx={{width: '100%', bgcolor: 'background.paper'}}
            component="nav"
            aria-labelledby="outbound-rule-sub-list"
        >
            {attemptRuleListItems}
            <ListItemButton onClick={handleAddAttemptSequenceItem}>
                <ListItemIcon>
                    <AddBoxIcon/>
                </ListItemIcon>
                <ListItemText primary="Add New Outbound Attempt"/>
            </ListItemButton>
        </List>
    );
};

export default OutboundSequenceEditor;
