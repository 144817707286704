import React, {useEffect, useState, useCallback} from "react";
import {SpinnerButton} from "../../../../core/frontend/elements/SpinnerButton";
import {useAPI} from "../../../../core/frontend/components/APIProvider";
import {SelectMicrosoftCalendarsToSynchronize} from "./SelectMicrosoftCalendarsToSynchronize";
import {Typography} from "@mui/material";
import "./MicrosoftOutlookIntegrationWidget.scss";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import LocalTranslatedText from "../../../../core/translation/frontend/components/LocalTranslatedText";
import {useFrontendConfiguration} from "../../../../core/frontend/components/FrontendConfigurationProvider";


export function MicrosoftOutlookIntegrationWidget({calendar}) {
    const api = useAPI();
    const config = useFrontendConfiguration();
    const [microsoftCalendarIntegrationSettings, setMicrosoftCalendarIntegrationSettings] = useState({});
    const [microsoftCalendarTokenStatus, setMicrosoftCalendarTokenStatus] = React.useState(null);
    const [isShowingConsent, setIsShowingConsent] = React.useState(false);
    const [hasConsented, setHasConsented] = React.useState(false);

    const getMicrosoftCalendarTokenStatus = useCallback(async () => {
        return await api.getMicrosoftCalendarTokenStatus(calendar.id).then((data) => {
            setMicrosoftCalendarTokenStatus(data.status);
        })
    }, [api, calendar.id]);

    useEffect(() => {
        api.getMicrosoftCalendarIntegrationSettings(calendar.id).then((data) => {
            setMicrosoftCalendarIntegrationSettings(data);
        }, (error) => {
            console.log(error);
        });
        getMicrosoftCalendarTokenStatus(calendar.id).then(() => {
        }, (error) => {
            console.log(error);
        });
    }, [api, getMicrosoftCalendarTokenStatus, calendar.id]);

    const handleAuthenticateWithMicrosoft = useCallback(async () => {
        const result = await api.getMicrosoftCalendarAuthenticationURL(calendar.id);

        const url = result.url;

        // Redirect the user
        window.location.href = url;
    }, [api, calendar.id]);

    const startConsentFlow = useCallback(async () => {
        setIsShowingConsent(true);
    }, []);

    const handleSelectedCalendarsChanged = useCallback((selectedCalendars) => {
        const newSettings = {
            ...microsoftCalendarIntegrationSettings,
            calendars_to_check_for_conflicts: selectedCalendars
        };

        setMicrosoftCalendarIntegrationSettings(newSettings);

        api.saveMicrosoftCalendarIntegrationSettings(newSettings).then((data) => {
            console.log(data);
        }, (error) => {
            console.log(error);
        });
    }, [api, microsoftCalendarIntegrationSettings]);

    const handleConsentClicked = useCallback(() => {
        setHasConsented(true);
    }, []);

    return <div className={'microsoft-outlook-integration-widget'}>
        {
            isShowingConsent ?
                <div className={"consent-widget"}>
                    <p><LocalTranslatedText language={"en"}
                                            text="You are granting access to your private Calendar data."/></p>

                    <p><LocalTranslatedText language={"en"}
                                            text="Private calendar details, such as when you are free & busy, will be shared with third-party AI providers in order to allow the bot to schedule appointments on your behalf."/>
                    </p>

                    <p>
                        <LocalTranslatedText language={"en"} text="Please review the "/>
                        &nbsp;<a target={"_blank"} rel="noreferrer" href={config.frontend.privacy_policy_url}>
                            <LocalTranslatedText language={"en"} text="Privacy Policy"/>
                        </a>
                        &nbsp;<LocalTranslatedText language={"en"} text="and"/>&nbsp;
                        <a target={"_blank"} rel="noreferrer" href={config.frontend.terms_and_conditions_url}>
                            <LocalTranslatedText language={"en"} text="Terms & Conditions"/>
                        </a>
                        &nbsp;<LocalTranslatedText language={"en"} text="at your discretion."/>
                    </p>
                    <FormControlLabel
                        control={<Checkbox />}
                        label={<LocalTranslatedText language={"en"} text="I have reviewed the Privacy Policy and Terms & Conditions" />}
                        onChange={handleConsentClicked}
                    />

                    <SpinnerButton className={"btn btn-primary"}
                                   variant={"contained"}
                                   onClick={handleAuthenticateWithMicrosoft}
                                   disabled={!hasConsented}>
                        <LocalTranslatedText language={"en"} text="Authenticate with Microsoft Calendar" />
                    </SpinnerButton>
                </div>
                : <>
                    {
                        microsoftCalendarTokenStatus === "valid" ?
                            <div>
                                <Typography variant={"p"}><LocalTranslatedText language={"en"} text="Your Microsoft Calendar is connected"/></Typography>
                                <br/>
                                <br/>
                                <Typography variant={'p'}><LocalTranslatedText language={"en"} text="Please select which calendars you want to check for conflicts when booking appointments:"/></Typography>

                                <SelectMicrosoftCalendarsToSynchronize
                                    selectedCalendars={microsoftCalendarIntegrationSettings.calendars_to_check_for_conflicts}
                                    onChange={handleSelectedCalendarsChanged}
                                    calendarId={calendar._id}
                                />

                                <p><LocalTranslatedText language={"en"} text="Reconnect with Microsoft Calendar"/></p>

                                <SpinnerButton className={"btn btn-primary"}
                                               variant={"contained"}
                                               onClick={startConsentFlow}>
                                    <LocalTranslatedText language={"en"} text="Connect To Microsoft"/>
                                </SpinnerButton>
                            </div> : null
                    }
                    {
                        microsoftCalendarTokenStatus === "no_token" ?
                            <div>
                                <p><LocalTranslatedText language={"en"} text="Connect with Microsoft Calendar"/></p>

                                <SpinnerButton className={"btn btn-primary"}
                                               variant={"contained"}
                                               onClick={startConsentFlow}>
                                    <LocalTranslatedText language={"en"} text="Connect To Microsoft"/>
                                </SpinnerButton>
                            </div> : null
                    }
                    {
                        microsoftCalendarTokenStatus === "expired" ?
                            <div>
                                <p><LocalTranslatedText language={"en"} text="Your connection to Microsoft Calendar has expired. Please reconnect"/></p>

                                <SpinnerButton className={"btn btn-primary"}
                                               variant={"contained"}
                                               onClick={startConsentFlow}>
                                    <LocalTranslatedText language={"en"} text="Reconnect To Microsoft"/>
                                </SpinnerButton>
                            </div> : null
                    }
                </>
        }
    </div>
}

