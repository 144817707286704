import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import React, { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./SmartChainEditPage.scss";
import { useAPI } from "../../../frontend/components/APIProvider";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import CircularProgress from "@mui/material/CircularProgress";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";
import SmartChainStepInputFieldList from "./SmartChainStepInputFieldList";
import SmartChainEditorTopBar from "./SmartChainEditorTopBar";
import SmartChainStepEditor from "./SmartChainStepEditor";
import SmartChainStepList from "./SmartChainStepList";
import { randomString } from "../../../frontend/utils/random";
import ChangeSmartChainStepTypeDialog from "./ChangeSmartChainStepTypeDialog";
import SmartChainStepOutputList from "./SmartChainStepOutputList";
import SmartChainWholeChainView from "./SmartChainWholeChainView";
import LLMModelList from "../../../llm_model_providers/frontend/LLMModelList";
import AgentActionList from "../../../agent_kernel/frontend/AgentActionList";
import PublishedSmartChainVersionsList from "./PublishedSmartChainVersionsList";

export default function SmartChainEditPage(props) {
  const api = useAPI();
  const params = useParams();
  const navigate = useNavigate();
  const [smartChain, setSmartChain] = useState(null);
  const [isChainStepsOpen, setIsChainStepsOpen] = useState(false);
  const [isPromptContextOpen, setIsPromptContextOpen] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [selectedSmartChainStepIndex, setSelectedSmartChainStepIndex] =
    useState(0);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isShowingPromptOutput, setIsShowingPromptOutput] = useState(false);
  const [stepTypes, setStepTypes] = useState([]);
  const [isChangeSmartChainStepTypeOpen, setIsChangeSmartChainStepTypeOpen] =
    useState(false);
  const [isShowingModelList, setIsShowingModelList] = useState(false);
  const [isShowingActionList, setIsShowingActionList] = useState(false);
  const [newStepType, setNewStepType] = useState(null);
  const [editorReloadCounter, setEditorReloadCounter] = useState(0);
  const [tab, setTab] = useState("content");
  const [showPublishedSmartChainDialog, setShowPublishedSmartChainDialog] =
    useState(false);

  useEffect(() => {
    api.getSmartChainStepTypes().then((types) => {
      setStepTypes(types);
    });
  }, [api]);

  const handleSmartChainSave = useCallback(async () => {
    const savedVersion = await api.saveSmartChain(smartChain);

    if (savedVersion.id !== params?.smartChainId) {
      navigate(`/smart_chains/${savedVersion.id}`);
    }

    setHasUnsavedChanges(false);
  }, [api, smartChain, params?.smartChainId, navigate]);

  const handleOpenPromptContextClicked = useCallback(() => {
    setIsPromptContextOpen(!isPromptContextOpen);
    setIsChainStepsOpen(false);
  }, [isPromptContextOpen]);

  const handleOpenChainStepsClicked = useCallback(() => {
    setIsPromptContextOpen(false);
    setIsChainStepsOpen(!isChainStepsOpen);
  }, [isChainStepsOpen]);

  const handleChainStepClicked = useCallback((step, stepIndex) => {
    setSelectedSmartChainStepIndex(stepIndex);
  }, []);

  const handleSwitchLightDarkModeClick = useCallback(() => {
    setIsDarkMode(!isDarkMode);
  }, [isDarkMode]);

  const handleShowPromptOutputClicked = useCallback(() => {
    setIsShowingPromptOutput(!isShowingPromptOutput);
    setIsShowingModelList(false);
    setIsShowingActionList(false);
  }, [isShowingPromptOutput]);

  const handleShowPublishedSmartChainDialog = useCallback(() => {
    setShowPublishedSmartChainDialog(!showPublishedSmartChainDialog);
    setIsShowingModelList(false);
    setIsShowingActionList(false);
    setIsShowingPromptOutput(false);
  }, [showPublishedSmartChainDialog]);

  const handleAddNewStepClicked = useCallback(() => {
    const newStep = {
      step_id: randomString(24),
      chain_name: smartChain.chain_name,
      step_name: "Untitled Step",
      options_template: "{}",
      content_template: "",
      output_schema: {},
      step_type: "plain_text_template",
    };
    setSmartChain({ ...smartChain, steps: [...smartChain.steps, newStep] });
    setSelectedSmartChainStepIndex(smartChain.steps.length);
  }, [smartChain]);

  const handleDeleteSmartChainStep = useCallback(
    (step, stepIndex) => {
      setSmartChain({
        ...smartChain,
        steps: smartChain.steps.filter((s) => s.step_id !== step.step_id),
      });
      setSelectedSmartChainStepIndex(0);
    },
    [smartChain]
  );

  const handleRenameSmartChainStep = useCallback(
    (step, stepIndex, newName) => {
      const newSteps = [...smartChain.steps];
      newSteps[stepIndex] = { ...step, step_name: newName };
      setSmartChain({ ...smartChain, steps: newSteps });
    },
    [smartChain]
  );

  const handleStepChanged = useCallback(
    (newStep) => {
      const newSteps = [...smartChain.steps];
      newSteps[selectedSmartChainStepIndex] = newStep;
      setSmartChain({ ...smartChain, steps: newSteps });
    },
    [smartChain, selectedSmartChainStepIndex]
  );

  // const handleDeleteClicked = useCallback(async () => {
  //   return await api.deleteSmartChain(smartChain.id).then(() => {
  //     navigate(`/smart_chains`);
  //   });
  // }, [navigate, api, smartChain?.id]);

  // const onContentChanged = useCallback(
  //   (newValue, e) => {
  //     setSmartChain({ ...smartChain, content: newValue });
  //     setHasUnsavedChanges(true);
  //   },
  //   [smartChain]
  // );

  const handleChainNameChanged = useCallback(
    (newValue) => {
      setSmartChain({ ...smartChain, chain_name: newValue });
    },
    [smartChain]
  );

  const handleNewStepTypeSelected = useCallback((newStepType) => {
    setNewStepType(newStepType);
    setIsChangeSmartChainStepTypeOpen(true);
  }, []);

  const handleChainSelectedForValue = useCallback(() => {
    setSelectedSmartChainStepIndex(null);
  }, []);

  const handleConfirmChangeSmartChainStepType = useCallback(() => {
    // Fetch the step type in the list of step types
    const stepTypeInfo = stepTypes.find((type) => type.name === newStepType);

    setIsChangeSmartChainStepTypeOpen(false);
    const newSteps = [...smartChain.steps];
    newSteps[selectedSmartChainStepIndex] = {
      ...smartChain.steps[selectedSmartChainStepIndex],
      step_type: newStepType,
      options_template: stepTypeInfo.default_options_template,
      output_schema: {},
    };
    setSmartChain({ ...smartChain, steps: newSteps });
    setEditorReloadCounter(editorReloadCounter + 1);
  }, [
    newStepType,
    smartChain,
    selectedSmartChainStepIndex,
    stepTypes,
    editorReloadCounter,
  ]);

  const handleCloseChangeSmartChainStepType = useCallback(() => {
    setIsChangeSmartChainStepTypeOpen(false);
  }, []);

  const handleModelListClicked = useCallback(() => {
    setIsShowingModelList(!isShowingModelList);
    setIsShowingPromptOutput(false);
    setIsShowingActionList(false);
  }, [isShowingModelList]);

  const handleActionListClicked = useCallback(() => {
    setIsShowingModelList(false);
    setIsShowingActionList(!isShowingActionList);
    setIsShowingPromptOutput(false);
  }, [isShowingActionList]);

  const handleMoveStepUpClicked = useCallback(
    (stepIndex) => {
      if (stepIndex === 0) {
        return;
      }
      const newSteps = [...smartChain.steps];
      const temp = newSteps[stepIndex];
      newSteps[stepIndex] = newSteps[stepIndex - 1];
      newSteps[stepIndex - 1] = temp;
      setSmartChain({ ...smartChain, steps: newSteps });
    },
    [smartChain]
  );

  const handleMoveStepDownClicked = useCallback(
    (stepIndex) => {
      if (stepIndex === smartChain.steps.length - 1) {
        return;
      }
      const newSteps = [...smartChain.steps];
      const temp = newSteps[stepIndex];
      newSteps[stepIndex] = newSteps[stepIndex + 1];
      newSteps[stepIndex + 1] = temp;
      setSmartChain({ ...smartChain, steps: newSteps });
    },
    [smartChain]
  );

  useEffect(() => {
    if (!smartChain) {
      api.getSmartChain(params?.smartChainId).then((smartChain) => {
        setSmartChain(smartChain);
      });
    }
  }, [handleSmartChainSave, smartChain, params?.smartChainId, api]);

  if (!smartChain) {
    return (
      <PageWrapperWithMenu>
        <PageHeader
          title={
            <LocalTranslatedText language={"en"} text='Edit Smart Chain' />
          }
        />
        <div className='smart-chain-edit-page'>
          <CircularProgress />
        </div>
      </PageWrapperWithMenu>
    );
  }

  return (
    <PageWrapperWithMenu
      padding={false}
      injectedTopBarItems={
        <div className={"smart-chain-top-bar-title-area"}>
          <h2>Smart Chain Editor</h2>
        </div>
      }
    >
      <div className={"smart-chain-edit-page"}>
        <SmartChainEditorTopBar
          onSaveClick={handleSmartChainSave}
          smartChain={smartChain}
          selectedSmartChainStep={
            selectedSmartChainStepIndex === null
              ? null
              : smartChain.steps[selectedSmartChainStepIndex]
          }
          onChainNameChanged={handleChainNameChanged}
          onStepTypeChange={handleNewStepTypeSelected}
          isContextOpen={isPromptContextOpen}
          isChainStepsOpen={isChainStepsOpen}
          onOpenPromptContextClicked={handleOpenPromptContextClicked}
          onOpenChainStepsClicked={handleOpenChainStepsClicked}
          hasUnsavedChanges={hasUnsavedChanges}
          onSwitchLightDarkModeClick={handleSwitchLightDarkModeClick}
          isDarkMode={isDarkMode}
          onShowPromptOutputClicked={handleShowPromptOutputClicked}
          isShowingPromptOutput={isShowingPromptOutput}
          onModelListClicked={handleModelListClicked}
          onActionListClicked={handleActionListClicked}
          handleShowPublishedSmartChainDialog={
            handleShowPublishedSmartChainDialog
          }
        />
        <div className={"smart-chain-main-editor-area"}>
          {isChainStepsOpen ? (
            <div className={`step-input-fields-column`}>
              <SmartChainStepList
                smartChain={smartChain}
                selectedStep={
                  selectedSmartChainStepIndex === null
                    ? null
                    : smartChain.steps[selectedSmartChainStepIndex]
                }
                onChainClicked={handleChainSelectedForValue}
                onStepClicked={handleChainStepClicked}
                onRenameSmartChain={handleChainNameChanged}
                onAddNewStepClicked={handleAddNewStepClicked}
                onDeleteSmartChainStep={handleDeleteSmartChainStep}
                onRenameSmartChainStep={handleRenameSmartChainStep}
                onMoveStepUpClicked={handleMoveStepUpClicked}
                onMoveStepDownClicked={handleMoveStepDownClicked}
              />
            </div>
          ) : null}
          {isPromptContextOpen ? (
            <div className={`step-input-fields-column`}>
              <SmartChainStepInputFieldList
                smartChain={smartChain}
                selectedSmartChainStepIndex={selectedSmartChainStepIndex ?? 0}
              />
            </div>
          ) : null}
          <div className={"prompt-editor-view-column"}>
            {selectedSmartChainStepIndex !== null ? (
              <SmartChainStepEditor
                smartChain={smartChain}
                selectedStepIndex={selectedSmartChainStepIndex}
                key={`${smartChain.steps[selectedSmartChainStepIndex].step_id}_${editorReloadCounter}`}
                isDarkMode={isDarkMode}
                tab={tab}
                setTab={setTab}
                onChangeStep={handleStepChanged}
              />
            ) : (
              <SmartChainWholeChainView
                smartChain={smartChain}
                isDarkMode={isDarkMode}
                tab={tab}
                setTab={setTab}
              />
            )}
          </div>
          {isShowingPromptOutput ? (
            <div className={"chain-step-output-view-column"}>
              <SmartChainStepOutputList
                smartChain={smartChain}
                selectedSmartChainStepIndex={
                  selectedSmartChainStepIndex ?? smartChain.steps.length - 1
                }
              />
            </div>
          ) : null}
          {isShowingModelList ? (
            <div className={"chain-step-output-view-column"}>
              <LLMModelList />
            </div>
          ) : null}
          {isShowingActionList ? (
            <div className={"chain-step-output-view-column"}>
              <AgentActionList />
            </div>
          ) : null}
          {showPublishedSmartChainDialog ? (
            <div className={"chain-step-output-view-column"}>
              <PublishedSmartChainVersionsList
                smartChain={smartChain}
                setSmartChain={setSmartChain}
              />
            </div>
          ) : null}
        </div>
      </div>

      <ChangeSmartChainStepTypeDialog
        open={isChangeSmartChainStepTypeOpen}
        handleClose={handleCloseChangeSmartChainStepType}
        handleConfirm={handleConfirmChangeSmartChainStepType}
      />
    </PageWrapperWithMenu>
  );
}
