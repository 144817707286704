import _ from "lodash";

const initialNodes = {
    "2": {
        "id": "2",
        "data": {
            "title": "Test Input",
            "generatedTitle": "Test Input",
            "prompt": "This is some testing input for the CI/CD",
            "currentValues": [],
            "stale": true,
            "fileId": null,
            "method": "custom",
            "templateId": null,
            "templateInsertionValues": {},
            "enable_ai_completion": false,
        },
        "position": {
            "x": 86,
            "y": 207
        },
        "style": {
            "height": 400,
            "width": 500,
        },
        "type": "promptNode",
    },
    "3": {
        "id": "3",
        "data": {
            "title": "",
            "generatedTitle": "Interesting Content",
            "prompt": "<p><span data-type=\"mention\" class=\"mention\" data-id=\"2\">@Test Input</span></p><p></p><p>Do something interesting with the above text.</p>",
            "currentValues": ["Initial current value"],
            "stale": false,
            "fileId": null,
            "method": "custom",
            "templateId": null,
            "templateInsertionValues": {},
            "enable_ai_completion": true,
        },
        "position": {
            "x": 869,
            "y": 220
        },
        "type": "promptNode",
        "style": {
            "width": 708,
            "height": 300
        }
    },
    "4": {
        "id": "4",
        "data": {
            "title": "",
            "prompt": "<p>Testing Chart<br><br>Interesting Content:</p><p><span data-type=\"mention\" class=\"mention\" data-id=\"3\">@Interesting Content</span></p>",
            "currentValues": ["Filled out data"],
            "stale": false,
            "fileId": null,
            "method": "custom",
            "templateId": null,
            "templateInsertionValues": {},
            "generatedTitle": "",
            "enable_ai_completion": false,
        },
        "position": {
            "x": 1727,
            "y": 241
        },
        "type": "promptNode",
        "style": {}
    }
};

const initialEdges = {
    "reactflow__edge-2out-3in": {
        "id": "reactflow__edge-2out-3in",
        "source": "2",
        "sourceHandle": "out",
        "target": "3",
        "targetHandle": "in"
    },
    "reactflow__edge-3out-outputin": {
        "id": "reactflow__edge-3out-outputin",
        "source": "3",
        "sourceHandle": "out",
        "target": "4",
        "targetHandle": "in"
    }
};

export const createTestingChart = () => {
    return {
        id: null,
        id_counter: 100,
        title: "",
        user_id: null,
        nodes: _.cloneDeep(initialNodes),
        edges: _.cloneDeep(initialEdges),
        allowPublicViewing: true,
        created_at: new Date().toISOString(),
    }
}
