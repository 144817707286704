import "../nodes/TextInputNode/TextInputNode.scss";
import "./NodeTitleEditor.scss";
import * as React from 'react';
import BehaviorTracker from "../../../../core/frontend/components/BehaviorTracker";
import TextField from '@mui/material/TextField';
import useChartStore from "../ChartState";
import {useCallback} from 'react';


function NodeTitleEditor({nodeId, placeholder}) {
    const {node, changeNodeData} = useChartStore((state) => {
        return {
            node: state.getNode(nodeId),
            changeNodeData: state.changeNodeData,
            recomputeChart: state.recomputeChart,
            isUpdatingChart: state.is_recomputing_chart,
        }
    });

    const onChangeTitle = useCallback((evt) => {
        changeNodeData(nodeId, {
            title: evt.target.value,
            stale: true,
        });

        if (evt.target.value.trim()) {
            BehaviorTracker.trackInteraction({
                id: "change-node-title",
                mixpanel: null,
            });
        }
    }, [nodeId, changeNodeData]);

    return (<TextField
            id={`title-editor-${nodeId}`}
            variant="standard"
            multiline={true}
            className="node-title-editor nodrag always-show-caret"
            value={node.data.title}
            title={`Title: ${node.displayTitle}`}
            placeholder={node.displayTitle}
            onChange={(event) => onChangeTitle(event)}
            onKeyDown={(event) => {
                // This is needed to allow the user to do things like press ctrl-delete
                // without triggering ReactFlow to delete the node.
                event.stopPropagation();
            }}
        />
    );
}

export default NodeTitleEditor;
