import React from "react";
import styles from "./HookForm.module.scss";
import { Controller, useFormContext } from "react-hook-form";
import { FormControl, FormHelperText, TextField } from "@mui/material";

export default function RHFTextField({
  name,
  type,
  label,
  helperText,
  multiline,
  ...props
}) {
  const { control } = useFormContext();

  return (
    <div className={styles.formGroup}>
      <label>{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormControl fullWidth error={!!error} {...props}>
            <TextField
              {...field}
              fullWidth
              type={type}
              value={type === "number" && field === 0 ? "" : field.value}
              onChange={(event) => {
                if (type === "number") {
                  field.onChange(Number(event.target.value));
                } else {
                  field.onChange(event.target.value);
                }
              }}
              error={!!error}
              multiline={multiline}
              {...props}
            />
            {(!!error || helperText) && (
              <FormHelperText error={!!error} className={styles.helperText}>
                {error ? error?.message : helperText}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </div>
  );
}
