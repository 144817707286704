import React, {useCallback} from "react";
import "./SubscriptionPage.scss";
import {PageWrapperWithMenu} from "../../../core/frontend/elements/PageWrapperWithMenu";
import {PageHeader} from "../../../core/frontend/elements/PageHeader";
import {PageSection} from "../../../core/frontend/elements/PageSection";
import {Card, CardActions, CardContent} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import ChatIcon from '@mui/icons-material/Chat';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListItem from "@mui/material/ListItem";
import {useAPI} from "../../../core/frontend/components/APIProvider";


export default function SubscriptionPage() {
    const api = useAPI();

    const handleBillingClick = useCallback(() => {
        api.getBookingPortalUrl().then((data) => {
            const url = data.url;

            // Redirect user over to the booking portal
            window.location.href = url;
        });
    }, [api]);


    return <PageWrapperWithMenu>
        <div className={"subscription-widget"}>
            <PageHeader title={`Subscription`}/>

            <PageSection>

                <Card variant="outlined">
                    <CardContent>
                        <Typography sx={{fontSize: 28}} gutterBottom>
                            Standard
                        </Typography>
                        <List
                            sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    Simple prices, peace of mind
                                </ListSubheader>
                            }
                        >
                            <ListItem>
                                <ListItemIcon>
                                    <LocalPhoneIcon/>
                                </ListItemIcon>
                                <ListItemText primary="$0.10 / voice minute"/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <ChatIcon/>
                                </ListItemIcon>
                                <ListItemText primary="$0.01 / web message"/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckCircleIcon/>
                                </ListItemIcon>
                                <ListItemText primary="All Features Included"/>
                            </ListItem>
                        </List>
                    </CardContent>
                    <CardActions>
                        <Button size="small" onClick={handleBillingClick}>View Billing Details</Button>
                    </CardActions>
                </Card>
            </PageSection>
        </div>
    </PageWrapperWithMenu>
}

