import _ from "lodash";

const initialNodes = {
    "2": {
        "id": "2",
        "data": {
            "title": "Product Description",
            "generatedTitle": "Product Description",
            "prompt": "Imagine a product, called FlowThought, that allows you to use AI as easily as you use a spreadsheet. " +
                "It allows you to automate tedious, knowledge oriented tasks and perform complex analysis. " +
                "It's as good at doing qualitative analysis as a calculator is at multiplying numbers. " +
                "The product accomplishes this by allowing you to chain together a series of prompts, which " +
                "are like instructions provided to the AI on how to think, much like equations are what " +
                "to calculate.",
            "currentValues": [],
            "stale": true,
            "fileId": null,
            "method": "custom",
            "templateId": null,
            "templateInsertionValues": {},
            "enable_ai_completion": false,
        },
        "position": {
            "x": 86,
            "y": 207
        },
        "style": {
            "height": 400,
            "width": 500,
        },
        "type": "promptNode",
    },
    "3": {
        "id": "3",
        "data": {
            "title": "",
            "generatedTitle": "Product Sales Pitch",
            "prompt": "<p><span data-type=\"mention\" class=\"mention\" data-id=\"2\">@Product Description</span></p><p></p><p>Give me a short, 2 paragraph sales pitch for the above described product.</p>",
            // Yes I know this technically isn't a current value, but its setup this way to provide more awe when user lands on this chart anonymously
            "currentValues": ["Give me a short, 2 paragraph sales pitch for the above described product."],
            "stale": false,
            "fileId": null,
            "method": "custom",
            "templateId": null,
            "templateInsertionValues": {},
            "enable_ai_completion": true,
        },
        "position": {
            "x": 869,
            "y": 220
        },
        "type": "promptNode",
        "style": {
            "width": 708,
            "height": 300
        }
    },
    "4": {
        "id": "4",
        "data": {
            "title": "",
            "generatedTitle": "10 Industries where product is useful",
            "prompt": "<p><span data-type=\"mention\" class=\"mention\" data-id=\"2\">@Product Description</span></p><p></p><p>List 10 Industries that might be able to use the product</p>",
            // Yes I know this technically isn't a current value, but its setup this way to provide more awe when user lands on this chart anonymously
            "currentValues": ["List 10 Industries that might be able to use the product"],
            "stale": false,
            "fileId": null,
            "method": "custom",
            "templateId": null,
            "templateInsertionValues": {},
            "enable_ai_completion": true,
        },
        "position": {
            "x": 871,
            "y": 599
        },
        "type": "promptNode",
        "style": {
            "width": 715,
            "height": 300
        }
    },
    "5": {
        "id": "5",
        "data": {
            "title": "",
            "generatedTitle": "Product Usage Scenario",
            "prompt": "<p><span data-type=\"mention\" class=\"mention\" data-id=\"2\">@Product Description</span></p><p></p><p>Write me a short usage scenario describing a real world user facing a problem and using the product to solve it.</p>",
            // Yes I know this technically isn't a current value, but its setup this way to provide more awe when user lands on this chart anonymously
            "currentValues": ["Write me a short usage scenario describing a real world user facing a problem and using the product to solve it."],
            "stale": false,
            "fileId": null,
            "method": "custom",
            "templateId": null,
            "templateInsertionValues": {},
            "enable_ai_completion": true,
        },
        "position": {
            "x": 856,
            "y": 989
        },
        "type": "promptNode",
        "style": {
            "width": 725,
            "height": 304
        }
    },
    "6": {
        "id": "6",
        "data": {
            "title": "",
            "prompt": "<p>Blog Article<br><br>Sales Pitch:</p><p><span data-type=\"mention\" class=\"mention\" data-id=\"3\">@Product Sales Pitch</span> </p><p></p><p>10 Industries that the product can be used in</p><p><span data-type=\"mention\" class=\"mention\" data-id=\"4\">@Versatile Product Applications</span></p><p></p><p>An example scenario where the product is used:</p><p><span data-type=\"mention\" class=\"mention\" data-id=\"5\">@Product Usage Scenario</span></p>",
            "currentValues": ["Blog Article"],
            "stale": false,
            "fileId": null,
            "method": "custom",
            "templateId": null,
            "templateInsertionValues": {},
            "generatedTitle": "",
            "enable_ai_completion": false,
        },
        "position": {
            "x": 1727,
            "y": 241
        },
        "type": "promptNode",
        "style": {
            "width": 550,
            "height": 700
        }
    }
};

const initialEdges = {
    "reactflow__edge-2out-3in": {
        "id": "reactflow__edge-2out-3in",
        "source": "2",
        "sourceHandle": "out",
        "target": "3",
        "targetHandle": "in"
    },
    "reactflow__edge-2out-4in": {
        "id": "reactflow__edge-2out-4in",
        "source": "2",
        "sourceHandle": "out",
        "target": "4",
        "targetHandle": "in"
    },
    "reactflow__edge-2out-5in": {
        "id": "reactflow__edge-2out-5in",
        "source": "2",
        "sourceHandle": "out",
        "target": "5",
        "targetHandle": "in"
    },
    "reactflow__edge-5out-outputin": {
        "id": "reactflow__edge-5out-outputin",
        "source": "5",
        "sourceHandle": "out",
        "target": "6",
        "targetHandle": "in"
    },
    "reactflow__edge-4out-outputin": {
        "id": "reactflow__edge-4out-outputin",
        "source": "4",
        "sourceHandle": "out",
        "target": "6",
        "targetHandle": "in"
    },
    "reactflow__edge-3out-outputin": {
        "id": "reactflow__edge-3out-outputin",
        "source": "3",
        "sourceHandle": "out",
        "target": "6",
        "targetHandle": "in"
    }
};

export const createAnonymousHomeChart = () => {
    return {
        id: null,
        id_counter: 100,
        title: "",
        user_id: null,
        nodes: _.cloneDeep(initialNodes),
        edges: _.cloneDeep(initialEdges),
        allowPublicViewing: false,
        created_at: new Date().toISOString(),
    }
}
