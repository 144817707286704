import React, {useCallback} from "react";
import PromptChartBindingWidget from "../../../../prompt_chart/frontend/PromptChartBindingWidget";
import {useChangeFormData, useFormData} from "../FormRoot";

export function PromptChartSelectorInput({template, label, fieldName}) {
    const formData = useFormData();
    const changeFormData = useChangeFormData();

    const handleChange = useCallback((newPromptChartId) => {
        changeFormData(fieldName, newPromptChartId);
    }, [fieldName, changeFormData]);

    return <PromptChartBindingWidget
        template={template}
        label={label}
        name={"fieldName"}
        value={formData[fieldName]}
        onChange={handleChange}
    />
}

export default PromptChartSelectorInput;

