import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ChangeSmartChainStepTypeDialog({open, handleClose, handleConfirm}) {
    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Change Step Type?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Changing the step type will reset the step options, and may
                        render the step template invalid for the new type. Only do
                        this if you know what you are doing.
                        <br/>
                        <br/>
                        Are you sure you want to change the step type?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={"success"} autoFocus>Cancel</Button>
                    <Button onClick={handleConfirm} color={'info'}>
                        Change Type
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
