import "./FormButtons.scss";
import { SpinnerButton } from "../SpinnerButton";
import React from "react";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";

export function FormButtons({
  saveButtonText,
  onSaveClicked,
  children,
  showSaveButton,
}) {
  return (
    <div className={"form-buttons"}>
      {showSaveButton ? (
        <SpinnerButton
          variant="contained"
          color="primary"
          onClick={onSaveClicked}
        >
          <LocalTranslatedText
            language={"en"}
            text={saveButtonText || "Save"}
          />
        </SpinnerButton>
      ) : null}
      {children}
    </div>
  );
}

export default FormButtons;
