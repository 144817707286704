import "./SystemHealthPage.scss";
import CircularProgress from "@mui/material/CircularProgress";
import CustomJSONView from "../elements/CustomJSONView";
import React from "react";
import {PageHeader} from "../elements/PageHeader";
import {PageSection} from "../elements/PageSection";
import {PageWrapperWithMenu} from "../elements/PageWrapperWithMenu";
import {useAPI} from "../components/APIProvider";
import HealthCheckSummary from "../elements/HealthCheckSummary";

export default function SystemHealthPage() {
  const api = useAPI();
  const [healthCheckData, setHealthCheckData] = React.useState(null);

  React.useEffect(() => {
    if (!healthCheckData) {
      api.healthCheck().then((healthCheckData) => {
        setHealthCheckData(healthCheckData);
      }, (error) => {
        // An error response from the health check endpoint
        // is expected when system is unhealthy. So we should
        // still display the result data to the user
        if (error) {
          setHealthCheckData(error);
        }
      });
    }
  }, [healthCheckData]);

  return (
    <PageWrapperWithMenu>
      <div className={"system-health-page"}>
        <PageHeader title={`System Health`}/>
        {
          !healthCheckData ? <PageSection>
            <p>Please wait while we perform a system health check. This may take up to thirty seconds.</p>
            <br/>
            <CircularProgress/>
          </PageSection> : null
        }
        {
          healthCheckData ?
            <PageSection title={"Summary"}>
              <HealthCheckSummary healthCheckData={healthCheckData}/>
            </PageSection>
            : null
        }
        {
          healthCheckData ?
            <PageSection title={"Detailed Health Results"}>
              <CustomJSONView
                data={healthCheckData}
                field_name={"Health Check Results"}
                disable_collapse={false}
                openToLevel={2}
              />
            </PageSection>
            : null
        }
      </div>
    </PageWrapperWithMenu>
  );
}
