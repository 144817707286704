import { PageWrapperWithMenu } from "../../../core/frontend/elements/PageWrapperWithMenu";
import React, { useCallback, useEffect, useState } from "react";
import { useAPI } from "../../../core/frontend/components/APIProvider";
import { useParams } from "react-router-dom";
import { Card, CardContent, Typography } from "@mui/material";
import "./ConversationViewPage.scss";
import { ConversationHistory } from "./ConversationHistory";
import { PageHeader } from "../../../core/frontend/elements/PageHeader";
import globalWebChatEventConnectionManager from "../../web/frontend/WebChatEventConnectionManager";
import { SpinnerButton } from "../../../core/frontend/elements/SpinnerButton";
import ConversationDetails from "./ConversationDetails";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";
import { useFrontendConfiguration } from "../../frontend/components/FrontendConfigurationProvider";

export default function ConversationViewPage(props) {
  const api = useAPI();
  const params = useParams();
  const features = useFrontendConfiguration();

  const [conversation, setConversation] = useState(null);
  const [events, setEvents] = React.useState([]);

  function scrollToBottom() {
    const mainContentArea = document.querySelector(".main-content-area");
    if (mainContentArea) {
      mainContentArea.scrollTop = mainContentArea.scrollHeight;
    }
  }

  const addEvent = useCallback((newEventData) => {
    setEvents((events) => {
      // Double check to see if the new event data being added isn't already in the list of events
      for (let i = 0; i < events.length; i++) {
        if (events[i].id === newEventData.id) {
          return events;
        }
      }

      return [...events, newEventData];
    });
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  }, []);

  useEffect(() => {
    globalWebChatEventConnectionManager.updateEventHandle(addEvent);
  }, [addEvent]);

  const startListeningForMessages = useCallback(
    (conversationId, conversationToken, mostRecentEventTime) => {
      globalWebChatEventConnectionManager.subscribeToEvents(
        conversationId,
        conversationToken,
        mostRecentEventTime,
        addEvent
      );
    },
    [addEvent]
  );

  React.useEffect(() => {
    if (!conversation) {
      api.getConversation(params?.conversationId).then((conversation) => {
        setConversation(conversation);

        api.getRecentEvents(params?.conversationId).then((data) => {
          const reversedMessaged = data.messages;
          reversedMessaged.reverse();
          setEvents(reversedMessaged);

          if (conversation.status === "active") {
            const mostRecentMessage = data.messages[data.messages.length - 1];
            startListeningForMessages(
              conversation._id,
              conversation?.metadata?.conversation_token,
              mostRecentMessage?.created_at
            );
            setTimeout(() => {
              scrollToBottom();
            }, 100);
          }
        });
      });
    }
  }, [startListeningForMessages, conversation, params?.conversationId, api]);

  React.useEffect(() => {
    return () => {
      globalWebChatEventConnectionManager.disconnect();
    };
  }, []);

  const handleExportConversationClicked = useCallback(
    (e) => {
      e.preventDefault();
      return api.downloadConversationExport(conversation.id).then((pdfData) => {
        const blob = new Blob([pdfData], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `conversation-export-${conversation.id}.pdf`;
        a.click();
        URL.revokeObjectURL(url);
      });
    },
    [api, conversation]
  );

  if (!conversation) {
    return (
      <PageWrapperWithMenu>
        <PageHeader title={`View Conversation`} />
      </PageWrapperWithMenu>
    );
  }

  return (
    <PageWrapperWithMenu>
      <PageHeader title={`View Conversation`} />
      <div className='conversation-view-page'>
        <Card className='conversation-details-card'>
          <CardContent>
            <ConversationDetails conversation={conversation} />
            <div className={"buttons-column"}>
              <SpinnerButton
                variant='contained'
                color='primary'
                onClick={handleExportConversationClicked}
              >
                <LocalTranslatedText
                  language={"en"}
                  text='Export Conversation'
                />
              </SpinnerButton>
            </div>
          </CardContent>
        </Card>

        <Typography variant={"h6"} fontWeight={700}>
          <LocalTranslatedText language={"en"} text='Recent Messages' />
        </Typography>
        <Card className='conversation-events-card'>
          <CardContent>
            <ConversationHistory
              events={events}
              onlyShowMessages={false}
              conversation={conversation}
            />
          </CardContent>
        </Card>
      </div>
    </PageWrapperWithMenu>
  );
}
