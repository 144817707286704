import * as React from 'react';
import {useCallback, useMemo} from "react";
import {useAPI} from "../../../frontend/components/APIProvider";
import TableElementLookupSelectorInput from "../../../frontend/elements/form/inputs/TableElementLookupSelectorInput";


export default function SmartChainBindingSelector({label, fieldName}) {
    const api = useAPI();

    const getSuggestions = useCallback((tableQuery) => {
        return api.getSmartChainBindings(tableQuery);
    }, [api]);

    return <TableElementLookupSelectorInput
        id="smart-chain-binding-selector"
        label={label}
        fieldName={fieldName}
        getSuggestions={getSuggestions}
        getSingleItem={api.getSmartChainBinding}
        searchFieldName={'binding_name'}
        displayFieldName={'binding_name'}
        multiple={false}
    />
}