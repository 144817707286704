import FormControl from "@mui/material/FormControl";
import React, {useState, useCallback, useEffect, useMemo} from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useAPI} from "../../../../core/frontend/components/APIProvider";
import "./PromptChartTemplateSampleValuesList.scss";
import {useTemplateSampleValuesCache} from "../TemplateSampleValuesCacheProvider";
import {isString} from "lodash";
import moment from "moment";

export function PromptChartTemplateSampleValuesList({template_id, field_name, onChange, onBlur}) {
    const api = useAPI();
    const samplesCache = useTemplateSampleValuesCache();
    const cacheKey = `${template_id}-${field_name}`;
    const [sampleValues, setSampleValues] = useState(samplesCache[cacheKey] || null);

    const handleChange = useCallback((event) => {
        if (onChange) {
            if (event.target.value !== "default") {
                onChange(event.target.value);
            }
        }
    }, [onChange]);

    const fetchCharts = useCallback(() => {
        api.getPromptChartTemplateSampleValues(template_id, field_name).then((data) => {
            setSampleValues(data);
            samplesCache[cacheKey] = data;
        });
    }, [api, template_id, field_name, samplesCache, cacheKey]);

    useEffect(() => {
        if (!sampleValues) {
            fetchCharts();
        }
    }, [fetchCharts, sampleValues]);

    const handleOnBlur = useCallback((event) => {
        if (onBlur) {
            onBlur(event);
        }
    }, [onBlur]);

    const sampleValueOptions = useMemo(() => {
        if (!sampleValues) {
            return [<MenuItem value={"default"}>
                Loading...
            </MenuItem>];
        }
        if (sampleValues && sampleValues.length === 0) {
            return [<MenuItem value={"default"}>
                No Sample Values Found. Please use this prompt chart<br/>
                to build up the list of sample input values.
            </MenuItem>]
        }
        if (sampleValues && sampleValues.length > 0) {
            const values = [
                <MenuItem value={"default"}>
                    <span className={"sample-placeholder-text"}>Select a sample value to test with ...</span>
                </MenuItem>
            ]

            sampleValues.map((sampleValue, sampleIndex) => {
                let formattedValue = "";
                if (isString(sampleValue.value)) {
                    formattedValue = sampleValue.value.trim();

                } else {
                    let valueHash = "";
                    if (sampleValue.matching_hash) {
                        valueHash = sampleValue.matching_hash.toString().substring(0, 3);
                    }
                    formattedValue = `Sample ${valueHash}`

                    // Use moment to turn the utc ISO string into a relative date, e.g. "75 seconds ago"
                    if (sampleValue.updated_at) {
                        const updatedAt = moment.utc(sampleValue.updated_at);
                        const relativeTime = updatedAt.fromNow();
                        formattedValue = `${formattedValue} (${relativeTime})`;
                    }

                    const valueJSON = JSON.stringify(sampleValue.value, null, 4);
                    formattedValue = `${formattedValue}\n${valueJSON}`;
                }

                if (formattedValue.length > 100) {
                    formattedValue = formattedValue.substring(0, 100) + "...";
                }

                values.push(<MenuItem value={sampleValue.value} key={sampleValue.value}>{formattedValue}</MenuItem>);
            });

            return values;
        }
        return [];
    }, [sampleValues, sampleValues?.length]);

    return <div className={"prompt-chart-template-sample-values-list nodrag"}>
        <FormControl>
            <Select
                title={"Sample Values"}
                placeholder={"Sample Values..."}
                onChange={handleChange}
                value={"default"}
                onBlur={handleOnBlur}
                defaultOpen={field_name !== "root"}
            >
                {sampleValueOptions}
            </Select>
        </FormControl>
    </div>;
}

export default PromptChartTemplateSampleValuesList;

