import React, {useCallback, useEffect, useState} from 'react';
import {PageWrapperWithMenu} from '../../frontend/elements/PageWrapperWithMenu';
import "./WA2ChatConfigurationPage.scss";
import {useAPI} from "../../frontend/components/APIProvider";
import {PageHeader} from "../../frontend/elements/PageHeader";
import {SpinnerButton} from "../../frontend/elements/SpinnerButton";
import {MonitoredGroupSelector} from "./MonitoredGroupSelector";
import {PageSection} from "../../frontend/elements/PageSection";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";
import CircularProgress from "@mui/material/CircularProgress";
import {BasicLanguageSelector} from "./BasicLanguageSelector";

function LanguageEditor({wa2ChatConfiguration, onLanguageChange, onSaveClicked}) {
    const handleValueChange = useCallback((newValue) => {
        onLanguageChange(newValue);
    }, [onLanguageChange]);

    return <PageSection
        title={"Language"}
        subheading={"Please select the default language that you want the bot to use when it's addressed"}
    >
        <BasicLanguageSelector
            value={wa2ChatConfiguration?.language_id ?? "en-US"}
            onChange={handleValueChange}
        />

        <SpinnerButton
            color={'primary'}
            variant={'contained'}
            onClick={onSaveClicked}
        >
            <LocalTranslatedText language={"en"} text="Save Language" />
        </SpinnerButton>
    </PageSection>;
}

export const WA2ChatConfigurationPage = () => {
    const api = useAPI();
    const [wa2ChatConfiguration, setWA2ChatConfiguration] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const loadWA2ChatConfiguration = useCallback(() => {
        setIsLoading(true);
        api.getWA2ChatConfiguration().then((data) => {
            setIsLoading(false);
            setWA2ChatConfiguration(data);
        }, (error) => {
            setIsLoading(false);
        });
    }, [api]);

    useEffect(() => {
        loadWA2ChatConfiguration();
    }, [loadWA2ChatConfiguration]);

    const handleWA2ChatConfigChange = useCallback((changes) => {
        console.log("changes:" + JSON.stringify(changes));
        setWA2ChatConfiguration({
            ...wa2ChatConfiguration,
            ...changes
        });
    }, [wa2ChatConfiguration]);

    const handleSaveWA2ChatConfigurationClicked = useCallback(() => {
        return api.saveWA2ChatConfiguration(wa2ChatConfiguration);
    }, [api, wa2ChatConfiguration]);

    const handleMonitoredGroupsChanged = useCallback((monitored_groups) => {
        handleWA2ChatConfigChange({monitored_groups: monitored_groups})
    }, [handleWA2ChatConfigChange]);

    const handleLanguageChange = useCallback((newLanguageId) => {
        handleWA2ChatConfigChange({language_id: newLanguageId})
    }, [handleWA2ChatConfigChange]);

    return (
        <PageWrapperWithMenu>
            <PageHeader title={`2Chat Configuration`}/>
            <div className='wa-2chat-group-configuration-page'>
                <PageSection
                    title={"Whatsapp Group Configuration"}
                >
                    {
                        isLoading ? <CircularProgress/> :
                        wa2ChatConfiguration ? (
                            <MonitoredGroupSelector
                                wa2ChatConfiguration={wa2ChatConfiguration}
                                onChange={handleMonitoredGroupsChanged}
                            />
                        ) : null
                    }

                    <SpinnerButton
                        color={'primary'}
                        variant={'contained'}
                        onClick={handleSaveWA2ChatConfigurationClicked}
                    >
                        <LocalTranslatedText language={"en"} text="Save Group Selection"/>
                    </SpinnerButton>
                </PageSection>

                <LanguageEditor wa2ChatConfiguration={wa2ChatConfiguration}
                                onLanguageChange={handleLanguageChange}
                                onSaveClicked={handleSaveWA2ChatConfigurationClicked}/>
            </div>
        </PageWrapperWithMenu>
    );
};

export default WA2ChatConfigurationPage;
