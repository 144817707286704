import {PageWrapperWithMenu} from "../../../core/frontend/elements/PageWrapperWithMenu";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useAPI} from "../../../core/frontend/components/APIProvider";
import {TranscriptProcessorConfigurationTable} from "./TranscriptProcessorConfigurationTable";
import "./TranscriptProcessorConfigurationListPage.scss";
import {PageHeader} from "../../../core/frontend/elements/PageHeader";
import {WhiteButton} from "../../../core/frontend/elements/WhiteButton";
import Paper from "@mui/material/Paper";
import {useCallback} from "react";


export default function TranscriptProcessorConfigurationListPage() {
    const api = useAPI();
    const navigate = useNavigate();

    const handleNewTranscriptProcessorConfigurationClicked = useCallback(async () => {
        navigate(`/transcript_processor_configurations/new`);
    }, [navigate]);

    return <PageWrapperWithMenu>
        <PageHeader title={"Transcript Processor Configurations"}>
            <WhiteButton onClick={handleNewTranscriptProcessorConfigurationClicked}>
                Add New Transcript Processor Configuration
            </WhiteButton>
        </PageHeader>
        <div className={"transcript-processor-configuration-list-page"}>
            <div className={"transcript-processor-configuration-table-wrapper"}>
                <Paper className={"main-page-contents"}>
                    <TranscriptProcessorConfigurationTable/>
                </Paper>
            </div>
        </div>
    </PageWrapperWithMenu>
}

