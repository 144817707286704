import { PageWrapperWithMenu } from "../elements/PageWrapperWithMenu";
import React, { useEffect } from "react";
import "./HomePage.scss";
import { PageHeader } from "../elements/PageHeader";
import { PageSection } from "../elements/PageSection";
import OnboardingChecklist from "../../onboarding/frontend/OnboardingChecklist";
import { useNavigate } from "react-router-dom";
import { useAPI } from "../components/APIProvider";
import { PageWrapperWithoutMenu } from "../elements/PageWrapperWithoutMenu";
import { useFrontendConfiguration } from "../components/FrontendConfigurationProvider";

export default function HomePage() {
  const config = useFrontendConfiguration();

  const api = useAPI();
  const [checklistData, setChecklistData] = React.useState(null);

  React.useEffect(() => {
    if (!checklistData) {
      api.getOnboardingChecklist().then((checklistData) => {
        setChecklistData(checklistData);
      });
    }
  }, [checklistData]);

  const navigate = useNavigate();

  useEffect(() => {
    if (config.frontend.home_page_redirect) {
      navigate(config.frontend.home_page_redirect);
    }
  }, [navigate]);

  if (config.frontend.home_page_redirect) {
    if (process.env.REACT_APP_HIDE_LEFT_MENU_WHEN_NOT_ADMIN === "true") {
      return <PageWrapperWithoutMenu />;
    } else {
      return <PageWrapperWithMenu />;
    }
  }

  return (
    <PageWrapperWithMenu>
      <PageHeader title={`Home`} />

      <PageSection>
        {/*<p>Welcome to {config.frontend.top_bar_title}</p>*/}

        <OnboardingChecklist
          checklistData={checklistData}
          defaultIsExpanded={true}
        />
      </PageSection>
    </PageWrapperWithMenu>
  );
}
