import React, {useState, useCallback, useMemo, useEffect} from 'react';
import {PageWrapperWithMenu} from '../../frontend/elements/PageWrapperWithMenu';
import {TextField} from "@mui/material";
import "./VoiceConfigurationPage.scss";
import {PhoneNumberPurchaseFlow} from "./PhoneNumberPurchaseFlow";
import {PhoneNumberConfigurationMethodSelector} from "./PhoneNumberConfigurationMethodSelector";
import {PhoneNumberPortFlow} from "./PhoneNumberPortFlow";
import {useAPI} from "../../frontend/components/APIProvider";
import {PageHeader} from "../../frontend/elements/PageHeader";
import CircularProgress from "@mui/material/CircularProgress";
import {PhoneNumberTestingNumberFlow} from "./PhoneNumberTestingNumberFlow";
import {SpinnerButton} from "../../frontend/elements/SpinnerButton";
import {formatPhoneNumberHumanReadable} from "../../frontend/utils/phone";
import {VoiceSelector} from "./VoiceSelector";
import {PageSection} from "../../frontend/elements/PageSection";
import _ from "lodash";
import WaitingNoiseSelector from "./WaitingNoiseSelector";
import {BasicLanguageSelector} from "./BasicLanguageSelector";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";
import CountrySelectorForAvailablePhoneNumbers from "./CountrySelectorForAvailablePhoneNumbers";
import countryData from "../data/supported_countries_for_phone_numbers.json";
import Box from "@mui/material/Box";

function InitialGreetingEditor({voiceConfiguration, onInitialGreetingChanged, onHangUpMessageChanged,
                                   onTransferMessageChanged, onSaveClicked}) {
    const [initialGreeting, setInitialGreeting] = useState(voiceConfiguration.initial_greeting);
    const [hangUpMessage, setHangUpMessage] = useState(voiceConfiguration.hang_up_message);
    const [transferMessage, setTransferMessage] = useState(voiceConfiguration.transfer_message);

    const onInitialGreetingChangedDebounced = useMemo(() => _.debounce(onInitialGreetingChanged, 500), [onInitialGreetingChanged]);

    const onHangUpMessageChangedDebounced = useMemo(() => _.debounce(onHangUpMessageChanged, 500), [onHangUpMessageChanged]);

    const onTransferMessageChangedDebounced = useMemo(() => _.debounce(onTransferMessageChanged, 500), [onTransferMessageChanged]);

    const handleInitialGreetingChange = useCallback((evt) => {
        setInitialGreeting(evt.target.value);
        onInitialGreetingChangedDebounced(evt.target.value);
    }, [onInitialGreetingChangedDebounced]);

    const handleHangUpMessageChange = useCallback((evt) => {
        setHangUpMessage(evt.target.value);
        onHangUpMessageChangedDebounced(evt.target.value);
    }, [onHangUpMessageChangedDebounced]);

    const handleTransferMessageChange = useCallback((evt) => {
        setTransferMessage(evt.target.value);
        onTransferMessageChangedDebounced(evt.target.value);
    }, [onTransferMessageChangedDebounced]);

    return <PageSection
        title={"Greeting and Goodbye"}
        subheading={"Please type in what you want the bot to say at the start and end of phone calls"}
    >
        <TextField
            label={<LocalTranslatedText language={"en"} text="Initial Greeting" />}
            name={'initial_greeting'}
            fullWidth
            multiline
            rows={4}
            value={initialGreeting}
            onChange={handleInitialGreetingChange}
        />

        <br/>

        <TextField
            label={<LocalTranslatedText language={"en"} text="Good Bye Message" />}
            name={'hang_up_message'}
            fullWidth
            multiline
            rows={4}
            value={hangUpMessage}
            onChange={handleHangUpMessageChange}
        />
        <TextField
            label={<LocalTranslatedText language={"en"} text="Transfer to Human Message" />}
            name={'transfer_message'}
            fullWidth
            multiline
            rows={4}
            value={transferMessage}
            onChange={handleTransferMessageChange}
        />

        <SpinnerButton
            color={'primary'}
            variant={'contained'}
            onClick={onSaveClicked}
        >
            <LocalTranslatedText language={"en"} text="Save Greeting & Goodbye" />
        </SpinnerButton>
    </PageSection>;
}

function HumanTransferPhoneNumberEditor({voiceConfiguration, onHumanTransferPhoneNumberChanged, onSaveClicked}) {
    const splitFullNumber = (fullNumber) => {
          if (!fullNumber) {
              return { letterCode: 'US', numberCode: 1, localNumber: null };
          } else if (!fullNumber.startsWith('+')) {
              return { letterCode: 'US', numberCode: 1, localNumber: fullNumber };
          }

          var country = countryData.countries.find(country => fullNumber.startsWith(`+${country.phone_country_code}`));
          if (country) {
              return {
                  // +1 will always show US, since there are multiple countries with the same phone code
                  letterCode: country.phone_country_code === '1' ? 'US' : country.code,
                  numberCode: country.phone_country_code,
                  localNumber: fullNumber.slice(country.phone_country_code.length + 1) // +1 for the '+' sign
              };
          }
          throw new Error("Phone number without recognized country code: " + fullNumber);
    };

    const splitNumber = splitFullNumber(voiceConfiguration.human_transfer_phone_number);
    const [humanTransferNumber, setHumanTransferNumber] = useState(splitNumber.localNumber);
    const [countryLetterCode, setCountryLetterCode] = useState(splitNumber.letterCode);

    const onHumanTransferPhoneNumberChangedDebounced = useMemo(() => _.debounce(onHumanTransferPhoneNumberChanged, 500), [onHumanTransferPhoneNumberChanged]);

    /**
     * Get the phone country code from the country code, ie 'US' -> 1
     * @param countryLetterCode
     * @returns {*|null}
     */
    const getPhoneCodeFromCountryLetterCode = (countryLetterCode) => {
      const country = countryData.countries.find(country => country.code === countryLetterCode);
      return country ? country.phone_country_code : null;
    };

    useEffect(() => {
        if (humanTransferNumber && humanTransferNumber.startsWith('+')) {
            const code = humanTransferNumber.match(/^\+(\d+)/)[1];
            setCountryLetterCode(code);
            setHumanTransferNumber(humanTransferNumber.slice(code.length + 1));
        }
    }, [humanTransferNumber]);

    const handleCountryCodeChange = useCallback((newCountryLetterCode) => {
        const numberCode = getPhoneCodeFromCountryLetterCode(newCountryLetterCode);
        setCountryLetterCode(newCountryLetterCode);
        const fullNumber = `+${numberCode}${humanTransferNumber}`;
        onHumanTransferPhoneNumberChangedDebounced(fullNumber);
    }, [humanTransferNumber, onHumanTransferPhoneNumberChangedDebounced]);

    const handleNumberChange = useCallback((newLocalNumber) => {
        const newNumber = newLocalNumber.target.value;
        setHumanTransferNumber(newNumber);
        const countryNumberCode = getPhoneCodeFromCountryLetterCode(countryLetterCode)
        const fullNumber = `+${countryNumberCode}${newNumber}`;
        onHumanTransferPhoneNumberChangedDebounced(fullNumber);
    }, [countryLetterCode, onHumanTransferPhoneNumberChangedDebounced]);

    return <PageSection
        title={"Human Transfer"}
        subheading={"Please provide the phone number you want called when a caller asks to speak with a human."}
    >
        <Box sx={{
          display: 'flex',
          flexDirection: 'row', // This makes children align horizontally
          gap: 2 // Optional: adds space between the divs
        }}>
            <CountrySelectorForAvailablePhoneNumbers
                onChange={handleCountryCodeChange}
                value={countryLetterCode}
                allowNone={false}
            />
            <TextField
                className={'human-transfer-phone-textfield'}
                label={<LocalTranslatedText language={"en"} text="Human Transfer Phone Number" />}
                name={'human_transfer_phone_number'}
                type="text"
                inputmode="numeric"
                pattern="[0-9]{*}"
                value={humanTransferNumber}
                onChange={handleNumberChange}
            />
        </Box>
        <SpinnerButton
            color={'primary'}
            variant={'contained'}
            onClick={onSaveClicked}
        >
            <LocalTranslatedText language={"en"} text="Save Human Transfer Number" />
        </SpinnerButton>
    </PageSection>;
}

function WaitingNoiseEditor({voiceConfiguration, onWaitingNoiseChange, onSaveClicked}) {
    const handleValueChange = useCallback((newValue) => {
        onWaitingNoiseChange(newValue);
    }, [onWaitingNoiseChange]);

    return <PageSection
        title={"Waiting Noise"}
        subheading={"Please select which noise you would like to play while the bot is thinking"}
    >
        <WaitingNoiseSelector
            value={voiceConfiguration?.waiting_noise}
            onChange={handleValueChange}
        />

        <SpinnerButton
            color={'primary'}
            variant={'contained'}
            onClick={onSaveClicked}
        >
            <LocalTranslatedText language={"en"} text="Save Waiting Noise" />
        </SpinnerButton>
    </PageSection>;
}

function LanguageEditor({voiceConfiguration, onLanguageChange, onSaveClicked}) {
    const handleValueChange = useCallback((newValue) => {
        onLanguageChange(newValue);
    }, [onLanguageChange]);

    return <PageSection
        title={"Language"}
        subheading={"Please select the default language that you want the bot to use when it's called"}
    >
        <BasicLanguageSelector
            value={voiceConfiguration?.language_id ?? "en-US"}
            onChange={handleValueChange}
        />

        <SpinnerButton
            color={'primary'}
            variant={'contained'}
            onClick={onSaveClicked}
        >
            <LocalTranslatedText language={"en"} text="Save Language" />
        </SpinnerButton>
    </PageSection>;
}

export const VoiceConfigurationPage = () => {
    const api = useAPI();
    const [voiceConfiguration, setVoiceConfiguration] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const loadVoiceConfiguration = useCallback(() => {
        setIsLoading(true);
        api.getVoiceConfiguration().then((data) => {
            setIsLoading(false);
            setVoiceConfiguration(data);
        }, (error) => {
            setIsLoading(false);
        });
    }, [api]);

    useEffect(() => {
        loadVoiceConfiguration();

    }, [loadVoiceConfiguration]);

    const handleConfigurationModeChanged = useCallback((purchaseMethod) => {
        setVoiceConfiguration({
            ...voiceConfiguration,
            phone_number_configuration_mode: purchaseMethod
        });
    }, [voiceConfiguration]);

    const handleResetConfigurationClicked = useCallback(() => {
        return api.saveVoiceConfiguration({
            phone_number_configuration_mode: null,
            bot_number: null,
        }).then(() => {
            setVoiceConfiguration({
                ...voiceConfiguration,
                phone_number_configuration_mode: null,
                bot_number: null,
                status: 'not_configured'
            })
        })
    }, [api, voiceConfiguration]);

    const handleVoiceConfigChange = useCallback((changes) => {
        setVoiceConfiguration({
            ...voiceConfiguration,
            ...changes
        });
    }, [voiceConfiguration]);

    const handleSaveVoiceConfigurationClicked = useCallback(() => {
        return api.saveVoiceConfiguration(voiceConfiguration);
    }, [api, voiceConfiguration]);

    const handleVoiceIdChanged = useCallback((voiceId) => {
        handleVoiceConfigChange({voice_id: voiceId})
    }, [handleVoiceConfigChange]);

    const handleInitialGreetingChanged = useCallback((newInitialGreeting) => {
        handleVoiceConfigChange({initial_greeting: newInitialGreeting})
    }, [handleVoiceConfigChange]);

    const handleHangUpMessageChanged = useCallback((newHangUpMessage) => {
        handleVoiceConfigChange({hang_up_message: newHangUpMessage})
    }, [handleVoiceConfigChange]);

    const handleTransferMessageChanged = useCallback((newTransferMessage) => {
        handleVoiceConfigChange({transfer_message: newTransferMessage})
    }, [handleVoiceConfigChange]);

    const handleHumanTransferPhoneNumberChanged = useCallback((newNumber) => {
        handleVoiceConfigChange({human_transfer_phone_number: newNumber})
    }, [handleVoiceConfigChange]);

    const handleWaitingNoiseChange = useCallback((newWaitingNoise) => {
        handleVoiceConfigChange({waiting_noise: newWaitingNoise})
    }, [handleVoiceConfigChange]);

    const handleLanguageChange = useCallback((newLanguageId) => {
        handleVoiceConfigChange({language_id: newLanguageId})
    }, [handleVoiceConfigChange]);

    return (
        <PageWrapperWithMenu>
            <PageHeader title={`Voice Configuration`}/>
            <div className='voice-configuration-page'>
                <PageSection
                    title={"Phone Number"}
                    subheading={"You can configure the phone number for your bot here"}
                >
                    {
                        isLoading ? <CircularProgress/> : null
                    }
                    {
                        voiceConfiguration?.status === "not_configured"
                            ? <div>

                                <PhoneNumberConfigurationMethodSelector
                                    onConfigurationModeSelected={handleConfigurationModeChanged}
                                />

                                {
                                    voiceConfiguration?.phone_number_configuration_mode === "purchase"
                                        ? <PhoneNumberPurchaseFlow
                                            onVoiceConfigurationChanged={handleVoiceConfigChange}
                                        />
                                        : null
                                }
                                {
                                    voiceConfiguration?.phone_number_configuration_mode === "port"
                                        ? <PhoneNumberPortFlow
                                            onVoiceConfigurationChanged={handleVoiceConfigChange}
                                        />
                                        : null
                                }
                                {
                                    voiceConfiguration?.phone_number_configuration_mode === "test_number"
                                        ? <PhoneNumberTestingNumberFlow
                                            onVoiceConfigurationChanged={handleVoiceConfigChange}
                                        />
                                        : null
                                }
                            </div>
                            : null
                    }
                    {
                        voiceConfiguration?.status === 'ready'
                            ? <div>
                                <p>
                                    <LocalTranslatedText language={"en"} text={"Status: "}/>
                                    <strong><LocalTranslatedText language={"en"} text="Ready"/></strong>
                                </p>

                                <p>
                                    <LocalTranslatedText language={"en"} text={"Your account is currently configured to use the phone number"} />
                                    {formatPhoneNumberHumanReadable(voiceConfiguration?.bot_number)}.</p>
                                <br/>

                                <SpinnerButton
                                    color={'primary'}
                                    variant={'contained'}
                                    onClick={handleResetConfigurationClicked}
                                >
                                    <LocalTranslatedText language={"en"} text="Reset Phone Number Configuration"/>
                                </SpinnerButton>
                            </div>
                            : null
                    }
                    {
                        voiceConfiguration?.status === 'port_processing'
                            ? <div>
                                <p>
                                    <LocalTranslatedText language={"en"} text="Status: "/>
                                    <strong><LocalTranslatedText language={"en"} text="Port Processing"/></strong>
                                </p>

                                <p>
                                    <LocalTranslatedText language={"en"} text="Phone Number: "/>
                                    {formatPhoneNumberHumanReadable(voiceConfiguration?.bot_number)}
                                </p>

                                <p>
                                    <LocalTranslatedText language={"en"} text="Your phone number porting request is currently being processed. Our team will reach out to you directly to complete the process." />
                                </p>

                                <SpinnerButton
                                    color={'primary'}
                                    variant={'contained'}
                                    onClick={handleResetConfigurationClicked}
                                >
                                    <LocalTranslatedText language={"en"} text="Cancel Phone Number Port Request" />
                                </SpinnerButton>
                            </div>
                            : null
                    }
                </PageSection>

                {
                    voiceConfiguration?.status === 'ready' ?
                        <InitialGreetingEditor voiceConfiguration={voiceConfiguration}
                                               onInitialGreetingChanged={handleInitialGreetingChanged}
                                               onHangUpMessageChanged={handleHangUpMessageChanged}
                                               onTransferMessageChanged={handleTransferMessageChanged}
                                               onSaveClicked={handleSaveVoiceConfigurationClicked}/>
                        : null
                }

                {
                    voiceConfiguration?.status === 'ready' ?
                        <HumanTransferPhoneNumberEditor voiceConfiguration={voiceConfiguration}
                                                        onHumanTransferPhoneNumberChanged={handleHumanTransferPhoneNumberChanged}
                                                        onSaveClicked={handleSaveVoiceConfigurationClicked}/>
                        : null
                }

                {
                    voiceConfiguration?.status === 'ready' ?
                        <LanguageEditor voiceConfiguration={voiceConfiguration}
                                        onLanguageChange={handleLanguageChange}
                                        onSaveClicked={handleSaveVoiceConfigurationClicked}/>
                        : null
                }

                {
                    voiceConfiguration?.status === 'ready' ?
                        <WaitingNoiseEditor voiceConfiguration={voiceConfiguration}
                                            onWaitingNoiseChange={handleWaitingNoiseChange}
                                            onSaveClicked={handleSaveVoiceConfigurationClicked}/>
                        : null
                }

                {
                    voiceConfiguration?.status === 'ready'
                        ? <PageSection
                            title={"Voice"}
                            subheading={"Please select the voice you would like the bot to use"}
                        >
                            <VoiceSelector
                                value={voiceConfiguration.voice_id}
                                onChange={handleVoiceIdChanged}
                            />

                            <SpinnerButton
                                color={'primary'}
                                variant={'contained'}
                                onClick={handleSaveVoiceConfigurationClicked}
                            >
                                <LocalTranslatedText language={"en"} text="Save Voice Selection"/>
                            </SpinnerButton>
                        </PageSection>
                        : null
                }
            </div>
        </PageWrapperWithMenu>
    );
};

export default VoiceConfigurationPage;
