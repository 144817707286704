import * as React from 'react';
import "./InputGroupNode.scss";
import useChartStore from "../../ChartState";
import Card from "@mui/material/Card";
import PromptChartTemplateSampleValuesList from "../../components/PromptChartTemplateSampleValuesList";
import {useCallback} from "react";



function InputGroupNode(props) {
    const {nodes, getNode, changeNodeData, createTextInputNode, createFileInputNode, isInputNode, template, recomputeChart} = useChartStore((state) => {
        return {
            nodes: state.nodes,
            getNode: state.getNode,
            changeNodeData: state.changeNodeData,
            createTextInputNode: state.createTextInputNode,
            createFileInputNode: state.createFileInputNode,
            isInputNode: state.isInputNode,
            template: state.template,
            recomputeChart: state.recomputeChart,
        }
    });

    const onAddTextInputClicked = function(evt) {
        createTextInputNode("", "");
    }

    const onAddFileInputClicked = function(evt) {
        createFileInputNode("", "");
    }

    const node = getNode('input');

    const inputNodes = Object.values(nodes).filter((node) => {
       return isInputNode(node);
    });

    const handleTemplateValueSelected = useCallback(function(value) {
        const keys = Object.keys(value);
        for (let key of keys) {
            const nodeId = `input_${key}`;
            const node = getNode(nodeId);
            if (node) {
                let currentValues;
                if (typeof value[key] === "string") {
                    currentValues = [value[key]];
                } else {
                    currentValues = value[key];
                }

                changeNodeData(nodeId, {
                    ...node.data,
                    prompt: "",
                    currentValues: currentValues,
                    stale: true
                });
            }
        }

        recomputeChart();
    }, []);

    if (!node) {
        return null;
    }

    return (
        <div className="input-group-node" title={"Input"}>
            <Card>
                <div className={"sample-values-list-wrapper"}>
                    <div>All:</div>
                    <PromptChartTemplateSampleValuesList
                        template_id={template._id}
                        field_name={"root"}
                        onChange={handleTemplateValueSelected}
                    />
                </div>
                {/*<div/>*/}
                {/*<div>*/}
                {/*    {*/}
                {/*        inputNodes.length === 0 ?*/}
                {/*            <span className={"no-input-nodes-text"}>No input nodes...</span> : null*/}
                {/*    }*/}
                {/*</div>*/}
                <div className={"add-input-buttons-wrapper"}>
                    <div className={"sample-values-list-wrapper"}>
                        <div>All:</div>
                        <PromptChartTemplateSampleValuesList
                            template_id={template._id}
                            field_name={"root"}
                            onChange={handleTemplateValueSelected}
                        />
                    </div>
                    {/*    <Button*/}
                    {/*        className={"add-input-button " + (inputNodes.length === 0 ? "pulsating" : "")}*/}
                    {/*        onClick={onAddTextInputClicked}*/}
                    {/*        variant={"contained"}*/}
                    {/*        color={"secondary"}*/}
                    {/*        endIcon={<TextInputNodeIcon />*/}
                    {/*        }>*/}
                    {/*        <div className={"button-text"}>New Text Input</div>*/}
                    {/*    </Button>*/}
                    {/*    <Button*/}
                    {/*        className={"add-input-button " + (inputNodes.length === 0 ? "pulsating" : "")}*/}
                    {/*        onClick={onAddFileInputClicked}*/}
                    {/*        variant={"contained"}*/}
                    {/*        color={"secondary"}*/}
                    {/*        endIcon={<FileInputNodeIcon />*/}
                    {/*        }>*/}
                    {/*        <div className={"button-text"}>New File Input</div>*/}
                    {/*    </Button>*/}
                </div>
            </Card>
        </div>
    );
}

export default InputGroupNode;
