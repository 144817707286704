import React, {useCallback, useMemo} from 'react';
import {TextField} from '@mui/material';
import {StoryFormWidgetContainer} from "./StoryFormWidgetContainer";
import "./MainCharacterPersonalitySelector.scss"
import _ from "lodash";


export const MainCharacterPersonalitySelector = ({value, onChange, mainCharacterType}) => {
    const [localValue, setLocalValue] = React.useState(value);

    const onChangeDebounced = useMemo(() => _.debounce(onChange, 500), [onChange]);

    const handleTextChange = useCallback((event) => {
        onChangeDebounced(event.target.value);
        setLocalValue(event.target.value);
    }, [onChangeDebounced]);

    let label = "Main Character Personality";
    if (mainCharacterType === "your pet") {
        label = "Your Pets Personality";
    } else if (mainCharacterType === "your kid") {
        label = "Your Kid's Personality";
    }

    return (
        <StoryFormWidgetContainer label={label}
                                  className={"main-character-personality-selector"}>

            <TextField
                id="other-feature"
                label="Personality"
                onChange={handleTextChange}
                value={localValue}
                placeholder={"Please describe their personality, attitudes, behaviors and anything else that is unique about them"}
            />
        </StoryFormWidgetContainer>
    );
};