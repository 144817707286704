import "./ContextMenu.scss";
import * as React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


export default function ChartContextMenu({contextMenu, handleClose, handleAddNewPrompt}) {
    return (
        <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
                contextMenu !== null
                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                    : undefined
            }
        >
            <MenuItem onClick={handleAddNewPrompt} className={"context-menu-action-item"}>
                <AddCircleIcon/>
                <span>Add New Prompt</span>
            </MenuItem>
        </Menu>
    );
}
