import React, {useCallback, useEffect, useState} from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from "@mui/material/ListItemText";
import {useAPI} from "../../frontend/components/APIProvider";
import "./LLMModelList.scss";
import ListItemButton from "@mui/material/ListItemButton";
import CircularProgress from "@mui/material/CircularProgress";
import {useSnackbar} from "../../frontend/components/SnackbarProvider";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import IconButton from "@mui/material/IconButton";
import _ from "lodash";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import Tooltip from "@mui/material/Tooltip";

export function LLMModelListItem({modelInfo, modelIndex, isFavorite, onFavoriteClicked, onModelClicked}) {
  const [localIsFavorite, setLocalIsFavorite] = useState(isFavorite);

  const handleFavoriteClicked = useCallback((modelInfo, modelIndex) => {
    setLocalIsFavorite(!localIsFavorite);
    onFavoriteClicked(modelInfo, modelIndex);
  }, [localIsFavorite, onFavoriteClicked]);

  const modelInfoFormatted = `Provider: ${modelInfo.provider}\nName: ${modelInfo.name}\nFeatures:\nJSON Mode: ${modelInfo.features.json_mode}\nStructured Outputs: ${modelInfo.features.structured_outputs}\nTool Completion: ${modelInfo.features.tool_completion}`;

  return <Tooltip
    title={modelInfoFormatted}
    placement="top-start"
    classes={{tooltip: "schema-field-additional-details-and-description-tooltip"}}
    disableInteractive={true}
  >
    <ListItem
      disablePadding
      secondaryAction={
        <IconButton
          edge="end"
          aria-label="favorite"
          onClick={() => handleFavoriteClicked(modelInfo, modelIndex)}
        >
          {
            localIsFavorite ? <FavoriteIcon/> : <FavoriteBorderIcon/>
          }
        </IconButton>
      }
    >
      <ListItemButton
        onClick={() => onModelClicked(modelInfo, modelIndex)}
      >
        <ListItemText
          primary={modelInfo.name}
          secondary={modelInfo.provider}
        />
      </ListItemButton>
    </ListItem>
  </Tooltip>
}

export default function LLMModelList() {
  const [favoriteModelNames, setFavoriteModelNames] = useState(localStorage.getItem('favoriteModels') ? JSON.parse(localStorage.getItem('favoriteModels')) : []);
  const [favoriteModels, setFavoriteModels] = useState([]);
  const [allLLMModels, setAllLLMModels] = useState([]);
  const [groupedLLMModels, setGroupedLLMModels] = useState({});
  const [open, setOpen] = useState({});
  const snackbar = useSnackbar();
  const api = useAPI();

  console.log(groupedLLMModels);

  const handleFavoriteClicked = useCallback((modelInfo, modelIndex) => {
    let newFavoriteModels = [];
    if (favoriteModelNames.includes(modelInfo.name)) {
      newFavoriteModels = favoriteModelNames.filter((name) => name !== modelInfo.name);
    } else {
      newFavoriteModels = [...favoriteModelNames, modelInfo.name];
    }
    setFavoriteModelNames(newFavoriteModels);
    localStorage.setItem('favoriteModels', JSON.stringify(newFavoriteModels));
  }, [favoriteModelNames]);

  useEffect(() => {
    api.getLLMModels().then((responseData) => {
      // First group by provider
      const groupedByProvider = _.groupBy(responseData.llm_models, 'provider');
      setGroupedLLMModels(groupedByProvider);
      setAllLLMModels(responseData.llm_models);
    });
  }, [api]);

  useEffect(() => {
      const favoriteModelsList = [];
      // First find the favorite models within thelist of llm models
      favoriteModelNames.forEach((favoriteModelName) => {
        const foundModel = allLLMModels.find((model) => model.name === favoriteModelName);
        if (foundModel) {
          favoriteModelsList.push(foundModel);
        }
      });
      // Sort the favorite models list by name
      favoriteModelsList.sort((a, b) => a.name.localeCompare(b.name));
      setFavoriteModels(favoriteModelsList);
  }, [allLLMModels, favoriteModelNames]);

  const handleModelClicked = useCallback((modelInfo, modelIndex) => {
    // Save the model name into the clipboard
    navigator.clipboard.writeText(modelInfo.name);
    snackbar.toast({
      message: `Model name ${modelInfo.name} copied to clipboard`,
      severity: "info",
      autoHideMs: 5000,
    });
  }, [snackbar]);

  if (allLLMModels.length === 0) {
    return <div className={"llm-model-list loading"}>
      <CircularProgress/>
    </div>
  }

  return <div className={"llm-model-list"}>
    <div className={"llm-model-list-inner"}>
      <List sx={{width: '100%', bgcolor: 'background.paper'}} dense={true}>
        <ListItem className={'title-row'}>
          <ListItemText primary={"LLM Model List"}/>
        </ListItem>
        {
          favoriteModels.map((modelInfo, modelIndex) => {
            return <LLMModelListItem
              key={`${modelInfo.name}-${modelIndex}`}
              modelInfo={modelInfo}
              modelIndex={modelIndex}
              isFavorite={true}
              onFavoriteClicked={handleFavoriteClicked}
              onModelClicked={handleModelClicked}
            />
          })
        }

        {
          Object.keys(groupedLLMModels).map((providerName, providerIndex) => {
            return <List
              key={providerName}
              sx={{width: '100%', bgcolor: 'background.paper'}}
              disablePadding
              dense={true}
            >
              <ListItemButton onClick={() => setOpen({...open, [providerName]: !open[providerName]})}>
                <ListItemText primary={providerName}/>
                {open ? <ExpandLess/> : <ExpandMore/>}
              </ListItemButton>
              <Collapse in={open[providerName]} timeout="auto" unmountOnExit>
                {
                  groupedLLMModels[providerName].map((modelInfo, modelIndex) => {
                    return <LLMModelListItem
                      key={`${modelInfo.name}-${modelIndex}`}
                      modelInfo={modelInfo}
                      modelIndex={modelIndex}
                      isFavorite={false}
                      onFavoriteClicked={handleFavoriteClicked}
                      onModelClicked={handleModelClicked}
                    />
                  })
                }
              </Collapse>
            </List>;
          })
        }

      </List>
    </div>
  </div>
}
