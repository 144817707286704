import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import styles from "./HookForm.module.scss";
import {
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from "@mui/material";

export default function RHFSelectField({
  name,
  chip,
  label,
  options,
  checkbox,
  placeholder,
  helperText,
  isMultiple,
  ...props
}) {
  const { control } = useFormContext();
  const renderValues = (selectedValues) => {
    if (!selectedValues || selectedValues.length === 0) {
      return <span className={styles.placeholder}>{placeholder}</span>;
    }

    const selectedItems = options.filter((option) =>
      selectedValues.includes(option.value)
    );

    if (chip) {
      return (
        <div className={styles.chipBox}>
          {selectedItems.map((item) => (
            <Chip key={item.value} size='small' label={item.label} />
          ))}
        </div>
      );
    }

    return selectedItems.map((item) => item.label).join(", ");
  };

  return (
    <div className={styles.formGroup}>
      <label>{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormControl fullWidth error={!!error} {...props}>
            <Select
              {...field}
              displayEmpty={!!placeholder}
              id={`select-${name}`}
              placeholder={placeholder}
              renderValue={renderValues}
              multiple={isMultiple}
            >
              {options.map((option) => {
                const selected = field.value.includes(option.value);
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {checkbox && (
                      <Checkbox checked={selected} disableRipple size='small' />
                    )}
                    {option.label}
                  </MenuItem>
                );
              })}
            </Select>
            {(!!error || helperText) && (
              <FormHelperText error={!!error} className={styles.helperText}>
                {error ? error?.message : helperText}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </div>
  );
}
