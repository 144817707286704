import * as React from 'react';
import {TableView} from "../../core/frontend/elements/TableView";
import {useAPI} from "../../core/frontend/components/APIProvider";
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

export function StoryTable() {
    const api = useAPI();
    const navigate = useNavigate();

    const handleStoryRowClicked = useCallback((story) => {
        navigate(`/story/${story.id}`);
    }, [navigate]);


    return (
        <TableView
            schemaAPI={api.getStorySchema}
            fetchAPI={api.getStorys}
            onRowClicked={handleStoryRowClicked}
        />
    );
}
