import {PageWrapperWithMenu} from "../../../core/frontend/elements/PageWrapperWithMenu";
import React, {useCallback, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Card, CardContent} from "@mui/material";
import "./ContentSampleEditPage.scss";
import {useAPI} from "../../../core/frontend/components/APIProvider";
import {PageHeader} from "../../../core/frontend/elements/PageHeader";
import ContentSampleForm from "./ContentSampleForm";
import CircularProgress from "@mui/material/CircularProgress";


export default function ContentSampleEditPage(props) {
    const api = useAPI();
    const params = useParams();

    const navigate = useNavigate();
    const [contentSample, setContentSample] = useState(null);

    const handleContentSampleSave = useCallback(async (newContentSample) => {
        return await api.saveContentSample(newContentSample);
    }, [api]);

    const handleDeleteClicked = useCallback(async () => {
        return await api.deleteContentSample(contentSample.id).then(() => {
            navigate(`/content_samples`);
        })
    }, [navigate, api, contentSample?.id]);

    React.useEffect(() => {
        if (!contentSample) {
            api.getContentSample(params?.contentSampleId).then((contentSample) => {
                setContentSample(contentSample);
            });
        }
    }, [handleContentSampleSave, contentSample, params?.contentSampleId, api]);

    if (!contentSample) {
        return <PageWrapperWithMenu>
            <PageHeader title={`Edit Content Sample`}/>
            <div className="content-sample-edit-page">
                <CircularProgress/>
            </div>
        </PageWrapperWithMenu>
    }

    return <PageWrapperWithMenu>
        <PageHeader title={`Edit Content Sample`}/>
        <div className="content-sample-edit-page">
            <Card className="content-sample-edit-form-card">
                <CardContent>
                    <ContentSampleForm
                        initialContentSample={contentSample}
                        onContentSampleSave={handleContentSampleSave}
                        onDeletedClicked={handleDeleteClicked}
                    />
                </CardContent>
            </Card>
        </div>

    </PageWrapperWithMenu>
}