import React from "react";
import FormInput from "../../elements/form/FormInput";
import FormSection from "../../elements/form/FormSection";
import FormSubFieldRoot from "../../elements/form/FormSubFieldRoot";

export default function AnthropicConfigurationForm() {
    return <FormSection
        title={"Anthropic Integration"}
        subtitle={"Configuration for the Anthropic API Integration for this tenant"}
        collapsable={true}
    >
        <FormSubFieldRoot
            field={"anthropic"}
        >
            <FormInput
                type={"text"}
                label={"API Key"}
                fieldName={"api_key"}
                placeholder={"The API key used to connect to their API"}
            />

            <FormSubFieldRoot field={"rate_limits_tokens_per_minute"}>
                <FormInput
                    type={"number"}
                    label={"Rate Limit For: claude-3-5-sonnet-20240620"}
                    fieldName={"claude-3-5-sonnet-20240620"}
                    placeholder={"The rate limit applied to outbound requests made with the claude-3-5-sonnet-20240620 model in terms of tokens per minute."}
                />
                <FormInput
                    type={"number"}
                    label={"Rate Limit For: claude-3-opus-20240229"}
                    fieldName={"claude-3-opus-20240229"}
                    placeholder={"The rate limit applied to outbound requests made with the claude-3-opus-20240229 model in terms of tokens per minute."}
                />
                <FormInput
                    type={"number"}
                    label={"Rate Limit For: claude-3-sonnet-20240229"}
                    fieldName={"claude-3-sonnet-20240229"}
                    placeholder={"The rate limit applied to outbound requests made with the claude-3-sonnet-20240229 model in terms of tokens per minute."}
                />
                <FormInput
                    type={"number"}
                    label={"Rate Limit For: claude-3-haiku-20240307"}
                    fieldName={"claude-3-haiku-20240307"}
                    placeholder={"The rate limit applied to outbound requests made with the claude-3-haiku-20240307 model in terms of tokens per minute."}
                />
                <FormInput
                    type={"number"}
                    label={"Rate Limit For: other"}
                    fieldName={"other"}
                    placeholder={"The rate limit applied to outbound requests made with any other models that didn't have a specific rate limit set, in terms of tokens per minute."}
                />
            </FormSubFieldRoot>
        </FormSubFieldRoot>
    </FormSection>
}