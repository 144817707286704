import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardContent } from "@mui/material";
import "./CustomDataSchemaEditPage.scss";
import { useAPI } from "../../../frontend/components/APIProvider";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import CustomDataSchemaForm from "./CustomDataSchemaForm";
import CircularProgress from "@mui/material/CircularProgress";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";

export default function CustomDataSchemaEditPage(props) {
  const api = useAPI();
  const params = useParams();

  const navigate = useNavigate();
  const [customDataSchema, setCustomDataSchema] = useState(null);

  const handleCustomDataSchemaSave = useCallback(
    async (newCustomDataSchema) => {
      return await api.saveCustomDataSchema(newCustomDataSchema);
    },
    [api]
  );

  const handleDeleteClicked = useCallback(async () => {
    return await api.deleteCustomDataSchema(customDataSchema.id).then(() => {
      navigate(`/custom_data_schema`);
    });
  }, [navigate, api, customDataSchema?.id]);

  React.useEffect(() => {
    if (!customDataSchema) {
      api
        .getCustomDataSchema(params?.customDataSchemaId)
        .then((customDataSchema) => {
          setCustomDataSchema(customDataSchema);
        });
    }
  }, [
    handleCustomDataSchemaSave,
    customDataSchema,
    params?.customDataSchemaId,
    api,
  ]);

  if (!customDataSchema) {
    return (
      <PageWrapperWithMenu>
        <PageHeader
          title={
            <LocalTranslatedText
              language={"en"}
              text='Edit Custom Data Schema'
            />
          }
        />
        <div className='custom-data-schema-edit-page'>
          <CircularProgress />
        </div>
      </PageWrapperWithMenu>
    );
  }

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={
          <LocalTranslatedText language={"en"} text='Edit Custom Data Schema' />
        }
      />
      <div className='custom-data-schema-edit-page'>
        <Card className='custom-data-schema-edit-form-card'>
          <CardContent>
            <CustomDataSchemaForm
              initialCustomDataSchema={customDataSchema}
              onCustomDataSchemaSave={handleCustomDataSchemaSave}
              onDeletedClicked={handleDeleteClicked}
            />
          </CardContent>
        </Card>
      </div>
    </PageWrapperWithMenu>
  );
}
