import {randomString} from "../../../../core/frontend/utils/random";

/**
 * This class represents a prompt. It provides methods to manipulate the prompt easily.
 *
 *
 * */
export default class PromptManipulator {
    constructor(promptText) {
        this.prompt = promptText;
    }

    toString() {
        return this.prompt;
    }

    addNodeReferenceToStartOfPrompt(nodeId, nodeTitle) {
        const mention = `<span class="mention" data-id="${nodeId}" data-type="mention">@${nodeTitle}</span>`;
        if (!this.isPromptEmpty()) {
            this.prompt = mention + "\n" + this.prompt;
        } else {
            this.prompt = mention;
        }
    }

    removeNodeReferencesFromPrompt(nodeId) {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(this.prompt, 'text/html');
        // do whatever you want with htmlDoc.getElementsByTagName('a');
        const mentions = htmlDoc.getElementsByClassName('mention');
        for (let mention of mentions) {
            const mentionId = mention.getAttribute("data-id");
            if (mentionId === nodeId) {
                mention.remove();
            }
        }

        this.prompt = htmlDoc.body.innerHTML;
    }

    getAllNodeReferencesInPrompt() {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(this.prompt, 'text/html');
        // do whatever you want with htmlDoc.getElementsByTagName('a');
        const mentions = htmlDoc.getElementsByClassName('mention');
        if (mentions) {
            return Array.from(mentions).map(mention => mention.getAttribute("data-id"));
        } else {
            return [];
        }

    }

    isPromptEmpty() {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(this.prompt, 'text/html');

        // TODO: Need to find another way to do this.
        const text = htmlDoc.all[0].textContent;
        if (!text) {
            return true;
        } else {
            return text.trim() === "";
        }
    }

    onlyHasReferences() {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(this.prompt, 'text/html');

        const mentions = htmlDoc.getElementsByClassName('mention');
        for (let mention of mentions) {
            mention.remove();
        }

        const text = (htmlDoc.all[0].textContent ?? "").trim().replace(/@/g, "")
        return !Boolean(text);
    }

    simplifyPromptStructureToOnlyMentions() {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(this.prompt, 'text/html');

        const mentionReplaceValues = [];
        const mentionOriginalHtmls = [];
        const mentions = htmlDoc.getElementsByClassName('mention');
        for (let mention of mentions) {
            mentionOriginalHtmls.push(mention.outerHTML);
            const mentionRandomString = randomString(16);
            mention.textContent = mentionRandomString;
            mentionReplaceValues.push(mentionRandomString);
        }

        let text = htmlDoc.all[0].textContent;
        for (let mentionIndex = 0; mentionIndex < mentionReplaceValues.length; mentionIndex++) {
            text = text.replace(mentionReplaceValues[mentionIndex], mentionOriginalHtmls[mentionIndex]);
        }

        this.prompt = text;
    }

    getPureTextPrompt() {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(this.prompt, 'text/html');

        const mentions = htmlDoc.getElementsByClassName('mention');
        for (let mention of mentions) {
            mention.remove();
        }

        return htmlDoc.all[0].textContent;
    }
}