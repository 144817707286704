import {PageWrapperWithMenu} from "../../../core/frontend/elements/PageWrapperWithMenu";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useAPI} from "../../../core/frontend/components/APIProvider";
import {GeneratedContentTable} from "./GeneratedContentTable";
import "./GeneratedContentListPage.scss";
import {PageHeader} from "../../../core/frontend/elements/PageHeader";
import Paper from "@mui/material/Paper";


export default function GeneratedContentListPage() {
    const api = useAPI();
    const navigate = useNavigate();

    return <PageWrapperWithMenu>
        <PageHeader title={"Generated Content"} />
        <div className={"generated-content-list-page"}>
            <div className={"generated-content-table-wrapper"}>
                <Paper className={"main-page-contents"}>
                    <GeneratedContentTable/>
                </Paper>
            </div>
        </div>
    </PageWrapperWithMenu>
}

