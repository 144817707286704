import axios from "axios";
import { axiosETAGCache } from "axios-etag-cache";

let globalCustomersSchema = null;

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);
const api = {
  async getCustomerSchema() {
    if (globalCustomersSchema) {
      return globalCustomersSchema;
    }
    const response = await axiosWithETAGCache.get(`/customer/schema`);
    globalCustomersSchema = response.data;
    return response.data;
  },
  async getCustomers(tableQuery) {
    const queryParams = {
      query: JSON.stringify(tableQuery),
    };

    const response = await axiosWithETAGCache.get(`/customer`, {
      params: queryParams,
    });

    return response.data;
  },
  async getCustomerCSVExport() {
    const queryParams = {};

    const response = await axiosWithETAGCache.get(`/customers/csv`, {
      params: queryParams,
    });

    return response.data;
  },
  async createNewCustomer(newCustomerData) {
    const response = await axios.post(`/customer`, newCustomerData);

    return response.data;
  },
  async getCustomer(customerId) {
    const queryParams = {};

    const response = await axiosWithETAGCache.get(`/customer/${customerId}`, {
      params: queryParams,
    });

    return response.data;
  },
  async forceFollowUpWithCustomer(customerId) {
    const queryParams = {};

    const response = await axiosWithETAGCache.post(
      `/customer/${customerId}/force_follow_up`,
      {
        params: queryParams,
      }
    );

    return response.data;
  },
  async startOptInConversation(contactId) {
    const queryParams = {};

    const response = await axiosWithETAGCache.post(
      `/customer/${contactId}/start_opt_in`,
      {
        params: queryParams,
      }
    );

    return response.data;
  },
  async saveCustomer(customer) {
    const response = await axios.put(`/customer/${customer.id}`, customer);
    return response.data;
  },
  async deleteCustomer(customerId) {
    const response = await axios.delete(`/customer/${customerId}`);

    return response.data;
  },
};

export default api;
