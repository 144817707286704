import './CustomEdge.scss';
import React, {useMemo} from 'react';
import {BaseEdge, EdgeText, getBezierPath} from 'reactflow';


export default function CustomEdge({
                                       id,
                                       sourceX,
                                       sourceY,
                                       targetX,
                                       targetY,
                                       sourcePosition,
                                       targetPosition,
                                       style = {},
                                       markerEnd,
                                       data,
                                        label,
                                   }) {

    const [edgePath, labelX, labelY] = useMemo(() => getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY: targetY,
        targetPosition
    }), [
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    ]);

    // Get the edge path but ditch the other two values, the label x,y
    let [edgePathAbove, , ] = useMemo(() => getBezierPath({
        sourceX,
        sourceY: sourceY - 5,
        sourcePosition,
        targetX: targetX - 4,
        targetY: targetY - 5,
        targetPosition
    }), [
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    ]);

    // Get the edge path but ditch the other two values, the label x,y
    let [edgePathBelow, , ] = useMemo(() => getBezierPath({
        sourceX,
        sourceY: sourceY + 5,
        sourcePosition,
        targetX: targetX - 4,
        targetY: targetY + 5,
        targetPosition
    }), [
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    ]);

    return (
        <>
            {
                data.multi ?
                    <BaseEdge
                        path={edgePathAbove}
                        style={style}
                    />
                    : null
            }
            {
                data.multi ?
                    <BaseEdge
                        path={edgePathBelow}
                        style={style}
                    />
                    : null
            }
            <BaseEdge
                path={edgePath}
                markerEnd={markerEnd}
                style={style}
            />
            {
                label ?
                    <EdgeText
                        x={labelX}
                        y={labelY}
                        label={label}
                        labelStyle={{ fill: 'black' }}
                        labelShowBg
                        labelBgStyle={{ fill: 'white', "strokeWidth":1, "stroke": "rgb(128,128,128)"}}
                        labelBgPadding={[2, 4]}
                        labelBgBorderRadius={5}
                    />
                : null
            }
        </>
    );
}
