export const StoryConfigInputFields = [
        {
            title: "Theme",
            field_name: "theme",
        },
        {
            title: "Environment",
            field_name: "environment",
        },
        {
            title: "Macguffin",
            field_name: "macguffin",
        },
        {
            title: "Child's Age",
            field_name: "childs_age",
        },
        {
            title: "Main Character Name",
            field_name: "main_character_name",
        },
        {
            title: "Main Character Gender",
            field_name: "main_character_gender",
        },
        {
            title: "Main Character Visual Description",
            field_name: "main_character_visual_description",
        },
        {
            title: "Main Character Personality",
            field_name: "main_character_personality",
        },
        {
            title: "General Character Appearance",
            field_name: "general_character_appearance",
        },
        {
            title: "Text Style",
            field_name: "text_style",
        },
        {
            title: "Story Villain",
            field_name: "story_villain",
        },
        {
            title: "Artistic Style",
            field_name: "artistic_style",
        },
        {
            title: "Artistic Style Sentence",
            field_name: "artistic_style_sentence",
        }
]


export const MainStoryTemplate = {
    _id: "main_story_prompt",
    title: "Main Story Prompt",
    input_fields:  StoryConfigInputFields,
    output_fields: [
        {
            title: "Story Outline",
            field_name: "story_outline",
        },
        {
            title: "Character Sheet",
            field_name: "character_sheet",
        },
        {
            title: "Book Title",
            field_name: "book_title",
        },
        {
            title: "Page Descriptions",
            field_name: "page_descriptions",
        },
        {
            title: "Cover Page Image Prompt",
            field_name: "cover_page_image_prompt",
        },
    ]
}




export const MainPageTemplate = {
    _id: "main_page_prompt",
    title: "Main Page Prompt",
    input_fields:  StoryConfigInputFields.concat([
        {
            title: "Story Outline",
            field_name: "story_outline",
        },
        {
            title: "Character Sheet",
            field_name: "character_sheet",
        },
        {
            title: "Book Title",
            field_name: "book_title",
        },
        {
            title: "Page Description",
            field_name: "page_description",
        }
    ]),
    output_fields: [
        {
            title: "Page Text",
            field_name: "text",
        },
        {
            title: "Image Prompt for Dall-E",
            field_name: "image_prompt",
        }
    ]
}


