import React, { useCallback, useEffect, useState } from "react";
import { useAPI } from "../../../frontend/components/APIProvider";
import { useNavigate, useParams } from "react-router-dom";
import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import { Button, CircularProgress, Stack } from "@mui/material";
import Paper from "@mui/material/Paper";
import "./ViewFilledIntakePage.scss";
import CustomAccordion from "../../../frontend/elements/CustomAccordion";

export default function ViewFilledIntakePage() {
  const api = useAPI();
  const params = useParams();
  const [filledIntake, setFilledIntake] = useState(null);
  const [intake, setIntake] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingIntake, setLoadingIntake] = useState(false);
  const navigate = useNavigate();

  const getFilledIntake = useCallback(async () => {
    setLoading(true);
    const intake = await api.getFilledIntake(params?.filledIntakeId);
    setFilledIntake(intake);
    setLoading(false);
  }, [api, params]);

  const getIntake = useCallback(async () => {
    setLoadingIntake(true);
    const intake = await api.getIntake(filledIntake?.intake_id);
    setLoadingIntake(false);
    setIntake(intake);
  }, [api, filledIntake]);

  useEffect(() => {
    if (!filledIntake) {
      getFilledIntake();
    } else {
      getIntake();
    }
  }, [getFilledIntake, filledIntake, getIntake]);

  return (
    <PageWrapperWithMenu>
      <div className={"filled-intake-page"}>
        {loading ? (
          <CircularProgress />
        ) : filledIntake ? (
          <Paper elevation={3} padding='true'>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <PageHeader
                title={`Filled Intake on ${
                  loadingIntake ? "loading..." : intake ? intake.title : "N/A"
                }`}
              />
              <Button
                variant='outlined'
                color='secondary'
                onClick={() => navigate(`/intakes/${filledIntake.intake_id}`)}
              >
                View Associated Intake
              </Button>
            </Stack>
            {filledIntake?.answers?.map((answer, index) => (
              <CustomAccordion
                key={index}
                title={`${index + 1}. ${answer.bot_question}`}
              >
                <p className='answer-text'>{answer.user_answer}</p>
              </CustomAccordion>
            ))}
          </Paper>
        ) : null}
      </div>
    </PageWrapperWithMenu>
  );
}
