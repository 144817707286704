import {PageWrapperWithMenu} from "../../../frontend/elements/PageWrapperWithMenu";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useAPI} from "../../../frontend/components/APIProvider";
import {ImportedDocumentTable} from "./ImportedDocumentTable";
import "./ImportedDocumentListPage.scss";
import {PageHeader} from "../../../frontend/elements/PageHeader";
import {WhiteButton} from "../../../frontend/elements/WhiteButton";
import Paper from "@mui/material/Paper";
import {useCallback} from "react";


export default function ImportedDocumentListPage() {
    const api = useAPI();
    const navigate = useNavigate();

    const handleNewDocumentClicked = useCallback(async () => {
        navigate(`/imported_documents/new`);
    }, [navigate]);

    const handleUploadDocumentClicked = useCallback(async () => {
        navigate(`/imported_documents/upload`);
    }, [navigate]);

    return <PageWrapperWithMenu>
        <PageHeader title={"Imported Documents"}>
            <WhiteButton onClick={handleNewDocumentClicked}>
                Create Document from Scratch
            </WhiteButton>
            <WhiteButton onClick={handleUploadDocumentClicked}>
                Import Document
            </WhiteButton>
        </PageHeader>
        <div className={"document-list-page"}>
            <div className={"document-table-wrapper"}>
                <Paper className={"main-page-contents"}>
                    <ImportedDocumentTable/>
                </Paper>
            </div>
        </div>
    </PageWrapperWithMenu>
}

