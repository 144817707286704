import "./FormInput.scss";
import React from "react";
import EmailInput from "./inputs/EmailInput";
import PhoneNumberInput from "./inputs/PhoneNumberInput";
import FullNameInput from "./inputs/FullNameInput";
import CompanyNameInput from "./inputs/CompanyNameInput";
import TextInput from "./inputs/TextInput";
import CheckboxesInput from "./inputs/CheckboxesInput";
import _ from "lodash";
import DateTimeInput from "./inputs/DateTimeInput";
import CurrencyInput from "./inputs/CurrencyInput";
import RadioButtonsInput from "./inputs/RadioButtonsInput";
import DropdownInput from "./inputs/DropdownInput";
import StringListInput from "./inputs/StringList";
import InsuranceAgentSelectionInput from "./inputs/InsuranceAgentSelectionInput";
import AddressInput from "./inputs/AddressInput";
import URLInput from "./inputs/URLInput";
import NumberInput from "./inputs/NumberInput";
import { JSONInput } from "./inputs/JSONInput";

export function FormInput({ label, type, optional, ...props }) {
  let labelWithOptional = label;
  if (optional) {
    if (_.isString(label)) {
      labelWithOptional = `${label} (optional)`;
    }
  }

  switch (type) {
    case "text":
      return <TextInput label={labelWithOptional} {...props} />;
    case "number":
      return <NumberInput label={labelWithOptional} {...props} />;
    case "email":
      return <EmailInput label={labelWithOptional} {...props} />;
    case "phone":
      return <PhoneNumberInput label={labelWithOptional} {...props} />;
    case "address":
      return <AddressInput label={labelWithOptional} {...props} />;
    case "url":
      return <URLInput label={labelWithOptional} {...props} />;
    case "full_name":
      return <FullNameInput label={labelWithOptional} {...props} />;
    case "company_name":
      return <CompanyNameInput label={labelWithOptional} {...props} />;
    case "checkboxes":
      return <CheckboxesInput label={label} {...props} />;
    case "datetime":
      return <DateTimeInput label={labelWithOptional} {...props} />;
    case "currency":
      return <CurrencyInput label={labelWithOptional} {...props} />;
    case "radio":
      return <RadioButtonsInput label={labelWithOptional} {...props} />;
    case "dropdown":
      return <DropdownInput label={labelWithOptional} {...props} />;
    case "string_list":
      return <StringListInput label={labelWithOptional} {...props} />;
    case "json":
      return <JSONInput label={labelWithOptional} {...props} />;
    case "insurance_agent":
      return (
        <InsuranceAgentSelectionInput label={labelWithOptional} {...props} />
      );
    default:
      throw new Error(`Unknown form input type: ${type}`);
  }
}

export default FormInput;
