import { PageWrapperWithMenu } from "../../../core/frontend/elements/PageWrapperWithMenu";
import React, { useCallback, useEffect, useState } from "react";
import "../../../core/frontend/pages/HomePage.scss";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardContent } from "@mui/material";
import "./CustomerEditPage.scss";
import { useAPI } from "../../../core/frontend/components/APIProvider";
import { PageHeader } from "../../../core/frontend/elements/PageHeader";
import CustomerForm from "./CustomerForm";
import CircularProgress from "@mui/material/CircularProgress";

export default function CustomerEditPage(props) {
  const api = useAPI();
  const params = useParams();

  const navigate = useNavigate();
  const [customer, setCustomer] = useState(null);

  const handleCustomerSave = useCallback(
    async (newCustomer) => {
      return await api.saveCustomer(newCustomer);
    },
    [api]
  );

  const handleDeleteClicked = useCallback(async () => {
    return await api.deleteCustomer(customer.id).then(() => {
      navigate(`/customers`);
    });
  }, [navigate, api, customer?.id]);

  const handleFollowUpNowClicked = useCallback(
    async (customerData) => {
      await api.saveCustomer(customerData);

      const result = await api.forceFollowUpWithCustomer(customer.id);
      navigate(`/conversations/${result.conversation_id}`);
    },
    [navigate, api, customer?.id]
  );

  const handleViewConversationClicked = useCallback(
    async (customerData) => {
      await api.saveCustomer(customerData);

      navigate(`/conversations/${customer.follow_up_conversation_id}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, customer?.follow_up_conversation_id]
  );

  const handleStartOptInConversationClicked = useCallback(
    async (customerData) => {
      await api.saveCustomer(customerData);

      const result = await api.startOptInConversation(customer?.id);
      navigate(`/conversations/${result.conversation_id}`);
    },
    [navigate, api, customer?.id]
  );

  useEffect(() => {
    if (!customer) {
      api.getCustomer(params?.customerId).then((customer) => {
        if (customer.needs_review) {
          customer.needs_review = false;
          handleCustomerSave(customer);
        }

        setCustomer(customer);
      });
    }
  }, [handleCustomerSave, customer, params?.customerId, api]);

  if (!customer) {
    return (
      <PageWrapperWithMenu>
        <PageHeader title={`Edit Customer`} />
        <div className='customer-edit-page'>
          <CircularProgress />
        </div>
      </PageWrapperWithMenu>
    );
  }

  return (
    <PageWrapperWithMenu>
      <PageHeader title={`Edit Customer`} />
      <div className='customer-edit-page'>
        <Card className='customer-edit-form-card'>
          <CardContent>
            <CustomerForm
              initialCustomer={customer}
              onCustomerSave={handleCustomerSave}
              onDeletedClicked={handleDeleteClicked}
              onStartOptInConversationClicked={
                handleStartOptInConversationClicked
              }
              onFollowUpNowClicked={handleFollowUpNowClicked}
              onViewConversationClicked={handleViewConversationClicked}
            />
          </CardContent>
        </Card>
      </div>
    </PageWrapperWithMenu>
  );
}
