import FormControl from "@mui/material/FormControl";
import React, { useState, useCallback, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useChangeFormData, useFormData, useFormOptions } from "../FormRoot";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useAPI } from "../../../components/APIProvider";
import LocalTranslatedText from "../../../../translation/frontend/components/LocalTranslatedText";

export function InsuranceAgentSelectionInput({ label, fieldName, size }) {
  const formData = useFormData();
  const changeFormData = useChangeFormData();
  const formOptions = useFormOptions();
  const api = useAPI();
  const [insuranceAgentList, setInsuranceAgentList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .getInsuranceAgents({
        startRow: 0,
        endRow: 100,
        filterModel: {},
        sortModel: [],
        fields: ["full_name"],
      })
      .then((data) => {
        setLoading(false);
        setInsuranceAgentList(data);
      })
      .catch((error) => {
        console.error("Error loading insurance agents", error);
        setLoading(false);
        setInsuranceAgentList([]);
      });
  }, [api]);

  const handleChange = useCallback(
    (event) => {
      changeFormData(fieldName, event.target.value);
    },
    [fieldName, changeFormData]
  );

  const displayValue = formData[fieldName] || "null";

  return (
    <div className={"form-input"}>
      {label ? (
        <Typography variant={"label"}>
          <LocalTranslatedText language={"en"} text={label} />
        </Typography>
      ) : null}
      <FormControl>
        <Select
          value={displayValue}
          name={fieldName}
          onChange={handleChange}
          disabled={!formOptions.editable}
          size={size || "medium"}
        >
          {loading ? <MenuItem value={"null"}>Loading...</MenuItem> : null}
          {insuranceAgentList.length === 0 ? (
            <MenuItem value={"null"}>No Insurance Agent Found</MenuItem>
          ) : null}
          {insuranceAgentList.length > 0 ? (
            <MenuItem value={"null"}>No Agent Selected</MenuItem>
          ) : null}
          {insuranceAgentList.map((insuranceAgent) => {
            return (
              <MenuItem value={insuranceAgent.id} key={insuranceAgent.id}>
                {insuranceAgent.full_name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}

export default InsuranceAgentSelectionInput;
