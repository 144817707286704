import React from "react";
import { TextInput } from "./TextInput";

export function FullNameInput({ label, fieldName, size }) {
  return (
    <TextInput
      fieldName={fieldName}
      label={label}
      example={"Jane Doe"}
      size={size}
    />
  );
}

export default FullNameInput;
