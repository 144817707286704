import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React, {useCallback, useMemo} from "react";
import List from "@mui/material/List";
import {ListItemButton} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import ListItemIcon from "@mui/material/ListItemIcon";
import AbcIcon from "@mui/icons-material/Abc";
import NumbersIcon from "@mui/icons-material/Numbers";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import DataObjectIcon from "@mui/icons-material/DataObject";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import {recursivelyModifySchemaForFrontend} from "./schema_utils";
import _ from "lodash";
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import "./SchemaFieldList.scss";
import Tooltip from "@mui/material/Tooltip";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';


export function SchemaIcon({schema}) {
  let icon = null;
  if (schema.type === 'string') {
    icon = <ListItemIcon><AbcIcon/></ListItemIcon>;
  } else if (schema.type === 'number' || schema.type === 'integer') {
    icon = <ListItemIcon><NumbersIcon/></ListItemIcon>;
  } else if (schema.type === 'array') {
    icon = <ListItemIcon><FormatListBulletedIcon/></ListItemIcon>;
  } else if (schema.type === 'boolean') {
    icon = <ListItemIcon><CheckBoxOutlinedIcon/></ListItemIcon>;
  } else if (schema.type === 'null') {
    icon = <ListItemIcon><HighlightOffOutlinedIcon/></ListItemIcon>;
  } else if (schema.type === 'object') {
    icon = <ListItemIcon><DataObjectIcon/></ListItemIcon>;
  } else {
    icon = <ListItemAvatar>
      <Avatar sx={{width: 24, height: 24}}>
        <span className={"unknown-type-text"}>?</span>
      </Avatar>
    </ListItemAvatar>
  }
  return icon;
}

function SchemaField({fieldName, fieldSchema, rootName}) {
  let fieldInformation = '';
  if (rootName) {
    fieldInformation += `Field: ${rootName}.${fieldName}\n`;
  } else {
    fieldInformation += `Field: ${fieldName}\n`;
  }
  fieldInformation += `Type: ${fieldSchema.type}\n`;
  fieldInformation += `Description: ${fieldSchema.description ?? 'No description provided'}\n`;
  if (fieldSchema.enum) {
    fieldInformation += `Allowed Values: ${fieldSchema.enum.join(', ')}\n`;
  }
  if (fieldSchema.examples) {
    fieldInformation += `Examples: ${fieldSchema.examples.join(', ')}\n`;
  } else {
    fieldInformation += `Examples: No examples\n`;
  }

  return <Tooltip
    title={fieldInformation}
    placement="top-start"
    classes={{tooltip: "schema-field-additional-details-and-description-tooltip"}}
    disableInteractive={true}
  >
    <ListItem className={"schema-field-item"}>
      <ListItemIcon>
        <SchemaIcon schema={fieldSchema}/>
      </ListItemIcon>
      <ListItemText primary={fieldName}/>
    </ListItem>
  </Tooltip>
}


function RecursiveFieldList({schema, schemaDefs, rootName, fieldName, isOpenByDefault, level, hideFirstLayer}) {
  const [open, setOpen] = React.useState(isOpenByDefault ?? false);

  const handleClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  let keysList = null;

  if (schema.properties) {
    keysList = <List component="div" disablePadding dense={true}>
      {
        /** Treat the schema as a JSON schema and recursively render the fields */
        Object.keys(schema.properties).map((fieldName) => {
          const fieldSchema = schema.properties[fieldName];
          if (fieldSchema['$ref']) {
            const refList = fieldSchema['$ref'].split('/');

            return <RecursiveFieldList
              key={fieldName}
              schema={schemaDefs[refList[refList.length - 1]]}
              rootName={rootName ? `${rootName}.${fieldName}` : fieldName}
              fieldName={fieldName}
              schemaDefs={schemaDefs}
              level={level + 1}
            />

          } else if (fieldSchema.type === 'object') {
            return <RecursiveFieldList
              key={fieldName}
              schema={fieldSchema}
              rootName={rootName ? `${rootName}.${fieldName}` : fieldName}
              fieldName={fieldName}
              schemaDefs={schemaDefs}
              level={level + 1}
            />
         } else if (fieldSchema.type === "array") {
            let itemsSchema = fieldSchema.items;
            if (itemsSchema['$ref'] && schemaDefs) {
              const refList = itemsSchema['$ref'].split('/');
              itemsSchema =  schemaDefs[refList[refList.length - 1]]
            }

            itemsSchema = {
              ...itemsSchema,
              type: "array"
            }

            return <RecursiveFieldList
              key={fieldName}
              schema={itemsSchema}
              rootName={rootName ? `${rootName}.${fieldName}.[]` : fieldName}
              fieldName={fieldName}
              schemaDefs={schemaDefs}
              level={level + 1}
            />
          } else {
            return <SchemaField
              key={fieldName}
              rootName={rootName}
              fieldName={fieldName}
              fieldSchema={fieldSchema}
            />
          }
        })
      }
    </List>;
  } else if (schema.items) {
    keysList = <List component="div" disablePadding dense={true}>
      {
        <RecursiveFieldList
          schema={schema.items}
          rootName={rootName}
          fieldName={fieldName}
          schemaDefs={schemaDefs}
          level={level + 1}
        />
      }
    </List>;
  }

  if (level > 0 || !hideFirstLayer) {
    return <List
      sx={{width: '100%', bgcolor: 'background.paper'}}
      disablePadding
      dense={true}
    >
      <ListItemButton onClick={handleClick}>
        <SchemaIcon schema={schema}/>
        <ListItemText primary={fieldName || schema.title}/>
        {open ? <ExpandLess/> : <ExpandMore/>}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {keysList}
      </Collapse>
    </List>
  } else {
    return keysList;
  }
}

export function SchemaFieldList({schema, fieldName, hideFirstLayer}) {
  const schemaDefs = schema['$defs'];

  const cleanedSchema = useMemo(() => {
    return recursivelyModifySchemaForFrontend(_.cloneDeep(schema), schemaDefs);
  }, [schema, schemaDefs]);

  schema = cleanedSchema;

  hideFirstLayer = hideFirstLayer ?? true;

  return <div className={"schema-field-list"}>
    <RecursiveFieldList
      schema={schema}
      rootName={""}
      fieldName={fieldName}
      schemaDefs={schemaDefs}
      isOpenByDefault={true}
      level={0}
      hideFirstLayer={hideFirstLayer}
    />

  </div>
}

export default SchemaFieldList;
