import "./PersonalInfoPage.scss";
import Paper from "@mui/material/Paper";
import React, { useCallback, useEffect, useState } from "react";
import { PageWrapperWithMenu } from "../../core/frontend/elements/PageWrapperWithMenu";
import { PageHeader } from "../../core/frontend/elements/PageHeader";
import { SpinnerButton } from "../../core/frontend/elements/SpinnerButton";
import { useAPI } from "../../core/frontend/components/APIProvider";
import PersonalInfoForm from "./PersonalInfoForm";

export const PersonalInfoPage = () => {
  const api = useAPI();
  const [initialPersonalInfo, setInitialPersonalInfo] = useState(null);

  useEffect(() => {
    api.getPersonalInfo().then((data) => {
      setInitialPersonalInfo(data);
    });
  }, [api]);

  const savePersonalInformation = useCallback(
    (newPersonalInfo) => {
      setInitialPersonalInfo(newPersonalInfo);
      return api.savePersonalInfo(newPersonalInfo);
    },
    [api]
  );

  return (
    <PageWrapperWithMenu>
      <PageHeader title={"Personal Profile"} />
      <div className='personal-info-form-page'>
        <Paper className='personal-info-card'>
          {initialPersonalInfo ? (
            <PersonalInfoForm
              initialPersonalInfo={initialPersonalInfo}
              onPersonalInfoSave={savePersonalInformation}
            />
          ) : (
            <SpinnerButton />
          )}
        </Paper>
      </div>
    </PageWrapperWithMenu>
  );
};

export default PersonalInfoPage;
