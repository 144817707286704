import _ from "lodash";

const initialNodes = {};

const initialEdges = {};

export const createBlankChart = (template) => {
    if (!template) {
        template = null;
    }

    return {
        id: null,
        id_counter: 1,
        title: template?.title || "",
        user_id: null,
        nodes: _.cloneDeep(initialNodes),
        edges: _.cloneDeep(initialEdges),
        template: template,
        allowPublicViewing: false,
        created_at: new Date().toISOString(),
    }
}