import {PageWrapperWithMenu} from "../elements/PageWrapperWithMenu";
import React from "react";
import {PageHeader} from "../elements/PageHeader";
import {PageSection} from "../elements/PageSection";



export default function PrivacyPolicyPage() {
    return <PageWrapperWithMenu>
        <PageHeader title={`Privacy Policy`} />

        <PageSection>
            <p>This page has yet to be implemented.</p>
        </PageSection>
    </PageWrapperWithMenu>
}

