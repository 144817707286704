import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import "./SplittingStrategySelector.scss";

export function SplittingStrategySelector({value, onChange}) {
    const handleChange = (event) => {
        if (event.target.value === "default") {
            onChange(null);
        } else {
            onChange(event.target.value);
        }
    };

    if (!value) {
        value = "default";
    }

    return (
        <div className={"splitting-strategy-selector nodrag"}>
            <Select
                id="splitting-strategy-selector"
                value={value}
                label="Splitting Strategy"
                onChange={handleChange}
            >
                <MenuItem value={"default"}>No Splitting</MenuItem>
                <MenuItem value={"line"}>Single Line</MenuItem>
                <MenuItem value={"double_line"}>Double Line</MenuItem>
            </Select>
        </div>
    );
}