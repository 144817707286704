import React, {useState} from 'react';
import {SpinnerButton} from "../../../core/frontend/elements/SpinnerButton";
import "./PhoneNumberPortFlow.scss";
import {useAPI} from "../../../core/frontend/components/APIProvider";
import {Typography} from "@mui/material";
import {TextField} from "@mui/material";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";


export const PhoneNumberPortFlow = ({onVoiceConfigurationChanged}) => {
    const api = useAPI();
    const [portPhoneNumber, setPortPhoneNumber] = useState(null);

    const handlePhoneNumberChanged = (phoneNumber) => {
        // Remove any characters that aren't allowed in a phone number
        phoneNumber = phoneNumber.replace(/[^0-9+\-()\s.]/g, '');

        setPortPhoneNumber(phoneNumber);
    }

    const handlePhoneNumberStartPortClicked = () => {
        return api.saveVoiceConfiguration({
            phone_number_configuration_mode: "port",
            bot_number: portPhoneNumber,
        }).then(() => {
            onVoiceConfigurationChanged({
                phone_number_configuration_mode: "port",
                bot_number: portPhoneNumber,
                status: 'port_processing'
            })
        })
    }

    return ( <div className={"port-phone-number-flow"}>
            <Typography variant={'p'}><LocalTranslatedText language={"en"} text="Please enter in the phone number you would like to port."/></Typography>

            <TextField
                label={<LocalTranslatedText language={"en"} text="Phone Number" />}
                onChange={(event) => handlePhoneNumberChanged(event.target.value)}
                value={portPhoneNumber}
            />

            <Typography variant={'p'}>
                <LocalTranslatedText language={"en"} text="Porting numbers is a manual process and our team will email you directly to complete all of the steps." />
            </Typography>

            <SpinnerButton
                color={'primary'}
                variant={'contained'}
                onClick={handlePhoneNumberStartPortClicked}
                disabled={!portPhoneNumber}
            >
                <LocalTranslatedText language={"en"} text="Start Port Phone Number" />
            </SpinnerButton>
        </div>
    );
};
