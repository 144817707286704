import React from "react";
import FormInput from "../../elements/form/FormInput";
import FormSection from "../../elements/form/FormSection";
import FormSubFieldRoot from "../../elements/form/FormSubFieldRoot";

export default function SendGridConfigurationForm() {
    return (
        <FormSection
            title={"SendGrid Integration"}
            subtitle={"Configuration for the SendGrid API Integration for this tenant"}
            collapsable={true}
        >
            <FormSubFieldRoot field={"sendgrid"}>
                <FormInput
                    type={"text"}
                    label={"API Key"}
                    fieldName={"api_key"}
                    placeholder={"The API key used to connect to the SendGrid API"}
                />
                <FormInput
                    type={"text"}
                    label={"Email Domain"}
                    fieldName={"email_domain"}
                    placeholder={"The domain used for all outbound emails"}
                />
                <FormInput
                    type={"text"}
                    label={"Primary Send Email"}
                    fieldName={"primary_send_email"}
                    placeholder={"The primary email address used to send emails if no other email is provided"}
                />
                <FormInput
                    type={"text"}
                    label={"Primary Send Name"}
                    fieldName={"primary_send_name"}
                    placeholder={"The primary name used to send emails if no other name is provided"}
                />
                <FormSubFieldRoot field={"template_ids"}>
                    <FormInput
                        type={"text"}
                        label={"New Booking Email Template"}
                        fieldName={"new_booking"}
                        placeholder={"The template ID for the email sent when a new booking is created"}
                    />
                    <FormInput
                        type={"text"}
                        fieldName={"cancel_booking"}
                        label={"Cancel Booking Email Template"}
                        placeholder={"The template ID for the email sent when a booking is cancelled"}
                    />
                    <FormInput
                        type={"text"}
                        fieldName={"reschedule_booking"}
                        label={"Reschedule Booking Email Template"}
                        placeholder={"The template ID for the email sent when a booking is rescheduled"}
                    />
                    <FormInput
                        type={"text"}
                        fieldName={"data_updated"}
                        label={"Data Updated Email Template"}
                        placeholder={"The template ID for the email sent when data is updated"}
                    />
                </FormSubFieldRoot>
            </FormSubFieldRoot>
        </FormSection>
    );
}
