import React from "react";
import FormInput from "../../elements/form/FormInput";
import FormSection from "../../elements/form/FormSection";
import FormSubFieldRoot from "../../elements/form/FormSubFieldRoot";

export default function GoogleOAuthConfigurationForm() {
    return (
        <FormSection
            title={"Google OAuth Configuration"}
            subtitle={"Configuration for the Google OAuth API Integration for this tenant"}
            collapsable={true}
        >
            <FormSubFieldRoot field={"google_oauth"}>
                <FormInput
                    type={"text"}
                    label={"Client ID"}
                    fieldName={"client_id"}
                    placeholder={"The client ID used to connect to the Google OAuth API for end-user Calendar access"}
                />
                <FormInput
                    type={"text"}
                    label={"Client Secret"}
                    fieldName={"client_secret"}
                    placeholder={"The client secret used to connect to the Google OAuth API for end-user Calendar access"}
                />
                <FormInput
                    type={"string_list"}
                    label={"Redirect URIs"}
                    fieldName={"redirect_uris"}
                    placeholder={"The redirect URI used when an end-user is authenticating their Google account to allow Prospera access to their Google Calendar. This should lead back to the Prospera frontend."}
                    sx={{ width: '80%' }}
                />
                <FormInput
                    type={"text"}
                    label={"Frontend Redirect URL"}
                    fieldName={"frontend_redirect_url"}
                    placeholder={"The URL to redirect the user to after they have authenticated their Google account. This should be the Prospera frontend URL."}
                />
            </FormSubFieldRoot>
        </FormSection>
    );
}
