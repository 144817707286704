import "./ChartTitleEditor.scss";
import * as React from "react";
import BehaviorTracker from "../../../../core/frontend/components/BehaviorTracker";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import useChartStore from "../ChartState";
import {shallow} from "zustand/shallow";
import {useMemo} from "react";

export function ChartTitleEditor(props) {
    const { is_local, title, setTitle } = useChartStore((state) => ({
        chartId: state.id,
        is_local: state.is_local,
        title: state.title,
        setTitle: state.setTitle,
    }), shallow);

    const saveChangedChartTitle = useMemo( () => _.debounce((newValue) => {
        if(!is_local) {
            BehaviorTracker.trackInteraction({
                id: "change-chart-top-widget",
                mixpanel: null
            });
        }
    }, 1500), [is_local]);

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
        saveChangedChartTitle(event.target.value);
    };

    return <div className={"chart-title-editor"}>
            <TextField
                id={`chart-title-field`}
                variant="standard"
                InputProps={{
                    disableUnderline: true,
                }}
                multiline={false}
                className="nodrag always-show-caret"
                value={title}
                title={`Chart Title`}
                placeholder={"Chart Title"}
                onChange={handleTitleChange}
                onKeyDown={(event) => {
                    // This is needed to allow the user to do things like press ctrl-delete
                    // without triggering ReactFlow to delete the node.
                    event.stopPropagation();
                }}
            />
        </div>
}


export default ChartTitleEditor;

