import PromptManipulator from "./PromptManipulator";
import CardHeader from "@mui/material/CardHeader";
import * as React from "react";
import {useEffect} from "react";
import "./ShrinkingCardHeader.scss";
import {useDelayedStateChange} from "../../../../core/frontend/utils/delayed_state_change";

export function ShrinkingCardHeader(props) {
    const node = props.node;

    const [enableTitleAnimation, setEnableTitleAnimation] = React.useState(false);
    useEffect(() => {
        setTimeout(() => {
            setEnableTitleAnimation(true);
        }, 1000);
    });

    const stateChangeMinTimeMs = 1500;

    let hasMethod = node?.data?.method;
    let needsMethod = node?.type === "promptNode";

    const prompt = new PromptManipulator(node?.data?.prompt);

    let hasHumanTitle = Boolean(node?.data?.title);
    let isPromptEmpty = prompt.isPromptEmpty();
    let promptHasOnlyReferences = prompt.onlyHasReferences();
    let hasGeneratedTitle = Boolean(node?.data?.generatedTitle);
    let isMethodMissing = needsMethod && !hasMethod;
    let hasAnyTitle = hasHumanTitle || hasGeneratedTitle;
    const shouldHideTitleFromState = isPromptEmpty || promptHasOnlyReferences || isMethodMissing || !hasAnyTitle;

    const {state: shouldHideTitle} = useDelayedStateChange(shouldHideTitleFromState, stateChangeMinTimeMs);

    const cardHeaderClasses = `${props.className ?? ""} shrinking-card-header `
        + (shouldHideTitle ? "without-title" : "with-title")
        + (enableTitleAnimation ? " animated" : "");

    return <CardHeader
        {...props}
        className={cardHeaderClasses}
    >
    {props.children}
    </CardHeader>
}
