import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from "@mui/icons-material/Delete";
import "./ContextMenu.scss";

export default function EdgeContextMenu({contextMenu, handleClose, handleDeleteEdge}) {
    return (
        <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
                contextMenu !== null
                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                    : undefined
            }
        >
            <MenuItem onClick={handleDeleteEdge} className={"context-menu-action-item"}>
                <DeleteIcon/>
                <span>Delete Edge</span>
            </MenuItem>
        </Menu>
    );
}