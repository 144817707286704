import {useCallback} from 'react';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import "./TextInputNode.scss";
import useChartStore from "../../ChartState";
import NodeTitleEditor from "../../components/NodeTitleEditor";
import {NodeOutputHandle} from "../../components/NodeHandle";
import CardActions from "@mui/material/CardActions";
import {TextInputNodeIcon} from "../../components/icons";
import CardHeader from "@mui/material/CardHeader";
import PromptChartTemplateSampleValuesList from "../../components/PromptChartTemplateSampleValuesList";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';


function TextInputNode({id: nodeId, isConnectable}) {
    const [isShowingTextInput, setIsShowingTextInput] = React.useState(false);
    const [isShowingSampleValues, setIsShowingSampleValues] = React.useState(false);

    const {node, changeNodeData, recomputeChart, recomputeChartDebounced, template} = useChartStore((state) => {
        return {
            node: state.getNode(nodeId),
            changeNodeData: state.changeNodeData,
            recomputeChart: state.recomputeChart,
            recomputeChartDebounced: state.recomputeChartDebounced,
            template: state.template,
        }
    });

    const onChangeInput = useCallback((evt) => {
        changeNodeData(nodeId, {
            prompt: "",
            currentValues: [evt.target.value],
            stale: true,
        });

        // Trigger the chart to start updating. Recompute chart will wait for several seconds
        // before it actually recomputes the chart, to ensure the user has finished typing.
        recomputeChartDebounced();
    }, [changeNodeData, nodeId, recomputeChartDebounced]);

    const onInputBlur = useCallback((evt) => {
        // Trigger the chart to start updating in the background.
        // Don't use the debounced function, because we want to start
        // the recomputing immediately.
        recomputeChart();

        setIsShowingTextInput(false);
    }, [recomputeChart]);

    const onSelectBlur = useCallback((evt) => {
        setIsShowingSampleValues(false);
    }, []);

    const handleTemplateValueSelected = useCallback((value) => {
        changeNodeData(nodeId, {
            prompt: value,
            stale: true,
        });
        recomputeChart();
        setIsShowingSampleValues(false);
    }, [changeNodeData, nodeId, recomputeChart]);

    const templateFieldName = nodeId.replace("input_", "");

    const onKeyDown = useCallback((event) => {
        // This is needed to allow the user to do things like press ctrl-delete
        // without triggering ReactFlow to delete the node.
        event.stopPropagation();
    }, []);

    const onEditClick = useCallback((evt) => {
        setIsShowingTextInput(!isShowingTextInput);
        evt.stopPropagation();
    }, [isShowingTextInput]);

    const onShowSampleValuesClick = useCallback((evt) => {
        setIsShowingSampleValues(!isShowingSampleValues);
        evt.stopPropagation();
    }, [isShowingSampleValues]);

    const nodeClassName = `text-input-node ${isShowingTextInput ? "editing" : ""} ${isShowingSampleValues ? "showing-sample-values" : ""}`;

    if (!node) {
        return null;
    }

    return (
        <div className={nodeClassName}
             title={"Text Input"}>
            <Card>
                <CardHeader
                    avatar={<TextInputNodeIcon/>}
                    title={<NodeTitleEditor nodeId={nodeId} placeholder={"New Input"}/>}
                />
                {
                    isShowingTextInput || !isShowingSampleValues ?
                        <CardContent>
                            {
                                isShowingTextInput ?
                                    <TextField
                                        id={`text-input-editor-${nodeId}`}
                                        variant="outlined"
                                        multiline={true}
                                        rows={3}
                                        placeholder={"Type your input data here"}
                                        value={node.data.prompt}
                                        className="nodrag always-show-caret text-input-editor nowheel"
                                        onChange={onChangeInput}
                                        onKeyDown={onKeyDown}
                                        onBlur={onInputBlur}
                                        autoFocus={true}
                                    />
                                    : null
                            }
                            {
                                !isShowingTextInput && !isShowingSampleValues ?
                                    <div className={"value-display nodrag"} onClick={onEditClick}>
                                        <span>{node.data.prompt}</span>
                                        <div className={"edit-button-overlay"}>
                                            <IconButton
                                                size={"small"}
                                                onClick={onEditClick}
                                            >
                                                <EditIcon/>
                                            </IconButton>
                                            <IconButton
                                                size={"small"}
                                                onClick={onShowSampleValuesClick}
                                            >
                                                <TextSnippetIcon/>
                                            </IconButton>
                                        </div>
                                    </div> : null
                            }
                        </CardContent> : null
                }
                {
                    isShowingSampleValues ? <CardActions disableSpacing className={"text-input-node-action-bar"}>
                        <div className={"sample-values-list-wrapper"}>
                            <PromptChartTemplateSampleValuesList
                                template_id={template._id}
                                field_name={templateFieldName}
                                onChange={handleTemplateValueSelected}
                                onBlur={onSelectBlur}
                            />
                        </div>
                        {/*<DeleteNodeActionButton nodeId={nodeId} />*/}
                    </CardActions> : null
                }
            </Card>
            <NodeOutputHandle isConnectable={isConnectable} nodeId={nodeId}/>
        </div>
    );
}

export default TextInputNode;
