import React, {useCallback, useState} from "react";
import {PageWrapperWithMenu} from "../../core/frontend/elements/PageWrapperWithMenu";
import {PageHeader} from "../../core/frontend/elements/PageHeader";
import "./ProcessTranscriptPage.scss";
import {useAPI} from "../../core/frontend/components/APIProvider";
import {PageSection} from "../../core/frontend/elements/PageSection";
import IndeterminateLoadingBar from "../../core/frontend/elements/IndeterminateLoadingBar";
import CircularProgress from "@mui/material/CircularProgress";
import FadingText from "../../core/frontend/elements/FadingText";
import ProcessTranscriptForm from "./ProcessTranscriptForm";
import GeneratedContentViewer from "./generated_content/GeneratedContentViewer";


export default function ProcessTranscriptPage() {
    const api = useAPI();
    const [generatedContent, setGeneratedContent] = useState(null);
    const [isGenerating, setIsGenerating] = useState(false);
    const generatingTexts = [
        "Please wait, making magic can take up to a few minutes",
        "Content generation can take up to a couple of minutes... Please hold on tight",
        "Just one second, you will be sending content over to the client in no time...",
        "Your content is on the way, please hold on tight for another couple minutes"
    ]

    const handleGenerateClicked = useCallback((formData) => {
        setIsGenerating(true);
        return api.processTranscript(formData.transcript, formData.transcript_processor_configuration_id).then((newGeneratedContent) => {
            setIsGenerating(false);
            setGeneratedContent(newGeneratedContent);
        }, () => {
            setIsGenerating(false);
        })
    }, [api]);

    return <PageWrapperWithMenu>
        <PageHeader className={"generate-content-header"}/>
        <div className={"process-transcript-page"}>
            {
                !generatedContent && !isGenerating ?
                    <PageSection
                        title={"Generate Content from Transcript"}
                    >
                        <ProcessTranscriptForm
                            initialData={{}}
                            onGenerateClicked={handleGenerateClicked}
                        />
                    </PageSection> : null
            }
            {
                isGenerating ? <PageSection>
                    <div className={"generating-content-box"}>

                        <CircularProgress/>
                        <IndeterminateLoadingBar halfLifeSeconds={150}/>
                        <FadingText texts={generatingTexts}/>
                    </div>
                </PageSection> : null
            }
            {
                generatedContent ?
                    <PageSection>
                        <GeneratedContentViewer
                            generatedContent={generatedContent}
                        />
                    </PageSection>
                    : null
            }
        </div>
    </PageWrapperWithMenu>
}

