import "./CustomDataObjectForm.scss";
import FormInput from "../../../frontend/elements/form/FormInput";
import FormRoot from "../../../frontend/elements/form/FormRoot";
import FormSection from "../../../frontend/elements/form/FormSection";
import FormSubFieldRoot from "../../../frontend/elements/form/FormSubFieldRoot";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";
import React, { useCallback } from "react";
import { SpinnerButton } from "../../../frontend/elements/SpinnerButton";
import FormArraySection from "../../../frontend/elements/form/FormArraySection";
import {useAPI} from "../../../frontend/components/APIProvider";

function RecursiveFormSection({ schema, fieldName, showTitle = true }) {
  if (schema.type === "object") {
    const subFormSection = (
      <FormSection
        title={showTitle ? schema.title || fieldName : null}
        subtitle={showTitle ? schema.description : null}
        noPadding
      >
        {Object.keys(schema.properties).map((fieldName) => {
          const fieldSchema = schema.properties[fieldName];
          return (
            <RecursiveFormSection
              key={fieldName}
              schema={fieldSchema}
              fieldName={fieldName}
            />
          );
        })}
      </FormSection>
    );
    if (fieldName) {
      return (
        <FormSubFieldRoot field={fieldName}>{subFormSection}</FormSubFieldRoot>
      );
    } else {
      return subFormSection;
    }
  } else if (schema.type === "array") {
    return (
      <FormSection
        title={showTitle ? schema.title || fieldName : null}
        subtitle={showTitle ? schema.description ?? "Array of items" : null}
      >
        <FormArraySection
          fieldName={fieldName}
          defaultItemValue={schema.default ?? {}}
          arrayFormItemComponent={({ item, index }) => (
            <>
              <FormSection title={`${schema.title || fieldName} #${index + 1}`}>
                <RecursiveFormSection schema={schema.items} fieldName={null} />
              </FormSection>
            </>
          )}
        />
      </FormSection>
    );
  } else if (schema.type === "string") {
    return (
      <FormInput
        type={schema.form_input_type ?? "text"}
        label={schema.title || fieldName}
        placeholder={schema.placeholder ?? schema.description}
        fieldName={fieldName}
        required={schema.required}
        options={schema.options}
        optionLabels={schema.optionLabels}
        multiline={schema.multiline}
        rows={schema.rows}
      />
    );
  } else if (schema.type === "number") {
    return (
      <FormInput
        type={"number"}
        label={schema.title || fieldName}
        placeholder={schema.placeholder ?? schema.description}
        fieldName={fieldName}
        required={schema.required}
      />
    );
  } else if (schema.type === "boolean") {
    return (
      <FormInput
        type={"checkbox"}
        label={schema.title || fieldName}
        placeholder={schema.placeholder ?? schema.description}
        fieldName={fieldName}
        required={schema.required}
      />
    );
  } else {
    return null;
  }
}

export default function CustomDataObjectForm({
  customDataSchema,
  initialCustomDataObject,
  onCustomDataObjectSave,
  onDeletedClicked,
  saveButtonText,
}) {
  const api = useAPI();
  const [customDataObject, setCustomDataObject] = React.useState(
    initialCustomDataObject
  );

  const handleCustomDataObjectSaved = useCallback(
    (newCustomDataObjectData) => {
      if (onCustomDataObjectSave) {
        return onCustomDataObjectSave(newCustomDataObjectData);
      }
    },
    [onCustomDataObjectSave]
  );

  const handleCustomDataObjectChange = useCallback(
    (newCustomDataObjectData) => {
      setCustomDataObject(newCustomDataObjectData);
    },
    []
  );

  const handleExecuteCustomActionClicked = useCallback(
    async (actionName) => {
      await api.executeCustomAction(customDataObject.id, actionName);
    },
    []
  );

  return (
    <div className={"custom-data-object-form"}>
      <FormRoot
        initialValue={initialCustomDataObject}
        saveButtonText={saveButtonText ?? `Save ${customDataSchema.title}`}
        onSave={handleCustomDataObjectSaved}
        onChange={handleCustomDataObjectChange}
        extraButtons={
          <>
            {
              (customDataSchema.custom_actions ?? []).map((action) =>
                <SpinnerButton
                  variant='contained'
                  color='primary'
                  className={"execute-action-button"}
                  onClick={handleExecuteCustomActionClicked.bind(null, action.action_name)}
                >
                  <LocalTranslatedText language={"en"} text={action.title} />
                </SpinnerButton>
              )
            }
            {onDeletedClicked ? (
              <SpinnerButton
                variant='contained'
                color='error'
                className={"delete-button"}
                onClick={onDeletedClicked}
              >
                <LocalTranslatedText language={"en"} text='Delete' />
              </SpinnerButton>
            ) : null}
          </>
        }
      >
        <RecursiveFormSection
          schema={customDataSchema.json_schema}
          fieldName={"data"}
          showTitle={false}
        />
      </FormRoot>
    </div>
  );
}
