import { Alert } from "@mui/material";
import React from "react";
import moment from "moment";
import "./ConversationHistory.scss";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";
import FilePreview from "../../file_uploads/frontend/FilePreview";
import IndeterminateLoadingBar from "../../frontend/elements/IndeterminateLoadingBar";
import { ConversationAvatar } from "./ConversationAvatar";
import { useFrontendConfiguration } from "../../frontend/components/FrontendConfigurationProvider";

export function ConversationHistoryEvent({
  event,
  eventIndex,
  isLast,
  avatarUrl,
}) {
  const config = useFrontendConfiguration();

  const generatedDocumentUrl = `${config.frontend.backend_api_url}generated_document/${event?.metadata?.generated_document_id}/rendered`;

  const handleGeneratedDocumentClick = React.useCallback(
    (e) => {
      // Open the file in a new screen
      window.open(generatedDocumentUrl, "_blank");
      e.preventDefault();
    },
    [generatedDocumentUrl]
  );

  return (
    <div
      className={`event ${event.role} ${event.action_id} ${event.module_id}`}
    >
      {event.metadata?.generated_document_id ? (
        <div className={"event-file"}>
          <a
            href={generatedDocumentUrl}
            target={"_blank"}
            rel='noreferrer'
            onClick={handleGeneratedDocumentClick}
          >
            <FilePreview
              fileId={event.metadata?.file_id}
              fileName={`${event.metadata?.generated_document_title}.pdf`}
            />
          </a>
        </div>
      ) : null}
      <div className={"event-inner"}>
        {event.role === "assistant" ? (
          <div className={"event-role-bot"}>
            <ConversationAvatar
              avatarUrl={avatarUrl}
              role={event.role}
              moduleId={event.module_id}
            />
          </div>
        ) : null}
        <div className={"date-alert-combo-widget"}>
          <div className={"event-date"}>
            {!event.created_at ? (
              <LocalTranslatedText language={"en"} text='Invalid Date' />
            ) : (
              moment.utc(event.created_at).local().format("MMM Do, h:mm a")
            )}
          </div>

          <Alert
            variant='filled'
            severity={event.role === "user" ? "info" : "success"}
            icon={null}
          >
            <div className={"event-text"}>
              <LocalTranslatedText
                text={event.content}
                showOptionToViewOriginalLanguage={true}
              />
            </div>
          </Alert>
        </div>
        {event.role === "user" ? (
          <div className={"event-role-user"}>
            <ConversationAvatar
              avatarUrl={avatarUrl}
              role={event.role}
              moduleId={event.module_id}
            />
          </div>
        ) : null}
      </div>
      {event.metadata?.show_loading_bar && isLast ? (
        <div className={"event-loading-bar"}>
          <IndeterminateLoadingBar
            halfLifeSeconds={event.metadata?.loading_bar_halflife}
          />
        </div>
      ) : null}
    </div>
  );
}

export function ConversationHistory({
  events,
  onlyShowMessages,
  conversation,
}) {
  let filteredEvents = events;
  if (onlyShowMessages) {
    filteredEvents = filteredEvents.filter((event) => {
      return event.module_id === "user_interactor" || event.role === "user";
    });
  }

  return (
    <div className={"bacbs-conversation-history"}>
      {filteredEvents.map((event, eventIndex) => (
        <ConversationHistoryEvent
          key={event._id ?? event.id ?? eventIndex}
          event={event}
          eventIndex={eventIndex}
          isLast={eventIndex === filteredEvents.length - 1}
          avatarUrl={conversation?.metadata?.avatar_url}
        />
      ))}
    </div>
  );
}
