import React from "react";
import FormInput from "../../elements/form/FormInput";
import FormSection from "../../elements/form/FormSection";
import FormSubFieldRoot from "../../elements/form/FormSubFieldRoot";


export default function MongoConfigurationForm() {
    return <FormSection
        title={"MongoDB"}
        subtitle={"Configuration for the database connection for this tenant"}
        collapsable={true}
    >
        <FormSubFieldRoot
            field={"mongo"}
        >
            <FormInput
                type={"text"}
                label={"Mongo URI"}
                fieldName={"mongo_uri"}
                placeholder={"The URI of the MongoDB server. Note that the username, password, and database name (as a path) should all be included within the URI."}
            />

            <FormInput
                type={"text"}
                label={"Username"}
                fieldName={"username"}
                placeholder={"The username to connect to the MongoDB server"}
            />

            <FormInput
                type={"text"}
                label={"Password"}
                fieldName={"password"}
                placeholder={"The password to connect to the MongoDB server"}
            />

            <FormInput
                type={"text"}
                label={"Database Name"}
                fieldName={"database_name"}
                placeholder={"The name of the database within the MongoDB server that will be used for this mongo connection."}
            />
        </FormSubFieldRoot>
    </FormSection>
}