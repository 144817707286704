import React from "react";
import FormInput from "../../elements/form/FormInput";
import FormSection from "../../elements/form/FormSection";
import FormSubFieldRoot from "../../elements/form/FormSubFieldRoot";

export default function HomeAPIServerConfigurationForm() {
    return (
        <FormSection
            title={"Home API Server Configuration"}
            subtitle={"Configuration for where the home api server for this tenant is located, impacted which servers it gets preloaded on."}
            collapsable={true}
        >
            <FormSubFieldRoot field={"home_api_server"}>
                <FormInput
                    type={"text"}
                    label={"GCP Project"}
                    fieldName={"gcp_project"}
                    placeholder={"This is the GCP project name where the 'home' API server instance is located."}
                />
                <FormInput
                    type={"text"}
                    label={"Service Name"}
                    fieldName={"service_name"}
                    placeholder={"This is the name of the service under which the 'home' API server instance is running."}
                />
            </FormSubFieldRoot>
        </FormSection>
    );
}
