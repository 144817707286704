import React from "react";
import FormInput from "../../elements/form/FormInput";
import FormSection from "../../elements/form/FormSection";
import FormSubFieldRoot from "../../elements/form/FormSubFieldRoot";

export default function DeepgramConfigurationForm() {
    return <FormSection
        title={"Deepgram Integration"}
        subtitle={"Configuration for the Deepgram API Integration for this tenant"}
        collapsable={true}
    >
        <FormSubFieldRoot
            field={"deepgram"}
        >
            <FormInput
                type={"text"}
                label={"API Key"}
                fieldName={"api_key"}
                placeholder={"The API key used to connect to their API"}
            />
        </FormSubFieldRoot>
    </FormSection>
}
