import "./ImportWebpagePage.scss";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";
import React, {useCallback, useState} from "react";
import {Card, CardContent, TextField} from "@mui/material";
import {PageHeader} from "../../../frontend/elements/PageHeader";
import {PageWrapperWithMenu} from "../../../frontend/elements/PageWrapperWithMenu";
import {SpinnerButton} from "../../../frontend/elements/SpinnerButton";
import {useAPI} from "../../../frontend/components/APIProvider";
import {useNavigate} from "react-router-dom";


export default function ImportWebpagePage(props) {
    const api = useAPI();
    const navigate = useNavigate();
    const [urlToImport, setUrlToImport] = useState(null);

    const handleUrlChange = useCallback((evt) => {
        setUrlToImport(evt.target.value);
    }, [setUrlToImport]);

    const handleImportWebpageClicked = useCallback(async (result) => {
        const importedPage = await api.importWebpage(urlToImport);
        navigate(`/imported_webpages/${importedPage.id}`);
    }, [api, navigate, urlToImport]);

    return <PageWrapperWithMenu>
        <PageHeader title={"Import Webpage"}/>
        <div className="import-webpage-page">
            <Card className="import-webpage-card">
                <CardContent>
                    <TextField
                        label={"URL"}
                        value={urlToImport}
                        onChange={handleUrlChange}
                    />

                    <SpinnerButton
                        variant="contained"
                        color="primary"
                        onClick={handleImportWebpageClicked}
                    >
                        <LocalTranslatedText
                            text={"Import"}
                            language={"en"}
                        />
                    </SpinnerButton>
                </CardContent>
            </Card>
        </div>

    </PageWrapperWithMenu>
}