import "./NodeTemplatedPromptEditor.scss";
import * as React from "react";
import * as promptTemplates from "./PromptTemplates";
import BehaviorTracker from "../../../../core/frontend/components/BehaviorTracker";
import useChartStore from "../ChartState";
import {TextEditorWithNodeSuggester} from "./TextEditorWithNodeSuggester";


export function NodeInsertionValueEditor({nodeId, insertionName, onChange, onBlur}) {
    const {node} = useChartStore((state) => {
        return {
            node: state.getNode(nodeId),
        }
    });

    const currentTemplateValue = (node.data.templateInsertionValues || {})[insertionName] ?? "";

    const handleChange = function (newHtml) {
        if (onChange) {
            onChange(insertionName, newHtml);
        }
    }

    const handleBlur = function () {
        if (onBlur) {
            onBlur();
        }
    }

    return <TextEditorWithNodeSuggester
        id={`templated-prompt-insertion-field-${nodeId}-${insertionName}`}
        nodeId={nodeId}
        className={"nodrag template-insertion-editor"}
        onChange={handleChange}
        value={currentTemplateValue}
        placeholder={insertionName}
        focus={false}
        onBlur={handleBlur}
    />;
}


export function NodeTemplatedPromptEditor({nodeId, savePrompt}) {
    const {node, changeNodeData} = useChartStore((state) => {
        return {
            node: state.getNode(nodeId),
            changeNodeData: state.changeNodeData,
        }
    });

    const handleInsertionValueChanged = function (insertionName, newHtml) {
        const newInsertionValues = {
            ...node.data.templateInsertionValues,
            [insertionName]: newHtml,
        };

        const template = promptTemplates.findTemplateById(node.data.templateId);

        // Recompute the full prompt by merging together each of the template bits with the values
        changeNodeData(nodeId, {
            stale: true,
            prompt: template.constructPrompt(newInsertionValues),
            templateInsertionValues: newInsertionValues
        });

        BehaviorTracker.trackInteraction({
            id: 'templated-prompt-edit',
            mixpanel: null,
        });
    }

    const handleOnBlur = function() {
        // Can't save the prompt here because the user might still want to edit the other prompt
        //     savePrompt();
    }

    const template = promptTemplates.findTemplateById(node.data.templateId);

    // Now we go through the template string, and find all of the insertions points, which are
    // just a variable name surrounded by square brackets.
    const pieces = template.getPieces();

    const templatePieceElements = pieces.map((piece) => {
        if (piece.type === 'text') {
            return <span key={piece.index} className={"template-raw-text-piece"}>{piece.text}</span>;
        } else if (piece.type === 'newline') {
            return <br key={piece.index}/>;
        } else if (piece.type === 'insertion') {
            return <NodeInsertionValueEditor
                key={piece.index}
                nodeId={nodeId}
                insertionName={piece.insertionName}
                onChange={handleInsertionValueChanged}
                onBlur={handleOnBlur}
            />;
        } else {
            throw new Error("Unknown piece type: " + piece.type);
        }
    });

    return <div className={"node-templated-prompt-editor"}>
        <div className={"template-title"}>Template: {template.title}</div>
        <div className={"template-edit-area"}>
            <span className={"template-pieces-wrapper"}>
                {templatePieceElements}
            </span>
        </div>
    </div>;
}

