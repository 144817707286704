import FormControl from "@mui/material/FormControl";
import React, { useState, useCallback, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "./PromptChartBindingWidget.scss";
import { useAPI } from "../../../core/frontend/components/APIProvider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Paper from "@mui/material/Paper";
import { createBlankChart } from "./builtincharts/blank";
import { useNavigate } from "react-router-dom";
import { SpinnerButton } from "../../../core/frontend/elements/SpinnerButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

export function InputFieldsList({ template }) {
  return (
    <List
      dense
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
    >
      {template.input_fields.map((inputField) => {
        const labelId = `checkbox-list-secondary-label-${inputField.field_name}`;
        return (
          <ListItem key={inputField.field_name} disablePadding>
            <ListItemButton>
              <ListItemText id={labelId} primary={`${inputField.title}`} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}

export function OutputFieldsList({ template }) {
  return (
    <List
      dense
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
    >
      {template.output_fields.map((outputField) => {
        const labelId = `checkbox-list-secondary-label-${outputField.field_name}`;
        return (
          <ListItem key={outputField.field_name} disablePadding>
            <ListItemButton>
              <ListItemText id={labelId} primary={`${outputField.title}`} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}

export function PromptChartBindingWidget({
  template,
  label,
  name,
  value,
  onChange,
}) {
  const api = useAPI();
  const navigate = useNavigate();
  const [promptChartList, setPromptChartList] = useState(null);

  const fetchCharts = useCallback(() => {
    api.getCharts(template._id).then((data) => {
      setPromptChartList(data);
    });
  }, [api, template]);

  useEffect(() => {
    fetchCharts();
  }, [fetchCharts]);

  const handleChange = useCallback(
    (event) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  const handleNewChartClicked = useCallback(async () => {
    const newChart = createBlankChart(template);

    // Check to see how many charts in the list have the same title.
    let duplicateTitleIndex = 0;
    for (let i = 0; i < promptChartList.length; i++) {
      if (promptChartList[i].title.includes(newChart.title)) {
        duplicateTitleIndex += 1;
      }
    }

    if (duplicateTitleIndex > 0) {
      newChart.title = newChart.title + ` (${duplicateTitleIndex})`;
    }

    return api.createNewChart(newChart).then((newChartData) => {
      fetchCharts();
      return onChange(newChartData._id);
    });
  }, [api, fetchCharts, template, onChange, promptChartList]);

  const handleEditChartClicked = useCallback(() => {
    navigate(`/prompt_charts/${value}`);
  }, [navigate, value]);

  const handleDeleteChartClicked = useCallback(() => {
    return api.deleteChart(value).then(() => {
      fetchCharts();
      return onChange("");
    });
  }, [api, fetchCharts, value, onChange]);

  return (
    <>
      <Paper className={"prompt-chart-binding-widget"} elevation={2}>
        {label ? <Typography variant={"h5"}>{label}</Typography> : null}
        <div className={"widget-columns"}>
          <div className={"binding-selector-column"}>
            <Typography variant={"h6"}>Binding</Typography>
            <FormControl>
              <Select value={value} name={name} onChange={handleChange}>
                {!promptChartList ? (
                  <MenuItem value={""}>Loading...</MenuItem>
                ) : null}
                {promptChartList && promptChartList.length === 0 ? (
                  <MenuItem value={""}>
                    No Prompt Charts Found for this Binding
                  </MenuItem>
                ) : null}
                {promptChartList &&
                  promptChartList.map((promptChart) => {
                    return (
                      <MenuItem value={promptChart._id} key={promptChart._id}>
                        {promptChart.title ||
                          `Unnamed Prompt Chart ${promptChart._id}`}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <div className={"buttons-row"}>
              <SpinnerButton
                onClick={handleNewChartClicked}
                variant={"contained"}
                color={"success"}
                endIcon={<AddIcon />}
              >
                Add New Chart
              </SpinnerButton>
              {value ? (
                <SpinnerButton
                  onClick={handleEditChartClicked}
                  variant={"contained"}
                  color={"primary"}
                  endIcon={<EditIcon />}
                >
                  Edit Chart
                </SpinnerButton>
              ) : null}
              {value ? (
                <SpinnerButton
                  onClick={handleDeleteChartClicked}
                  variant={"contained"}
                  color={"error"}
                  endIcon={<DeleteIcon />}
                >
                  Delete Chart
                </SpinnerButton>
              ) : null}
            </div>
          </div>

          <div className={"input-information-column"}>
            <Typography variant={"h6"}>Inputs</Typography>

            <InputFieldsList template={template} />
          </div>

          <div className={"output-information-column"}>
            <Typography variant={"h6"}>Outputs</Typography>

            <OutputFieldsList template={template} />
          </div>
        </div>
      </Paper>
    </>
  );
}

export default PromptChartBindingWidget;
