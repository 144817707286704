import "./EditCalendarPage.scss";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import React, {useCallback, useEffect, useState} from "react";
import {CalendarForm} from "./CalendarForm";
import {PageHeader} from "../../../../core/frontend/elements/PageHeader";
import {PageWrapperWithMenu} from "../../../../core/frontend/elements/PageWrapperWithMenu";
import {useAPI} from "../../../../core/frontend/components/APIProvider";
import {useNavigate, useParams} from "react-router-dom";


export default function EditCalendarPage() {
    const api = useAPI();
    const params = useParams();
    const navigate = useNavigate();

    const [calendar, setCalendar] = useState(null);

    useEffect(() => {
        api.getCalendar(params.calendarId).then((data) => {
            setCalendar(data);
        });
    }, [params.calendarId, api]);

    const handleSaveCalendarClicked = useCallback(async () => {
        await api.saveCalendar(calendar);
    }, [calendar, api]);

    const handleDeleteCalendarClicked = useCallback(async () => {
        await api.deleteCalendar(calendar.id);
        navigate("/calendars");
    }, [calendar, navigate, api]);

    return <PageWrapperWithMenu>
        <PageHeader title={"Edit Calendar"}/>
        <div className="edit-calendar-page">
            <Paper className={"main-page-contents"}>
                {
                    calendar ?
                        <CalendarForm
                            calendar={calendar}
                            onChange={setCalendar}
                            onSaveClicked={handleSaveCalendarClicked}
                            showDeleteButton={true}
                            onDeleteClicked={handleDeleteCalendarClicked}
                        />
                        : null
                }
                {
                    !calendar ?
                        <CircularProgress/>
                        : null
                }
            </Paper>
        </div>
    </PageWrapperWithMenu>
}

