import React from "react";
import FormInput from "../../elements/form/FormInput";
import FormSection from "../../elements/form/FormSection";
import FormSubFieldRoot from "../../elements/form/FormSubFieldRoot";


export default function APIConfigurationForm() {
    return <FormSection
        title={"API"}
        subtitle={"Configuration for how the API should work"}
        collapsable={true}
    >
        <FormSubFieldRoot
            field={"api"}
        >
            <FormInput
                type={"string_list"}
                label={"API Domains"}
                fieldName={"api_domains"}
                placeholder={"The list of domain names that the API is allowed to be accessed from. These domain names are used to figure out which tenant to use for fulfilling a request."}
                sx={{ width: '45ch' }}
            />

            <FormInput
                type={"string_list"}
                label={"Admin Email Domains"}
                fieldName={"admin_email_domains"}
                placeholder={"The list of email domains. Users with any of these email domains are considered administrators for the purpose of this tenant"}
                sx={{ width: '45ch' }}
            />

            <FormInput
                type={"text"}
                label={"Fixed User ID"}
                fieldName={"hardcode_user_id"}
                placeholder={"This allows us to hardcode the user_id in specific environments, effectively creating a single shared view for all users. This is meant to be used by tenants who only need a single bot for their single company."}
            />
        </FormSubFieldRoot>
    </FormSection>
}