import React, { useCallback } from "react";
import FormInput from "../../../frontend/elements/form/FormInput";
import FormRoot from "../../../frontend/elements/form/FormRoot";
import FormSection from "../../../frontend/elements/form/FormSection";
import { SpinnerButton } from "../../../frontend/elements/SpinnerButton";
import FilePreview from "../../../file_uploads/frontend/FilePreview";
import "./ImportedDocumentForm.scss";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";

export default function ImportedDocumentForm({
  initialDocument,
  onDocumentSave,
  onDeletedClicked,
  saveButtonText,
}) {
  const [document, setDocument] = React.useState(initialDocument);

  const handleDocumentSaved = useCallback(
    (newDocumentData) => {
      if (onDocumentSave) {
        return onDocumentSave(newDocumentData);
      }
    },
    [onDocumentSave]
  );

  const handleDocumentChange = useCallback((newDocumentData) => {
    setDocument(newDocumentData);
  }, []);

  return (
    <div className={"document-form"}>
      <FormRoot
        initialValue={initialDocument}
        saveButtonText={saveButtonText ?? "Save Document"}
        onSave={handleDocumentSaved}
        onChange={handleDocumentChange}
        extraButtons={
          <>
            {onDeletedClicked ? (
              <SpinnerButton
                variant='contained'
                color='error'
                className={"delete-button"}
                onClick={onDeletedClicked}
              >
                <LocalTranslatedText language={"en"} text='Delete' />
              </SpinnerButton>
            ) : null}
          </>
        }
      >
        <FormSection
          title={"General"}
          subtitle={"Metadata that can be used to find this document"}
        >
          <FormInput type={"text"} label={"Title"} fieldName={"title"} />
        </FormSection>

        {document.file_id ? (
          <FormSection
            title={"Preview"}
            subtitle={<span>{document.original_filename}</span>}
          >
            <div className={"file-preview-wrapper"}>
              <FilePreview
                fileId={document.file_id}
                fileName={document.original_filename}
              />
            </div>
          </FormSection>
        ) : null}

        <FormSection title={"Content"}>
          <FormInput
            type={"text"}
            label={"The raw text that was extracted from the document:"}
            fieldName={"raw_content"}
            multiline
            rows={36}
          />
        </FormSection>
      </FormRoot>
    </div>
  );
}
