import FormControl from "@mui/material/FormControl";
import React, {useCallback} from "react";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";

const phoneNumberTypes = [
    {
        id: "mobile",
        label: "Mobile"
    },
    {
        id: "local",
        label: "Local"
    },
    {
        id: "toll_free",
        label: "Toll Free"
    }
]


export function PhoneNumberTypeSelector({
                                            label,
                                            name,
                                            placeholder,
                                            emptyLabel,
                                            disabled,
                                            onChange,
                                            value
                                        }) {
    const options = phoneNumberTypes.map(type => type.id);
    const optionLabels = phoneNumberTypes.map(type => type.label);

    placeholder = placeholder ?? "Select a type of phone number";

    const handleChange = useCallback((event) => {
        onChange(event.target.value);
    }, [onChange]);

    let valueLocal = value ?? '';

    return <FormControl fullWidth={true} className={"phone-number-type-selector"}>
        {
            label ? <Typography variant={'label'}>
                <LocalTranslatedText language={"en"} text={label}/>
            </Typography> : null
        }
        <Select
            variant={"outlined"}
            value={disabled ? "" : valueLocal}
            name={name}
            fullWidth={true}
            onChange={handleChange}
            disabled={disabled}
            displayEmpty={true}
            renderValue={(value) => value ?
                <LocalTranslatedText language={"en"} text={optionLabels[options.indexOf(value)]}/> :
                <span className={"placeholder"}><LocalTranslatedText language={"en"} text={placeholder}/></span>
            }>
            {
                options.map((optionStr, optionIndex) =>
                    <MenuItem value={optionStr} key={optionStr}>
                        <LocalTranslatedText language={"en"} text={optionLabels[optionIndex]}/>
                    </MenuItem>
                )
            }
        </Select>
    </FormControl>;
}

export default PhoneNumberTypeSelector;

