import React, {useState, useCallback, useMemo} from 'react';
import {Select, MenuItem, TextField} from '@mui/material';
import {StoryFormWidgetContainer} from "./StoryFormWidgetContainer";
import "./StoryMacGuffinSelector.scss";
import _ from "lodash";

const allowedOptions = [
    'Homework',
    'Toy',
    'Pet',
    'Secret Treasure',
    'Enchanted Book',
    'Mysterious Key',
    'Family Heirloom',
    'Secret Recipe',
    'Other'
];

export const StoryMacguffinSelector = ({value, onChange}) => {
    let initialSelected = value;
    let initialCustom = "";
    // Fill in a default value if none is provided
    if (!initialSelected) {
        initialSelected = 'Homework';
    }
    // If the value isn't in the list of allowed values, we set environment
    // to "Other" allowing the user to type in their own value
    if (!allowedOptions.includes(initialSelected)) {
        initialSelected = 'Other';
        initialCustom = value;
    }

    const onChangeDebounced = useMemo(() => _.debounce(onChange, 500), [onChange]);

    const [selectedMacguffin, setSelectedMacguffin] = useState(initialSelected);
    const [customMacguffin, setCustomMacguffin] = useState(initialCustom);

    const handleSelectChange = useCallback((event) => {
        setSelectedMacguffin(event.target.value);
        onChange(event.target.value);
    }, [onChange]);

    const handleTextChange = useCallback((event) => {
        setCustomMacguffin(event.target.value);
        onChangeDebounced(event.target.value);
    }, [onChangeDebounced]);

    return (
        <StoryFormWidgetContainer label={"Main Objective"} className={"story-macguffin-selector"}>
            <Select
                labelId="macguffin-label"
                id="macguffin-select"
                value={selectedMacguffin}
                onChange={handleSelectChange}
            >
                {
                    allowedOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))
                }
            </Select>
            {selectedMacguffin === 'Other' && (
                <TextField
                    id="other-macguffin"
                    label="Custom Main Objective"
                    onChange={handleTextChange}
                    placeholder={"Describe in one sentence"}
                    value={customMacguffin}
                    fullWidth={true}
                />
            )}
        </StoryFormWidgetContainer>
    );
};