import {PageWrapperWithMenu} from "../../../core/frontend/elements/PageWrapperWithMenu";
import React, {useCallback, useState} from "react";
import {Card, CardContent} from "@mui/material";
import {useAPI} from "../../../core/frontend/components/APIProvider";
import {PageHeader} from "../../../core/frontend/elements/PageHeader";
import InsuranceAgentForm from "./InsuranceAgentForm";
import "./InsuranceAgentEditPage.scss";
import {useNavigate} from "react-router-dom";


export default function NewInsuranceAgentPage() {
    const api = useAPI();
    const navigate = useNavigate();

    const handleCreateInsuranceAgent = useCallback(async (insuranceAgentData) => {
        return await api.createNewInsuranceAgent(insuranceAgentData).then((newAgent)=> {
            navigate(`/insurance_agents/${newAgent.id}`);
        })
    }, [api]);

    return <PageWrapperWithMenu>
        <PageHeader title={`New Insurance Agent`}/>
        <div className="insurance_agent-edit-page">
            <Card className="insurance_agent-edit-form-card">
                <CardContent>
                    <InsuranceAgentForm
                        initialInsuranceAgent={{}}
                        onInsuranceAgentSave={handleCreateInsuranceAgent}
                        saveButtonText={"Create"}
                    />
                </CardContent>
            </Card>
        </div>

    </PageWrapperWithMenu>
}