import {PageWrapperWithMenu} from "../../../core/frontend/elements/PageWrapperWithMenu";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useAPI} from "../../../core/frontend/components/APIProvider";
import {TopicExtractorPromptTable} from "./TopicExtractorPromptTable";
import "./TopicExtractorPromptListPage.scss";
import {PageHeader} from "../../../core/frontend/elements/PageHeader";
import {WhiteButton} from "../../../core/frontend/elements/WhiteButton";
import Paper from "@mui/material/Paper";
import {useCallback} from "react";


export default function TopicExtractorPromptListPage() {
    const api = useAPI();
    const navigate = useNavigate();

    const handleNewTopicExtractorPromptClicked = useCallback(async () => {
        navigate(`/topic_extractor_prompts/new`);
    }, [navigate]);

    return <PageWrapperWithMenu>
        <PageHeader title={"Topic Extractor Prompts"}>
            <WhiteButton onClick={handleNewTopicExtractorPromptClicked}>
                Add New Topic Extractor Prompt
            </WhiteButton>
        </PageHeader>
        <div className={"topic-extractor-prompt-list-page"}>
            <div className={"topic-extractor-prompt-table-wrapper"}>
                <Paper className={"main-page-contents"}>
                    <TopicExtractorPromptTable/>
                </Paper>
            </div>
        </div>
    </PageWrapperWithMenu>
}

