import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {IconButton} from "@mui/material";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import {useCallback} from "react";


export default function RenameSmartChainButton({onNameChanged, currentName}) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleNameChangeSubmitted = useCallback((event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        const name = formJson.chain_name;
        onNameChanged(name);
        handleClose();
    }, [onNameChanged, handleClose]);

    return (
        <React.Fragment>
            <IconButton edge="end" aria-label="delete" onClick={handleClickOpen}>
                <DriveFileRenameOutlineIcon />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: handleNameChangeSubmitted
                }}
            >
                <DialogTitle>Rename</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to rename this smart chain?
                        <br/>
                        <strong>Warning!</strong> This will immediately cause any bindings that point to this existing chain_name to break.
                        Please make sure you adjust those bindings before renaming this chain.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="smart-chain-name"
                        name="chain_name"
                        label="Chain Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        defaultValue={currentName}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit" color={"error"}>Rename</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}