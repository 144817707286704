import React, { useState, useEffect } from 'react';
import './FadingText.css';

const FadingText = ({ texts }) => {
  const [index, setIndex] = useState(0);
  const [fade, setFade] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setFade(false);
      }, 1000);
    }, 7000);
    return () => clearInterval(timer);
  }, [texts]);

  return (
    <div className={`fade-text ${fade ? 'fade-out' : ''}`}>
      {texts[index]}
    </div>
  );
};

export default FadingText;