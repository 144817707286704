import {useState, useMemo, useEffect, useRef, useCallback} from 'react';
import ReactFlow, {
    Controls,
    Background,
    MiniMap, Panel,
} from 'reactflow';
import Card from '@mui/material/Card';
import 'reactflow/dist/style.css';
import PromptNode from "./nodes/PromptNode/PromptNode";
import TextInputNode from "./nodes/TextInputNode/TextInputNode";
import "./ChartEditor.scss";
import useChartStore from "./ChartState";
import { shallow } from 'zustand/shallow';
import FileInputNode from "./nodes/FileInputNode/FileInputNode";
import CircularProgress from "@mui/material/CircularProgress";
import InputGroupNode from "./nodes/InputGroupNode/InputGroupNode";
import OutputNode from "./nodes/OutputNode/OutputNode.js";
import {useNavigate} from "react-router-dom";
import {promptNodeInitialHeight} from "../../../core/frontend/theme/sizes";
import _ from "lodash";
import * as React from "react";
import ChartContextMenu from "./components/ChartContextMenu";
import EdgeContextMenu from "./components/EdgeContextMenu";
import CustomEdge from "./components/CustomEdge";
import PromptNodeContextMenu from "./components/PromptNodeContextMenu";
import InputGroupNodeContextMenu from "./components/InputGroupNodeContextMenu";
import {useSnackbar} from "../../../core/frontend/components/SnackbarProvider";
import {TipsWidget} from "./TipsWidget";
import BehaviorTracker from "../../../core/frontend/components/BehaviorTracker";
import OutputNodeContextMenu from "./components/OutputNodeContextMenu";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Color from "colorjs.io";
import ChartTitleEditor from "./components/ChartTitleEditor";
import {TemplateSampleValuesCacheProvider} from "./TemplateSampleValuesCacheProvider";
import {chartMaxZoom, chartMinZoom} from "./ChartConstants";

const nodeColor = (node) => {
    let baseColor = "#ffffff";
    if (node.data.color) {
        baseColor = node.data.color;
    }

    const color = new Color(baseColor)
    color.hsl.l = 80;
    return color.toString();
};

const selector = (state) => ({
    id: state.id,
    nodes: state.nodes,
    edges: state.edges,
    createTextInputNode: state.createTextInputNode,
    createFileInputNode: state.createFileInputNode,
    createPromptNode: state.createPromptNode,
    changeNode: state.changeNode,
    onNodesChange: state.onNodesChange,
    onEdgesChange: state.onEdgesChange,
    onConnect: state.onConnect,
    loadChart: state.loadChart,
    is_loading_new_chart: state.is_loading_new_chart,
    is_local: state.is_local,
    zoom: state.viewport.zoom,
    getViewport: state.getViewport,
    setViewport: state.setViewport,
    addEdge: state.addEdge,
    getNode: state.getNode,
    calculateHeightForNode: state.calculateHeightForNode,
    calculateWidthForNode: state.calculateWidthForNode,
    deleteEdge: state.deleteEdge,
    deleteNode: state.deleteNode,
});

const nodeTypes = {
    promptNode: PromptNode,
    textInputNode: TextInputNode,
    fileInputNode: FileInputNode,
    inputGroupNode: InputGroupNode,
    outputNode: OutputNode,
};

const edgeTypes = {
    'default': CustomEdge
};

function isValidConnection(connection) {
    return connection.source !== connection.target &&
        connection.sourceHandle === "out" &&
        connection.targetHandle === "in";
}

export const ChartEditor = ({chartId, local, singleChartEvaluation}) => {
    const nodeTypesMemoized = useMemo(() => nodeTypes, []);
    const edgeTypesMemoized = useMemo(() => edgeTypes, []);

    const { _id, nodes, edges, onNodesChange, onEdgesChange,
        onConnect, createTextInputNode, createFileInputNode,
        createPromptNode,  loadChart,
        is_loading_new_chart, is_local,
        getViewport, setViewport, addEdge,
        getNode, zoom,
        calculateHeightForNode, calculateWidthForNode,
        deleteEdge, deleteNode} = useChartStore(selector, shallow);

    const snackbar = useSnackbar();

    let [didCompleteEdge, setDidCompleteEdge] = useState(false);
    let [startEdgeNodeId, setStartEdgeNodeId] = useState(null);
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const chartWrapperElement = useRef(null);
    const [contextMenuPosition, setContextMenuPosition] = React.useState(null);
    const [contextMenuType, setContextMenuType] = React.useState(null);
    const [contextMenuEdge, setContextMenuEdge] = React.useState(null);
    const [contextMenuNode, setContextMenuNode] = React.useState(null);
    const enableContextMenu = process.env.REACT_APP_PROMPT_CHART_ENABLE_CONTEXT_MENU === "true";

    const navigate = useNavigate();

    const connectionRadius = 150;

    // Just double check that the chartId we've been given as a prop is the same as the chartId in the store.
    // If not, we need to load the new chart from the store.
    useEffect(() => {
        if (chartId !== _id || local !== is_local) {
             loadChart(chartId, local, singleChartEvaluation).catch((err) => {
                 navigate("/");
             })
        }
    }, [chartId, local, singleChartEvaluation, _id, is_local, loadChart, navigate]);

    const handleOnInit = useCallback((newInstance) => {
        setReactFlowInstance(newInstance);
    }, [setReactFlowInstance]);

    useEffect(() => {
        if (reactFlowInstance) {
            const currentViewport = reactFlowInstance.getViewport();
            if (getViewport().x !== currentViewport.x || getViewport().y !== currentViewport.y || getViewport().zoom !== currentViewport.zoom) {
                reactFlowInstance.setViewport(getViewport());
            }
        }
    }, [getViewport, reactFlowInstance]);

    const handleOnConnectStart = useCallback((evt, connection) => {
        setDidCompleteEdge(false);
        setStartEdgeNodeId(connection.nodeId);
    }, [setDidCompleteEdge, setStartEdgeNodeId]);

    const handleOnConnect = useCallback((connection) => {
        didCompleteEdge = true;
        setDidCompleteEdge(true);
        BehaviorTracker.trackInteraction({
            id: "connect-nodes-drag-line",
            mixpanel: "connect-nodes"
        });
        onConnect(connection);
    }, [onConnect]);

    const convertScreenCoordsToChartCoords = useCallback(function convertScreenCoordsToChartCoords(coords) {
        const chartCoords = chartWrapperElement.current.getBoundingClientRect();

        const relativeX = coords.x - chartCoords.x;
        const relativeY = coords.y - chartCoords.y;

        return reactFlowInstance.project({x: relativeX, y: relativeY});
    }, [chartWrapperElement, reactFlowInstance]);

    const determineIfCoordsIsNearNodeHandle = useCallback(function determineIfCoordsIsNearNodeHandle(coordinates) {
        for (let node of Object.values(nodes)) {
            const leftHandlePosition = {
                x: node.position.x,
                y: node.position.y + calculateHeightForNode(node) / 2,
            }
            const rightHandlePosition = {
                x: node.position.x + calculateWidthForNode(node),
                y: node.position.y + calculateHeightForNode(node) / 2,
            }

            const distToLeftHandle = Math.sqrt(Math.pow(leftHandlePosition.x - coordinates.x, 2) + Math.pow(leftHandlePosition.y - coordinates.y, 2));
            const distToRightHandle = Math.sqrt(Math.pow(rightHandlePosition.x - coordinates.x, 2) + Math.pow(rightHandlePosition.y - coordinates.y, 2));
            if (distToLeftHandle < (connectionRadius * 1.1) || distToRightHandle < (1.1 * connectionRadius)) {
                return true;
            }
        }

        return false;
    }, [nodes, calculateHeightForNode, calculateWidthForNode]);

    const doCoordsIntersectAnyNodes = useCallback(function doCoordsIntersectAnyNodes(coordinates) {
        for (let node of Object.values(nodes)) {
            // Determine if the coordinates lay inside the node bounding box
            if (coordinates.x >= node.position.x &&
                coordinates.x <= node.position.x + calculateWidthForNode(node) &&
                coordinates.y >= node.position.y &&
                coordinates.y <= node.position.y + calculateHeightForNode(node)) {
                return true;
            }
        }
        return false;
    }, [nodes, calculateHeightForNode, calculateWidthForNode]);

    const handleOnConnectEnd = useCallback((evt) => {
        if (!reactFlowInstance) {
            // Don't handle this event if react flow isn't loaded.
            return;
        }
        // Don't do anything, probably something is not initialized.
        if (!chartWrapperElement.current) {
            return null;
        }

        if (!didCompleteEdge) {
            const coordinates = convertScreenCoordsToChartCoords({
                x: evt.clientX,
                y: evt.clientY - (promptNodeInitialHeight / 2) * getViewport().zoom,
            });

            const newPromptNode = createPromptNode("<p></p>", [], coordinates.x, coordinates.y);
            addEdge(startEdgeNodeId, newPromptNode.id);

            BehaviorTracker.trackInteraction({
                id: "add-new-prompt-connection-line-empty-space",
                mixpanel: "add-new-prompt"
            });
        }
    }, [reactFlowInstance, didCompleteEdge, convertScreenCoordsToChartCoords, createPromptNode, addEdge, startEdgeNodeId, getViewport]);

    const handleOpenChartContextMenu = useCallback((evt) => {
        if (!reactFlowInstance) {
            // Don't handle this event if react flow isn't loaded.
            return;
        }
        // Don't do anything, probably something is not initialized.
        if (!chartWrapperElement.current) {
            return null;
        }

        // Do not open the context menu if the position
        // of the click is inside a node.
        if (doCoordsIntersectAnyNodes(convertScreenCoordsToChartCoords({
            x: evt.clientX,
            y: evt.clientY,
        }))) {
            return;
        }

        evt.preventDefault();
        setContextMenuPosition(
            contextMenuPosition === null
                ? {
                    mouseX: evt.clientX + 2,
                    mouseY: evt.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );

        setContextMenuType("chart");
    }, [reactFlowInstance, convertScreenCoordsToChartCoords, doCoordsIntersectAnyNodes]);

    const handleOnEdgeContextMenu = useCallback((evt, edge) => {
        evt.preventDefault();
        evt.stopPropagation();
        setContextMenuPosition(
            contextMenuPosition === null
                ? {
                    mouseX: evt.clientX + 2,
                    mouseY: evt.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );

        setContextMenuType("edge");
        setContextMenuEdge(edge);
    }, [setContextMenuPosition, contextMenuPosition]);

    const handleOnNodeContextMenu = useCallback((evt, node) => {
        evt.preventDefault();
        evt.stopPropagation();
        setContextMenuPosition(
            contextMenuPosition === null
                ? {
                    mouseX: evt.clientX + 2,
                    mouseY: evt.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );

        if (node.type === "inputGroupNode") {
            setContextMenuType("input");
        } else if (node.type === "outputNode") {
            setContextMenuType("output");
        } else {
            setContextMenuType("node");
        }
        setContextMenuNode(node);
    }, [setContextMenuPosition, contextMenuPosition]);

    const handleCloseContextMenu = useCallback(() => {
        setContextMenuPosition(null);
    }, [setContextMenuPosition]);

    const handleContextMenuAddNewPrompt = useCallback(() => {
        // Double check that there is a context menu position for us to put the prompt at.
        if (contextMenuPosition === null) {
            return;
        }
        // Don't do anything, probably something is not initialized.
        if (!chartWrapperElement.current) {
            return null;
        }

        BehaviorTracker.trackInteraction({
            id: "add-new-prompt-context-menu",
            mixpanel: "add-new-prompt"
        });

        const coordinates = convertScreenCoordsToChartCoords({
            x: contextMenuPosition.mouseX,
            y: contextMenuPosition.mouseY - (promptNodeInitialHeight / 2) * getViewport().zoom,
        })
        createPromptNode("<p></p>", [], coordinates.x, coordinates.y);
        setContextMenuPosition(null);
    }, [contextMenuPosition, convertScreenCoordsToChartCoords, createPromptNode, setContextMenuPosition, getViewport]);

    const handleContextMenuDeleteEdge = useCallback(() => {
        setContextMenuPosition(null);
        deleteEdge(contextMenuEdge.id);

        BehaviorTracker.trackInteraction({
            id: "delete-connection-context-menu",
            mixpanel: "delete-connection"
        });
    }, [contextMenuEdge, deleteEdge]);

    const handleContextMenuDeleteNodeClicked = useCallback(() => {
        setContextMenuPosition(null);
        deleteNode(contextMenuNode.id);

        BehaviorTracker.trackInteraction({
            id: "delete-node-context-menu",
            mixpanel: "delete-node"
        });
    }, [contextMenuNode, deleteNode]);

    const handleNewTextInputNodeClicked = useCallback(() => {
        setContextMenuPosition(null);
        createTextInputNode("", "");

        BehaviorTracker.trackInteraction({
            id: "create-text-input-node",
            mixpanel: null
        });
    }, [createTextInputNode]);

    const handleNewFileInputNodeClicked = useCallback(() => {
        setContextMenuPosition(null);
        createFileInputNode("", "");

        BehaviorTracker.trackInteraction({
            id: "copy-file-input-node",
            mixpanel: null
        });
    }, [createFileInputNode]);

    const handleCopyContentsClicked = useCallback(() => {
        let contents = contextMenuNode.data.currentValues.join("\n\n");
        if (contextMenuNode.type === "textInputNode") {
            contents = contextMenuNode.data.prompt;
        }

        if (process.env.REACT_APP_PROMPT_CHART_ENABLE_CLIPBOARD !== "false") {
            navigator.clipboard.writeText(contents);
        }
        snackbar.toast({
            message: `Copied contents to clipboard`,
            severity: "success",
            autoHideMs: 5000
        });
        setContextMenuPosition(null);

        BehaviorTracker.trackInteraction({
            id: "copy-contents-context-menu",
            mixpanel: null
        });
    }, [contextMenuNode, snackbar]);

    const handleOnMove = useMemo(() => _.debounce((evt, newViewport) => {
        setViewport(newViewport);

        if (newViewport.zoom !== getViewport().zoom) {
            BehaviorTracker.trackInteraction({
                id: "zoom-chart",
                mixpanel: null
            });
        }

        if (newViewport.x !== getViewport().x || newViewport.y !== getViewport().y) {
            BehaviorTracker.trackInteraction({
                id: "pan-chart",
                mixpanel: null
            });
        }
    }, 500), [setViewport, getViewport]);

    const handleAddFirstPromptClicked = useCallback(() => {
        // Don't do anything, probably something is not initialized.
        if (!chartWrapperElement.current) {
            return null;
        }

        // Compute the pixel x,y coordinates for the center of the chart-editor div
        const chartWrapperElementRect = chartWrapperElement.current.getBoundingClientRect();
        const centerX = chartWrapperElementRect.x + chartWrapperElementRect.width / 2;
        const centerY = chartWrapperElementRect.y + chartWrapperElementRect.height / 2;

        // Project the pixel x,y coordinates into graph coordinates
        // const centerCoords = reactFlowInstance.project({x: centerX, y: centerY});
        const centerCoords = convertScreenCoordsToChartCoords({x: centerX, y: centerY});

        const newPromptX = centerCoords.x - 300;
        const newPromptY = centerCoords.y - promptNodeInitialHeight / 2;

        createPromptNode("<p></p>", [], newPromptX, newPromptY);

        BehaviorTracker.trackInteraction({
            id: "add-first-prompt-button",
            mixpanel: null
        });
    }, [createPromptNode, convertScreenCoordsToChartCoords, reactFlowInstance, chartWrapperElement]);

    const edgesWithMarker = useMemo(() => _.map(Object.values(edges), (edge) => {
        const sourceNode = getNode(edge.source);

        const multi = (sourceNode?.data?.currentValues?.length ?? 0) > 1;

        return {
            ...edge,
            animated: false,
            markerEnd: {
                type: 'arrowclosed',
                width: 30,
                height: 30,
                color: "grey",
                strokeWidth: 2.5,
            },
            data: {
                multi,
            },
            label: multi ? `${sourceNode.data.currentValues.length} datapoints` : null,
        };
    }), [edges, getNode]);


    const nodeCount = Object.keys(nodes).length;
    const nodeList = useMemo(() => {
        // We assemble the node list so that the input group node is always first, therefore
        // ensuring that it gets rendered underneath everything else.
        const textInputNode = nodes['input'];
        const remainingNodes = _.omit(nodes, 'input');
        let nodeList = [];
        if (textInputNode) {
            nodeList = [textInputNode];
        }
        nodeList = nodeList.concat(Object.values(remainingNodes));
        return nodeList;
    }, [nodeCount, nodes]) // DO NOT REMOVE THE NODE COUNT DEPENDENCY. IT IS NECESSARY TO TRIGGER A RERENDER WHEN NODES ARE ADDED/REMOVED

    if (is_loading_new_chart) {
        return <div className={"loading-new-chart"}>
            <div className={"loading-new-chart-text-area"}>
                <span>Loading...</span>
                <CircularProgress />
            </div>
        </div>
    }

    return (
        <div id={"chart-editor"}
             className={"chart-editor"}
             ref={chartWrapperElement}
             onContextMenu={enableContextMenu ? handleOpenChartContextMenu : () => null}
        >
            <TemplateSampleValuesCacheProvider>
                <ReactFlow
                    nodes={nodeList}
                    edges={edgesWithMarker}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    nodeTypes={nodeTypesMemoized}
                    edgeTypes={edgeTypesMemoized}
                    isValidConnection={isValidConnection}
                    connectionRadius={connectionRadius}
                    defaultViewport={{x: 0, y: 0, zoom: 0.65}}
                    minZoom={chartMinZoom}
                    maxZoom={chartMaxZoom}
                    onMove={handleOnMove}
                    onInit={handleOnInit}
                    onConnectStart={handleOnConnectStart}
                    onConnect={handleOnConnect}
                    onConnectEnd={handleOnConnectEnd}
                    onEdgeContextMenu={enableContextMenu ? handleOnEdgeContextMenu : () => null}
                    onNodeContextMenu={enableContextMenu ? handleOnNodeContextMenu : () => null}
                    onlyRenderVisibleElements={false}
                >
                    <Background
                        variant={"dots"}
                        size={3}
                        color={"#bebecc"}
                    />
                    <Controls />
                    <MiniMap
                        nodeColor={nodeColor}
                        nodeStrokeWidth={3}
                        zoomable
                        pannable
                        ariaLabel={"Mini Map"}
                        position={"top-right"}
                    />
                    {
                        Object.keys(nodes).length === 0 ?
                            <Panel position="bottom-center">
                                <Card className={"add-first-prompt-area"}>
                                    <CardContent>
                                        <Button
                                            className={"add-first-prompt-button"}
                                            variant="contained"
                                            color="primary"
                                            onClick={handleAddFirstPromptClicked}
                                        >
                                            Add a prompt to get started
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Panel>
                        : null
                    }
                    <Panel position={'top-center'}>
                        <Card className={"chart-title-editor-wrapper-card"}>
                            <CardContent>
                                <ChartTitleEditor />
                            </CardContent>
                        </Card>
                    </Panel>
                </ReactFlow>
                <ChartContextMenu
                    contextMenu={contextMenuType === 'chart' ? contextMenuPosition : null}
                    handleClose={handleCloseContextMenu}
                    handleAddNewPrompt={handleContextMenuAddNewPrompt}
                />
                <EdgeContextMenu
                    contextMenu={contextMenuType === 'edge' ? contextMenuPosition : null}
                    handleClose={handleCloseContextMenu}
                    handleDeleteEdge={handleContextMenuDeleteEdge}
                />
                <PromptNodeContextMenu
                    node={contextMenuNode}
                    contextMenu={contextMenuType === 'node' ? contextMenuPosition : null}
                    handleClose={handleCloseContextMenu}
                    handleDeleteNodeClicked={handleContextMenuDeleteNodeClicked}
                    handleCopyContentsClicked={handleCopyContentsClicked}
                />
                <InputGroupNodeContextMenu
                    contextMenu={contextMenuType === 'input' ? contextMenuPosition : null}
                    handleClose={handleCloseContextMenu}
                    handleNewTextInputNodeClicked={handleNewTextInputNodeClicked}
                    handleNewFileInputNodeClicked={handleNewFileInputNodeClicked}
                />
                <OutputNodeContextMenu
                    contextMenu={contextMenuType === 'output' ? contextMenuPosition : null}
                    handleClose={handleCloseContextMenu}
                    handleCopyContentsClicked={handleCopyContentsClicked}
                />

                {/*<TipsWidget*/}
                {/*    show={!is_loading_new_chart}*/}
                {/*/>*/}
            </TemplateSampleValuesCacheProvider>
        </div>
    );
};


