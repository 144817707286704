import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardContent } from "@mui/material";
import "./CustomFormEditPage.scss";
import { useAPI } from "../../../frontend/components/APIProvider";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import CustomFormForm from "./CustomFormForm";
import CircularProgress from "@mui/material/CircularProgress";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";

export default function CustomFormEditPage(props) {
  const api = useAPI();
  const params = useParams();

  const navigate = useNavigate();
  const [customForm, setCustomForm] = useState(null);

  const handleCustomFormSave = useCallback(
    async (newCustomForm) => {
      return await api.saveCustomForm(newCustomForm);
    },
    [api]
  );

  const handleDeleteClicked = useCallback(async () => {
    return await api.deleteCustomForm(customForm.id).then(() => {
      navigate(`/custom_form`);
    });
  }, [navigate, api, customForm?.id]);

  React.useEffect(() => {
    if (!customForm) {
      api
        .getCustomForm(params?.customFormId)
        .then((customForm) => {
          setCustomForm(customForm);
        });
    }
  }, [
    handleCustomFormSave,
    customForm,
    params?.customFormId,
    api,
  ]);

  if (!customForm) {
    return (
      <PageWrapperWithMenu>
        <PageHeader
          title={
            <LocalTranslatedText
              language={"en"}
              text='Edit Custom Form'
            />
          }
        />
        <div className='custom-form-edit-page'>
          <CircularProgress />
        </div>
      </PageWrapperWithMenu>
    );
  }

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={
          <LocalTranslatedText language={"en"} text='Edit Custom Form' />
        }
      />
      <div className='custom-form-edit-page'>
        <Card className='custom-form-edit-form-card'>
          <CardContent>
            <CustomFormForm
              initialCustomForm={customForm}
              onCustomFormSave={handleCustomFormSave}
              onDeletedClicked={handleDeleteClicked}
            />
          </CardContent>
        </Card>
      </div>
    </PageWrapperWithMenu>
  );
}
