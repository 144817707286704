import React, {useState} from 'react';
import {useEffect} from 'react';
import "./MonitoredGroupSelector.scss";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {useAPI} from "../../frontend/components/APIProvider";
import CircularProgress from "@mui/material/CircularProgress";
import {Typography} from "@mui/material";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";
import Checkbox from "@mui/material/Checkbox";
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

export function MonitoredGroupSelector({wa2ChatConfiguration, onChange}) {
    const [availableGroups, setAvailableGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const api = useAPI();
    useEffect(() => {
        setIsLoading(true);
        api.getJoinedGroups(wa2ChatConfiguration.bot_number).then((data) => {
            setIsLoading(false);
            setAvailableGroups(data.joined_groups);
            setSelectedGroups(wa2ChatConfiguration.monitored_groups)
        }, (error) => {
            setIsLoading(false);
            console.log(error);
        });

    }, [api]);

    const handleCheckboxChange = (value) => {
        setSelectedGroups(prev => {
            const newSelectedGroups = prev.includes(value)
                ? prev.filter(item => item !== value)
                : [...prev, value];
            
            // Call onChange with the updated state
            onChange(newSelectedGroups);
            return newSelectedGroups;
        });
    };

    return (
        <div className={"wa-groups-selector"}>
            <Typography variant={'p'}>
                <LocalTranslatedText language={"en"}
                    text="Please select which groups the bot should actively participate in."/>
            </Typography>
            {
                isLoading ? <CircularProgress/> : null
            }
            <List dense sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                {availableGroups !== null && availableGroups.length > 0 ?
                    availableGroups.map((group) => {
                        return (
                            <ListItem
                                key={group.uuid}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        checked={selectedGroups.includes(group.uuid)}
                                        value={group.uuid}
                                        onChange={() => handleCheckboxChange(group.uuid)}
                                    />
                                }
                                disablePadding
                            >
                                <ListItemButton>
                                    <ListItemAvatar>
                                        <Avatar alt={group.wa_group_name} src={group.profile_pic_url} sx={{ width: 64, height: 64 }}/>
                                    </ListItemAvatar>
                                    &nbsp;&nbsp;
                                    <ListItemText
                                        primary={
                                            <Typography variant="h6" component="span">
                                                {group.wa_group_name}
                                            </Typography>
                                        }
                                        secondary={
                                            <Typography variant="body2" color="textSecondary">
                                                &nbsp;{group.wa_subject}
                                            </Typography>
                                        }
                                    />
                                </ListItemButton>
                            </ListItem>
                        )
                    })
                    : null
                }
            </List>
            <br/>
        </div>
    );
};
