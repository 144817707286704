import "./CalendarListPage.scss";
import React, { useCallback } from "react";
import { CalendarsTable } from "./CalendarsTable";
import { PageHeader } from "../../../../core/frontend/elements/PageHeader";
import { PageWrapperWithMenu } from "../../../../core/frontend/elements/PageWrapperWithMenu";
import { WhiteButton } from "../../../../core/frontend/elements/WhiteButton";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";

export default function CalendarListPage() {
  const navigate = useNavigate();

  const handleNewCalendarClicked = useCallback(() => {
    navigate(`/calendars/new`);
  }, [navigate]);

  const handleCalendarClicked = useCallback(
    (calendar) => {
      navigate(`/calendars/${calendar.id}`);
    },
    [navigate]
  );

  return (
    <PageWrapperWithMenu>
      <PageHeader title={"Calendars"}>
        <WhiteButton onClick={handleNewCalendarClicked}>
          Add New Calendar
        </WhiteButton>
      </PageHeader>

      <div className={"calendars-list-page"}>
        <Paper className={"main-page-contents"}>
          <CalendarsTable onCalendarClicked={handleCalendarClicked} />
        </Paper>
      </div>
    </PageWrapperWithMenu>
  );
}
