import { Button, Grid } from "@mui/material";
import React from "react";
import RHFTextField from "../../../../frontend/elements/form/hook-form/RHFTextField";
import "./EditIntakeForm.scss";
import RHFCheckboxField from "../../../../frontend/elements/form/hook-form/RHFCheckboxField";
import { useFieldArray, useFormContext } from "react-hook-form";
import DataPointsContainer from "./DataPointsContainer";
import { dataPointsInitialValues } from "../../IntakeSchema";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomAccordion from "../../../../frontend/elements/CustomAccordion";
import AddIcon from "@mui/icons-material/Add";

export default function QuestionsContainer({
  questionIndex,
  removeQuestionField,
  isDisabled,
  onInserQuestionField,
}) {
  const { control } = useFormContext();

  const {
    fields: dataPointsFields,
    append: appendDataPointField,
    remove: removeDataPointField,
  } = useFieldArray({
    name: `questions.${questionIndex}.data_points`,
    control,
  });

  return (
    <div className='card'>
      <CustomAccordion
        key={questionIndex}
        title={`Question ${questionIndex + 1}`}
      >
        <div className='detail-box'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RHFTextField
                name={`questions.${questionIndex}.question_text`}
                placeholder='Enter the question...'
                label='Question'
              />
            </Grid>
            <Grid item xs={6}>
              <RHFCheckboxField
                name={`questions.${questionIndex}.is_required`}
                label='Check if this question is required'
              />
            </Grid>
            <Grid item xs={12}>
              <div className='box'>
                {dataPointsFields.map((dataPoint, index) => (
                  <DataPointsContainer
                    questionIndex={questionIndex}
                    key={dataPoint.id}
                    dataPointIndex={index}
                    removeDataPointField={removeDataPointField}
                    isDisabled={dataPointsFields.length === 1}
                  />
                ))}
                <div className='box row indent'>
                  <Button
                    type='button'
                    onClick={() =>
                      appendDataPointField(dataPointsInitialValues)
                    }
                    variant='outlined'
                    color='secondary'
                    startIcon={<AddIcon />}
                  >
                    Add Data Point
                  </Button>
                  <Button
                    type='button'
                    onClick={() => removeQuestionField(questionIndex)}
                    variant='outlined'
                    color='error'
                    startIcon={<DeleteIcon />}
                    disabled={isDisabled}
                  >
                    Remove Question
                  </Button>
                </div>
              </div>
              <Button
                type='button'
                onClick={() => onInserQuestionField(questionIndex)}
                variant='outlined'
                color='info'
                startIcon={<AddIcon />}
                sx={{ mt: 2 }}
              >
                Add a question under question {questionIndex + 1}
              </Button>
            </Grid>
          </Grid>
        </div>
      </CustomAccordion>
    </div>
  );
}
