import React, {useCallback, useState} from "react";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useNavigate} from "react-router-dom";
import BugReportIcon from '@mui/icons-material/BugReport';

export default function DebugStoryButton({story}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleDebugStoryClicked = useCallback((evt) => {
        setAnchorEl(evt.currentTarget);
    }, [setAnchorEl]);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const handleViewStoryChart = useCallback(() => {
        navigate(`/single_chart_evaluation/${story.story_chart_evaluation_id}`);
        setAnchorEl(null);
    }, [story.story_chart_evaluation_id, navigate]);

    const handleViewPageChart = useCallback((page) => {
        navigate(`/single_chart_evaluation/${page.page_chart_evaluation_id}`);
        setAnchorEl(null);
    })

    return <>
        <Button variant="contained" color="success" type="submit" onClick={handleDebugStoryClicked} startIcon={<BugReportIcon />}>
            Debug Story
        </Button>

        <Menu
            id="debug-story-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <MenuItem onClick={handleViewStoryChart}>View Story Chart</MenuItem>
            {
                story.pages.map((page, pageIndex) => {
                    return <MenuItem onClick={() => handleViewPageChart(page)}>View Page Chart for Page #{pageIndex + 1}</MenuItem>
                })
            }
        </Menu>
    </>
}

