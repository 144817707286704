import React, {useState, useCallback, useMemo} from 'react';
import {Select, MenuItem, TextField} from '@mui/material';
import {StoryFormWidgetContainer} from "./StoryFormWidgetContainer";
import "./MainCharacterGenderSelector.scss";
import _ from "lodash";

export const MainCharacterGenderSelector = ({value, onChange, mainCharacterType}) => {
    const allowedOptions = ['Male', 'Female', 'Custom'];

    let initialSelected = value;
    let initialCustom = "";
    // Fill in a default value if none is provided
    if (!initialSelected) {
        initialSelected = 'Male';
    }
    // If the value isn't in the list of allowed values, we set gender
    // to "Custom" allowing the user to type in their own value
    if (!allowedOptions.includes(initialSelected)) {
        initialSelected = 'Custom';
        initialCustom = value;
    }

    const onChangeDebounced = useMemo(() => _.debounce(onChange, 500), [onChange]);

    const [selectedGender, setSelectedGender] = useState(initialSelected);
    const [customGender, setCustomGender] = useState(initialCustom);

    const handleSelectChange = useCallback((event) => {
        setSelectedGender(event.target.value);
        onChange(event.target.value);
    }, [onChange]);

    const handleTextChange = useCallback((event) => {
        setCustomGender(event.target.value);
        onChangeDebounced(event.target.value);
    }, [onChangeDebounced]);

    let label = "Main Character Gender";
    if (mainCharacterType === "your pet") {
        label = "Your Pets Gender";
    } else if (mainCharacterType === "your kid") {
        label = "Your Kid's Gender";
    }

    return (
        <StoryFormWidgetContainer label={label} className={"main-character-gender-selector"}>
            <Select
                labelId="gender-label"
                id="gender-select"
                value={selectedGender}
                onChange={handleSelectChange}
            >
                {
                    allowedOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))
                }
            </Select>
            {selectedGender === 'Custom' && (
                <TextField
                    id="other-gender"
                    label="Custom Gender"
                    onChange={handleTextChange}
                    value={customGender}
                />
            )}
        </StoryFormWidgetContainer>
    );
};