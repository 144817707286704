import { Button, Grid } from "@mui/material";
import React from "react";
import RHFTextField from "../../../../frontend/elements/form/hook-form/RHFTextField";
import "./EditIntakeForm.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomAccordion from "../../../../frontend/elements/CustomAccordion";

export default function DataPointsContainer({
  questionIndex,
  dataPointIndex,
  removeDataPointField,
  isDisabled,
}) {
  return (
    <div className='card indent'>
      <CustomAccordion
        key={questionIndex}
        title={`Data Point ${dataPointIndex + 1}`}
        smallTitle
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RHFTextField
              name={`questions.${questionIndex}.data_points.${dataPointIndex}.data_point`}
              placeholder='Enter the data point...'
              label='Data Point'
            />
          </Grid>
          <Grid item xs={12}>
            <RHFTextField
              label='Description'
              name={`questions.${questionIndex}.data_points.${dataPointIndex}.description`}
              placeholder='Enter the description of the data point...'
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <div className='box row'>
              <Button
                type='button'
                onClick={() => removeDataPointField(dataPointIndex)}
                variant='outlined'
                color='error'
                startIcon={<DeleteIcon />}
                disabled={isDisabled}
              >
                Remove Data Point
              </Button>
            </div>
          </Grid>
        </Grid>
      </CustomAccordion>
    </div>
  );
}
