import React, {useCallback} from 'react';
import {useAPI, useIsAuthenticated} from "../../../../core/frontend/components/APIProvider";
import "./SelectGoogleCalendarsToSynchronize.scss";
import {FormControlLabel, FormGroup} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import LocalTranslatedText from "../../../../core/translation/frontend/components/LocalTranslatedText";

export function SelectGoogleCalendarsToSynchronize({selectedCalendars, onChange, calendarId}) {
    const isAuthenticated = useIsAuthenticated();
    const api = useAPI();
    const [availableGoogleCalendars, setAvailableGoogleCalendars] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    const getAvailableGoogleCalendars = useCallback(async () => {
        setIsLoading(true);
        const result = await api.getAvailableGoogleCalendars(calendarId);
        setAvailableGoogleCalendars(result);
        setIsLoading(false);
    }, [api, calendarId]);

    const handleCalendarSelectionChanged = useCallback((calendar) => {
        // See if the scheduling id is already in the list
        const index = selectedCalendars.findIndex((selectedCalendarId) => selectedCalendarId === calendar.id);
        if (index !== -1) {
            // Remove the scheduling
            const newCalendars = selectedCalendars.filter((selectedCalendarId) => selectedCalendarId !== calendar.id);
            onChange(newCalendars);
        } else {
            // Add the scheduling
            const newCalendars = [...selectedCalendars, calendar.id];
            onChange(newCalendars);
        }
    }, [selectedCalendars, onChange]);

    React.useEffect(() => {
        if (isAuthenticated) {
            getAvailableGoogleCalendars(calendarId);
        }
    }, [getAvailableGoogleCalendars, api, isAuthenticated, calendarId]);


    return (
        <div className={"select-google-calendars-to-synchronize-widget"}>
            <FormGroup>
                {
                    isLoading ? <CircularProgress /> : null
                }
                {
                    availableGoogleCalendars.length === 0 && !isLoading ? <Typography>
                        <LocalTranslatedText language={"en"} text="No calendars found"/>
                    </Typography> : null
                }
                {
                    availableGoogleCalendars.map((calendar) => {
                        return <FormControlLabel
                            control={<Checkbox/>}
                            label={calendar.summary}
                            key={calendar.id}
                            onChange={() => handleCalendarSelectionChanged(calendar)}
                            checked={selectedCalendars.includes(calendar.id)}
                        />
                    }, [])
                }
            </FormGroup>
        </div>
    );
}