import { PageWrapperWithMenu } from "../../../core/frontend/elements/PageWrapperWithMenu";
import React from "react";
import "../../../core/frontend/pages/HomePage.scss";
import { useNavigate } from "react-router-dom";
import { useAPI } from "../../../core/frontend/components/APIProvider";
import { CustomersTable } from "./CustomersTable";
import "./CustomersListPage.scss";
import { PageHeader } from "../../../core/frontend/elements/PageHeader";
import { WhiteButton } from "../../../core/frontend/elements/WhiteButton";
import Paper from "@mui/material/Paper";
import { useCallback } from "react";

export default function CustomersListPage() {
  const api = useAPI();
  const navigate = useNavigate();

  const handleNewCustomerClicked = useCallback(async () => {
    navigate(`/customers/new`);
  }, [navigate]);

  const handleDownloadAllCustomerDataClicked = useCallback(async () => {
    const csvExport = await api.getCustomerCSVExport();
    const blob = new Blob([csvExport], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "customers.csv";
    a.click();
    URL.revokeObjectURL(url);
  }, [api]);

  return (
    <PageWrapperWithMenu>
      <PageHeader title={"Customers"}>
        <WhiteButton onClick={handleNewCustomerClicked}>
          Add New Customer
        </WhiteButton>
        <WhiteButton onClick={handleDownloadAllCustomerDataClicked}>
          Download All Customer Data in CSV
        </WhiteButton>
      </PageHeader>
      <div className={"customer-list-page"}>
        <div className={"customer-table-wrapper"}>
          <Paper className={"main-page-contents"}>
            <CustomersTable />
          </Paper>
        </div>
      </div>
    </PageWrapperWithMenu>
  );
}
