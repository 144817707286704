import * as React from 'react';
import {useCallback, useMemo} from "react";
import {useAPI} from "../../../core/frontend/components/APIProvider";
import TableElementLookupSelectorInput from "../../../core/frontend/elements/form/inputs/TableElementLookupSelectorInput";


export default function TranscriptProcessorConfigurationSelector({label, fieldName}) {
    const api = useAPI();

    const getSuggestions = useCallback((tableQuery) => {
        return api.getTranscriptProcessorConfigurations(tableQuery);
    }, [api]);

    return <TableElementLookupSelectorInput
        id="transcript-processor-configuration-selector"
        label={label}
        fieldName={fieldName}
        getSuggestions={getSuggestions}
        getSingleItem={api.getTranscriptProcessorConfiguration}
        searchFieldName={'title'}
        displayFieldName={'title'}
        multiple={false}
    />
}