import React from "react";
import FormInput from "../../elements/form/FormInput";
import FormSection from "../../elements/form/FormSection";
import FormSubFieldRoot from "../../elements/form/FormSubFieldRoot";

export default function GCPStorageConfigurationForm() {
    return (
        <FormSection
            title={"GCS Storage Configuration"}
            subtitle={"Configuration for the Google Cloud Storage buckets for this tenant"}
            collapsable={true}
        >
            <FormSubFieldRoot field={"gcp_storage"}>
                <FormInput
                    type={"text"}
                    label={"File Storage Bucket"}
                    fieldName={"file_storage_bucket"}
                    placeholder={"The GCS bucket used to store files or data that is provided from end-users"}
                />
                <FormInput
                    type={"text"}
                    label={"Model Storage Bucket"}
                    fieldName={"model_storage_bucket"}
                    placeholder={"The GCS bucket used to store internally trained models"}
                />
            </FormSubFieldRoot>
        </FormSection>
    );
}