import React from "react";
import FormInput from "../../elements/form/FormInput";
import FormSection from "../../elements/form/FormSection";
import FormSubFieldRoot from "../../elements/form/FormSubFieldRoot";


export default function GoogleGeminiConfigurationForm() {
    return (
        <FormSection
            title={"Google Gemini Integration"}
            subtitle={"Configuration for the Google Gemini API Integration for this tenant"}
            collapsable={true}
        >
            <FormSubFieldRoot field={"google_gemini"}>
                <FormInput
                    type={"text"}
                    label={"API Key"}
                    fieldName={"api_key"}
                    placeholder={"The API key used to connect to the Google Gemini API"}
                />
            </FormSubFieldRoot>
        </FormSection>
    );
}
