import FormControl from "@mui/material/FormControl";
import React, {useCallback} from "react";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import countryData from "../data/supported_countries_for_phone_numbers.json";
import "./CountrySelectorForAvailablePhoneNumbers.scss";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";

countryData.countries.sort((a, b) => {
    return a.name.localeCompare(b.name);
}, []);

export function CountrySelectorForAvailablePhoneNumbers({
                                                            label,
                                                            name,
                                                            placeholder,
                                                            emptyLabel,
                                                            disabled,
                                                            onChange,
                                                            value,
                                                            allowNone=true
                                                        }) {
    const options = countryData.countries.map(country => country.code);
    const optionLabels = countryData.countries.map(country =>
        <div className={"country-selector-for-available-phone-numbers-option-label"}>
            <div className={"phone-country-code"}>
                +{country.phone_country_code}
            </div>
            <div>
                <LocalTranslatedText language={"en"} text={country.name} />
            </div>
        </div>
    );

    placeholder = placeholder ?? "Select a country";

    const handleChange = useCallback((event) => {
        onChange(event.target.value);
    }, [onChange]);

    let valueLocal = value ?? '';

    return <FormControl fullWidth={true} className={"country-selector-for-available-phone-numbers"}>
        {
            label ? <Typography variant={'label'}>{label}</Typography> : null
        }
        <Select
            variant={"outlined"}
            value={disabled ? "" : valueLocal}
            name={name}
            fullWidth={true}
            onChange={handleChange}
            disabled={disabled}
            displayEmpty={true}
            renderValue={(value) => value ? optionLabels[options.indexOf(value)] :
                <span className={"placeholder"}>
                    <LocalTranslatedText language={"en"} text={placeholder} />
                </span>}
        >
            {allowNone && (
                <MenuItem value={""}>{emptyLabel ?? "None"}</MenuItem>
            )}
            {
                options.map((optionStr, optionIndex) =>
                    <MenuItem value={optionStr} key={optionStr}>
                        <LocalTranslatedText language={"en"} text={optionLabels[optionIndex]} />
                    </MenuItem>
                )
            }
        </Select>
    </FormControl>;
}

export default CountrySelectorForAvailablePhoneNumbers;

