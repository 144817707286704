import {PageWrapperWithMenu} from "../../../core/frontend/elements/PageWrapperWithMenu";
import React, {useCallback, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Card, CardContent} from "@mui/material";
import {useAPI} from "../../../core/frontend/components/APIProvider";
import {PageHeader} from "../../../core/frontend/elements/PageHeader";
import CircularProgress from "@mui/material/CircularProgress";
import {SpinnerButton} from "../../../core/frontend/elements/SpinnerButton";
import SchemaIcon from '@mui/icons-material/Schema';
import "./ViewGeneratedContentPage.scss";
import GeneratedContentViewer from "./GeneratedContentViewer";


export default function ViewGeneratedContentPage(props) {
    const api = useAPI();
    const params = useParams();
    const [generatedContent, setGeneratedContent] = useState(null);

    React.useEffect(() => {
        if (!generatedContent) {
            api.getGeneratedContent(params?.generatedContentId).then((generatedContent) => {
                setGeneratedContent(generatedContent);
            });
        }
    }, [generatedContent, params?.generatedContentId, api]);

    if (!generatedContent) {
        return <PageWrapperWithMenu>
            <PageHeader title={`View Generated Content`}/>
            <div className="view-generated-content-page">
                <CircularProgress/>
            </div>
        </PageWrapperWithMenu>
    }

    return <PageWrapperWithMenu>
        <PageHeader title={`View Generated Content`}/>
        <div className="view-generated-content-page">
            <Card className="view-generated-content-form-card">
                <CardContent>
                    <GeneratedContentViewer
                        generatedContent={generatedContent}
                    />
                </CardContent>
            </Card>
        </div>

    </PageWrapperWithMenu>
}
