import * as React from 'react';
import {useCallback} from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import CasinoIcon from '@mui/icons-material/Casino';
import "./TemperatureSlider.css";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";

export function TemperatureSlider({value, onChange}) {
    const minTemperature = 0.1;
    const maxTemperature = 1.5;
    const temperatureStep = 0.1;

    const handleSliderChange = useCallback((event, newValue) => {
        onChange(newValue);
    }, [onChange]);

    const handleInputChange = useCallback((event) => {
        onChange(event.target.value === '' ? 0 : Number(event.target.value));
    }, [onChange]);

    const handleBlur = useCallback(() => {
        if (value < minTemperature) {
            onChange(minTemperature);
        } else if (value > maxTemperature) {
            onChange(maxTemperature);
        }
    }, [value, onChange]);

    return (
        <Box sx={{width: 500}} className={"temperature-slider"}>
            <Typography id="input-slider" gutterBottom>
                <LocalTranslatedText language={"en"} text="Randomness (Temperature)" />
            </Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <CasinoIcon />
                </Grid>
                <Grid item xs>
                    <Slider
                        value={typeof value === 'number' ? value : 0}
                        onChange={handleSliderChange}
                        aria-labelledby="input-slider"
                        min={minTemperature}
                        max={maxTemperature}
                        step={temperatureStep}
                    />
                </Grid>
                <Grid item>
                    <MuiInput
                        value={value}
                        size="small"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        inputProps={{
                            step: temperatureStep,
                            min: minTemperature,
                            max: maxTemperature,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
