import React from "react";
import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import { Paper } from "@mui/material";
import FilledIntakeTable from "./FilledIntakeTable";

export default function IntakeListPage() {
  return (
    <PageWrapperWithMenu>
      <PageHeader title='Filled Intakes' />
      <Paper elevation={4}>
        <FilledIntakeTable />
      </Paper>
    </PageWrapperWithMenu>
  );
}
