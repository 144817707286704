


export const promptNodeInitialHeight = 200;
export const promptNodeWidth = 500;

export const textInputNodeHeight = 125;
export const textInputNodeWidth = 360;


export const fileInputNodeHeight = 450;
export const fileInputNodeWidth = 360;

export const outputNodeInitialHeight = 400;
export const outputNodeWidth = 500;
