import {PageWrapperWithMenu} from "../../../frontend/elements/PageWrapperWithMenu";
import React, {useCallback, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Card, CardContent} from "@mui/material";
import "./GeneratedDocumentEditPage.scss";
import {useAPI} from "../../../frontend/components/APIProvider";
import {PageHeader} from "../../../frontend/elements/PageHeader";
import GeneratedDocumentForm from "./GeneratedDocumentForm";
import CircularProgress from "@mui/material/CircularProgress";


export default function GeneratedDocumentEditPage() {
    const api = useAPI();
    const params = useParams();

    const navigate = useNavigate();
    const [generatedDocument, setGeneratedDocument] = useState(null);

    const handleGeneratedDocumentSave = useCallback(async (newGeneratedDocument) => {
        return await api.saveGeneratedDocument(newGeneratedDocument);
    }, [api]);

    const handleDeleteClicked = useCallback(async () => {
        return await api.deleteGeneratedDocument(generatedDocument.id).then(() => {
            navigate(`/generated_document`);
        })
    }, [navigate, api, generatedDocument?.id]);

    const handleRenderClicked = useCallback(async () => {
        return await api.renderGeneratedDocument(generatedDocument.id).then((pdfData) => {
            const blob = new Blob([pdfData], {type: 'application/pdf'});
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${generatedDocument.title}.pdf`;
            a.click();
            URL.revokeObjectURL(url);
        })
    }, [api, generatedDocument?._id, generatedDocument?.title]);

    React.useEffect(() => {
        if (!generatedDocument) {
            api.getGeneratedDocument(params?.generatedDocumentId).then((generatedDocument) => {
                setGeneratedDocument(generatedDocument);
            });
        }
    }, [handleGeneratedDocumentSave, generatedDocument, params?.generatedDocumentId, api]);

    if (!generatedDocument) {
        return <PageWrapperWithMenu>
            <PageHeader title={`Edit Generated Document`}/>
            <div className="generated-document-edit-page">
                <CircularProgress/>
            </div>
        </PageWrapperWithMenu>
    }

    return <PageWrapperWithMenu>
        <PageHeader title={`Edit Generated Document`}/>
        <div className="generated-document-edit-page">
            <Card className="generated-document-edit-form-card">
                <CardContent>
                    <GeneratedDocumentForm
                        initialGeneratedDocument={generatedDocument}
                        onGeneratedDocumentSave={handleGeneratedDocumentSave}
                        onDeletedClicked={handleDeleteClicked}
                        onRenderClicked={handleRenderClicked}
                    />
                </CardContent>
            </Card>
        </div>

    </PageWrapperWithMenu>
}