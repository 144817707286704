import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function IndeterminateLoadingBar({halfLifeSeconds}) {
  const [progress, setProgress] = React.useState(0);

  if (!halfLifeSeconds) {
    halfLifeSeconds = 30;
  }
  const updateFrequencySeconds = 0.5;
  const decayRate = updateFrequencySeconds / halfLifeSeconds;

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 0) {
          return 1;
        }
        const diff = (100 - oldProgress) * (Math.random() * 2 * decayRate);
        return Math.min(oldProgress + diff, 100);
      });
    }, updateFrequencySeconds * 1000);

    return () => {
      clearInterval(timer);
    };
  }, [decayRate]);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  );
}