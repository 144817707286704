import React from "react";
import { TextInput } from "./TextInput";

export function AddressInput({ label, fieldName, size }) {
  return (
    <TextInput
      fieldName={fieldName}
      label={label}
      example={"123 Main Street, Anytown, USA 12345"}
      size={size}
    />
  );
}

export default AddressInput;
