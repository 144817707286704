import {PageWrapperWithMenu} from "../../core/frontend/elements/PageWrapperWithMenu";
import React, {useCallback, useState} from "react";
import "../../core/frontend/pages/HomePage.scss";
import {useNavigate, useParams} from "react-router-dom";
import {Card, CardContent} from "@mui/material";
import "./ContactEditPage.scss";
import {useAPI} from "../../core/frontend/components/APIProvider";
import {PageHeader} from "../../core/frontend/elements/PageHeader";
import ContactForm from "./ContactForm";
import CircularProgress from "@mui/material/CircularProgress";


export default function ContactEditPage(props) {
    const api = useAPI();
    const params = useParams();

    const navigate = useNavigate();
    const [contact, setContact] = useState(null);

    const handleContactSave = useCallback(async (newContact) => {
        return await api.saveContact(newContact);
    }, [api]);

    const handleDeleteClicked = useCallback(async () => {
        return await api.deleteContact(contact.id).then(() => {
            navigate(`/contacts`);
        })
    }, [navigate, api, contact?.id]);

    const handleFollowUpNowClicked = useCallback(async (contactData) => {
        await api.saveContact(contactData);

        const result = await api.forceFollowUpWithContact(contact.id);
        navigate(`/conversations/${result.conversation_id}`);
    }, [navigate, api, contact?.id]);

    const handleViewConversationClicked = useCallback(async (contactData) => {
        await api.saveContact(contactData);

        navigate(`/conversations/${contact.follow_up_conversation_id}`);
    }, [api, navigate, contact?.follow_up_conversation_id]);

    React.useEffect(() => {
        if (!contact) {
            api.getContact(params?.contactId).then((contact) => {
                if (contact.needs_review) {
                    contact.needs_review = false;
                    handleContactSave(contact);
                }

                setContact(contact);
            });
        }
    }, [contact, params?.contactId, api, handleContactSave]);

    if (!contact) {
        return <PageWrapperWithMenu>
            <PageHeader title={`Edit Contact`}/>
            <div className="contact-edit-page">
                <CircularProgress/>
            </div>
        </PageWrapperWithMenu>
    }

    return <PageWrapperWithMenu>
        <PageHeader title={`Edit Contact`}/>
        <div className="contact-edit-page">
            <Card className="contact-edit-form-card">
                <CardContent>
                    <ContactForm
                        initialContact={contact}
                        onContactSave={handleContactSave}
                        onDeletedClicked={handleDeleteClicked}
                        onFollowUpNowClicked={handleFollowUpNowClicked}
                        onViewConversationClicked={handleViewConversationClicked}
                    />
                </CardContent>
            </Card>
        </div>

    </PageWrapperWithMenu>
}