import React from "react";
import { Grid, TextField } from "@mui/material";
import "./SchemaMetadataEditor.scss";
import LocalTranslatedText from "../../../../translation/frontend/components/LocalTranslatedText";

export default function SchemaMetadataEditor({
  schema,
  onMetadataChange,
  disabled,
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label={<LocalTranslatedText language={"en"} text={"Schema Title"} />}
          fullWidth
          size='small'
          value={schema.title || ""}
          onChange={(e) => onMetadataChange("title", e.target.value)}
          disabled={disabled}
          helperText='Name of the schema. This should be lowercase and only contain alphanumerics and underscores.'
          sx={{
            "& .MuiFormHelperText-root": {
              marginLeft: 0,
            },
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label='Schema Description'
          size='small'
          value={schema.description || ""}
          onChange={(e) => onMetadataChange("description", e.target.value)}
          multiline
          rows={4}
          disabled={disabled}
          helperText='Describe the type of data this schema represents. Provide a lot of detail here as this can help improve the results from AI prompts.'
          sx={{
            "& .MuiFormHelperText-root": {
              marginLeft: 0,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
