import {TextField} from "@mui/material";
import React, {useCallback} from "react";
import "./ChatMessageInputBox.scss";
import Button from "@mui/material/Button";
import SendIcon from '@mui/icons-material/Send';
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";

export const ChatMessageInputBox = ({ onSendMessage }) => {
    const [message, setMessage] = React.useState("");

    const handleInputChange = useCallback((event) => {
        setMessage(event.target.value);
    }, []);

    const handleKeyDown = useCallback((event) => {
        if (event.key === "Enter") {
            // Check if ctrl is being held, and if so, allow the enter key to go through normally
            if (event.ctrlKey) {
                setMessage(message + "\n");
            }
            else {
                event.preventDefault();
                if (onSendMessage) {
                    onSendMessage(message);
                    setMessage("");
                }
            }
        }
    }, [message, onSendMessage, setMessage]);

    const handleButtonClick = useCallback(() => {
        if (onSendMessage) {
            onSendMessage(message);
            setMessage("");
        }
    }, [message, onSendMessage]);

    return <div className={"prospera-chat-message-input-box"}>
        <TextField
            id="current-message"
            label={<LocalTranslatedText language={"en"} text="Message" />}
            variant="outlined"
            value={message}
            fullWidth
            multiline={true}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
        />
        <Button
            variant="outlined"
            color={"secondary"}
            disabled={!message}
            onClick={handleButtonClick}
        >
            <SendIcon />
        </Button>
    </div>
}