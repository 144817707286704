import React, {useCallback} from "react";
import FormInput from "../../../frontend/elements/form/FormInput";
import FormRoot from "../../../frontend/elements/form/FormRoot";
import FormSection from "../../../frontend/elements/form/FormSection";
import {SpinnerButton} from "../../../frontend/elements/SpinnerButton";
import FilePreview from "../../../file_uploads/frontend/FilePreview";
import "./ImportedWebpageForm.scss";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";


export default function ImportedWebpageForm({
                                         initialImportedWebpage,
                                         onImportedWebpageSave,
                                         onDeletedClicked,
                                         saveButtonText,
                                     }) {
    const [importedWebpage, setImportedWebpage] = React.useState(initialImportedWebpage);

    const handleImportedWebpageSaved = useCallback((newImportedWebpageData) => {
        if (onImportedWebpageSave) {
            return onImportedWebpageSave(newImportedWebpageData);
        }
    }, [onImportedWebpageSave]);

    const handleImportedWebpageChange = useCallback((newImportedWebpageData) => {
        setImportedWebpage(newImportedWebpageData);
    }, []);

    return <div className={"imported-webpage-form"}>
        <FormRoot
            initialValue={initialImportedWebpage}
            saveButtonText={saveButtonText ?? "Save Imported Webpage"}
            onSave={handleImportedWebpageSaved}
            onChange={handleImportedWebpageChange}
            extraButtons={<>
                {
                    onDeletedClicked ?
                        <SpinnerButton
                            variant="contained"
                            color="error"
                            className={"delete-button"}
                            onClick={onDeletedClicked}
                        >
                            <LocalTranslatedText language={"en"} text="Delete" />
                        </SpinnerButton>
                        : null
                }
            </>
            }
        >
            <FormSection
                title={"General"}
                subtitle={"Metadata that can be used to find this webpage"}
            >
                <FormInput
                    type={"text"}
                    label={"Page Title"}
                    fieldName={"page_title"}
                />

                <FormInput
                    type={"url"}
                    label={"Original URL"}
                    fieldName={"original_url"}
                />
            </FormSection>

            <FormSection
                title={"Content"}
            >
                <FormInput
                    type={"text"}
                    label={"The text that was extracted from the web page"}
                    fieldName={"text_content"}
                    multiline
                    rows={36}
                />
            </FormSection>
        </FormRoot>
    </div>
}