import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useAPI} from "../components/APIProvider";
import {useNavigate} from "react-router-dom";
import {useCallback} from "react";

export default function CopyTenantPopup({open, setOpen, copyTenant}) {
  const api = useAPI();
  const navigate = useNavigate();


  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    // Create new tenant data. Reset the id to whatever
    // the user typed into the pop. Reset API domains
    // to an empty list to ensure they don't conflict
    // with any existing api domains, which must be
    // unique.
    const newTenant = {
      ...copyTenant,
      id: formData.get('id'),
      _id: formData.get('id'),
      api: {
        ...copyTenant.api,
        api_domains: [],
      }
    }

    return api.createNewTenantConfiguration(newTenant).then(() => {
      navigate(`/tenant_configuration/${newTenant.id}`);
    });

  }, [api, copyTenant, navigate]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit,
        }}
      >
        <DialogTitle>Copy Tenant</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To copy this tenant, please select a new tenant ID.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            name="id"
            label="Tenant ID"
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Create</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}