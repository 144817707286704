import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import {useCallback, useEffect, useMemo} from "react";
import {useAPI} from "../components/APIProvider";

const globalCachedItems = {};

export default function TableElementLookupSelector({
                                                       id,
                                                       label,
                                                       idFieldName,
                                                       searchFieldName,
                                                       displayFieldName,
                                                       getSuggestions,
                                                       getSingleItem,
                                                       multiple,
                                                       value,
                                                       onChange,
                                                       onBlur,
                                                   }) {
    const api = useAPI();
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [localValue, setLocalValue] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    if (!idFieldName) {
        idFieldName = "id";
    }

    if (!value) {
        if (multiple) {
            value = [];
        } else {
            value = "";
        }
    }

    useEffect(() => {
        if (value) {
            if (!multiple) {
                if (globalCachedItems[value]) {
                    setLocalValue(globalCachedItems[value]);
                } else {
                    getSingleItem(value).then((item) => {
                        setLocalValue(item);
                        globalCachedItems[value] = item;
                    });
                }
            } else {
                setLocalValue([]);
                let loadedValues = [];
                value.map((x) => {
                    if (globalCachedItems[x]) {
                        loadedValues.push(globalCachedItems[x]);
                        if (loadedValues.length === value.length) {
                            setLocalValue(loadedValues);
                        }
                        return;
                    } else {
                        getSingleItem(x).then((item) => {
                            loadedValues.push(item);
                            if (loadedValues.length === value.length) {
                                setLocalValue(loadedValues);
                            }
                            globalCachedItems[x] = item;
                        });
                    }
                });
            }
        }
    }, []);

    const handleChange = useCallback((event, value) => {
        setLocalValue(value);
        if (value) {
            if (multiple) {
                onChange(value.map((x) => x[idFieldName]));
            } else {
                onChange(value[idFieldName]);
            }
        }
    }, [multiple]);

    const fetchOptions = useCallback((searchTextValue) => {
        api.getUserDetails().then((userDetails) => {
            const tableQuery = {
                "startRow": 0,
                "endRow": 10,
                "filterModel": {
                    "user_id": {
                        "filterType": "set",
                        "values": [userDetails.user_id, "", null]
                    }
                },
                "sortModel": [],
                "fields": [searchFieldName, displayFieldName]
            }

            if (searchTextValue) {
                tableQuery.filterModel[searchFieldName] = {
                    "filterType": "text",
                    "type": "contains",
                    "filter": searchTextValue
                }
            }

            setIsLoading(true);
            getSuggestions(tableQuery).then((options) => {
                let filteredOptions = options;
                if (multiple) {
                    filteredOptions = options.filter((x) => !localValue.find((y) => y[idFieldName] === x[idFieldName]))
                }
                setOptions(filteredOptions);
                setIsLoading(false);
            }, (err) => {
                setOptions([]);
                setIsLoading(false);
            })
        });
    }, [getSuggestions, localValue, displayFieldName, searchFieldName, multiple]);

    const handleInputChange = useCallback((event, searchTextValue) => {
        fetchOptions(searchTextValue);
    }, [fetchOptions]);

    const handleOnOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const handleOnClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const getOptionLabel = useMemo(() => (option) => option[displayFieldName] ?? "", []);

    const getOptionKey = useMemo(() => (option) => option[idFieldName], []);

    const isOptionEqualToValue = useMemo(() =>
            (option, value) => (option[idFieldName] === value) || (option[idFieldName] === value[idFieldName]),
        []);

    const fillterOptions = useMemo(() => (x) => x, []);

    React.useEffect(() => {
        if (open) {
            fetchOptions("");
        }
    }, [open]);

    return (
        <Autocomplete
            id={id}
            multiple={multiple}
            open={open}
            onOpen={handleOnOpen}
            onClose={handleOnClose}
            onBlur={onBlur}
            onChange={handleChange}
            onInputChange={handleInputChange}
            isOptionEqualToValue={isOptionEqualToValue}
            getOptionKey={getOptionKey}
            getOptionLabel={getOptionLabel}
            options={options}
            loading={isLoading}
            freeSolo={false}
            filterOptions={fillterOptions}
            value={localValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}