import {PageWrapperWithMenu} from "../elements/PageWrapperWithMenu";
import React, {useCallback} from "react";
import {Card, CardContent} from "@mui/material";
import {useAPI} from "../components/APIProvider";
import {PageHeader} from "../elements/PageHeader";
import TenantConfigurationForm from "./TenantConfigurationForm";
import {useNavigate} from "react-router-dom";


export default function NewTenantConfigurationPage(props) {
    const api = useAPI();
    const navigate = useNavigate();
    const handleCreateTenant = useCallback(async (tenantData) => {
        return await api.createNewTenantConfiguration(tenantData).then((newTenant) => {
            navigate(`/tenant_configuration/${newTenant.id}`);
        });
    }, [api, navigate]);

    return <PageWrapperWithMenu>
        <PageHeader title={`New Tenant`}/>
        <div className="new-tenant-configuration-page">
            <Card className="tenant-configuration-form-card">
                <CardContent>
                    <TenantConfigurationForm
                        initialTenantConfiguration={{}}
                        onTenantConfigurationSave={handleCreateTenant}
                        saveButtonText={"Create New Tenant"}
                    />
                </CardContent>
            </Card>
        </div>

    </PageWrapperWithMenu>
}