import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import "./ContextMenu.scss";
import {FileInputNodeIcon, TextInputNodeIcon} from "./icons";

export default function InputGroupNodeContextMenu({contextMenu, handleClose, handleNewTextInputNodeClicked, handleNewFileInputNodeClicked}) {
    return (
        <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
                contextMenu !== null
                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                    : undefined
            }
        >
            <MenuItem className={"context-menu-action-item"} onClick={handleNewTextInputNodeClicked}>
                <TextInputNodeIcon/>
                <span>Add Text Input</span>
            </MenuItem>
            <MenuItem className={"context-menu-action-item"} onClick={handleNewFileInputNodeClicked}>
                <FileInputNodeIcon/>
                <span>Add File Input</span>
            </MenuItem>
        </Menu>
    );
}