import React, {useCallback} from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from "@mui/material/ListItemText";
import {useAPI} from "../../../frontend/components/APIProvider";
import "./SmartChainStepList.scss";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";
import ListItemButton from "@mui/material/ListItemButton";
import Avatar from "@mui/material/Avatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteSmartChainStepButton from "./DeleteSmartChainStepButton";
import RenameSmartChainStepButton from "./RenameSmartChainStepButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import RenameSmartChainButton from "./RenameSmartChainButton";
import LinkIcon from '@mui/icons-material/Link';
import {IconButton} from "@mui/material";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

export default function SmartChainStepList({
                                             smartChain,
                                             selectedStep,
                                             onChainClicked,
                                             onStepClicked,
                                             onAddNewStepClicked,
                                             onRenameSmartChain,
                                             onDeleteSmartChainStep,
                                             onRenameSmartChainStep,
                                             onMoveStepUpClicked,
                                             onMoveStepDownClicked,
                                           }) {
  const api = useAPI();

  const handleDeleteSmartChainStep = useCallback((step, stepIndex) => {
    onDeleteSmartChainStep(step, stepIndex);
  }, [onDeleteSmartChainStep]);

  const handleRenameSmartChainStep = useCallback((step, stepIndex, newName) => {
    onRenameSmartChainStep(step, stepIndex, newName);
  }, [onRenameSmartChainStep]);

  const handleRenameSmartChain = useCallback((newName) => {
    onRenameSmartChain(newName);
  }, [onRenameSmartChain]);

  const handleMoveStepUpClicked = useCallback((stepIndex) => {
    onMoveStepUpClicked(stepIndex);
  }, [onMoveStepUpClicked]);

  const handleMoveStepDownClicked = useCallback((stepIndex) => {
    onMoveStepDownClicked(stepIndex);
  }, [onMoveStepDownClicked]);

  return <div className={"smart-chain-step-list"}>
    <List sx={{width: '100%', bgcolor: 'background.paper'}} dense={true}>
      <ListItem
        className={"step-list-header-row"}
        secondaryAction={
          <>
            <RenameSmartChainButton
              currentName={smartChain.chain_name}
              onNameChanged={handleRenameSmartChain}
            />
            {
              smartChain.steps.length > 1 ?
                <DeleteSmartChainStepButton
                  onDeleteSmartChainStepConfirmed={handleDeleteSmartChainStep}
                />
                : null
            }
          </>
        }
        disablePadding
      >
        <ListItemButton
          selected={selectedStep === null}
          onClick={() => onChainClicked()}
        >
          <ListItemIcon>
            <LinkIcon />
          </ListItemIcon>
          <ListItemText primary={
            smartChain.chain_name || <LocalTranslatedText language={"en"} text={"Untitled Chain"}/>
          }/>
        </ListItemButton>
      </ListItem>
      {
        smartChain.steps.map((step, stepIndex) => {
          return <ListItem
            key={step.step_id}
            secondaryAction={
              <>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleMoveStepUpClicked(stepIndex)}
                  disabled={stepIndex === 0}
                >
                  <ArrowCircleUpIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleMoveStepDownClicked(stepIndex)}
                  disabled={stepIndex === smartChain.steps.length - 1}
                  >
                  <ArrowCircleDownIcon />
                </IconButton>
                <RenameSmartChainStepButton
                  currentName={step.step_name}
                  onNameChanged={handleRenameSmartChainStep.bind(null, step, stepIndex)}
                />
                {
                  smartChain.steps.length > 1 ?
                    <DeleteSmartChainStepButton
                      onDeleteSmartChainStepConfirmed={handleDeleteSmartChainStep.bind(null, step, stepIndex)}
                    />
                    : null
                }
              </>
            }
            disablePadding
          >
            <ListItemButton
              selected={step.step_id === selectedStep?.step_id}
              onClick={() => onStepClicked(step, stepIndex)}
              key={step.step_id}
            >
              <ListItemAvatar>
                <Avatar sx={{width: 24, height: 24}}>
                  {stepIndex + 1}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={
                step.step_name || <LocalTranslatedText language={"en"} text={"Untitled Step"}/>
              }/>
            </ListItemButton>
          </ListItem>
        })
      }
      <ListItemButton
        onClick={onAddNewStepClicked}
      >
        <ListItemIcon>
          <AddBoxIcon/>
        </ListItemIcon>
        <ListItemText primary={<LocalTranslatedText language={"en"} text={"Add New Step"}/>}/>
      </ListItemButton>
    </List>
  </div>
}
