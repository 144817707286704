import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import "./EnvironmentTitleMenu.scss";
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import GroupsIcon from '@mui/icons-material/Groups';
import SchemaIcon from '@mui/icons-material/Schema';
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";
import {useFrontendConfiguration} from "../components/FrontendConfigurationProvider";
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import CampaignIcon from '@mui/icons-material/Campaign';
import ChecklistIcon from '@mui/icons-material/Checklist';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import {useCallback} from "react";
import GavelIcon from '@mui/icons-material/Gavel';


export default function EnvironmentTitleMenu() {
  const config = useFrontendConfiguration();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, [setAnchorEl]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const onReceptionistClick =useCallback( () => {
      window.location.href = "https://receptionist.prosperalabs.ai/"
  }, []);

  const onSalesBotClick = useCallback(() => {
      window.location.href = "https://salesbot.prosperalabs.ai/"
  }, []);

  const onNetworkingBotClick = useCallback(() => {
      window.location.href = "https://networkingbot.prosperalabs.ai/"
  }, []);

  const onFlowThoughtClick = useCallback(() => {
      window.location.href = "https://flowthought.prosperalabs.ai/"
  }, []);

  const onKnowledgeBotClick = useCallback(() => {
      window.location.href = "https://knowledgebot.prosperalabs.ai/"
  }, []);

  const onWordFactoryClick = useCallback(() => {
      window.location.href = "https://wordfactory.prosperalabs.ai/"
  }, []);

  const onIntakeBotClick = useCallback(() => {
      window.location.href = "https://intake.prosperalabs.ai/"
  }, []);

  const onCustomAgentClick = useCallback(() => {
      window.location.href = "https://custom.prosperalabs.ai/"
  }, []);

  const onPoliticianClick = useCallback(() => {
      window.location.href = "https://politician.prosperalabs.ai/"
  }, []);

  return (
    <div className={"environment-menu"}>
      <Button
        id="environment-title-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        className={"title-button"}
        onClick={handleClick}
      >
        {config.frontend.top_bar_title}
      </Button>
      <Menu
        id="environment-selector"
        className={"environment-selector"}
        aria-labelledby="environment-title-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={onReceptionistClick}><PhoneInTalkIcon />&nbsp;&nbsp;<LocalTranslatedText language={"en"} text="Receptionist" /></MenuItem>
        <MenuItem onClick={onSalesBotClick}><MonetizationOnIcon />&nbsp;&nbsp;<LocalTranslatedText language={"en"} text="Sales Bot"/></MenuItem>
        <MenuItem onClick={onNetworkingBotClick}><GroupsIcon />&nbsp;&nbsp;<LocalTranslatedText language={"en"} text="Networking Bot"/></MenuItem>
        <MenuItem onClick={onFlowThoughtClick}><SchemaIcon />&nbsp;&nbsp;<LocalTranslatedText language={"en"} text="FlowThought" /></MenuItem>
        <MenuItem onClick={onKnowledgeBotClick}><LightbulbCircleIcon />&nbsp;&nbsp;<LocalTranslatedText language={"en"} text="Knowledge Bot" /></MenuItem>
        <MenuItem onClick={onWordFactoryClick}><CampaignIcon />&nbsp;&nbsp;<LocalTranslatedText language={"en"} text="Word Factory" /></MenuItem>
        <MenuItem onClick={onIntakeBotClick}><ChecklistIcon />&nbsp;&nbsp;<LocalTranslatedText language={"en"} text="Intake Bot" /></MenuItem>
        <MenuItem onClick={onCustomAgentClick}><SupportAgentIcon />&nbsp;&nbsp;<LocalTranslatedText language={"en"} text="Custom Agent" /></MenuItem>
        <MenuItem onClick={onPoliticianClick}><GavelIcon />&nbsp;&nbsp;<LocalTranslatedText language={"en"} text="Politician" /></MenuItem>
      </Menu>
    </div>
  );
}

