import React, {useEffect, useState} from "react";
import {PageWrapperWithMenu} from "../../../core/frontend/elements/PageWrapperWithMenu";
import {ChartEditor} from "../../prompt_chart/frontend/ChartEditor";
import {useParams} from "react-router-dom";
import {useAPI} from "../../../core/frontend/components/APIProvider";


export default function SingleChartEvaluationPage() {
    const params = useParams();
    const api = useAPI();
    const singleChartEvaluationId = params.singleChartEvaluationId;
    const [singleChartEvaluation, setSingleChartEvaluation] = useState();
    useEffect(() => {
        api.getSingleChartEvaluation(singleChartEvaluationId).then((data) => {
            setSingleChartEvaluation(data);
        })
    }, [api, singleChartEvaluationId]);

    return <PageWrapperWithMenu padding={false}>
        {
            singleChartEvaluation ? <ChartEditor
                chartId={singleChartEvaluation.prompt_chart_id}
                singleChartEvaluation={singleChartEvaluation}
                local={false}
            /> : null
        }
    </PageWrapperWithMenu>
}

