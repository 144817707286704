import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import React, {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import { CustomDataObjectTable } from "./CustomDataObjectTable";
import "./CustomDataObjectListPage.scss";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import { WhiteButton } from "../../../frontend/elements/WhiteButton";
import Paper from "@mui/material/Paper";
import { useCallback } from "react";
import {useAPI} from "../../../frontend/components/APIProvider";

export default function CustomDataObjectListPage() {
  const navigate = useNavigate();
  const params = useParams();
  const api = useAPI();
  const [customDataSchema, setCustomDataSchema] = React.useState(null);

  const handleCreateNewCustomDataObject = useCallback(async () => {
    navigate(`/c/${params.customDataSchemaId}/new`);
  }, [navigate]);

  useEffect(() => {
    api.getCustomDataSchema(params.customDataSchemaId).then((data) => {
      setCustomDataSchema(data);
    });
  }, [params.customDataSchemaId]);

  return (
    <PageWrapperWithMenu>
      <PageHeader title={customDataSchema?.title}>
        <WhiteButton onClick={handleCreateNewCustomDataObject}>
          New {customDataSchema?.title}
        </WhiteButton>
      </PageHeader>
      <div className={"custom-data-object-list-page"}>
        <div className={"custom-data-object-table-wrapper"}>
          <Paper className={"main-page-contents"}>
            <CustomDataObjectTable
              customSchemaId={params.customDataSchemaId}
            />
          </Paper>
        </div>
      </div>
    </PageWrapperWithMenu>
  );
}
