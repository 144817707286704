import "./ActionButton.scss";
import "./NodeColorPickerWidget.scss";
import "./PromptWritingTipsActionButton.scss";
import * as React from 'react';
import BehaviorTracker from "../../../../core/frontend/components/BehaviorTracker";
import Color from "colorjs.io";
import IconButton from '@mui/material/IconButton';
import PaletteIcon from '@mui/icons-material/Palette';
import useChartStore from "../ChartState";
import {Popover} from "@mui/material";
import {useCallback, useMemo} from 'react';

export function ColorOption({color, onOptionClicked}) {
    return <div
        style={{
            backgroundColor: color.optionColor,
        }}
        className={"color-option"}
        title={color.title}
        onClick={() => onOptionClicked(color.backgroundColor)}
    >

    </div>
}


export function NodeColorPickerWidget({nodeId}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    let {changeNodeData} = useChartStore((state) => {
        return {
            changeNodeData: state.changeNodeData,
        }
    });

    const handleOpenColorPickerClicked = useCallback((evt) => {
        setAnchorEl(evt.currentTarget);
    }, []);

    const handleColorClicked = useCallback((color) => {
        BehaviorTracker.trackInteraction({
            id: "change-prompt-color",
            mixpanel: null
        });

        changeNodeData(nodeId, {
           color: color,
        });

        setAnchorEl(null);
    }, [changeNodeData, nodeId]);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const colorOptions = useMemo(() => {
        const baseColors = [
            "Red",
            "Orange",
            "Yellow",
            "Green",
            "Blue",
            "Purple",
            "Aqua",
        ]

        const colorOptions = [
            {
                title: "white",
                optionColor: "#ffffff",
                backgroundColor: "#ffffff",
            }
        ];
        for (let colorName of baseColors) {
            const optionColor = new Color(colorName.toLowerCase())
            optionColor.hsl.l = 80;

            const backgroundColor = new Color(colorName.toLowerCase())
            backgroundColor.hsl.l = 97;

            colorOptions.push({
                title: colorName,
                optionColor: optionColor.toString(),
                backgroundColor: backgroundColor.toString(),
            });
        }

        return colorOptions;
    }, [])


    return (
        <>
            <IconButton
                aria-label="Change Color"
                onClick={handleOpenColorPickerClicked}
                className={'nodrag actionbutton'}
                title={"Change Color"}
            >
                <PaletteIcon/>
            </IconButton>
            <Popover
                id={`node-color-picker-popover-${nodeId}`}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorPosition={{
                    left: 0,
                    top: 0,
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                className={"node-color-picker-popover"}
            >
                {
                    colorOptions.map((colorInfo) => {
                        return <ColorOption
                            key={colorInfo.title}
                            color={colorInfo}
                            onOptionClicked={handleColorClicked}
                        />
                    })
                }
            </Popover>
        </>);
}

export default NodeColorPickerWidget;
