import "./AboutSystemPage.scss";
import CircularProgress from "@mui/material/CircularProgress";
import CustomJSONView from "../elements/CustomJSONView";
import React from "react";
import {PageHeader} from "../elements/PageHeader";
import {PageSection} from "../elements/PageSection";
import {PageWrapperWithMenu} from "../elements/PageWrapperWithMenu";
import {useAPI} from "../components/APIProvider";


export default function AboutSystemPage() {
  const api = useAPI();
  const [homeData, setHomeData] = React.useState(null);

  React.useEffect(() => {
    if (!homeData) {
      api.home().then((healthCheckData) => {
        setHomeData(healthCheckData);
      }, (error) => {
        // An error response from the health check endpoint
        // is expected when system is unhealthy. So we should
        // still display the result data to the user
        if (error) {
          setHomeData(error);
        }
      });
    }
  }, [homeData]);

  return (
    <PageWrapperWithMenu>
      <div className={"about-system-page"}>
        <PageHeader title={`About System`}/>
        {
          !homeData ? <PageSection>
            <CircularProgress/>
          </PageSection> : null
        }
        {
          homeData ?
            <PageSection>
              <CustomJSONView
                data={homeData}
                field_name={"About"}
                disable_collapse={false}
                openToLevel={2}
              />
              <a href={"https://prosperalabs.ai/"}>
                <img src={"/logo_text.png"} className={"logo"} />
              </a>
            </PageSection>
            : null
        }
      </div>
    </PageWrapperWithMenu>
  );
}
