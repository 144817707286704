import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ImportedWebpageTable } from "./ImportedWebpageTable";
import "./ImportedWebpageListPage.scss";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import { WhiteButton } from "../../../frontend/elements/WhiteButton";
import Paper from "@mui/material/Paper";
import { useCallback } from "react";

export default function ImportedWebpageListPage() {
  const navigate = useNavigate();

  const handleImportWebpageClicked = useCallback(async () => {
    navigate(`/imported_webpages/import`);
  }, [navigate]);

  return (
    <PageWrapperWithMenu>
      <PageHeader title={"Imported Webpages"}>
        <WhiteButton onClick={handleImportWebpageClicked}>
          Import Web Page URL
        </WhiteButton>
      </PageHeader>
      <div className={"imported-webpage-list-page"}>
        <div className={"imported-webpage-table-wrapper"}>
          <Paper className={"main-page-contents"}>
            <ImportedWebpageTable />
          </Paper>
        </div>
      </div>
    </PageWrapperWithMenu>
  );
}
