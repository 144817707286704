import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import AbcIcon from "@mui/icons-material/Abc";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import NumbersIcon from "@mui/icons-material/Numbers";
import DataObjectIcon from "@mui/icons-material/DataObject";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

export const getDefaultObject = (mode = "simplified", isChildNode = false) => ({
  type: "object",
  title: "",
  description: "",
  properties: {},
  ...(mode === "full" &&
    isChildNode && {
      multiline: false,
      rows: 1,
      show_in_table_view: false,
      table_view_order: 0,
      placeholder: "",
      form_input_type: "text", // text, number, email, phone, address, url, full_name, company_name, checkboxes, datetime, currency, radio, dropdown, string_list, json
      required: false,
      options: [],
      optionLabels: [],
      default: "",
    }),
});

export const schemaTypes = ["object", "array", "string", "number", "boolean"];

export const formInputTypes = [
  "text",
  "number",
  "email",
  "phone",
  "address",
  "url",
  "full_name",
  "company_name",
  "checkboxes",
  "datetime",
  "currency",
  "radio",
  "dropdown",
  "string_list",
  "json",
];

export const isNotEmptyObject = (obj) =>
  obj && typeof obj === "object" && Object.keys(obj).length > 0;

export const getIcon = (type) => {
  switch (type) {
    case "object":
      return <DataObjectIcon />;
    case "array":
      return <FormatListBulletedIcon />;
    case "string":
      return <AbcIcon />;
    case "number":
      return <NumbersIcon />;
    case "boolean":
      return <CheckBoxOutlinedIcon />;
    default:
      return <HighlightOffOutlinedIcon />;
  }
};
