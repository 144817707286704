import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import "./CustomAccordion.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ShowMore = () => (
  <IconButton aria-label='Expense Status'>
    <ExpandMoreIcon />
  </IconButton>
);

export default function CustomAccordion({
  title,
  children,
  smallTitle,
  noBorderBottom,
  noPadding,
}) {
  return (
    <Accordion
      defaultExpanded={false}
      className='accordion'
      sx={{
        "&.MuiPaper-root": {
          backgroundColor: "transparent",
          boxShadow: "none",
          "::before": {
            display: "none",
          },
          padding: noPadding ? "0px 16px" : "8px 16px",
        },
        "&.Mui-expanded": {
          margin: "0px !important",
          minHeight: 0,
        },
        "& .MuiButtonBase-root": {
          minHeight: 0,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ShowMore />}
        aria-controls='panel1a-content'
        id='panel1a-header'
        className='accordion-summary'
        sx={{
          minHeight: "52px !important",
          borderBottom: noBorderBottom ? "none" : "1px solid #E0E0E0",
          "&.Mui-expanded": {
            margin: "0px !important",
            minHeight: "52px",
            padding: "4px 0px", // Adjust padding
          },
          "& .MuiAccordionSummary-content": {
            margin: "0px !important",
          },
        }}
      >
        <Typography
          variant='h4'
          className={`summary-title ${smallTitle ? "small-title" : ""}`}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        className='accordion-details'
        sx={{
          padding: "8px 0px",
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
