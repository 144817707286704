import React, { useCallback } from "react";
import FormInput from "../../../core/frontend/elements/form/FormInput";
import FormRoot from "../../../core/frontend/elements/form/FormRoot";
import FormSection from "../../../core/frontend/elements/form/FormSection";
import { SpinnerButton } from "../../../core/frontend/elements/SpinnerButton";
import "./TopicExtractorPromptForm.scss";

export default function TopicExtractorPromptForm({
  initialTopicExtractorPrompt,
  onTopicExtractorPromptSave,
  onDeletedClicked,
  saveButtonText,
}) {
  const handleTopicExtractorPromptSaved = useCallback(
    (newTopicExtractorPromptData) => {
      if (onTopicExtractorPromptSave) {
        return onTopicExtractorPromptSave(newTopicExtractorPromptData);
      }
    },
    [onTopicExtractorPromptSave]
  );

  return (
    <div className='topic-extractor-prompt-form'>
      <FormRoot
        initialValue={initialTopicExtractorPrompt}
        saveButtonText={saveButtonText ?? "Save Topic Extractor Prompt"}
        onSave={handleTopicExtractorPromptSaved}
        extraButtons={
          <>
            {onDeletedClicked ? (
              <SpinnerButton
                variant='contained'
                color='error'
                className={"delete-button"}
                onClick={onDeletedClicked}
              >
                Delete
              </SpinnerButton>
            ) : null}
          </>
        }
      >
        <FormSection
          title={"General"}
          subtitle={
            "Metadata that can be used to find this topic extractor prompt in the DB"
          }
        >
          <FormInput type={"text"} label={"Title"} fieldName={"title"} />

          <FormInput type={"text"} label={"Tags"} fieldName={"tags"} />

          <FormInput
            type={"text"}
            label={"Description"}
            fieldName={"description"}
          />

          <FormInput
            type={"text"}
            label={"Prompt to Extract Topics"}
            fieldName={"prompt"}
            multiline
            rows={12}
          />
        </FormSection>
      </FormRoot>
    </div>
  );
}
