import "./FilePreview.scss";
import * as React from 'react';
import {apiAccessTokenContext} from "../../frontend/components/api";
import {useContext} from "react";
import {useFrontendConfiguration} from "../../frontend/components/FrontendConfigurationProvider";

function FilePreview({fileId, fileName}) {
    const config = useFrontendConfiguration();

    const accessToken = useContext(apiAccessTokenContext);

    let imageUrl = `${config.frontend.backend_api_url}file/${fileId}/preview?`;

    if (accessToken) {
        imageUrl += `token=${accessToken}`;
    }

    return (
        <div className={"file-preview"}>
            {
                fileId ?
                    <img src={imageUrl} alt={`File Preview for: ${fileName}`} />
                    : null
            }

            {
                !fileId ?
                    <div className={"no-file-text-wrapper"}>
                        <span>No file uploaded...</span>
                    </div>
                    : null
            }
        </div>
    );
}

export default FilePreview;
