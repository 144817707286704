import React, { useEffect } from "react";
import { useAPI } from "../../frontend/components/APIProvider";
import { useNavigate } from "react-router-dom";
import { PageWrapperWithoutMenu } from "../../frontend/elements/PageWrapperWithoutMenu";

export default function IntakeStartPage() {
  const api = useAPI();
  const navigate = useNavigate();

  useEffect(() => {
    api.getIntakes({}).then((intakes) => {
      if (intakes.length > 0) {
        navigate(`/intake/${intakes[0]._id}`);
      } else {
        api.createNewIntake().then((newIntake) => {
          navigate(`/intake/${newIntake._id}`);
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <PageWrapperWithoutMenu></PageWrapperWithoutMenu>;
}
