import React from "react";
import "./PageSection.scss";
import {Typography} from "@mui/material";
import Paper from "@mui/material/Paper";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";

export const PageSection = ({title, subheading, children, elevation, className}) => {
    return <div className={`page-section ${className ? className : ''}`}>
            <Paper elevation={elevation}>
                {
                    (title || subheading)
                        ? <div className={"section-headers"}>
                            {
                                title ?
                                    <Typography variant={"h2"} className={"page-section-title"}>
                                        <LocalTranslatedText language={"en"} text={title} />
                                    </Typography>
                                    : null
                            }
                            {
                                subheading ?
                                    <Typography variant={"body1"}>
                                        <LocalTranslatedText language={"en"} text={subheading} />
                                    </Typography>
                                    : null
                            }
                        </div>
                    : null
                }
            {children}
        </Paper>
    </div>
};