import React, { useCallback, useEffect, useState } from "react";
import "./SmartChainStepOutputList.scss";
import { SchemaFieldList } from "./SchemaFieldList";
import { useAPI } from "../../../frontend/components/APIProvider";
import { CircularProgress } from "@mui/material";

export default function SmartChainStepOutputList({
  smartChain,
  selectedSmartChainStepIndex,
}) {
  const api = useAPI();
  let [outputSchema, setOutputSchema] = useState(null);

  const updateSchema = useCallback(async () => {
    // Refresh the whole smart chain, then find this one step in the refreshed result
    const newSmartChain = await api.refreshSmartChain(smartChain);
    let newOutputSchema = null;
    if (selectedSmartChainStepIndex === null) {
      newOutputSchema =
        smartChain.steps[smartChain.steps.length - 1].cumulative_output_schema;
    } else {
      const refreshedStepInfo = newSmartChain.steps.find(
        (s) =>
          s.step_id === smartChain.steps[selectedSmartChainStepIndex].step_id
      );
      newOutputSchema = refreshedStepInfo.cumulative_output_schema;
    }
    newOutputSchema.title = "Cumulative Output";
    setOutputSchema(newOutputSchema);
  }, [selectedSmartChainStepIndex, api, smartChain]);

  useEffect(() => {
    if (!outputSchema) {
      updateSchema();
    }
  }, [outputSchema, updateSchema]);

  if (!outputSchema) {
    return <CircularProgress />;
  }

  return (
    <div className={"smart-chain-step-output-list"}>
      <h5>Output</h5>
      {<SchemaFieldList schema={outputSchema} isOpenByDefault={true} />}
    </div>
  );
}
