

export const MainTranscriptProcessorTemplate = {
    _id: "transcript_processor",
    title: "Transcript Processor Chart",
    input_fields:  [
        {
            title: "Transcript",
            field_name: "transcript",
        },
        {
            title: "Topic Prompts",
            field_name: "topic_prompts",
        },
        {
            title: "Content Samples - Posts",
            field_name: "content_sample_posts",
        },
        {
            title: "Content Samples - Transcript Excerpts",
            field_name: "content_sample_transcript_excerpts",
        },
        {
            title: "Content Samples - Ideas",
            field_name: "content_sample_ideas",
        }
    ],
    output_fields: [
        {
            title: "Posts",
            field_name: "posts",
        },
    ]
}



