import React, {useState} from 'react';
import {SpinnerButton} from "../../../core/frontend/elements/SpinnerButton";
import "./PhoneNumberTestingNumberFlow.scss";
import {TestingPhoneNumberSelector} from "./TestingNumberSelector";
import {useAPI} from "../../../core/frontend/components/APIProvider";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";


export const PhoneNumberTestingNumberFlow = ({onVoiceConfigurationChanged}) => {
    const api = useAPI();
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);

    const handlePhoneNumberChanged = (phoneNumber) => {
        setSelectedPhoneNumber(phoneNumber);
    }

    const handleAdoptPhoneNumberClicked = () => {
        return api.saveVoiceConfiguration({
            phone_number_configuration_mode: "test_number",
            bot_number: selectedPhoneNumber,
        }).then(() => {
            onVoiceConfigurationChanged({
                phone_number_configuration_mode: "test_number",
                bot_number: selectedPhoneNumber,
                status: 'ready'
            })
        })
    }

    return ( <div>
        <TestingPhoneNumberSelector
            onChange={handlePhoneNumberChanged}
        />
        <SpinnerButton
            color={'primary'}
            variant={'contained'}
            onClick={handleAdoptPhoneNumberClicked}
            disabled={!selectedPhoneNumber}
        >
            <LocalTranslatedText language={"en"} text="Adopt Phone Number" />
        </SpinnerButton>
    </div>
    );
};
