import React, { useState, useCallback } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import {StoryFormWidgetContainer} from "./StoryFormWidgetContainer";
import "./StoryVillainSelector.scss";

export const StoryVillainSelector = ({ value, onChange }) => {
  const [hasVillain, setHasVillain] = useState(value);

  const handleCheckboxChange = useCallback((event) => {
    setHasVillain(event.target.checked);
    onChange(event.target.checked);
  }, [onChange]);

  return (
    <StoryFormWidgetContainer label={"Does story have villain?"} className={"story-villain-selector"}>
      <FormControlLabel
        control={
          <Checkbox
            checked={hasVillain}
            onChange={handleCheckboxChange}
            name="story-villain-checkbox"
          />
        }
        label="Yes"
      />
    </StoryFormWidgetContainer>
  );
};