import React from "react";
import FormInput from "../../elements/form/FormInput";
import FormSection from "../../elements/form/FormSection";
import FormSubFieldRoot from "../../elements/form/FormSubFieldRoot";

export default function WA2ChatConfigurationForm() {
    return (
        <FormSection
            title={"2Chat.co Integration"}
            subtitle={"Configuration required for WhatsApp Group chat"}
            collapsable={true}
        >
            <FormSubFieldRoot field={"wa_2chat"}>
                <FormInput
                    type={"text"}
                    label={"API Key"}
                    fieldName={"api_key"}
                    placeholder={"The API Key used to connect to the 2Chat API"}
                />
                <FormInput
                    type={"text"}
                    label={"API Prefix for Callbacks"}
                    fieldName={"api_prefix_for_callbacks"}
                    placeholder={"The URL prefix on our server for callback messages from 2Chat, ie " +
                        "https://myapp.prospera.ai/"}
                />
                <FormInput
                    type={"text"}
                    label={"WhatsApp Number"}
                    fieldName={"whatsapp_number"}
                    placeholder={"The phone number that is used for the WhatsApp integration. ie +14155238886"}
                />
                <FormInput
                    type={"text"}
                    label={"WhatsApp User ID"}
                    fieldName={"whatsapp_user_id"}
                    placeholder={"The user_id that will be used for WhatsApp messages"}
                />
            </FormSubFieldRoot>
        </FormSection>
    );
}