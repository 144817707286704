import axios from "axios";
import { axiosETAGCache } from "axios-etag-cache";

let globalCalendarSchema = null;
let globalBookingSchema = null;

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);
const api = {
  async getCalendarSchema() {
    if (globalCalendarSchema) {
      return globalCalendarSchema;
    }
    const response = await axiosWithETAGCache.get(`/calendar/schema`);
    globalCalendarSchema = response.data;
    return response.data;
  },
  async getBookingSchema() {
    if (globalBookingSchema) {
      return globalBookingSchema;
    }
    const response = await axiosWithETAGCache.get(`/booking/schema`);
    globalBookingSchema = response.data;
    return response.data;
  },
  async getGoogleCalendarAuthenticationURL(calendar_id) {
    const queryParams = {};

    const response = await axios.post(
      `/calendar/${calendar_id}/google/auth_url`,
      {
        params: queryParams,
      }
    );

    return response.data;
  },
  async getMicrosoftCalendarAuthenticationURL(calendar_id) {
    const queryParams = {};

    const response = await axios.post(
      `/calendar/${calendar_id}/microsoft/auth_url`,
      {
        params: queryParams,
      }
    );

    return response.data;
  },
  async getAvailableGoogleCalendars(calendar_id) {
    const response = await axios.get(
      `/calendar/${calendar_id}/google/available_calendars`
    );

    return response.data;
  },
  async getAvailableMicrosoftCalendars(calendar_id) {
    const response = await axios.get(
      `/calendar/${calendar_id}/microsoft/available_calendars`
    );

    return response.data;
  },
  async getSchedulingRules() {
    const response = await axios.get(`/scheduling_rules`);

    return response.data;
  },
  async saveSchedulingRules(obj) {
    const response = await axios.put(`/scheduling_rules`, obj);
    return response.data;
  },
  async getGoogleCalendarIntegrationSettings(calendar_id) {
    const queryParams = {};

    const response = await axiosWithETAGCache.get(
      `/calendar/${calendar_id}/google/integration_settings`,
      {
        params: queryParams,
      }
    );

    return response.data;
  },
  async saveGoogleCalendarIntegrationSettings(obj) {
    const response = await axios.put(
      `/calendar/${obj.calendar_id}/google/integration_settings`,
      obj
    );
    return response.data;
  },
  async getMicrosoftCalendarIntegrationSettings(calendar_id) {
    const queryParams = {};

    const response = await axiosWithETAGCache.get(
      `/calendar/${calendar_id}/microsoft/integration_settings`,
      {
        params: queryParams,
      }
    );

    return response.data;
  },
  async saveMicrosoftCalendarIntegrationSettings(obj) {
    const response = await axios.put(
      `/calendar/${obj.calendar_id}/microsoft/integration_settings`,
      obj
    );
    return response.data;
  },
  async getGoogleCalendarTokenStatus(calendar_id) {
    const response = await axios.get(
      `/calendar/${calendar_id}/google/token_status`
    );
    return response.data;
  },
  async getMicrosoftCalendarTokenStatus(calendar_id) {
    const response = await axios.get(
      `/calendar/${calendar_id}/microsoft/token_status`
    );
    return response.data;
  },
  async createNewBooking(newBookingData) {
    const response = await axios.post(`/booking`, newBookingData);
    return response.data;
  },
  async getBookings(tableQuery) {
    const queryParams = {
      query: JSON.stringify(tableQuery),
    };

    const response = await axiosWithETAGCache.get(`/booking`, {
      params: queryParams,
    });

    return response.data;
  },
  async getBooking(id) {
    const response = await axiosWithETAGCache.get(`/booking/${id}`);

    return response.data;
  },
  async updateBooking(booking) {
    const response = await axios.put(`/booking/${booking.id}`, booking);
    return response.data;
  },
  async deleteBooking(bookingId) {
    const response = await axios.delete(`/booking/${bookingId}`);
    return response.data;
  },
  async getCalendars(tableQuery) {
    const queryParams = {
      query: JSON.stringify(tableQuery),
    };

    const response = await axiosWithETAGCache.get(`/calendar`, {
      params: queryParams,
    });

    return response.data;
  },
  async createNewCalendar(data) {
    const response = await axios.post(`/calendar`, data);

    return response.data;
  },
  async getCalendar(calendarId) {
    const queryParams = {};

    const response = await axiosWithETAGCache.get(`/calendar/${calendarId}`, {
      params: queryParams,
    });

    return response.data;
  },
  async saveCalendar(calendar) {
    const response = await axios.put(`/calendar/${calendar.id}`, calendar);
    return response.data;
  },
  async deleteCalendar(calendarId) {
    const response = await axios.delete(`/calendar/${calendarId}`);

    return response.data;
  },
};

export default api;
