import "./OutboundRulesEditor.scss";
import React, { useCallback } from "react";
import { WeeklyAvailabilityWidget } from "../../frontend/elements/WeeklyAvailabilityWidget";
import { TimeZoneSelector } from "../../frontend/elements/TimeZoneSelector";
import { OutboundSequenceEditor } from "./OutboundSequenceEditor";
import { PageSection } from "../../frontend/elements/PageSection";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Checkbox, FormControlLabel } from "@mui/material";

export const OutboundRulesEditor = ({
  value,
  onChange,
  outboundWord,
  enableRepeat,
  enableOutreachPrompt,
}) => {
  if (outboundWord === undefined) {
    outboundWord = "Outbound";
  }

  if (enableOutreachPrompt === undefined) {
    enableOutreachPrompt = true;
  }

  if (enableRepeat === undefined) {
    enableRepeat = false;
  }

  const handleAttemptSequenceChange = useCallback(
    (newSequence) => {
      const newValue = {
        ...value,
        attempt_sequence: newSequence,
      };
      onChange(newValue);
    },
    [value, onChange]
  );

  const handleChangeAllowedTimeWindows = useCallback(
    (newAllowedTimeWindows) => {
      onChange({ ...value, allowed_time_windows: newAllowedTimeWindows });
    },
    [value, onChange]
  );

  const handleChangeTimeZone = useCallback(
    (evt) => {
      onChange({ ...value, timezone: evt.target.value });
    },
    [value, onChange]
  );

  const handleChangeRepeatFrequency = useCallback(
    (evt) => {
      onChange({ ...value, repeat_sequence_every_days: evt.target.value });
    },
    [value, onChange]
  );

  const handleCheck = useCallback(
    (evt) => {
      onChange({ ...value, stop_after_success: evt.target.checked });
    },
    [value, onChange]
  );

  return (
    <div className='outbound-rules-editor'>
      <PageSection
        title={`${outboundWord} Sequence`}
        subheading={`Define the sequence of ${outboundWord.toLowerCase()} attempts that the bot will make to reach out to the user`}
      >
        <OutboundSequenceEditor
          value={value.attempt_sequence}
          onChange={handleAttemptSequenceChange}
          enableOutreachPrompt={enableOutreachPrompt}
        />
      </PageSection>

      <PageSection
        title={`Allowed time windows for ${outboundWord.toLowerCase()}s`}
        subheading={`Define the time windows during which the bot is allowed to make ${outboundWord.toLowerCase()} attempts`}
      >
        <WeeklyAvailabilityWidget
          value={value.allowed_time_windows ?? []}
          onChange={handleChangeAllowedTimeWindows}
          allowEmptyDays={false}
        />
        <br />
        <strong>Time Zone</strong>
        <br />

        <TimeZoneSelector
          name='timezone'
          value={value?.timezone ?? "America/Toronto"}
          onChange={handleChangeTimeZone}
          fullWidth={true}
        />
      </PageSection>

      <PageSection
        title={`Enable/Disable ${outboundWord.toLowerCase()} reminders`}
        subheading={`When set to true, the ${outboundWord.toLowerCase()} sequence of reminders will end when the conversation was successful`}
      >
        <FormControlLabel
          control={
            <Checkbox
              name='stop_after_success'
              checked={value?.stop_after_success ?? false}
              onChange={handleCheck}
              color='primary'
            />
          }
          label={`${value?.stop_after_success ? "Disable" : "Enable"}`}
        />
      </PageSection>

      {enableRepeat ? (
        <PageSection
          title={`How frequently should the ${outboundWord.toLowerCase()} be repeated?`}
          subheading={`Define how frequently the ${outboundWord.toLowerCase()} should be repeated.`}
        >
          <Select
            value={value?.repeat_sequence_every_days ?? ""}
            label='Frequency'
            onChange={handleChangeRepeatFrequency}
          >
            <MenuItem value={""}>No Repeat</MenuItem>
            <MenuItem value={30}>Monthly</MenuItem>
            <MenuItem value={91}>Quarterly</MenuItem>
            <MenuItem value={182}>Semi-Annually</MenuItem>
            <MenuItem value={365}>Annually</MenuItem>
          </Select>
        </PageSection>
      ) : null}
    </div>
  );
};

export default OutboundRulesEditor;
