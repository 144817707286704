import "./TableView.scss";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-enterprise";
import * as React from "react";
import { useCallback, useEffect, useMemo } from "react";
import FlagIcon from "@mui/icons-material/Flag";
import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import moment from "moment/moment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import { SpinnerIconButton } from "./SpinnerIconButton";
import globalTranslationManager from "../../translation/frontend/components/translation_manager";
import { useAPI } from "../components/APIProvider";
import TableElementLookupSelector from "./TableElementLookupSelector";
import { useIsAdmin } from "../components/IsAdminProvider";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const createDatasource = (fetchAPI, showOnlyMyData, userDetails, schema) => {
  return {
    // called by the grid when more rows are required
    getRows: (params) => {
      // get data for request from server
      const requestData = {
        startRow: params.request.startRow,
        endRow: params.request.endRow,
        filterModel: params.request.filterModel,
        sortModel: params.request.sortModel,
        fields: getTableViewFieldNames(schema),
      };

      if (showOnlyMyData) {
        requestData.filterModel.user_id = {
          filterType: "set",
          type: "equals",
          values: [userDetails.user_id, "", null],
        };
      }

      fetchAPI(requestData)
        .then((newRows) => {
          params.success({
            rowData: newRows,
          });
        })
        .catch(() => {
          params.fail();
        });
    },
  };
};

const getTableViewFieldNames = (schema) => {
  if (!schema) {
    return [];
  }

  let tableViewFields = Object.keys(schema.properties).filter((fieldName) => {
    const fieldSchema = schema.properties[fieldName];
    return fieldSchema.show_in_table_view;
  });

  tableViewFields = tableViewFields.sort((a, b) => {
    const aSchema = schema.properties[a];
    const bSchema = schema.properties[b];

    if ((aSchema.table_view_order ?? 5) < (bSchema.table_view_order ?? 5)) {
      return -1;
    } else if (
      (aSchema.table_view_order ?? 5) > (bSchema.table_view_order ?? 5)
    ) {
      return 1;
    } else {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    }
  });

  return tableViewFields;
};

function makeActionCellRenderer({ onDeleteClicked, onCopyClicked, allowDeleteOnRow }) {
  function ActionCellRenderer(params) {
    // let editingCells = params.api.getEditingCells();

    // checks if the rowIndex matches in at least one of the editing cells
    // let isCurrentRowEditing = editingCells.some((cell) => {
    //     return cell.rowIndex === params.node.rowIndex;
    // });

    const rowData = params.data;

    let allowDeleteOnRowResult = true;
    if (allowDeleteOnRow) {
      allowDeleteOnRowResult = allowDeleteOnRow(rowData);
    }

    const handleDeleteClicked = useCallback(
      (evt) => {
        if (onDeleteClicked) {
          evt.stopPropagation();
          return onDeleteClicked(rowData).then(() => {
            params.api.refreshServerSide({ purge: true });
          });
        }
      },
      [params.api, rowData]
    );

    const handleCopyClicked = useCallback(
      (evt) => {
        if (onCopyClicked) {
          evt.stopPropagation();
          return onCopyClicked(rowData).then(() => {
            params.api.refreshServerSide({ purge: true });
          });
        }
      },
      [params.api, rowData]
    );

    return (
      <>
        {onDeleteClicked && allowDeleteOnRowResult ? (
          <SpinnerIconButton
            aria-label='delete'
            onClick={handleDeleteClicked}
            data-action='delete'
            color={"error"}
            title={"Delete"}
          >
            <DeleteIcon />
          </SpinnerIconButton>
        ) : null}
        {onCopyClicked ? (
          <SpinnerIconButton
            aria-label='copy'
            onClick={handleCopyClicked}
            data-action='copy'
            color={"primary"}
            title={"Copy"}
          >
            <ContentCopyIcon />
          </SpinnerIconButton>
        ) : null}
      </>
    );
  }

  return ActionCellRenderer;
}

export const SmartChainBindingEditor = ({
  value,
  onValueChange,
  stopEditing,
}) => {
  const api = useAPI();

  return (
    <TableElementLookupSelector
      label={"smart chain"}
      getSuggestions={api.getSmartChains}
      getSingleItem={api.getSmartChain}
      idFieldName={"chain_name"}
      searchFieldName={"chain_name"}
      displayFieldName={"chain_name"}
      value={value}
      onChange={onValueChange}
      onBlur={stopEditing}
      multiple={false}
    />
  );
};

const allStringFilterOptions = [
  {
    displayKey: "contains",
    displayName: "Contains",
    predicate: () => null,
  },
  {
    displayKey: "notContains",
    displayName: "Does not contain",
    predicate: () => null,
  },
  {
    displayKey: "startsWith",
    displayName: "Starts with",
    predicate: () => null,
  },
  {
    displayKey: "endsWith",
    displayName: "Ends with",
    predicate: () => null,
  },
  {
    displayKey: "equals",
    displayName: "Equals",
    predicate: () => null,
  },
  {
    displayKey: "notEqual",
    displayName: "Not equal",
    predicate: () => null,
  },
  {
    displayKey: "blank",
    displayName: "Blank",
    predicate: () => null,
  },
  {
    displayKey: "notBlank",
    displayName: "Not blank",
    predicate: () => null,
  },
];

const makeColumnDefsFromSchema = (schema, eventHandlers, editable) => {
  if (!schema) {
    return [];
  }

  // First, filter the list of properties for all the fields that should be shown in the table view
  let tableViewFields = getTableViewFieldNames(schema);

  let columnDefs = tableViewFields.map((fieldName) => {
    const fieldSchema = schema.properties[fieldName];
    let fieldTypes = _.isArray(fieldSchema.type)
      ? fieldSchema.type
      : [fieldSchema.type];
    if (!fieldSchema.type && fieldSchema.anyOf) {
      fieldTypes = fieldSchema.anyOf.map((subSchema) => subSchema.type);
    }

    if (fieldTypes.includes("boolean")) {
      let cellRenderer = (params) => {
        if (params.value) {
          return "yes";
        }
        return "no";
      };

      if (fieldSchema.use_flag_icon_for_table_view) {
        cellRenderer = (params) => {
          if (params.value) {
            return <FlagIcon color={"error"} />;
          }
          return "no";
        };
      }

      return {
        field: fieldName,
        headerName:
          globalTranslationManager.getTranslationIfAvailableAndEnabledSync(
            fieldSchema.title,
            globalTranslationManager.getLocalLanguage()
          ),
        cellRenderer: cellRenderer,
        filter: "agSetColumnFilter",
        cellEditor: "agTextCellEditor",
        editable: editable,
        floatingFilter: true,
        filterParams: {
          maxNumConditions: 1,
          buttons: ["reset", "cancel"],
          values: ["yes", "no"],
        },
      };
    } else if (fieldSchema.enum) {
      return {
        field: fieldName,
        headerName:
          globalTranslationManager.getTranslationIfAvailableAndEnabledSync(
            fieldSchema.title,
            globalTranslationManager.getLocalLanguage()
          ),
        filter: "agSetColumnFilter",
        cellEditor: "agTextCellEditor",
        editable: editable,
        floatingFilter: true,
        filterParams: {
          maxNumConditions: 1,
          buttons: ["reset", "cancel"],
          values: fieldSchema.enum,
        },
      };
    } else if (
      fieldTypes.includes("number") ||
      fieldTypes.includes("integer")
    ) {
      return {
        field: fieldName,
        headerName:
          globalTranslationManager.getTranslationIfAvailableAndEnabledSync(
            fieldSchema.title,
            globalTranslationManager.getLocalLanguage()
          ),
        filter: "agNumberColumnFilter",
        cellEditor: "agTextCellEditor",
        editable: editable,
        filterParams: {
          maxNumConditions: 1,
          buttons: ["reset"],
        },
        floatingFilter: true,
      };
    } else if (fieldSchema.is_date) {
      return {
        field: fieldName,
        headerName:
          globalTranslationManager.getTranslationIfAvailableAndEnabledSync(
            fieldSchema.title,
            globalTranslationManager.getLocalLanguage()
          ),
        filter: "agDateColumnFilter",
        cellEditor: "agTextCellEditor",
        editable: editable,
        filterParams: {
          comparator: function (filterLocalDateAtMidnight, cellValue) {
            const cellDate = moment.utc(cellValue).local().startOf("day");
            const filterDate = moment
              .utc(filterLocalDateAtMidnight)
              .local()
              .startOf("day");
            if (cellDate < filterDate) {
              return -1;
            } else if (cellDate > filterDate) {
              return 1;
            } else {
              return 0;
            }
          },
          buttons: ["reset"],
        },
        cellRenderer: (params) => {
          return moment
            .utc(params.value)
            .local()
            .format("MMM Do, YYYY, h:mm a");
        },
        floatingFilter: true,
      };
    } else if (fieldSchema.is_smart_chain_binding) {
      return {
        field: fieldName,
        headerName:
          globalTranslationManager.getTranslationIfAvailableAndEnabledSync(
            fieldSchema.title,
            globalTranslationManager.getLocalLanguage()
          ),
        filter: "agTextColumnFilter",
        cellEditor: SmartChainBindingEditor,
        editable: editable,
        filterParams: {
          maxNumConditions: 1,
          buttons: ["reset"],
          filterOptions: allStringFilterOptions,
        },
        floatingFilter: true,
      };
    } else if (fieldTypes.includes("string") || fieldTypes.includes("array")) {
      if (fieldSchema.enable_efficient_full_text_search) {
        return {
          field: fieldName,
          headerName:
            globalTranslationManager.getTranslationIfAvailableAndEnabledSync(
              fieldSchema.title,
              globalTranslationManager.getLocalLanguage()
            ),
          filter: "agTextColumnFilter",
          cellEditor: "agTextCellEditor",
          editable: editable,
          filterParams: {
            maxNumConditions: 1,
            buttons: ["reset"],
            filterOptions: allStringFilterOptions,
          },
          floatingFilter: true,
        };
      } else {
        // Without efficient full-text search, we remove some
        // of the filter options that are not efficient
        return {
          field: fieldName,
          headerName:
            globalTranslationManager.getTranslationIfAvailableAndEnabledSync(
              fieldSchema.title,
              globalTranslationManager.getLocalLanguage()
            ),
          filter: "agTextColumnFilter",
          cellEditor: "agTextCellEditor",
          editable: editable,
          filterParams: {
            maxNumConditions: 1,
            buttons: ["reset"],
            filterOptions: allStringFilterOptions.filter((filterOption) => {
              const badFilterOptions = ["contains", "notContains", "endsWith"];

              return !badFilterOptions.includes(filterOption.displayKey);
            }),
          },
          floatingFilter: true,
        };
      }
    } else {
      throw new Error(`Unrecognized field type: ${fieldTypes.join(", ")}`);
    }
  });

  if (
    Object.values(eventHandlers).some((value) => Boolean(value)) &&
    Object.keys(eventHandlers).length > 0
  ) {
    columnDefs.push({
      headerName:
        globalTranslationManager.getTranslationIfAvailableAndEnabledSync(
          "Actions",
          globalTranslationManager.getLocalLanguage()
        ),
      minWidth: 150,
      cellRenderer: makeActionCellRenderer(eventHandlers),
      editable: false,
      colId: "action",
    });
  }

  return columnDefs;
};

const getRowId = (params) => params.data._id ?? params.data.id;

export function TableView({
  schemaAPI,
  fetchAPI,
  updateAPI,
  onRowClicked,
  onDeleteClicked,
  allowDeleteOnRow,
  onCopyClicked,
  rowModelType,
  rowData,
  editable,
}) {
  const [schema, setSchema] = React.useState(null);
  const [showOnlyMyData, setShowOnlyMyData] = React.useState(true);
  const [userDetails, setUserDetails] = React.useState(null);
  const isAdmin = useIsAdmin();
  const api = useAPI();

  if (!rowModelType) {
    rowModelType = "serverSide";
  }

  useEffect(() => {
    schemaAPI().then((schema) => {
      setSchema(schema);
    });
  }, [schemaAPI]);

  useEffect(() => {
    api.getUserDetails().then((userDetails) => {
      setUserDetails(userDetails);
    });
  }, []);

  const eventHandlers = useMemo(
    () => ({
      onDeleteClicked,
      onCopyClicked,
      allowDeleteOnRow,
    }),
    [onDeleteClicked, onCopyClicked, allowDeleteOnRow]
  );

  const columns = useMemo(
    () => makeColumnDefsFromSchema(schema, eventHandlers, editable),
    [schema, eventHandlers, editable, api]
  );

  const datasource = useMemo(
    () => createDatasource(fetchAPI, showOnlyMyData, userDetails, schema),
    [fetchAPI, showOnlyMyData, userDetails, schema]
  );

  const handleCellValueChanged = useCallback((params) => {
    updateAPI(params.data);
  }, []);

  const handleShowOnlyMyDataChange = useCallback((evt) => {
    setShowOnlyMyData(evt.target.checked);
  }, []);

  const gridOptions = useMemo(
    () => ({
      rowModelType: rowModelType,
      columnDefs: columns,
      serverSideDatasource: datasource,
      pagination: true,
      paginationPageSize: 20,
      paginationPageSizeSelector: [10, 20, 50, 100],
      onCellClicked(params) {
        if (params.column.colId !== "action") {
          if (onRowClicked) {
            onRowClicked(params.data);
          }
        }
      },
      autoSizeStrategy: {
        type: "fitCellContents",
        skipHeader: false,
      },
      autoSizePadding: 20,
    }),
    [columns, datasource, onRowClicked, rowModelType]
  );

  if (!schema || !userDetails) {
    return null;
  }

  if (rowData) {
    rowData.forEach((row) => {
      row._id = row._id ?? row.id;
    });
  }

  return (
    <div className={"table-view"}>
      <div
        className={"table-element-wrapper ag-theme-quartz"}
        key={String(showOnlyMyData)}
      >
        <AgGridReact
          columnDefs={columns}
          gridOptions={gridOptions}
          getRowId={getRowId}
          rowData={rowData}
          onCellValueChanged={handleCellValueChanged}
        />
      </div>
      {isAdmin ? (
        <div className={"show-only-my-data-option"}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showOnlyMyData}
                  onChange={handleShowOnlyMyDataChange}
                />
              }
              label='Show only my data'
            />
          </FormGroup>
        </div>
      ) : null}
    </div>
  );
}
