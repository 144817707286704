// Make modifications to the theme with your own fields and widgets
import * as React from "react";
import { useCallback, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { withTheme } from "@rjsf/core";
import { Theme } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import json_schema from "./json_schema_schema.json";
import "./JSONSchemaForm.scss";
import _ from "lodash";
import ToggleButton from "@mui/material/ToggleButton";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import SourceIcon from "@mui/icons-material/Source";
import CodeEditor from "./CodeEditor";

const StyledForm = withTheme(Theme);
const ExampleAutocompletingTextBox = function ({
  id,
  name,
  required,
  readonly,
  value,
  disabled,
  label,
  autofocus,
  multiple,
  schema,
  onChange,
}) {
  const handleChangeTextField = useCallback(
    (event) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  const handleChangeAutocomplete = useCallback(
    (event, value) => {
      onChange(value);
    },
    [onChange]
  );

  if (!schema.examples || schema.examples.length === 0) {
    return (
      <TextField
        id={id}
        required={required}
        aria-readonly={readonly}
        disabled={disabled}
        label={label}
        value={value ?? ""}
        autoFocus={autofocus}
        onChange={handleChangeTextField}
      />
    );
  } else {
    return (
      <Autocomplete
        id={id}
        required={required}
        aria-readonly={readonly}
        disabled={disabled}
        value={value ?? ""}
        autoFocus={autofocus}
        multiple={multiple}
        onChange={handleChangeAutocomplete}
        noOptionsText={null}
        freeSolo
        options={schema.examples}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    );
  }
};
const widgets = {
  TextWidget: ExampleAutocompletingTextBox,
};

function ViewToggleButton({ isTextView, onClick }) {
  return (
    <ToggleButton
      value='check'
      onChange={onClick}
      color={"primary"}
      className='view-toggle-button'
    >
      {!isTextView ? <DeveloperModeIcon /> : <SourceIcon />}
    </ToggleButton>
  );
}

export function JSONSchemaForm(props) {
  const [viewMode, setViewMode] = React.useState("form");

  const handleViewModeChange = useCallback(() => {
    setViewMode(viewMode === "form" ? "text" : "form");
  }, [viewMode]);

  let dataString = JSON.stringify(props.formData, null, 2);

  const handleTextChange = useCallback(
    (event) => {
      dataString = event.target.value;
      try {
        const data = JSON.parse(dataString);
        if (props.onChange) {
          props.onChange(data);
        } else if (props.handleChange) {
          props.handleChange(data);
        }
      } catch (e) {
        console.error(e);
      }
    },
    [props.onChange, props.handleChange]
  );

  return (
    <div className={"json-schema-form"}>
      {viewMode === "form" ? (
        <StyledForm
          schema={props.schema}
          validator={validator}
          onChange={props.handleChange ?? props.onChange}
          onSubmit={props.handleSubmit ?? props.onSubmit}
          onError={props.handleError ?? props.onError}
          submitText={props.submitText}
          widgets={widgets}
          formData={props.formData ?? props.value}
          readOnly={props.readOnly ?? false}
          disabled={props.disabled ?? false}
          uiSchema={props.uiSchema}
        />
      ) : null}
      {viewMode === "text" ? (
        <CodeEditor
          height={"70vh"}
          defaultLanguage='json'
          value={dataString}
          readOnly={props.readOnly ?? false}
          onChange={handleTextChange}
          theme={"vs-light"}
        />
      ) : null}

      <ViewToggleButton
        isTextView={viewMode === "text"}
        onClick={handleViewModeChange}
      />
    </div>
  );
}

const schemaEditorUiSchema = {
  "ui:submitButtonOptions": {
    norender: true,
  },
};

export function JSONSchemaEditor(props) {
  const handleChange = useCallback(
    (event) => {
      const newSchema = _.cloneDeep(event.formData);
      recursiveFixJSONSchema(newSchema);
      if (props.onChange) {
        props.onChange(newSchema);
      } else if (props.handleChange) {
        props.handleChange(newSchema);
      }
    },
    [props.onChange]
  );

  return (
    <div className={"json-schema-editor"}>
      <JSONSchemaForm
        schema={json_schema}
        onChange={handleChange}
        onSubmit={props.handleSubmit ?? props.onSubmit}
        onError={props.handleError ?? props.onError}
        submitText={"Run"}
        formData={props.value}
        readOnly={props.readOnly ?? false}
        disabled={props.disabled ?? false}
        uiSchema={schemaEditorUiSchema}
      />
    </div>
  );
}

const recursiveFixJSONSchema = function (schema) {
  if (!schema) {
    return;
  }

  if (!schema.type) {
    if (schema.properties) {
      schema.type = "object";
    } else if (schema.items) {
      schema.type = "array";
    } else {
      schema.type = "string";
    }
  }
  if (schema.type === "object") {
    if (schema.properties) {
      Object.keys(schema.properties).forEach((key) => {
        recursiveFixJSONSchema(schema.properties[key]);
      });
    }
  } else if (schema.type === "array") {
    if (schema.items) {
      recursiveFixJSONSchema(schema.items);
    }
  }
};
