import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "./LLMModelSelector.scss";
import * as React from "react";

export function LLMModelSelector({value, onChange}) {
    const handleChange = (event) => {
        if (event.target.value === "default") {
            onChange("");
        } else {
            onChange(event.target.value);
        }
    };

    if (!value) {
        value = "default";
    }

    return (
        <div className={"llm-model-selector nodrag"}>
            <Select
                id="llm-model-selector"
                value={value}
                label="LLM Model"
                onChange={handleChange}
                autoWidth
            >
                <MenuItem value={"default"}>Default</MenuItem>
                <MenuItem value={"gpt-4o-mini"}>GPT 4o Mini</MenuItem>
                <MenuItem value={"gpt-3.5-turbo"}>GPT 3.5 Turbo</MenuItem>
                <MenuItem value={"gpt-4o"}>GPT-4o</MenuItem>
                <MenuItem value={"gpt-4-turbo"}>GPT-4 Turbo</MenuItem>
                <MenuItem value={"claude-3-5-sonnet-20240620"}>Claude 3.5 Sonnet</MenuItem>
                <MenuItem value={"claude-3-opus-20240229"}>Claude 3 Opus</MenuItem>
                <MenuItem value={"claude-3-sonnet-20240229"}>Claude 3 Sonnet</MenuItem>
                <MenuItem value={"claude-3-haiku-20240307"}>Claude 3 Haiku</MenuItem>
                <MenuItem value={"gemini-1.5-pro"}>Gemini 1.5 Pro</MenuItem>
                <MenuItem value={"gemini-1.5-flash"}>Gemini 1.5 Flash</MenuItem>
                <MenuItem value={"gemini-1.0-pro"}>Gemini 1.0 Pro</MenuItem>
                <MenuItem value={"command-r-plus-08-2024"}>Command-R Plus 08-2024 (Cohere</MenuItem>
                <MenuItem value={"command-r-08-2024"}>Command-R 08-2024 (Cohere</MenuItem>
                <MenuItem value={"command-r-plus"}>Command-R Plus (Cohere)</MenuItem>
                <MenuItem value={"command-r"}>Command-R (Cohere)</MenuItem>
                <MenuItem value={"llama-3.1-70b-versatile"}>Llama 3.1 70b Versatile on Groq</MenuItem>
                <MenuItem value={"llama-3.1-8b-instant"}>Llama 3.1 8b Instant on Groq</MenuItem>
                <MenuItem value={"llama-guard-3-8b"}>Llama Guard 8b on Groq</MenuItem>
                <MenuItem value={"llama3-70b-8192"}>Llama 3 70b 8192k limit on Groq</MenuItem>
                <MenuItem value={"llama3-8b-8192"}>Llama 3 8b 8192k limit on Groq</MenuItem>
                <MenuItem value={"gemma-7b-it"}>Gemma 7b Instant on Groq</MenuItem>
                <MenuItem value={"gemma2-9b-it"}>Gemma2 9b Instant on Groq</MenuItem>
                <MenuItem value={"mistral-large-latest"}>Mistral Large</MenuItem>
                <MenuItem value={"mistral-small-latest"}>Mistral Small</MenuItem>
                <MenuItem value={"open-mixtral-8x22b"}>Mistral 8x22B</MenuItem>
                <MenuItem value={"open-mixtral-8x7b"}>Mistral 8x7B</MenuItem>
                <MenuItem value={"open-mistral-7b"}>Mistral 7B</MenuItem>
            </Select>
        </div>
    );
}