import React, {useCallback} from "react";
import {SpinnerButton} from "../../../frontend/elements/SpinnerButton";
import SchemaIcon from '@mui/icons-material/Schema';
import {useNavigate} from "react-router-dom";
import "./KnowledgeChunkViewer.scss";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from "@mui/material/Typography";
import moment from "moment/moment";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";

export default function KnowledgeChunkViewer({knowledgeChunk}) {
    const navigate = useNavigate();

    return <div className={"knowledge-chunk-viewer"}>
        <div className={"accordian-wrapper"}>
        {
            knowledgeChunk._id ?
                <Typography variant={"p"}>
                    <strong>Knowledge Chunk ID:</strong>
                    {knowledgeChunk._id}
                </Typography>
                : null
        }
        {
            knowledgeChunk.originating_object_id ?
                <Typography variant={"p"}>
                    <strong>Originating Object ID:</strong>
                    {knowledgeChunk.originating_object_id}
                </Typography>
                : null
        }
        {
            knowledgeChunk.user_id ?
                <Typography variant={"p"}>
                    <strong>User ID:</strong>
                    {knowledgeChunk.user_id}
                </Typography>
                : null
        }
        <br/>
        <br/>
        <br/>
        {
            knowledgeChunk.matching_text ?
                <Typography variant={"p"}>
                    <strong>Matching Text:</strong>
                    <pre><LocalTranslatedText text={knowledgeChunk.matching_text} /></pre>
                </Typography>
                : null
        }
        {
            knowledgeChunk.qualifying_text ?
                <Typography variant={"p"}>
                    <strong>Qualifying Text:</strong>
                    <pre><LocalTranslatedText text={knowledgeChunk.qualifying_text} /></pre>
                </Typography>
                : null
        }
        {
            knowledgeChunk.content_text ?
                <Typography variant={"p"}>
                    <strong>Content Text:</strong>
                    <pre><LocalTranslatedText text={knowledgeChunk.content_text} /></pre>
                </Typography>
                : null
        }
        </div>
    </div>
}
