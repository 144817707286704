import FormControl from "@mui/material/FormControl";
import React, { useCallback } from "react";
import Typography from "@mui/material/Typography";
import { useChangeFormData, useFormData, useFormOptions } from "../FormRoot";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "./DropdownInput.scss";
import LocalTranslatedText from "../../../../translation/frontend/components/LocalTranslatedText";

export function DropdownInput({
  label,
  fieldName,
  options,
  optionLabels,
  placeholder,
  emptyLabel,
  disabled,
  size,
}) {
  const formData = useFormData();
  const changeFormData = useChangeFormData();
  const formOptions = useFormOptions();
  if (!optionLabels) {
    optionLabels = options;
  }

  const handleChange = useCallback(
    (event) => {
      changeFormData(fieldName, event.target.value);
    },
    [fieldName, changeFormData]
  );

  let disabledLocal = !formOptions.editable || disabled;
  let valueLocal = formData[fieldName] ?? "";

  return (
    <div className={"form-input dropdown-input"}>
      {label ? (
        <Typography variant={"label"}>
          <LocalTranslatedText language={"en"} text={label} />
        </Typography>
      ) : null}
      <FormControl>
        <Select
          value={disabledLocal ? "" : valueLocal}
          name={fieldName}
          onChange={handleChange}
          disabled={disabledLocal}
          displayEmpty={true}
          renderValue={(value) =>
            value ? (
              optionLabels[options.indexOf(value)]
            ) : (
              <span className={"placeholder"}>{placeholder}</span>
            )
          }
          size={size || "medium"}
        >
          <MenuItem value={""}>{emptyLabel ?? "None"}</MenuItem>
          {options.map((optionStr, optionIndex) => (
            <MenuItem value={optionStr} key={optionStr}>
              {optionLabels[optionIndex]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default DropdownInput;
