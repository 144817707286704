import {PageWrapperWithMenu} from "../elements/PageWrapperWithMenu";
import React from "react";
import {PageHeader} from "../elements/PageHeader";
import {PageSection} from "../elements/PageSection";



export default function TermsAndConditionsPage() {
    return <PageWrapperWithMenu>
        <PageHeader title={`Terms and Conditions`} />

        <PageSection>
            <p>This page has yet to be implemented.</p>
        </PageSection>
    </PageWrapperWithMenu>
}

