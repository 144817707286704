import "./BookingsListPage.scss";
import Paper from "@mui/material/Paper";
import {useCallback} from "react";
import {BookingsTable} from "./BookingsTable";
import {PageHeader} from "../../../../core/frontend/elements/PageHeader";
import {PageWrapperWithMenu} from "../../../../core/frontend/elements/PageWrapperWithMenu";
import {WhiteButton} from "../../../../core/frontend/elements/WhiteButton";
import {useNavigate} from "react-router-dom";


export default function BookingsListPage() {
    const navigate = useNavigate();

    const handleBookingClicked = useCallback((booking) => {
        navigate(`/bookings/${booking.id}`)
    }, [navigate]);

    const handleNewBookingClicked = useCallback(async () => {
        navigate(`/bookings/new`);
    }, [navigate]);

    return <PageWrapperWithMenu>
        <PageHeader title={"Bookings"}>
            <WhiteButton onClick={handleNewBookingClicked}>
                Add New Booking
            </WhiteButton>
        </PageHeader>
        <div className="booking-list-page">
            <Paper className={"main-page-contents"}>
                <BookingsTable onBookingClicked={handleBookingClicked}/>
            </Paper>
        </div>
    </PageWrapperWithMenu>
}

