import * as React from 'react';
import {TableView} from "../../../frontend/elements/TableView";
import {useAPI} from "../../../frontend/components/APIProvider";
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

export function GeneratedDocumentTable() {
    const api = useAPI();
    const navigate = useNavigate();

    const handleGeneratedDocumentRowClicked = useCallback((documentTemplate) => {
        navigate(`/generated_document/${documentTemplate.id}`);
    }, [navigate]);

    const handleDeleteClicked = useCallback((documentTemplate) => {
        return api.deleteGeneratedDocument(documentTemplate.id);
    }, [api]);


    return (
        <TableView
            schemaAPI={api.getGeneratedDocumentSchema}
            fetchAPI={api.getGeneratedDocumentTable}
            onRowClicked={handleGeneratedDocumentRowClicked}
            onDeleteClicked={handleDeleteClicked}
        />
    );
}
