import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import React, { useCallback } from "react";
import { Card, CardContent } from "@mui/material";
import { useAPI } from "../../../frontend/components/APIProvider";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import CustomFormForm from "./CustomFormForm";
import { useNavigate } from "react-router-dom";

export default function NewCustomFormPage(props) {
  const api = useAPI();
  const navigate = useNavigate();
  const handleCreateCustomForm = useCallback(
    async (customFormData) => {
      return await api
        .createNewCustomForm(customFormData)
        .then((newSchema) => {
          navigate(`/custom_form/${newSchema.id}`);
        });
    },
    [api, navigate]
  );

  return (
    <PageWrapperWithMenu>
      <PageHeader title={`New Form`} />
      <div className='custom-form-edit-page'>
        <Card className='custom-form-edit-form-card'>
          <CardContent>
            <CustomFormForm
              initialCustomForm={{
                json_schema: { type: "object", properties: {} },
              }}
              onCustomFormSave={handleCreateCustomForm}
              saveButtonText={"Create New Custom Form"}
            />
          </CardContent>
        </Card>
      </div>
    </PageWrapperWithMenu>
  );
}
