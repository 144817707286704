import "./OutputNode.scss";
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import useChartStore from "../../ChartState";
import {useCallback, useState,} from 'react';
import {OutputNodeIcon} from "../../components/icons";
import NodeCustomPromptEditor from "../../components/NodeCustomPromptEditor";
import {NodeInputHandle} from "../../components/NodeHandle";
import PromptManipulator from "../../components/PromptManipulator";
import CopyContentsActionButton from "../../components/CopyContentsActionButton";



function OutputNode({id: nodeId, isConnectable}) {
    const {node, recomputeChart, isUpdatingChart} = useChartStore((state) => {
        return {
            node: state.getNode(nodeId),
            recomputeChart: state.recomputeChart,
            isUpdatingChart: state.is_recomputing_chart,
        }
    });

    const [isEditingPrompt, setIsEditingPrompt] = useState(node?.data.stale);

    const handleSavePromptClicked = useCallback(() => {
        setIsEditingPrompt(false);

        // Trigger the chart to start updating in the background.
        recomputeChart();
    }, [recomputeChart]);

    const handleEditPrompt = useCallback(() => {
        setIsEditingPrompt(true);
    }, []);

    const onKeyDown = useCallback((event) => {
        // This is needed to allow the user to do things like press ctrl-delete
        // without triggering ReactFlow to delete the node.
        event.stopPropagation();
    }, []);

    if (!node) {
        return;
    }

    const combinedCurrentValue = (node.data.currentValues ?? []).join("\n\n").trim();

    const showPromptEditor = isEditingPrompt;
    const showCurrentValue = !isEditingPrompt && (!isUpdatingChart || !node.data.stale);
    const showLoadingIndicator = !isEditingPrompt && isUpdatingChart && node.data.stale;

    return (
        <div className="output-node"
             onKeyDown={onKeyDown}
             title={"Output"}
        >
            <Card>
                <CardHeader avatar={<OutputNodeIcon/>} title={node.data.title}/>
                <CardContent className={"output-node-content"}>
                    {
                        showPromptEditor ?
                            <NodeCustomPromptEditor
                                nodeId={nodeId}
                                savePrompt={handleSavePromptClicked}
                            />
                            : null
                    }
                    {
                        showCurrentValue ?
                            <div
                                className={"current-value-container " + (!combinedCurrentValue ? "current-value-container-empty" : "")}>
                                {
                                    (combinedCurrentValue) ? combinedCurrentValue.split("\n").map((line, index) => {
                                        return <span className={"current-value"} key={index}>
                                            {line}<br/>
                                        </span>
                                    }) : null
                                }
                                {
                                    (!combinedCurrentValue) && !isUpdatingChart ?
                                        <span className={"current-value no-data"}>No data...</span>
                                        : null
                                }

                                <div className={"current-value-overlay"}
                                     onClick={handleEditPrompt}
                                     title={"Edit Output Format or Drag to Move"}
                                >
                                    <IconButton aria-label="edit prompt" className={"edit-button"}>
                                        <EditIcon className={"edit-icon"}/>
                                    </IconButton>
                                </div>
                            </div>
                            : null
                    }
                    {
                        showLoadingIndicator ?
                            <div className={"spinner-wrapper"}>
                                <CircularProgress size={20}/>
                            </div>
                            : null
                    }
                </CardContent>
                <CardActions disableSpacing className={"output-node-action-bar"}>
                    <div className={"output-node-start-action-buttons"}>
                        <IconButton
                            aria-label="hidden button"
                            className={'nodrag hidden-button'}
                        >
                            <SaveIcon/>
                        </IconButton>
                    </div>
                    <div className={"output-node-center-action-buttons"}>
                        {
                            isEditingPrompt ?
                                <IconButton
                                    aria-label="Save prompt"
                                    onClick={handleSavePromptClicked}
                                    className={'nodrag'}
                                    disabled={(new PromptManipulator(node.data.prompt)).isPromptEmpty()}
                                >
                                    <SaveIcon/>
                                </IconButton>
                                : null
                        }
                    </div>
                    <div className={"output-node-end-action-buttons"}>
                        <CopyContentsActionButton
                            nodeId={nodeId}
                        />
                    </div>
                </CardActions>
            </Card>
            <NodeInputHandle isConnectable={isConnectable} nodeId={nodeId} />
        </div>
    );
}

export default OutputNode;
