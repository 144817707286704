import {PageWrapperWithMenu} from "../../../core/frontend/elements/PageWrapperWithMenu";
import React, {useCallback, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Card, CardContent} from "@mui/material";
import "./TopicExtractorPromptEditPage.scss";
import {useAPI} from "../../../core/frontend/components/APIProvider";
import {PageHeader} from "../../../core/frontend/elements/PageHeader";
import TopicExtractorPromptForm from "./TopicExtractorPromptForm";
import CircularProgress from "@mui/material/CircularProgress";


export default function TopicExtractorPromptEditPage(props) {
    const api = useAPI();
    const params = useParams();

    const navigate = useNavigate();
    const [topicExtractorPrompt, setTopicExtractorPrompt] = useState(null);

    const handleTopicExtractorPromptSave = useCallback(async (newTopicExtractorPrompt) => {
        return await api.saveTopicExtractorPrompt(newTopicExtractorPrompt);
    }, [api]);

    const handleDeleteClicked = useCallback(async () => {
        return await api.deleteTopicExtractorPrompt(topicExtractorPrompt.id).then(() => {
            navigate(`/topic_extractor_prompts`);
        })
    }, [navigate, api, topicExtractorPrompt?.id]);

    React.useEffect(() => {
        if (!topicExtractorPrompt) {
            api.getTopicExtractorPrompt(params?.topicExtractorPromptId).then((topicExtractorPrompt) => {
                setTopicExtractorPrompt(topicExtractorPrompt);
            });
        }
    }, [handleTopicExtractorPromptSave, topicExtractorPrompt, params?.topicExtractorPromptId, api]);

    if (!topicExtractorPrompt) {
        return <PageWrapperWithMenu>
            <PageHeader title={`Edit Topic Extractor Prompt`}/>
            <div className="topic-extractor-prompt-edit-page">
                <CircularProgress/>
            </div>
        </PageWrapperWithMenu>
    }

    return <PageWrapperWithMenu>
        <PageHeader title={`Edit Topic Extractor Prompt`}/>
        <div className="topic-extractor-prompt-edit-page">
            <Card className="topic-extractor-prompt-edit-form-card">
                <CardContent>
                    <TopicExtractorPromptForm
                        initialTopicExtractorPrompt={topicExtractorPrompt}
                        onTopicExtractorPromptSave={handleTopicExtractorPromptSave}
                        onDeletedClicked={handleDeleteClicked}
                    />
                </CardContent>
            </Card>
        </div>

    </PageWrapperWithMenu>
}