import "../nodes/PromptNode/PromptNode.scss";
import "./ActionButton.scss";
import * as React from 'react';
import BehaviorTracker from "../../../../core/frontend/components/BehaviorTracker";
import CheckIcon from '@mui/icons-material/Check';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import IconButton from '@mui/material/IconButton';
import useChartStore from "../ChartState";
import {useCallback, useState,} from 'react';
import {useSnackbar} from "../../../../core/frontend/components/SnackbarProvider";

export function CopyContentsActionButton({nodeId}) {
    const {node} = useChartStore((state) => {
        return {
            node: state.getNode(nodeId),
        }
    });

    const [showingCopySuccess, setShowingCopySuccess] = useState(false);
    const snackbar = useSnackbar();

    const handleCopyContentsClicked = useCallback(() => {
        setShowingCopySuccess(true);
        if (process.env.REACT_APP_PROMPT_CHART_ENABLE_CLIPBOARD !== "false") {
            navigator.clipboard.writeText(node.data.currentValues.join("\n\n"));
        }

        snackbar.toast({
            message: `Copied contents to clipboard`,
            severity: "success",
            autoHideMs: 5000
        });
        setTimeout(() => {
            setShowingCopySuccess(false);
        }, 1000);

        BehaviorTracker.trackInteraction({
            id: "copy-contents-action-button",
            mixpanel: null
        });
    }, [node, snackbar]);

    if (!node) {
        return;
    }

    return (
                        <IconButton
                            aria-label="Copy"
                            onClick={handleCopyContentsClicked}
                            className={'nodrag actionbutton'}
                            title={"Copy to Clipboard"}
                        >
                            {
                                showingCopySuccess ?
                                    <CheckIcon /> :
                                    <ContentPasteIcon />
                            }
                        </IconButton>
    )
}

export default CopyContentsActionButton;
