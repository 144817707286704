import "./CalendarSelector.scss";
import React, { useCallback, useEffect } from "react";
import {
  useAPI,
  useIsAuthenticated,
} from "../../../../core/frontend/components/APIProvider";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export function CalendarSelector({ value, onCalendarSelected }) {
  const api = useAPI();
  const isAuthenticated = useIsAuthenticated();
  const [calendars, setCalendars] = React.useState([]);

  useEffect(() => {
    if (isAuthenticated) {
      api
        .getCalendars({
          startRow: 0,
          endRow: 100,
          filterModel: {},
          sortModel: [],
          fields: ["person_name"],
        })
        .then((data) => {
          setCalendars(data);
        });
    }
  }, [isAuthenticated, api]);

  const handleChange = useCallback(
    (event) => {
      onCalendarSelected(event.target.value);
    },
    [onCalendarSelected]
  );

  return (
    <FormControl fullWidth className={"calendar-selector"}>
      <InputLabel id='calendar-selector-label'>Calendars</InputLabel>
      <Select
        labelId='calendar-selector-label'
        id='calendar-selector'
        value={value}
        label='Calendar'
        onChange={handleChange}
      >
        {calendars.map((calendar, idx) => {
          return (
            <MenuItem value={calendar.id} key={idx}>
              {calendar.person_name}
            </MenuItem>
          );
        })}
        {calendars.length === 0 ? (
          <MenuItem disabled={true}>No calendars created yet.</MenuItem>
        ) : null}
      </Select>
    </FormControl>
  );
}
