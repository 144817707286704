import React, { useCallback } from "react";
import FormInput from "../../core/frontend/elements/form/FormInput";
import FormRoot from "../../core/frontend/elements/form/FormRoot";
import FormSection from "../../core/frontend/elements/form/FormSection";
import "./PersonalInfoForm.scss";

export default function PersonalInfoForm({
  initialPersonalInfo,
  onPersonalInfoSave,
}) {
  const handlePersonalInfoSaved = useCallback(
    (newPersonalInfo) => {
      if (onPersonalInfoSave) {
        return onPersonalInfoSave(newPersonalInfo);
      }
    },
    [onPersonalInfoSave]
  );

  return (
    <div className='personal-info-form'>
      <FormRoot
        initialValue={initialPersonalInfo}
        saveButtonText={"Save Personal Info"}
        onSave={handlePersonalInfoSaved}
      >
        <FormSection
          title={"Basic Details"}
          subtitle={"Basic contact and profile details about you"}
        >
          <FormInput
            type={"full_name"}
            label={"Full Name"}
            fieldName={"full_name"}
          />

          <FormInput
            type={"text"}
            label={"Short Name"}
            fieldName={"short_name"}
          />

          <FormInput type={"email"} label={"Email"} fieldName={"email"} />

          <FormInput
            type={"phone"}
            label={"Cell Phone"}
            fieldName={"phone_number"}
          />

          <FormInput type={"address"} label={"Address"} fieldName={"address"} />

          <FormInput
            type={"url"}
            label={"Website URL"}
            fieldName={"website_url"}
          />
        </FormSection>

        <FormSection
          title={"Background"}
          subtitle={"Details the bot can use to make small talk"}
        >
          <FormInput
            type={"text"}
            label={"Background information (for reference by bot)"}
            fieldName={"background_information"}
            minRows={12}
            multiline={true}
            placeholder={"I recently did such and such in my career..."}
          />
        </FormSection>

        <FormSection
          title={"Raw Prmpt"}
          subtitle={
            "A customized prompt to use for the networking bot. Do not change this unless you are know what you are doing! Most people should leave this blank!"
          }
        >
          <FormInput
            type={"text"}
            label={"Raw Prompt"}
            fieldName={"raw_prompt"}
            minRows={12}
            multiline={true}
            placeholder={"You are a genius bot and you do such and such ...."}
          />
        </FormSection>
      </FormRoot>
    </div>
  );
}
