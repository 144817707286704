import React from "react";
import FormInput from "../../elements/form/FormInput";
import FormSection from "../../elements/form/FormSection";
import FormSubFieldRoot from "../../elements/form/FormSubFieldRoot";

export default function MistralConfigurationForm() {
    return (
        <FormSection
            title={"Mistral Integration"}
            subtitle={"Configuration for the Mistral API for this tenant"}
            collapsable={true}
        >
            <FormSubFieldRoot field={"mistral"}>
                <FormInput
                    type={"text"}
                    label={"API Key"}
                    fieldName={"api_key"}
                    placeholder={"The API key used to connect to the Mistral API"}
                />
            </FormSubFieldRoot>
        </FormSection>
    );
}

