import React, { memo } from "react";
import { schemaTypes, formInputTypes } from "../utils/schema-utils";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CommaSeparatedInput from "../../form/inputs/CommaSeparatedInput";

function RenderPrimitiveFields({
  value,
  fullPath,
  mode,
  handleFieldUpdate,
  canChangeType,
  disabled,
}) {
  return (
    <Grid container spacing={2} sx={{ mb: 1 }}>
      {canChangeType && (
        <Grid item xs={6}>
          <FormControl fullWidth size='small'>
            <InputLabel>Type</InputLabel>
            <Select
              label='Type'
              value={value.type || ""}
              onChange={(e) =>
                handleFieldUpdate(fullPath, "type", e.target.value)
              }
              size='small'
              disabled={disabled}
            >
              {schemaTypes.map((type) => (
                <MenuItem value={type} key={type}>
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={6}>
        <TextField
          fullWidth
          label='Title'
          size='small'
          value={value.title || ""}
          onChange={(e) => handleFieldUpdate(fullPath, "title", e.target.value)}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label='Description'
          size='small'
          value={value.description || ""}
          onChange={(e) =>
            handleFieldUpdate(fullPath, "description", e.target.value)
          }
          multiline
          rows={3}
          disabled={disabled}
        />
      </Grid>

      {mode === "full" && (
        <>
          <Grid item xs={12}>
            <FormControlLabel
              label='Required'
              control={
                <Checkbox
                  size='small'
                  checked={Boolean(value.required)}
                  onChange={(e) =>
                    handleFieldUpdate(fullPath, "required", e.target.checked)
                  }
                  disabled={disabled}
                />
              }
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Default Value'
              size='small'
              value={value.default || ""}
              onChange={(e) =>
                handleFieldUpdate(fullPath, "default", e.target.value)
              }
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth size='small'>
              <InputLabel>Form Input Type</InputLabel>
              <Select
                label='Form Input Type'
                value={value.form_input_type || ""}
                onChange={(e) =>
                  handleFieldUpdate(fullPath, "form_input_type", e.target.value)
                }
                size='small'
                disabled={disabled}
              >
                {formInputTypes.map((type) => {
                  const label = type.replace("_", " ");
                  return (
                    <MenuItem value={type} key={type}>
                      {label.charAt(0).toUpperCase() + label.slice(1)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label='Placeholder'
              size='small'
              value={value.placeholder || ""}
              onChange={(e) =>
                handleFieldUpdate(fullPath, "placeholder", e.target.value)
              }
              disabled={disabled}
            />
          </Grid>
          {value.type === "string" && (
            <>
              <Grid item xs={6}>
                <FormControlLabel
                  label='Multiline'
                  control={
                    <Checkbox
                      size='small'
                      checked={Boolean(value.multiline)}
                      onChange={(e) =>
                        handleFieldUpdate(
                          fullPath,
                          "multiline",
                          e.target.checked
                        )
                      }
                      disabled={disabled}
                    />
                  }
                />
              </Grid>
              {value.multiline && (
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label='Rows'
                    type='number'
                    size='small'
                    value={value.rows ?? 1}
                    onChange={(e) => {
                      const parsed = parseInt(e.target.value, 10);
                      handleFieldUpdate(fullPath, "rows", parsed || 1);
                    }}
                    disabled={disabled}
                    inputProps={{ min: 1 }}
                  />
                </Grid>
              )}
            </>
          )}

          <Grid item xs={6}>
            <FormControlLabel
              label='Show in Table'
              control={
                <Checkbox
                  size='small'
                  checked={Boolean(value.show_in_table_view)}
                  onChange={(e) =>
                    handleFieldUpdate(
                      fullPath,
                      "show_in_table_view",
                      e.target.checked
                    )
                  }
                  disabled={disabled}
                />
              }
            />
          </Grid>

          {value.show_in_table_view && (
            <Grid item xs={6}>
              <TextField
                fullWidth
                label='Table Order'
                type='number'
                size='small'
                value={value.table_view_order ?? 0}
                onChange={(e) => {
                  const parsed = parseInt(e.target.value, 10);
                  handleFieldUpdate(fullPath, "table_view_order", parsed || 0);
                }}
                disabled={disabled}
                inputProps={{ min: 0 }}
              />
            </Grid>
          )}

          {value.form_input_type === "dropdown" && (
            <>
              <Grid item xs={6}>
                <CommaSeparatedInput
                  size='small'
                  onChange={(values) =>
                    handleFieldUpdate(fullPath, "options", values)
                  }
                  values={value.options || []}
                  placeholder='Enter options'
                  label='Options'
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={6}>
                <CommaSeparatedInput
                  size='small'
                  onChange={(values) =>
                    handleFieldUpdate(fullPath, "optionLabels", values)
                  }
                  values={value.optionLabels || []}
                  placeholder='Enter options labels'
                  label='Options Labels'
                  disabled={disabled}
                />
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
}

export default memo(RenderPrimitiveFields);
