import "../nodes/PromptNode/PromptNode.scss";
import "./ActionButton.scss";
import * as React from 'react';
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from '@mui/material/IconButton';
import useChartStore from "../ChartState";
import {useCallback,} from 'react';


export function DeleteNodeActionButton({nodeId}) {
    const {node, deleteNode} = useChartStore((state) => {
        return {
            node: state.getNode(nodeId),
            deleteNode: state.deleteNode,
        }
    });

    const handleDeleteNodeClicked = useCallback(() => {
        deleteNode(nodeId);
    }, [nodeId, deleteNode]);

    if (!node) {
        return;
    }

    return (
        <IconButton
            aria-label="Delete"
            onClick={handleDeleteNodeClicked}
            className={'nodrag actionbutton'}
            title={"Delete"}
        >
            <DeleteIcon />
        </IconButton>
    )
}

export default DeleteNodeActionButton;
