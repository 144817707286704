import React, {useCallback} from "react";
import "./ContactEditPage.scss";
import FormInput from "../../core/frontend/elements/form/FormInput";
import FormRoot from "../../core/frontend/elements/form/FormRoot";
import FormSection from "../../core/frontend/elements/form/FormSection";
import {SpinnerButton} from "../../core/frontend/elements/SpinnerButton";
import LanguageSelector from "../../core/frontend/elements/form/inputs/LanguageSelector";


export default function ContactForm({
                                         initialContact,
                                         onContactSave,
                                         onDeletedClicked,
                                         onFollowUpNowClicked,
                                         onViewConversationClicked
                                     }) {
    const [contact, setContact] = React.useState(null);

    const handleContactSaved = useCallback((newContactData) => {
        if (onContactSave) {
            return onContactSave(newContactData);
        }
    }, [onContactSave]);

    const handleContactChanged = useCallback((newContactData) => {
        setContact(newContactData);
    }, [setContact]);

    const handleFollowUpNowClicked = useCallback(() => {
        if (onFollowUpNowClicked) {
            return onFollowUpNowClicked(contact);
        }
    }, [contact, onFollowUpNowClicked]);

    const handleViewConversationClicked = useCallback(() => {
        if (onViewConversationClicked) {
            return onViewConversationClicked(contact);
        }
    }, [contact, onViewConversationClicked]);

    return <FormRoot
        initialValue={initialContact}
        saveButtonText={"Save Contact"}
        onChange={handleContactChanged}
        onSave={handleContactSaved}
        extraButtons={<>
            <SpinnerButton
                variant="contained"
                color="error"
                className={"delete-button"}
                onClick={onDeletedClicked}
            >
                Delete
            </SpinnerButton>
            <SpinnerButton
                variant="contained"
                color="success"
                className={"follow-up-now-button"}
                onClick={handleFollowUpNowClicked}
            >
                Follow Up Now
            </SpinnerButton>
            {
                contact?.follow_up_conversation_id ?
                    <SpinnerButton
                        variant="contained"
                        color="primary"
                        className={"view-conversation-button"}
                        onClick={handleViewConversationClicked}
                    >
                        View Conversation
                    </SpinnerButton>
                    : null
            }
        </>
        }
    >
        <FormSection
            title={"Contact Details"}
            subtitle={"General details about the contact"}
        >

            <FormInput
                type={"full_name"}
                label={"Full Name"}
                fieldName={"full_name"}
            />

            <FormInput
                type={"phone"}
                label={"Cell Phone"}
                fieldName={"phone_number"}
            />

            <FormInput
                type={"email"}
                label={"Email"}
                fieldName={"email_address"}
            />

            <LanguageSelector
                label={"Language"}
                fieldName={"language_id"}
            />
        </FormSection>

        <FormSection title={"Follow Up Style"}>
            <FormInput
                type={"radio"}
                label={"Aggressiveness"}
                fieldName={"follow_up_aggressiveness"}
                options={['low', 'medium', 'high']}
                optionLabels={['Laid-Back', 'Normal', 'Aggressive']}
            />
            <FormInput
                type={"radio"}
                label={"Frequency"}
                fieldName={"follow_up_frequency"}
                options={['monthly', 'quarterly', 'semiannually', 'annually']}
                optionLabels={['Monthly', 'Quarterly', 'Semi-Annually', 'Annually']}
            />
            <FormInput
                type={"datetime"}
                label={"Next Follow Up"}
                fieldName={"next_follow_up_date"}
            />
        </FormSection>
    </FormRoot>
}