import React from "react";
import FormInput from "../../elements/form/FormInput";
import FormSection from "../../elements/form/FormSection";
import FormSubFieldRoot from "../../elements/form/FormSubFieldRoot";

export default function KnowledgeBaseConfigurationForm() {
  return (
    <FormSection
      title={"Knowledge Base Integration"}
      subtitle={"Configuration for the Knowledge Base at the tenant level"}
      collapsable={true}
    >
      <FormSubFieldRoot field={"knowledge_base"}>
        <FormInput
          type={"text"}
          label={"Knowledge Base Health Check User ID"}
          fieldName={"knowledge_base_health_check_user_id"}
          placeholder={
            "The user_id that is used to query " +
            "the knowledge base during health checks. This" +
            " gets overridden if there is a hardcoded user_id " +
            "for the whole environment"
          }
        />
      </FormSubFieldRoot>
    </FormSection>
  );
}