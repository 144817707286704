export const defaultInputGroupNode = {
    id: "input",
    data: {
        title: "",
        prompt: "",
        currentValues: [],
        stale: false,
        enable_ai_completion: false,
    },
    position: {
        x: 0,
        y: 0,
    },
    type: "inputGroupNode"
};

export const defaultOutputNode = {
    id: "output",
    data: {
        title: "",
        prompt: "",
        currentValues: [],
        stale: false,
        enable_ai_completion: true,
    },
    position: {
        x: 1400,
        y: 150,
    },
    type: "outputNode"
};

