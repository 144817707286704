import { useCallback, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import "./SpinnerIconButton.scss";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { ensureMinimumPromiseResolveTime } from "../utils/time";
import IconButton from "@mui/material/IconButton";
import globalTranslationManager from "../../translation/frontend/components/translation_manager";

export function SpinnerIconButton(props) {
  const [isSpinnerShowingLocal, setisSpinnerShowingLocal] = useState(false);
  const [isCheckShowingLocal, setIsShowingCheck] = useState(false);
  const [isCrossShowingLocal, setIsCrossShowingLocal] = useState(false);
  const spinnerColor = props.spinnerColor || "primary";

  const handleOnClick = useCallback(
    (evt) => {
      if (props.onClick) {
        if (isSpinnerShowingLocal) {
          // Don't allow clicking the button again if it's already showing a spinner,
          // this prevents double-clicking.
          return;
        }

        setisSpinnerShowingLocal(true);

        try {
          let promise = props.onClick(evt);
          if (!promise) {
            setisSpinnerShowingLocal(false);
            return;
          } else {
            promise = ensureMinimumPromiseResolveTime(promise, 500);
          }

          return promise
            .then(
              (result) => {
                setisSpinnerShowingLocal(false);
                setIsShowingCheck(true);
                setTimeout(() => {
                  setIsShowingCheck(false);
                }, 1000);
                return result;
              },
              (err) => {
                setisSpinnerShowingLocal(false);
                setIsCrossShowingLocal(true);
                setTimeout(() => {
                  setIsCrossShowingLocal(false);
                }, 1000);
                return err;
              }
            )
            .catch((err) => {
              setisSpinnerShowingLocal(false);
              setIsCrossShowingLocal(true);
              setTimeout(() => {
                setIsCrossShowingLocal(false);
              }, 1000);
              return err;
            });
        } catch (err) {
          setisSpinnerShowingLocal(false);
          setIsCrossShowingLocal(true);
          setTimeout(() => {
            setIsCrossShowingLocal(false);
          }, 1000);
          throw err;
        }
      }
    },
    [props, isSpinnerShowingLocal]
  );

  const isSpinnerShowing = props.showSpinner ?? isSpinnerShowingLocal;
  const isCheckShowing = isCheckShowingLocal;
  const isCrossShowing = isCrossShowingLocal;

  const className = `${props.className || ""} spinner-icon-button`.trim();
  let titleProp = {};
  if (props.title) {
    titleProp = {
      title: globalTranslationManager.getTranslationIfAvailableAndEnabledSync(
        props.title
      ),
    };
  }

  const newProps = {
    ...props,
    className: className,
    onClick: handleOnClick,
    // startIcon: isSpinnerShowing ? null : props.startIcon,
    // endIcon: isSpinnerShowing ? null : props.endIcon,
    ...titleProp,
  };

  return (
    <IconButton {...newProps}>
      {isSpinnerShowing ? (
        <CircularProgress size={16} color={spinnerColor} />
      ) : null}
      {!isSpinnerShowing && isCheckShowing ? <CheckIcon /> : null}
      {!isSpinnerShowing && !isCheckShowing && isCrossShowing ? (
        <CloseIcon />
      ) : null}
      {!isSpinnerShowing && !isCheckShowing && !isCrossShowing
        ? props.children
        : null}
    </IconButton>
  );
}
