import {PageWrapperWithMenu} from "../../core/frontend/elements/PageWrapperWithMenu";
import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Card, CardContent} from "@mui/material";
import {useAPI} from "../../core/frontend/components/APIProvider";
import {PageHeader} from "../../core/frontend/elements/PageHeader";
import CircularProgress from "@mui/material/CircularProgress";
import {StoryPDFViewer} from "./StoryPDFViewer";
import DownloadStoryPdfButton from "./DownloadStoryPdfButton";
import DebugStoryButton from "./DebugStoryButton";
import "./ViewStoryPage.scss";


export default function ViewStoryPage(props) {
    const api = useAPI();
    const params = useParams();

    const [story, setStory] = useState(null);

    React.useEffect(() => {
        if (!story) {
            api.getStory(params?.storyId).then((story) => {
                setStory(story);
            });
        }
    }, [story, params?.storyId, api]);

    if (!story) {
        return <PageWrapperWithMenu>
            <PageHeader title={`Edit Story`}/>
            <div className="story-edit-page">
                <CircularProgress/>
            </div>
        </PageWrapperWithMenu>
    }

    return <PageWrapperWithMenu>
        <PageHeader title={`View Story`}/>
        <div className="story-view-page">
            <Card className="story-view-card">
                <CardContent>
                    <StoryPDFViewer story={story}/>
                    <div className={"buttons-bar"}>
                        <DownloadStoryPdfButton story={story} />
                        <DebugStoryButton story={story} />
                    </div>
                </CardContent>
            </Card>
        </div>

    </PageWrapperWithMenu>
}
