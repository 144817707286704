import { PageWrapperWithMenu } from "../../../core/frontend/elements/PageWrapperWithMenu";
import React, { useCallback } from "react";
import { Card, CardContent } from "@mui/material";
import { useAPI } from "../../../core/frontend/components/APIProvider";
import { PageHeader } from "../../../core/frontend/elements/PageHeader";
import ContentSampleForm from "./ContentSampleForm";
import { useNavigate } from "react-router-dom";
import "./ContentSampleEditPage.scss";

export default function NewContentSamplePage(props) {
  const api = useAPI();
  const navigate = useNavigate();
  const handleCreateContentSample = useCallback(
    async (contentSampleData) => {
      return await api
        .createNewContentSample(contentSampleData)
        .then((newContentSample) => {
          navigate(`/content_samples/${newContentSample.id}`);
        });
    },
    [api, navigate]
  );

  return (
    <PageWrapperWithMenu>
      <PageHeader title={`New Content Sample`} />
      <div className='content-sample-edit-page'>
        <Card className='content-sample-edit-form-card'>
          <CardContent>
            <ContentSampleForm
              initialContentSample={{}}
              onContentSampleSave={handleCreateContentSample}
              saveButtonText={"Create New Content Sample"}
            />
          </CardContent>
        </Card>
      </div>
    </PageWrapperWithMenu>
  );
}
