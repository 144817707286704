import "./DocumentTemplateEditPage.scss";
import "@grapesjs/studio-sdk/style";
import Box from '@mui/material/Box';
import DocumentTemplateSettingsTab from "./DocumentTemplateSettingsTab";
import React, {useCallback, useEffect} from "react";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {PageWrapperWithMenu} from "../../../frontend/elements/PageWrapperWithMenu";
import {useParams} from "react-router-dom";
import {useAPI, useUser} from "../../../frontend/components/APIProvider";
import {randomString} from "../../../frontend/utils/random";

export default function DocumentTemplateEditPage() {
  const params = useParams();
  const documentTemplateId = params.documentTemplateId;
  const [tab, setTab] = React.useState('template');
  const api = useAPI();
  const user = useUser();
  const grapesJsUrl = process.env.REACT_APP_GRAPESJS_IFRAME
  const grapesJsOrigin = new URL(grapesJsUrl).origin
  const selfOrigin = window.location.origin

  const handleTabChange = useCallback((event, newValue) => {
    setTab(newValue);
  }, [setTab]);

  useEffect(() => {
    const iframe = document.getElementById('grapes_iframe');

    // Message handler function
    const handleMessage = async (event) => {
      // Verify the origin is your iframe domain
      if (event.origin === grapesJsOrigin) {
        if (event.data.type === 'childToParentSave') {
          await api.saveDocumentTemplate(documentTemplateId, event.data.data);
        } else if (event.data.type === 'childToParentLoad') {
          const documentTemplate = await api.getDocumentTemplate(documentTemplateId);
          const dataToSend = {
            type: 'parentToChildLoadResponse',
            data: {
                id: documentTemplateId,
                project: documentTemplate.editor_state ?? null,
            }
          };
          iframe.contentWindow.postMessage(dataToSend, grapesJsOrigin);
        }
      }
    };
    window.addEventListener('message', handleMessage);

    const sendMessage = async () => {
      try {
        // Await the document template
        const template = await api.getDocumentTemplate(documentTemplateId);

        const dataToSend = {
          type: 'parentToChild',
          data: template.editor_state
        };

        iframe.contentWindow.postMessage(dataToSend, grapesJsOrigin);
      } catch (error) {
        console.error('Error sending message:', error);
      }
    };

    if (iframe.contentWindow) {
      // Iframe already loaded, sending message
      sendMessage();
    }

    // Also attach load listener for safety
    iframe.addEventListener('load', () => {
      // Iframe load event triggered
      sendMessage();
    });

    // Cleanup
    return () => {
      iframe.removeEventListener('load', sendMessage);
      window.removeEventListener('message', handleMessage);
    };
  }, []); // Empty dependency array means this runs once on mount

  return (
    <PageWrapperWithMenu padding={false}>
      <div className={"document-template-edit-page"}>
        <div className={`document-template-edit-page-tab ${tab} ${tab === 'template' ? 'visible' : 'hidden'}`}>
          <iframe style={{width: '100%',height: '100%', border: 'none'}}
                  title={`grapes_iframe`}
                  id={`grapes_iframe`}
                  src={grapesJsUrl+
                    `?documentTemplateId=`+ documentTemplateId +
                      `&origin=` + selfOrigin +
                      `&userSub=` + user.sub +
                      `&` + randomString(8)}/> {/* Add random string to force reload */}
        </div>
        <div className={`document-template-edit-page-tab ${tab} ${tab === 'settings' ? 'visible' : 'hidden'}`}>
          {
            tab === "settings" ?
              <DocumentTemplateSettingsTab
                documentTemplateId={documentTemplateId}
              />
              : null
          }
        </div>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
          <Tabs value={tab} onChange={handleTabChange} aria-label="Tabs">
            <Tab label="Template" value={"template"}/>
            <Tab label="Settings" value={"settings"}/>
          </Tabs>
        </Box>
      </div>
    </PageWrapperWithMenu>
  );
}