import React, { useCallback, useState } from "react";
import FormInput from "../../../frontend/elements/form/FormInput";
import FormRoot from "../../../frontend/elements/form/FormRoot";
import FormSection from "../../../frontend/elements/form/FormSection";
import { SpinnerButton } from "../../../frontend/elements/SpinnerButton";
import "./CustomFormForm.scss";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";
import SchemaEditor from "../../../frontend/elements/schema-editor/SchemaEditor";
import IconSelector from "../../../frontend/elements/form/inputs/IconSelector";
import FormArraySection from "../../../frontend/elements/form/FormArraySection";
import {useAPI} from "../../../frontend/components/APIProvider";
import SmartChainBindingSelector from "../../../smart_chains/frontend/smart_chain_binding/SmartChainBindingSelector";

export default function CustomFormForm({
  initialCustomForm,
  onCustomFormSave,
  onDeletedClicked,
  saveButtonText,
}) {
  const api = useAPI();
  const [, setCustomForm] = useState(initialCustomForm);
  const [schemaData, setSchemaData] = useState(
    initialCustomForm?.json_schema || {}
  );

  const handleCustomFormSaved = useCallback(
    (newCustomFormData) => {
      if (onCustomFormSave) {
        const payload = {
          ...newCustomFormData,
          json_schema: schemaData,
        };
        return onCustomFormSave(payload);
      }
    },
    [onCustomFormSave, schemaData]
  );

  const handleCustomFormChange = useCallback(
    (newCustomFormData) => {
      setCustomForm(newCustomFormData);
    },
    []
  );

  const handleSchemaEditorChange = useCallback((schemaData) => {
    setSchemaData(schemaData);
  }, []);

  return (
    <div className={"custom-form-form"}>
      <FormRoot
        initialValue={initialCustomForm}
        saveButtonText={saveButtonText ?? "Save Custom Form"}
        onSave={handleCustomFormSaved}
        onChange={handleCustomFormChange}
        extraButtons={
          <>
            {onDeletedClicked ? (
              <SpinnerButton
                variant='contained'
                color='error'
                className={"delete-button"}
                onClick={onDeletedClicked}
              >
                <LocalTranslatedText language={"en"} text='Delete' />
              </SpinnerButton>
            ) : null}
          </>
        }
      >
        <FormSection
          title={"General"}
          subtitle={"Metadata that about this custom data schema"}
        >
          <FormInput
            type={"text"}
            label={"Machine Name"}
            fieldName={"machine_name"}
            placeholder={
              "The machine name/id for the schema. This must be an all" +
              " lower-case string with only alphanumerics, underscores and hyphens" +
              " (url compatible)."
            }
            size='small'
          />

          <FormInput
            type={"text"}
            label={"Title"}
            fieldName={"title"}
            size='small'
          />

          <FormInput
            type={"text"}
            label={"Description"}
            fieldName={"description"}
            rows={4}
            multiline={true}
            size='small'
          />
        </FormSection>

        <SchemaEditor
          defaultLayout='schema'
          mode='full'
          initialSchemaData={schemaData}
          onChange={handleSchemaEditorChange}
          options={{
            padding: false,
            boxShadow: false,
            metadataComponent: false,
            margin: false,
          }}
        />

        <FormSection title={"Options"}>
          <FormInput
            type={"checkboxes"}
            options={["Show in Left Menu"]}
            fieldName={["show_in_left_menu"]}
            size='small'
          />

          <IconSelector fieldName={"left_menu_icon"} label={"Left Menu Icon"} />
        </FormSection>

        <FormSection title={"Submit Action"}>
          <SmartChainBindingSelector
            label={"Smart chain binding"}
            fieldName={"smart_chain_binding_name"}
          />
        </FormSection>
      </FormRoot>
    </div>
  );
}
