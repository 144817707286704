export function formatPhoneNumber(phoneNumber) {
  return phoneNumber.replace(/[\s()-]/g, "");
}

export function generateYears(totalYearsToThePast = 50, futureYears = 10) {
  const currentYear = new Date().getFullYear();
  const totalYears = totalYearsToThePast;

  // Calculate the starting year
  const startYear = currentYear - (totalYears - futureYears - 1);

  // Generate the years array
  const years = [];
  for (let i = 0; i < totalYears; i++) {
    const year = startYear + i;
    years.push({ value: year.toString(), label: year.toString() });
  }

  return years?.reverse();
}

export function isDecimalPointFive(num) {
  const decimalPart = Number(num) - Math.floor(Number(num)); // Get the decimal part of the number
  return decimalPart === 0.5;
}

export const convertToFraction = (num) => {
  // Extract the integer part and the decimal part
  const integerPart = Math.floor(Number(num));
  const decimalPart = num - integerPart;

  // Check if it's a half (0.5), you can add more fractions if needed
  if (decimalPart === 0.5) {
    return integerPart > 0 ? `${integerPart}½` : "½"; // Return as a mixed fraction
  }
  // If no decimal or unsupported fraction, return the number as is
  return num;
};

export function reversePhoneNumber(number) {
  // Remove any non-numeric characters except the leading "+"
  const cleaned = ("" + number).replace(/[^\d]/g, "");

  // Check if the number starts with "1" (US country code)
  if (cleaned.length === 11 && cleaned[0] === "1") {
    // Remove the country code (1)
    const country = "+1";
    const localNumber = cleaned.slice(1); // Remove the leading '1'

    // Format the number as (XXX) XXX-XXXX
    const formatted = `${country} (${localNumber.slice(
      0,
      3
    )}) ${localNumber.slice(3, 6)}-${localNumber.slice(6)}`;
    return formatted;
  }

  return null; // Handle invalid number length or country code
}

export function textToArray(text) {
  // Split the text by a delimiter (e.g., periods, commas, newlines, etc.)
  const items = text.split(/[.]/); // You can customize the delimiter here

  // Trim whitespace from each item and filter out empty items
  const formattedArray = items
    .map((item) => item.trim())
    .filter((item) => item !== "");

  return formattedArray;
}

export function areDateRangesOverlapping(workHistory) {
  for (let i = 0; i < workHistory.length; i++) {
    const startDateA = new Date(workHistory[i].start_date);
    const endDateA = new Date(workHistory[i].end_date);

    for (let j = i + 1; j < workHistory.length; j++) {
      const startDateB = new Date(workHistory[j].start_date);
      const endDateB = new Date(workHistory[j].end_date);

      // Check if the date ranges overlap
      if (startDateA <= endDateB && startDateB <= endDateA) {
        return true; // Overlapping found
      }
    }
  }
  return false;
}

export function capitalizeWords(str) {
  return str
    .split(" ") // Split the string by spaces
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
    .join(" "); // Join the words back together with spaces
}

export function mergeSkillsAndSoftwareArrays(skills, software) {
  // Convert skills array to match the software structure
  const skillArray = skills.map((skill) => ({
    skill_name: skill?.skill_name,
    years_used: skill.years_used,
  }));

  // Convert software array to match the skills structure
  const softwareArray = software.map((software) => ({
    skill_name: software?.software_name,
    years_used: software?.years_used,
  }));

  // Merge the two arrays
  return [...skillArray, ...softwareArray];
}
