import {PageWrapperWithMenu} from "../../../frontend/elements/PageWrapperWithMenu";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useAPI} from "../../../frontend/components/APIProvider";
import {SmartChainTable} from "./SmartChainTable";
import "./SmartChainListPage.scss";
import {PageHeader} from "../../../frontend/elements/PageHeader";
import {WhiteButton} from "../../../frontend/elements/WhiteButton";
import Paper from "@mui/material/Paper";
import {useCallback} from "react";


export default function SmartChainListPage() {
    const api = useAPI();
    const navigate = useNavigate();

    const handleCreateNewSmartChainClicked = useCallback(async () => {
      const newSmartChain = await api.createNewSmartChain({});
        navigate(`/smart_chains/${newSmartChain.id}`);
    }, [api, navigate]);

    return <PageWrapperWithMenu>
        <PageHeader title={"Smart Chains"}>
            <WhiteButton onClick={handleCreateNewSmartChainClicked}>
                Create New Smart Chain
            </WhiteButton>
        </PageHeader>
        <div className={"smart-chain-list-page"}>
            <div className={"smart-chain-table-wrapper"}>
                <Paper className={"main-page-contents"}>
                    <SmartChainTable/>
                </Paper>
            </div>
        </div>
    </PageWrapperWithMenu>
}

