import React, {useCallback, useState} from 'react';
import {useEffect} from 'react';
import "./TestingNumberSelector.scss";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Radio from "@mui/material/Radio";
import {useAPI} from "../../../core/frontend/components/APIProvider";
import CircularProgress from "@mui/material/CircularProgress";
import {formatPhoneNumberHumanReadable} from "../../../core/frontend/utils/phone";
import {Typography} from "@mui/material";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";


export const TestingPhoneNumberSelector = ({onChange}) => {
    const [availablePhoneNumbers, setAvailablePhoneNumbers] = useState(null);
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const api = useAPI();

    useEffect(() => {
        setIsLoading(true);
        api.getTestingPhoneNumbers().then((data) => {
            setIsLoading(false);
            const phone_numbers = data.testing_phone_numbers;

            // Sort the phone numbers so they are nicer to display to the user
            phone_numbers.sort((a, b) => {
                return a.localeCompare(b);
            });

            setAvailablePhoneNumbers(phone_numbers);
        }, (error) => {
            setIsLoading(false);
            console.log(error);
        });

    }, [api]);

    const handleClickedPhoneNumber = useCallback((phoneNumber) => {
        setSelectedPhoneNumber(phoneNumber);
        onChange(phoneNumber);
    }, [onChange]);

    return (
        <div className={"testing-number-selector"}>
            <Typography variant={'p'}><LocalTranslatedText language={"en"} text="Please select one of the testing phone numbers to attach to this account."/></Typography>

            {
                isLoading ? <CircularProgress/> : null
            }

            {
                availablePhoneNumbers !== null && availablePhoneNumbers.length === 0 ?
                    <p><LocalTranslatedText language={"en"} text="No testing phone numbers available. Please contact your dev." /></p>
                    : null
            }

            <List dense sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                {availablePhoneNumbers !== null && availablePhoneNumbers.length > 0 ?
                    availablePhoneNumbers.map((phoneNumber) => {
                        const labelId = `phone-number-selector-${phoneNumber}`;
                        return (
                            <ListItem
                                key={phoneNumber}
                                secondaryAction={
                                    <Radio
                                        edge="end"
                                        checked={selectedPhoneNumber === phoneNumber}
                                        onChange={() => handleClickedPhoneNumber(phoneNumber)}
                                        inputProps={{'aria-labelledby': labelId}}
                                    />
                                }
                                onClick={() => handleClickedPhoneNumber(phoneNumber)}
                                disablePadding
                            >
                                <ListItemButton>
                                    <ListItemText id={labelId} primary={formatPhoneNumberHumanReadable(phoneNumber)}/>
                                </ListItemButton>
                            </ListItem>
                        )
                    })
                    : null
                }
            </List>
            <Typography variant={'p'}><LocalTranslatedText language={"en"} text="Note! The testing phone number will be removed from whatever other account it is attached to."/></Typography>
            <br/>
        </div>
    );
};
