import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import React from "react";
import { useNavigate } from "react-router-dom";
import { CustomFormTable } from "./CustomFormTable";
import "./CustomFormListPage.scss";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import { WhiteButton } from "../../../frontend/elements/WhiteButton";
import Paper from "@mui/material/Paper";
import { useCallback } from "react";

export default function CustomFormListPage() {
  const navigate = useNavigate();

  const handleNewCustomFormClicked = useCallback(async () => {
    navigate(`/custom_form/new`);
  }, [navigate]);

  return (
    <PageWrapperWithMenu>
      <PageHeader title={"Custom Forms"}>
        <WhiteButton onClick={handleNewCustomFormClicked}>
          Create New Form
        </WhiteButton>
      </PageHeader>
      <div className={"custom-form-list-page"}>
        <div className={"custom-form-table-wrapper"}>
          <Paper className={"main-page-contents"}>
            <CustomFormTable />
          </Paper>
        </div>
      </div>
    </PageWrapperWithMenu>
  );
}
