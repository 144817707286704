import React, { useCallback, useMemo, useState } from "react";
import { useChangeFormData, useFormData, useFormOptions } from "../FormRoot";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import LocalTranslatedText from "../../../../translation/frontend/components/LocalTranslatedText";
import globalTranslationManager from "../../../../translation/frontend/components/translation_manager";
import CodeEditor from "../../CodeEditor";
import Alert from "@mui/material/Alert";

const validateJSONStr = (value) => {
  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
};

export function JSONInput({
  label,
  example,
  fieldName,
  errorHelperText,
  disabled,
  ...props
}) {
  const formOptions = useFormOptions();
  const formData = useFormData();
  const changeFormData = useChangeFormData();

  let initialValue = JSON.stringify(formData[fieldName], null, 4);

  const [localValue, setLocalValue] = useState(initialValue);
  let isValid = validateJSONStr(localValue);
  let disabledLocal = !formOptions.editable || disabled;

  const onChangeFormDataDebounced = useMemo(
    () => _.debounce(changeFormData, 500),
    [changeFormData]
  );

  const handleInputChange = useCallback(
    (newValue) => {
      if (formOptions.editable) {
        setLocalValue(newValue);
        if (!newValue || validateJSONStr(newValue)) {
          onChangeFormDataDebounced(fieldName, JSON.parse(newValue ?? "{}"));
        }
      }
    },
    [formOptions, fieldName, onChangeFormDataDebounced]
  );

  if (props.placeholder) {
    props.placeholder =
      globalTranslationManager.getTranslationIfAvailableAndEnabledSync(
        props.placeholder
      );
  }

  return (
    <div className={"form-input"}>
      <Typography variant={"label"}>
        <LocalTranslatedText language={"en"} text={label} />
      </Typography>

      <CodeEditor
        height={"50vh"}
        defaultLanguage='json'
        value={localValue}
        theme={"vs-dark"}
        onChange={handleInputChange}
        options={{
          readOnly: disabledLocal,
          minimap: { enabled: false },
          automaticLayout: true,
        }}
      />

      {!isValid ? (
        <Alert severity='error'>
          <LocalTranslatedText language={"en"} text={"The JSON is not valid"} />
        </Alert>
      ) : null}
    </div>
  );
}

export default JSONInput;
