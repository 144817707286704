import { Button, CircularProgress, Grid, Paper } from "@mui/material";
import React, { useState } from "react";
import "./EditIntakeForm.scss";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { IntakeSchema, questionInitialValues } from "../../IntakeSchema";
import FormProvider from "../../../../frontend/elements/form/hook-form/FormProvider";
import RHFTextField from "../../../../frontend/elements/form/hook-form/RHFTextField";
import QuestionsContainer from "./QuestionsContainer";
import { useSnackbar } from "../../../../frontend/components/SnackbarProvider";
import botPersonalities from "../../../../backend/data/bot_personalities.json";
import RHFSelectField from "../../../../frontend/elements/form/hook-form/RHFSelectField";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";

const botPersonalityOptions = botPersonalities.personalities.map(
  (personality) => ({
    value: personality?.id,
    label: personality?.title,
  })
);

export default function EditIntakeForm({ intake, handleUpdateIntake }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const snackbar = useSnackbar();

  const methods = useForm({
    resolver: zodResolver(IntakeSchema),
    defaultValues: {
      ...intake,
      questions:
        intake?.questions?.length === 0
          ? [questionInitialValues]
          : intake?.questions,
    },
  });

  const { handleSubmit, getValues, control } = methods;

  const {
    fields: questionsFields,
    append: appendQuestionField,
    remove: removeQuestionField,
    insert: insertQuestionField,
  } = useFieldArray({
    name: "questions",
    control,
  });

  const onAddQuestionField = () => {
    appendQuestionField(questionInitialValues);
  };

  const onInserQuestionField = (index) => {
    insertQuestionField(index + 1, questionInitialValues);
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsSubmitting(true);
      await handleUpdateIntake({
        ...intake,
        ...data,
      });
      setIsSubmitting(false);
      snackbar.toast({
        message: "Intake saved successfully",
        severity: "success",
        autoHideMs: 5000,
      });
    } catch (error) {
      setIsSubmitting(false);
      snackbar.toast({
        message: error?.response?.data?.description || "Error saving intake",
        severity: "error",
        autoHideMs: 5000,
      });
    }
  });

  const onAutoSave = async () => {
    const data = getValues();
    await handleUpdateIntake({
      ...intake,
      ...data,
    });
  };

  return (
    <Paper elevation={4}>
      <div className='container'>
        <FormProvider
          methods={methods}
          onSubmit={onSubmit}
          onAutoSave={onAutoSave}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RHFTextField
                name='title'
                placeholder='Enter the title of the intake...'
                label='Title'
              />
            </Grid>
            <Grid item xs={12}>
              <RHFTextField
                label='Description'
                name='description'
                placeholder='Enter the description of the intake...'
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <RHFTextField
                label='Introduction'
                name='introduction'
                placeholder='This introduction is displayed to the user by the bot before starting the conversation...'
                multiline
                rows={8}
              />
            </Grid>
            <Grid item xs={12}>
              <RHFSelectField
                label='Bot Personality'
                name='bot_personality'
                options={botPersonalityOptions}
                placeholder='Select Bot Personality'
              />
            </Grid>
            <Grid item xs={12}>
              <div className='box'>
                {questionsFields.map((question, index) => (
                  <QuestionsContainer
                    questionIndex={index}
                    key={question.id}
                    isDisabled={questionsFields.length === 1}
                    removeQuestionField={removeQuestionField}
                    onInserQuestionField={onInserQuestionField}
                  />
                ))}
                <div className='box row'>
                  <Button
                    type='button'
                    onClick={onAddQuestionField}
                    variant='outlined'
                    color='success'
                    startIcon={<AddIcon />}
                  >
                    Add Question
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className='flex-end'>
                <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  disabled={isSubmitting}
                  startIcon={<SaveIcon />}
                >
                  {isSubmitting && <CircularProgress size={24} />}
                  Save Form
                </Button>
              </div>
            </Grid>
          </Grid>
        </FormProvider>
      </div>
    </Paper>
  );
}
