import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { SpinnerButton } from "../../../frontend/elements/SpinnerButton";
import "./PublishSmartChainDialog.scss";

export default function ReverPublishedSmartChainDialog({
  open,
  handleClose,
  handleConfirm,
}) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Revert to this Smart Chain?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Reverting to this version of smart chain will overwrite your current
            smart chain data. This action cannot be undone.
            <br />
            <br />
            Are you sure you want to revert your current smart chain data to
            this smart chain version?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color={"error"} autoFocus>
            Cancel
          </Button>
          <SpinnerButton
            onClick={handleConfirm}
            color={"success"}
            className={"publish-smart-chain-dark-spinner-button"}
          >
            Revert
          </SpinnerButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
