import React, {useState, useCallback, useMemo} from 'react';
import {TextField, RadioGroup, FormControlLabel, Radio} from '@mui/material';
import {StoryFormWidgetContainer} from "./StoryFormWidgetContainer";
import _ from "lodash";
import "./TextStyleSelector.scss";

export const TextStyleSelector = ({value, onChange}) => {
    const allowedOptions = ['Regular', 'Rhyming'];

    let initialSelected = value;
    if (!initialSelected) {
        initialSelected = 'Regular';
    }

    const [selectedStyle, setSelectedStyle] = useState(initialSelected);

    const handleSelectChange = useCallback((event) => {
        setSelectedStyle(event.target.value);
        onChange(event.target.value);
    }, [onChange]);


    return (
        <StoryFormWidgetContainer label={"Text Style"} className={"text-style-selector"}>
            <RadioGroup
                aria-label="text-style"
                name="text-style"
                className={"text-style-selector"}
                value={selectedStyle}
                onChange={handleSelectChange}
            >
                {
                    allowedOptions.map((option) => (
                        <FormControlLabel key={option} value={option} control={<Radio/>} label={option}/>
                    ))
                }
            </RadioGroup>
        </StoryFormWidgetContainer>
    );
};