import { PageWrapperWithMenu } from "../../../../core/frontend/elements/PageWrapperWithMenu";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAPI } from "../../../../core/frontend/components/APIProvider";
import Paper from "@mui/material/Paper";
import { PageHeader } from "../../../../core/frontend/elements/PageHeader";
import "./NewBookingPage.scss";
import { BookingForm } from "./BookingForm";

export default function NewBookingPage() {
  const api = useAPI();

  // Get users local IANA timezone from browser
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [newBookingData, setNewBookingData] = useState({
    start_time: "",
    end_time: "",
    duration_minutes: 30,
    timezone: localTimezone,
    customer_name: "",
    customer_email: "",
    customer_phone: "",
  });

  const navigate = useNavigate();

  const handleNewBookingClicked = useCallback(async () => {
    const booking = await api.createNewBooking(newBookingData);
    navigate(`/bookings/${booking.id}`);
  }, [api, navigate, newBookingData]);

  return (
    <PageWrapperWithMenu>
      <PageHeader title={"New Booking"} />
      <div className='new-booking-page'>
        <Paper className={"main-page-contents"}>
          <BookingForm
            booking={newBookingData}
            onChange={setNewBookingData}
            onSaveClicked={handleNewBookingClicked}
          />
        </Paper>
      </div>
    </PageWrapperWithMenu>
  );
}
