import "./FormRoot.scss";
import React from "react";
import FormButtons from "./FormButtons";
import CircularProgress from "@mui/material/CircularProgress";
import _ from "lodash";
// This must also be imported here. Do not remove.
import "./FormInput.scss";

export const formDataProvider = React.createContext(null);
export const useFormData = () => React.useContext(formDataProvider);

export const changeFormDataProvider = React.createContext(() => null);
export const useChangeFormData = () => React.useContext(changeFormDataProvider);

export const changeMultipleFormDataProvider = React.createContext(() => null);
export const useChangeMultipleFormData = () =>
  React.useContext(changeMultipleFormDataProvider);

export const formOptionsProvider = React.createContext(null);
export const useFormOptions = () => React.useContext(formOptionsProvider);

export function FormRoot({
  initialValue,
  children,
  saveButtonText,
  onSave,
  onChange,
  extraButtons,
  editable,
  autosave,
}) {
  editable = editable ?? true;

  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    if (!data && initialValue) {
      setData(initialValue);
      if (onChange) {
        onChange(initialValue);
      }
    }
  }, [onChange, data, initialValue]);

  const autoSaveDebounced = React.useMemo(
    () =>
      _.debounce((newData) => {
        if (onSave) {
          onSave(newData);
        }
      }, 500),
    [onSave]
  );

  const changeFormData = React.useCallback(
    (key, newValue) => {
      if (editable) {
        setData((data) => {
          const newFormData = {
            ...data,
            [key]: newValue,
          };

          if (onChange) {
            onChange(newFormData);
          }

          if (autosave) {
            autoSaveDebounced(newFormData);
          }

          return newFormData;
        });
      }
    },
    [editable, onChange, autosave, autoSaveDebounced]
  );

  const changeMultipleFormData = React.useCallback(
    (newData) => {
      if (editable) {
        setData((data) => {
          const newFormData = {
            ...data,
            ...newData,
          };

          if (onChange) {
            onChange(newFormData);
          }

          if (autosave) {
            autoSaveDebounced(newFormData);
          }

          return newFormData;
        });
      }
    },
    [editable, onChange, autosave, autoSaveDebounced]
  );

  const handleSaveClicked = React.useCallback(() => {
    if (onSave) {
      return onSave(data);
    }
  }, [onSave, data]);

  const formOptions = {
    editable,
  };

  if (!data) {
    return (
      <div className={"form-root"}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={"form-root"}>
      <formDataProvider.Provider value={data}>
        <changeFormDataProvider.Provider value={changeFormData}>
          <changeMultipleFormDataProvider.Provider
            value={changeMultipleFormData}
          >
            <formOptionsProvider.Provider value={formOptions}>
              {children}
            </formOptionsProvider.Provider>
          </changeMultipleFormDataProvider.Provider>
        </changeFormDataProvider.Provider>
      </formDataProvider.Provider>
      <div className='form-buttons-container'>
        <FormButtons
          saveButtonText={autosave ? null : saveButtonText}
          onSaveClicked={handleSaveClicked}
          showSaveButton={Boolean(saveButtonText) && !autosave}
        >
          {extraButtons}
        </FormButtons>
      </div>
    </div>
  );
}

export default FormRoot;
