import * as React from 'react';
import {TableView} from "../../../frontend/elements/TableView";
import {useAPI} from "../../../frontend/components/APIProvider";
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

export function CustomFormTable() {
    const api = useAPI();
    const navigate = useNavigate();

    const handleCustomFormRowClicked = useCallback((customForm) => {
        navigate(`/custom_form/${customForm.id}`);
    }, [navigate]);


    return (
        <TableView
            schemaAPI={api.getCustomFormSchema}
            fetchAPI={api.getCustomForms}
            onRowClicked={handleCustomFormRowClicked}
        />
    );
}
