import "./LeftSideMenu.scss";
import * as React from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTimeOutlined";
import AccountTreeIcon from "@mui/icons-material/AccountTreeOutlined";
import ArticleIcon from "@mui/icons-material/ArticleOutlined";
import AssignmentIcon from "@mui/icons-material/AssignmentOutlined";
import AutoStoriesIcon from "@mui/icons-material/AutoStoriesOutlined";
import BusinessIcon from "@mui/icons-material/BusinessOutlined";
import CalendarTodayIcon from "@mui/icons-material/CalendarTodayOutlined";
import Collapse from "@mui/material/Collapse";
import CommentIcon from "@mui/icons-material/CommentOutlined";
import CommitIcon from "@mui/icons-material/CommitOutlined";
import ConnectedTvIcon from "@mui/icons-material/ConnectedTvOutlined";
import DescriptionIcon from "@mui/icons-material/DescriptionOutlined";
import DifferenceIcon from "@mui/icons-material/DifferenceOutlined";
import DvrIcon from "@mui/icons-material/DvrOutlined";
import EditNoteIcon from "@mui/icons-material/EditNoteOutlined";
import EngineeringIcon from "@mui/icons-material/EngineeringOutlined";
import EventNoteIcon from "@mui/icons-material/EventNoteOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LightbulbIcon from "@mui/icons-material/LightbulbOutlined";
import LinkIcon from "@mui/icons-material/LinkOutlined";
import ListAltIcon from '@mui/icons-material/ListAlt';
import ListItemIcon from "@mui/material/ListItemIcon";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";
import MessageIcon from "@mui/icons-material/MessageOutlined";
import ModelTrainingIcon from "@mui/icons-material/ModelTrainingOutlined";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeartOutlined";
import NewspaperIcon from "@mui/icons-material/NewspaperOutlined";
import OutboundIcon from "@mui/icons-material/OutboundOutlined";
import PeopleIcon from "@mui/icons-material/PeopleOutlined";
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalkOutlined";
import PortraitIcon from "@mui/icons-material/PortraitOutlined";
import ScheduleIcon from "@mui/icons-material/ScheduleOutlined";
import SellIcon from "@mui/icons-material/SellOutlined";
import SendIcon from "@mui/icons-material/SendOutlined";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibilityOutlined";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplicationsOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import TopicIcon from "@mui/icons-material/TopicOutlined";
import WebIcon from "@mui/icons-material/WebOutlined";
import {Fragment, useCallback, useContext, useEffect, useState} from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import {NavLink} from "react-router-dom";
import {useAPI} from "../components/APIProvider";
import {useFrontendConfiguration} from "../components/FrontendConfigurationProvider";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import TableChartIcon from "@mui/icons-material/TableChart";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import SchemaIcon from "@mui/icons-material/Schema";
import DataObjectIcon from "@mui/icons-material/DataObject";
import {useEdges} from "reactflow";
import Icon from "@mui/material/Icon";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import {NamedIcon} from "../elements/NamedIcon";
import {useIsAdmin} from "../components/IsAdminProvider";


const globalOpenLeftMenusByDefault =
  window.location.search.includes("open-menus");

const LeftSideMenuStateContext = React.createContext(null);
export const LeftSideMenuStateProvider = ({children}) => {
  const [isExpanded, setIsExpanded] = React.useState(true);

  const leftMenuStateInterface = {
    isExpanded,
    setIsExpanded,
  };

  return (
    <LeftSideMenuStateContext.Provider value={leftMenuStateInterface}>
      {children}
    </LeftSideMenuStateContext.Provider>
  );
};
export const useLeftMenuState = useContext.bind(null, LeftSideMenuStateContext);

function LeftSideMenuEntry({link, icon, text, onClick}) {
  return (
    <ListItem className={"left-side-menu-entry"} disablePadding>
      <NavLink
        to={link}
        onClick={onClick}
        className={({isActive, isPending}) => {
          return isActive ? "active" : isPending ? "pending" : "";
        }}
      >
        <ListItemButton>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText
            primary={<LocalTranslatedText language={"en"} text={text}/>}
          />
        </ListItemButton>
      </NavLink>
    </ListItem>
  );
}

function LeftSideMenuSection({title, icon, links, children}) {
  const frontendConfiguration = useFrontendConfiguration();

  // Determine if the page should be open by checking if the current page is in the list of links
  let defaultOpen =
    links.includes(window.location.pathname) ||
    links.includes(
      window.location.pathname.replace(
        frontendConfiguration.frontend.router_basename,
        ""
      )
    ) ||
    globalOpenLeftMenusByDefault;
  const [open, setOpen] = React.useState(defaultOpen);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
      <ListItemButton onClick={handleClick} className={"left-side-menu-entry"}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title}/>
        {open ? <ExpandLess/> : <ExpandMore/>}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {children}
        </List>
      </Collapse>
    </Fragment>
  );
}

function LeftSideMenuEntryWithAction({
                                       link,
                                       icon,
                                       text,
                                       onClick,
                                       onExpandClicked,
                                       isExpanded,
                                     }) {
  return (
    <ListItem disablePadding className={"left-side-menu-entry"}>
      <NavLink
        to={link}
        onClick={onClick}
        className={({isActive, isPending}) => {
          return isActive ? "active" : isPending ? "pending" : "";
        }}
      >
        <ListItemButton>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText
            primary={<LocalTranslatedText language={"en"} text={text}/>}
          />
          <IconButton edge='end' aria-label='expand' onClick={onExpandClicked}>
            {isExpanded ? <MenuOpenIcon/> : <ChevronRightIcon/>}
          </IconButton>
        </ListItemButton>
      </NavLink>
    </ListItem>
  );
}

export default function LeftSideMenu() {
  const config = useFrontendConfiguration();
  const api = useAPI();
  const {isExpanded, setIsExpanded} = useLeftMenuState();
  const isAdmin = useIsAdmin();

  let defaultCustomDataSchemas = localStorage.getItem("customDataSchemas");
  if (defaultCustomDataSchemas) {
    try {
      defaultCustomDataSchemas = JSON.parse(defaultCustomDataSchemas);
    } catch (e) {
      defaultCustomDataSchemas = null;
      console.error(`Failed to parse custom data schemas from local storage. Got error: ${e}`);
    }
  }

  let defaultCustomForms = localStorage.getItem("customForms");
  if (defaultCustomForms) {
    try {
      defaultCustomForms = JSON.parse(defaultCustomForms);
    } catch (e) {
      defaultCustomForms = null;
      console.error(`Failed to parse custom forms from local storage. Got error: ${e}`);
    }
  }

  const [customDataSchemas, setCustomDataSchemas] = useState(defaultCustomDataSchemas);
  const [customForms, setCustomForms] = useState(defaultCustomForms);
  const [hasRetrievedCustomData, setHasRetrievedCustomData] = useState(false);

  useEffect(() => {
    if (!hasRetrievedCustomData) {
      api.getUserDetails().then((userDetails) => {
        const tableQuery = {
          "startRow": 0,
          "endRow": 1000,
          "filterModel": {
            "show_in_left_menu": {
              "filterType": "set",
              "values": [true]
            },
            "user_id": {
              "filterType": "set",
              "values": [userDetails.user_id, null, ""]
            }
          },
          "sortModel": [],
          "fields": ["id", "title", "machine_name", "show_in_left_menu", "left_menu_icon"],
        }

        api.getCustomDataSchemas(tableQuery).then((customDataSchemas) => {
          localStorage.setItem("customDataSchemas", JSON.stringify(customDataSchemas));
        });
        api.getCustomForms(tableQuery).then((customForms) => {
          localStorage.setItem("customForms", JSON.stringify(customForms));
        });
        setHasRetrievedCustomData(true);
      });
    }
  }, [hasRetrievedCustomData, api]);

  const handleExpandClicked = useCallback(
    (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
      setIsExpanded(!isExpanded);
    },
    [isExpanded, setIsExpanded]
  );

  const handleBillingClick = useCallback(
    (evt) => {
      evt.preventDefault();
      api.getBookingPortalUrl().then((data) => {
        window.location.href = data.url;
      });
    },
    [api]
  );

  return (
    <div className={`left-side-menu ${isExpanded ? "expanded" : ""}`}>
      <div className={"menu-pages-wrapper"} data-testid={"left-side-menu"}>
        <List>
          <LeftSideMenuEntryWithAction
            link={"/"}
            icon={<HouseOutlinedIcon/>}
            text={"Home"}
            onExpandClicked={handleExpandClicked}
            isExpanded={isExpanded}
          />
          {config.modules.bot ? (
            <LeftSideMenuEntry
              link={"/conversations"}
              icon={<MessageIcon/>}
              text={"Conversations"}
            />
          ) : null}
          {
            customDataSchemas?.map((schema) => (
              <LeftSideMenuEntry
                key={schema.id}
                link={`/c/${schema.machine_name}`}
                icon={<NamedIcon icon={schema.left_menu_icon}/>}
                text={schema.title}
              />
            ))
          }
          {
            customForms?.map((customForm) => (
              <LeftSideMenuEntry
                key={customForm.id}
                link={`/cf/${customForm.machine_name}`}
                icon={<NamedIcon icon={customForm.left_menu_icon}/>}
                text={customForm.title}
              />
            ))
          }

          {config.modules.intake ? (
            <LeftSideMenuSection
              title={"Intake"}
              icon={<AssignmentIcon/>}
              links={["/intakes", "/filled_intakes", "/default_intake_configuration"]}
            >
              <LeftSideMenuEntry
                link={"/intakes"}
                icon={<TableChartIcon/>}
                text={`Intakes`}
              />
              <LeftSideMenuEntry
                link={"/filled_intakes"}
                icon={<ChatBubbleOutlineIcon/>}
                text={`Filled Intakes`}
              />
              <LeftSideMenuEntry
                link={"/default_intake_configuration"}
                icon={<AssignmentTurnedInIcon/>}
                text={`Default Intake`}
              />
            </LeftSideMenuSection>
          ) : null}
          {config.modules.scheduling ? (
            <LeftSideMenuSection
              title={"Scheduling"}
              icon={<ScheduleIcon/>}
              links={["/calendars", "/scheduling_rules", "/bookings"]}
            >
              <LeftSideMenuEntry
                link={"/calendars"}
                icon={<CalendarTodayIcon/>}
                text={"Calendars"}
              />
              <LeftSideMenuEntry
                link={"/scheduling_rules"}
                icon={<AccessTimeIcon/>}
                text={"Scheduling Rules"}
              />
              <LeftSideMenuEntry
                link={"/bookings"}
                icon={<EventNoteIcon/>}
                text={"Bookings"}
              />
            </LeftSideMenuSection>
          ) : null}
          {config.modules.web_chat ? (
            <LeftSideMenuSection
              title={"Web Chat"}
              icon={<CommentIcon/>}
              links={["/chat", "/web_chat_configuration"]}
            >
              <LeftSideMenuEntry
                link={"/chat"}
                icon={<SendIcon/>}
                text={"Chat with your Bot"}
              />

              <LeftSideMenuEntry
                link={"/web_chat_configuration"}
                icon={<ConnectedTvIcon/>}
                text={"Web Chat Config"}
              />
            </LeftSideMenuSection>
          ) : null}
          {config.modules.knowledge_base ? (
            <LeftSideMenuSection
              title={"Knowledge Base"}
              icon={<LightbulbIcon/>}
              links={[
                "/imported_documents",
                "/imported_webpages",
                "/knowledge",
                "/knowledge_base_configuration",
              ]}
            >
              <LeftSideMenuEntry
                link={"/imported_documents"}
                icon={<ArticleIcon/>}
                text={"Imported Documents"}
              />
              <LeftSideMenuEntry
                link={"/imported_webpages"}
                icon={<WebIcon/>}
                text={`Imported Webpage`}
              />
              <LeftSideMenuEntry
                link={"/knowledge"}
                icon={<ModelTrainingIcon/>}
                text={"Knowledge"}
              />
            </LeftSideMenuSection>
          ) : null}
          {config.modules.document_generator ? (
            <LeftSideMenuSection
              title={"Document Generation"}
              icon={<AssignmentIcon/>}
              links={["/document_template", "/generated_document"]}
            >
              <LeftSideMenuEntry
                link={"/document_template"}
                icon={<DescriptionIcon/>}
                text={`Document Templates`}
              />
              <LeftSideMenuEntry
                link={"/generated_document"}
                icon={<ArticleIcon/>}
                text={`Generated Documents`}
              />
            </LeftSideMenuSection>
          ) : null}

          {config.modules.legacy_networkingbot ? (
            <LeftSideMenuEntry
              link={"/contacts"}
              icon={<PeopleIcon/>}
              text={"Contacts"}
            />
          ) : null}
          {config.modules.legacy_remindertap ? (
            <LeftSideMenuEntry
              link={"/insurance_agents"}
              icon={<EngineeringIcon/>}
              text={"Insurance Agents"}
            />
          ) : null}
          {config.modules.legacy_remindertap ? (
            <LeftSideMenuEntry
              link={"/customers"}
              icon={<PeopleIcon/>}
              text={"Customers"}
            />
          ) : null}
          {config.modules.legacy_mybookrobot ? (
            <LeftSideMenuEntry
              link={"/generate_story"}
              icon={<AutoStoriesIcon/>}
              text={"Generate Story"}
            />
          ) : null}
          {config.modules.legacy_mybookrobot ? (
            <LeftSideMenuEntry
              link={"/story"}
              icon={<DvrIcon/>}
              text={"List Stories"}
            />
          ) : null}
          {config.modules.legacy_mybookrobot ? (
            <LeftSideMenuEntry
              link={"/story_prompt_configuration"}
              icon={<DescriptionIcon/>}
              text={"Prompt Configuration"}
            />
          ) : null}
          {config.modules.legacy_wordfactory ? (
            <LeftSideMenuEntry
              link={"/content_samples"}
              icon={<ArticleIcon/>}
              text={"Content Samples"}
            />
          ) : null}
          {config.modules.legacy_wordfactory ? (
            <LeftSideMenuEntry
              link={"/topic_extractor_prompts"}
              icon={<TopicIcon/>}
              text={"Topic Prompts"}
            />
          ) : null}
          {config.modules.legacy_wordfactory ? (
            <LeftSideMenuEntry
              link={"/transcript_processor_configurations"}
              icon={<EditNoteIcon/>}
              text={"Processor Configs"}
            />
          ) : null}
          {config.modules.legacy_wordfactory ? (
            <LeftSideMenuEntry
              link={"/process_transcript"}
              icon={<DifferenceIcon/>}
              text={"Process New Transcript"}
            />
          ) : null}
          {config.modules.legacy_wordfactory ? (
            <LeftSideMenuEntry
              link={"/generated_content"}
              icon={<NewspaperIcon/>}
              text={"Generated Content History"}
            />
          ) : null}

          <LeftSideMenuSection
            title={"Configuration"}
            icon={<SettingsApplicationsIcon/>}
            links={[
              "/smart_chains",
              "/smart_chain_binding",
              "/tenant_configuration",
              "/personal_info",
              "/business_info",
              "/voice_configuration",
              "/wa_2chat_configuration",
              "/subscription",
              "/prompt_charts",
              "/default_outbound_rules",
              "/synthetic_user",
              "/system_health",
              "/custom_data_schema",
              "/custom_data_schemas",
              "/c",
              "/impersonate",
              "/custom_form",
              "/about",
              "/system",
            ]}
          >
            {config.modules.legacy_networkingbot ? (
              <LeftSideMenuEntry
                link={"/personal_info"}
                icon={<SettingsAccessibilityIcon/>}
                text={"Personal Info"}
              />
            ) : null}
            {config.modules.business_info ? (
              <LeftSideMenuEntry
                link={"/business_info"}
                icon={<BusinessIcon/>}
                text={"Business Info"}
              />
            ) : null}
            {config.modules.voice ? (
              <LeftSideMenuEntry
                link={"/voice_configuration"}
                icon={<PhoneInTalkIcon/>}
                text={"Voice Configuration"}
              />
            ) : null}
            {config.modules.whatsapp ? (
              <LeftSideMenuEntry
                link={"/wa_2chat_configuration"}
                icon={<PhoneInTalkIcon/>}
                text={"2Chat Configuration"}
              />
            ) : null}
            {config.modules.billing ? (
              <LeftSideMenuEntry
                link={"/subscription"}
                icon={<SellIcon/>}
                text={"Billing & Subscription"}
                onClick={handleBillingClick}
              />
            ) : null}
            {config.modules.outbound ? (
              <LeftSideMenuEntry
                link={"/default_outbound_rules"}
                icon={<OutboundIcon/>}
                text={`${config.frontend.default_outbound_rules_word} Rules`}
              />
            ) : null}

            <LeftSideMenuSection
              title={"Advanced"}
              icon={<ArrowCircleDownIcon/>}
              links={[
                "/smart_chains",
                "/smart_chain_binding",
                "/custom_data_schema",
                "/custom_data_schemas",
                "/c",
                "/tenant_configuration",
                "/custom_form",
                "/prompt_charts",
                "/synthetic_user",
                "/system_health",
                "/impersonate",
                "/about",
                "/system",
              ]}
            >
              <LeftSideMenuEntry
                link={"/smart_chains"}
                icon={<LinkIcon/>}
                text={"Smart Chains"}
              />
              <LeftSideMenuEntry
                link={"/smart_chain_binding"}
                icon={<CommitIcon/>}
                text={"Smart Chain Bindings"}
              />
              <LeftSideMenuEntry
                link={"/custom_data_schema"}
                icon={<SchemaIcon/>}
                text={"Custom Data Schemas"}
              />
              <LeftSideMenuEntry
                link={"/custom_form"}
                icon={<ListAltIcon/>}
                text={"Custom Forms"}
              />
              {config.modules.tenant ? (
                <LeftSideMenuEntry
                  link={"/tenant_configuration"}
                  icon={<SettingsIcon/>}
                  text={"Tenants"}
                />
              ) : null}
              {config.modules.prompt_chart ? (
                <LeftSideMenuEntry
                  link={"/prompt_charts"}
                  icon={<AccountTreeIcon/>}
                  text={"Prompt Charts"}
                />
              ) : null}
              {config.modules.bot ? (
                <LeftSideMenuEntry
                  link={"/synthetic_user"}
                  icon={<PortraitIcon/>}
                  text={"Synthetic User"}
                />
              ) : null}
              {isAdmin ? (
                <LeftSideMenuEntry
                  link={"/impersonate"}
                  icon={<SwitchAccountIcon/>}
                  text={"Impersonate"}
                />
              ) : null}
              <LeftSideMenuEntry
                link={"/system_health"}
                icon={<MonitorHeartIcon/>}
                text={"System Health"}
              />
              <LeftSideMenuEntry
                link={"/about"}
                icon={<FavoriteIcon/>}
                text={"About System"}
              />
            </LeftSideMenuSection>
          </LeftSideMenuSection>
        </List>
      </div>
    </div>
  );
}
