import React, { useCallback } from "react";
import FormInput from "../../../core/frontend/elements/form/FormInput";
import FormRoot from "../../../core/frontend/elements/form/FormRoot";
import FormSection from "../../../core/frontend/elements/form/FormSection";
import { SpinnerButton } from "../../../core/frontend/elements/SpinnerButton";
import "./ContentSampleForm.scss";

export default function ContentSampleForm({
  initialContentSample,
  onContentSampleSave,
  onDeletedClicked,
  saveButtonText,
}) {
  const handleContentSampleSaved = useCallback(
    (newContentSampleData) => {
      if (onContentSampleSave) {
        return onContentSampleSave(newContentSampleData);
      }
    },
    [onContentSampleSave]
  );

  return (
    <div className='content-sample-form'>
      <FormRoot
        initialValue={initialContentSample}
        saveButtonText={saveButtonText ?? "Save Content Sample"}
        onSave={handleContentSampleSaved}
        extraButtons={
          <>
            {onDeletedClicked ? (
              <SpinnerButton
                variant='contained'
                color='error'
                className={"delete-button"}
                onClick={onDeletedClicked}
              >
                Delete
              </SpinnerButton>
            ) : null}
          </>
        }
      >
        <FormSection
          title={"General"}
          subtitle={
            "Metadata that can be used to find this content sample in the DB"
          }
        >
          <FormInput type={"text"} label={"Title"} fieldName={"title"} />

          <FormInput type={"text"} label={"Tags"} fieldName={"tags"} />

          <FormInput
            type={"text"}
            label={"Description"}
            fieldName={"description"}
          />

          <FormInput
            type={"text"}
            label={"Content Idea"}
            fieldName={"idea"}
            multiline
            rows={4}
          />

          <FormInput
            type={"text"}
            label={"Excerpt From Transcript"}
            fieldName={"transcript_excerpt"}
            multiline
            rows={8}
          />

          <FormInput
            type={"text"}
            label={"Final Post Derived from Excerpt"}
            fieldName={"post"}
            multiline
            rows={16}
          />
        </FormSection>
      </FormRoot>
    </div>
  );
}
