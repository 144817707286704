import { ReactRenderer } from '@tiptap/react'
import tippy from 'tippy.js'
import MentionList from './NodeMentionList.js'
import useChartStore from "../ChartState";

export default function createNodeSuggesterForNode(node) {
    return {
        items: ({query}) => {
            const {nodes} = useChartStore.getState();

            const filtered = Object.values(nodes)
                .filter(item => (item.type !== "inputGroupNode" && item.type !== "outputNode"))
                .map(item => ({
                    id: item.id,
                    label: item.displayTitle ?? item.data.title ?? "Untitled Node",
                }))
                .filter(item => item.label.toLowerCase().startsWith(query.toLowerCase()) && item.id !== node.id)
                .slice(0, 5);

            return filtered
        },

        render: () => {
            let component
            let popup

            return {
                onStart: props => {
                    component = new ReactRenderer(MentionList, {
                        props,
                        editor: props.editor,
                    })

                    if (!props.clientRect) {
                        return
                    }

                    popup = tippy('body', {
                        getReferenceClientRect: props.clientRect,
                        appendTo: () => document.body,
                        content: component.element,
                        showOnCreate: true,
                        interactive: true,
                        trigger: 'manual',
                        placement: 'bottom-start',
                    })
                },

                onUpdate(props) {
                    component.updateProps(props)

                    if (!props.clientRect) {
                        return
                    }

                    popup[0].setProps({
                        getReferenceClientRect: props.clientRect,
                    })
                },

                onKeyDown(props) {
                    if (props.event.key === 'Escape') {
                        popup[0].hide()

                        return true
                    }

                    return component.ref?.onKeyDown(props)
                },

                onExit() {
                    popup[0].destroy()
                    component.destroy()
                },
            }
        },
    };
}