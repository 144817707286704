import "../nodes/PromptNode/PromptNode.scss";
import "./NodeCustomPromptEditor.scss";
import * as React from 'react';
import BehaviorTracker from "../../../../core/frontend/components/BehaviorTracker";
import useChartStore from "../ChartState";
import {TextEditorWithNodeSuggester} from "./TextEditorWithNodeSuggester";


function NodeCustomPromptEditor({nodeId, savePrompt}) {
    const {node, changeNodeData} = useChartStore((state) => {
        return {
            node: state.getNode(nodeId),
            changeNodeData: state.changeNodeData,
        }
    });

    const handleOnChange = function(html) {
        if (node.type === "outputNode") {
            BehaviorTracker.trackInteraction({
                id: 'edit-output-node',
                mixpanel: null,
            });
        } else if (node.type === "promptNode") {
            BehaviorTracker.trackInteraction({
                id: 'edit-prompt-node',
                mixpanel: null,
            });
        }


        changeNodeData(nodeId, {
            prompt: html,
            stale: true,
        });
    }

    const handleOnBlur = function() {
        if (savePrompt) {
            savePrompt();
        }
    }

    if (!node) {
        return;
    }

    return (
        <TextEditorWithNodeSuggester
            id={`prompt-editor-${nodeId}`}
            className={"node-custom-prompt-editor"}
            nodeId={nodeId}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            value={node?.data?.prompt ?? ""}
            placeholder={"Write your prompt here..."}
            focus={true}
        />
    );
}

export default NodeCustomPromptEditor;
