import { IconButton, TextField } from "@mui/material";
import React, { memo } from "react";
import { getIcon } from "../utils/schema-utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import "./KeyField.scss";

function KeyField({
  currentEditingKey,
  value,
  onKeyChange,
  handleKeyDown,
  onKeyBlur,
  handleRemoveField,
  nodeId,
  toggleSection,
  openSections,
  disabled,
}) {
  return (
    <div
      role='button'
      className='field-list-item'
      onClick={() => toggleSection(nodeId)}
      tabIndex={0}
    >
      <div className='field-list-item-key'>
        {getIcon(value?.type)}
        <div className='field-list-item-key-input'>
          <TextField
            size='small'
            label='Key'
            value={currentEditingKey}
            onChange={(e) => onKeyChange(e.target.value)}
            onBlur={onKeyBlur}
            onKeyDown={handleKeyDown}
            disabled={disabled}
            fullWidth
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      </div>
      <div className='field-list-item-actions'>
        <IconButton size='small' className='expand-button'>
          {openSections[nodeId] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        <IconButton
          onClick={handleRemoveField}
          size='small'
          className='remove-field-button'
          disabled={disabled}
        >
          <RemoveCircleOutlineIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default memo(KeyField);
