import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import {useCallback, useEffect} from "react";
import {styled} from "@mui/material/styles";
import "./SmartChainStepTypeSelector.scss";
import {useAPI} from "../../../frontend/components/APIProvider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Icon from '@mui/material/Icon';
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";
import Autocomplete from "@mui/material/Autocomplete";
import {TextField} from "@mui/material";
import _ from "lodash";

const StyledTextInput = styled(TextField)(({theme}) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0), // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(1)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '12vw',
        },
    },
}));

export default function SmartChainStepTypeSelector({value, onChange}) {
    const api = useAPI();
    const [stepTypes, setStepTypes] = React.useState([]);
    useEffect(() => {
        api.getSmartChainStepTypes().then((types) => {
            setStepTypes(types);
        });
    }, [api]);

    const handleChange = useCallback((event, newValue) => {
        onChange(newValue.name);
    }, [api]);

    if (!stepTypes) {
        return null;
    }

    return (
        <Autocomplete
            className={"smart-chain-step-type-selector"}
            id="smart-chain-step-type"
            autoHighlight
            disableClearable={true}
            size={'small'}
            value={value ? stepTypes.find((type) => type.name === value) ?? "" : ""}
            options={stepTypes}
            onChange={handleChange}
            getOptionLabel={(option) => option ? option.name : ""}
            getOptionKey={(option) => option ? option.name : ""}
            renderInput={(params) => <StyledTextInput {...params} />}
            isOptionEqualToValue={(option, value) => option.name === value || option === value}
            slotProps={{
                popper: {
                    style: {
                        width: "max(300px, 40vw)"
                    }
                }
            }}
            renderOption={(props, type) => {
                return <li key={type.name}
                           value={type.name}
                           className={"smart-chain-step-type-menu-entry"}
                           {..._.omit(props, "key")}
                >
                    <ListItemAvatar>
                        <Avatar><Icon>{type.icon}</Icon></Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={<LocalTranslatedText language={'en'} text={type.title}/>}
                        secondary={<LocalTranslatedText language={'en'} text={type.description}/>}
                    />
                </li>
            }}
        />
    );
}