import "./ChartListPage.scss";
import Paper from "@mui/material/Paper";
import {useCallback} from "react";
import {ChartTable} from "./ChartTable";
import {PageHeader} from "../../../core/frontend/elements/PageHeader";
import {PageWrapperWithMenu} from "../../../core/frontend/elements/PageWrapperWithMenu";
import {WhiteButton} from "../../../core/frontend/elements/WhiteButton";
import {useNavigate} from "react-router-dom";
import {createBlankChart} from "./builtincharts/blank";
import {useAPI} from "../../../core/frontend/components/APIProvider";

export default function ChartListPage() {
    const navigate = useNavigate();
    const api = useAPI();

    const handleChartClicked = useCallback((chart) => {
        navigate(`/prompt_charts/${chart.id}`)
    }, [navigate]);

    const handleNewChartClicked = useCallback(async () => {
        const newChart = createBlankChart();

        return api.createNewChart(newChart).then((newChartData) => {
            navigate(`/prompt_charts/${newChartData.id}`);
        });

    }, [api, navigate]);

    return <PageWrapperWithMenu>
        <PageHeader title={"Charts"}>
            <WhiteButton onClick={handleNewChartClicked}>
                Add New Chart
            </WhiteButton>
        </PageHeader>
        <div className="chart-list-page">
            <Paper className={"main-page-contents"}>
                <ChartTable onChartClicked={handleChartClicked}/>
            </Paper>
        </div>
    </PageWrapperWithMenu>
}

