import {PageWrapperWithMenu} from "../../../frontend/elements/PageWrapperWithMenu";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useAPI} from "../../../frontend/components/APIProvider";
import {SmartChainBindingTable} from "./SmartChainBindingTable";
import "./SmartChainBindingListPage.scss";
import {PageHeader} from "../../../frontend/elements/PageHeader";
import {WhiteButton} from "../../../frontend/elements/WhiteButton";
import Paper from "@mui/material/Paper";
import {useCallback} from "react";


export default function SmartChainBindingListPage() {
    const api = useAPI();
    const navigate = useNavigate();

    const handleCreateNewBindingClicked = useCallback(async () => {
        navigate(`/smart_chain_binding/new`);
    }, [navigate]);

    return <PageWrapperWithMenu>
        <PageHeader title={"Smart Chain Bindings"}>
            <WhiteButton onClick={handleCreateNewBindingClicked}>
                Creating New Binding
            </WhiteButton>
        </PageHeader>
        <div className={"smart-chain-binding-list-page"}>
            <div className={"smart-chain-binding-table-wrapper"}>
                <Paper className={"main-page-contents"}>
                    <SmartChainBindingTable/>
                </Paper>
            </div>
        </div>
    </PageWrapperWithMenu>
}

