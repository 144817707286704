import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import ErrorBoundary from "../components/ErrorBoundary";
import React, { useEffect } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { SnackbarProvider } from "../components/SnackbarProvider";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ThemeProvider } from "@mui/material";
import { theme } from "../theme/theme";
import { APIProvider } from "../components/APIProvider";
import { useFrontendConfiguration } from "../components/FrontendConfigurationProvider";
import { BackendPingWrapper } from "../components/BackendPingWrapper";
import "../components/ErrorReporting";
import { GoogleMapsProvider } from "../components/GoogleMapsProvider";
import { IsAdminProvider } from "../components/IsAdminProvider";
import { ErrorReportingWrapper } from "../components/ErrorReporting";
import globalTranslationManager from "../../translation/frontend/components/translation_manager";
import { LeftSideMenuStateProvider } from "../menu/LeftSideMenu";
import { BackendWakeupWrapper } from "../components/BackendWakeupWrapper";

const PageComponentWrapper = ({ component }) => {
  let Component = component.default;
  return () => <Component />;
};

const HomePage = PageComponentWrapper({
  component: require("../pages/HomePage"),
});

const SystemHealthPage = PageComponentWrapper({
  component: require("../pages/SystemHealthPage"),
});

const AboutSystemPage = PageComponentWrapper({
  component: require("../pages/AboutSystemPage"),
});

const TenantConfigurationsListPage = PageComponentWrapper({
  component: require("../../frontend/tenant_configuration/TenantConfigurationListPage"),
});

const NewTenantConfigurationsPage = PageComponentWrapper({
  component: require("../../frontend/tenant_configuration/NewTenantConfigurationPage"),
});

const TenantConfigurationsEditPage = PageComponentWrapper({
  component: require("../../frontend/tenant_configuration/TenantConfigurationEditPage"),
});

const ConversationsListPage = PageComponentWrapper({
  component: require("../../conversations/frontend/ConversationsListPage"),
});

const ConversationViewPage = PageComponentWrapper({
  component: require("../../conversations/frontend/ConversationViewPage"),
});

const CustomersListPage = PageComponentWrapper({
  component: require("../../../remindertap/customers/frontend/CustomersListPage"),
});

const CustomerEditPage = PageComponentWrapper({
  component: require("../../../remindertap/customers/frontend/CustomerEditPage"),
});

const BusinessInfoPage = PageComponentWrapper({
  component: require("../../../shared/business_info/frontend/BusinessInfoPage"),
});

const SchedulingRulesPage = PageComponentWrapper({
  component: require("../../../shared/scheduling/frontend/scheduling_rules/SchedulingRulesPage"),
});

const ChatWithBotPage = PageComponentWrapper({
  component: require("../../web/frontend/ChatWithBotPage"),
});

const VoiceConfigurationPage = PageComponentWrapper({
  component: require("../../voice/frontend/VoiceConfigurationPage"),
});

const WA2ChatConfigurationPage = PageComponentWrapper({
  component: require("../../wa_2chat/frontend/WA2ChatConfigurationPage"),
});

const BookingsListPage = PageComponentWrapper({
  component: require("../../../shared/scheduling/frontend/bookings/BookingsListPage"),
});

const NewBookingPage = PageComponentWrapper({
  component: require("../../../shared/scheduling/frontend/bookings/NewBookingPage"),
});

const EditBookingPage = PageComponentWrapper({
  component: require("../../../shared/scheduling/frontend/bookings/EditBookingPage"),
});

const RunSyntheticUserPage = PageComponentWrapper({
  component: require("../../synthetic/frontend/RunSyntheticUserPage"),
});

const EditStoryPromptConfigurationPage = PageComponentWrapper({
  component: require("../../../mybookrobot/frontend/EditStoryPromptConfigurationPage"),
});

const GenerateNewStoryPage = PageComponentWrapper({
  component: require("../../../mybookrobot/frontend/GenerateNewStoryPage"),
});

const StoryListPage = PageComponentWrapper({
  component: require("../../../mybookrobot/frontend/StoryListPage"),
});

const ViewStoryPage = PageComponentWrapper({
  component: require("../../../mybookrobot/frontend/ViewStoryPage"),
});

const CalendarListPage = PageComponentWrapper({
  component: require("../../../shared/scheduling/frontend/calendars/CalendarListPage"),
});

const NewCalendarPage = PageComponentWrapper({
  component: require("../../../shared/scheduling/frontend/calendars/NewCalendarPage"),
});

const EditCalendarPage = PageComponentWrapper({
  component: require("../../../shared/scheduling/frontend/calendars/EditCalendarPage"),
});

const WebChatConfigurationPage = PageComponentWrapper({
  component: require("../../web/frontend/WebChatConfigurationPage"),
});

const PrivacyPolicyPage = PageComponentWrapper({
  component: require("../pages/PrivacyPolicyPage"),
});

const TermsAndConditionsPage = PageComponentWrapper({
  component: require("../pages/TermsAndConditionsPage"),
});

const ChartListPage = PageComponentWrapper({
  component: require("../../prompt_chart/frontend/ChartListPage"),
});

const ChartEditPage = PageComponentWrapper({
  component: require("../../prompt_chart/frontend/ChartEditPage"),
});

const InsuranceAgentsListPage = PageComponentWrapper({
  component: require("../../../remindertap/insurance_agents/frontend/InsuranceAgentsListPage"),
});

const NewInsuranceAgentPage = PageComponentWrapper({
  component: require("../../../remindertap/insurance_agents/frontend/NewInsuranceAgentPage"),
});

const InsuranceAgentEditPage = PageComponentWrapper({
  component: require("../../../remindertap/insurance_agents/frontend/InsuranceAgentEditPage"),
});

const SubscriptionPage = PageComponentWrapper({
  component: require("../../billing/frontend/SubscriptionPage"),
});

const ContactsListPage = PageComponentWrapper({
  component: require("../../../networkingbot/frontend/ContactListPage"),
});

const ContactEditPage = PageComponentWrapper({
  component: require("../../../networkingbot/frontend/ContactEditPage"),
});

const PersonalInfoPage = PageComponentWrapper({
  component: require("../../../networkingbot/frontend/PersonalInfoPage"),
});

const NewCustomerPage = PageComponentWrapper({
  component: require("../../../remindertap/customers/frontend/NewCustomerPage"),
});

const SingleChartEvaluationPage = PageComponentWrapper({
  component: require("../../bulk_chart_evaluations/frontend/SingleChartEvaluationPage"),
});

const ContentSampleListPage = PageComponentWrapper({
  component: require("../../../wordfactory/frontend/content_sample/ContentSampleListPage"),
});

const ContentSampleEditPage = PageComponentWrapper({
  component: require("../../../wordfactory/frontend/content_sample/ContentSampleEditPage"),
});

const NewContentSamplePage = PageComponentWrapper({
  component: require("../../../wordfactory/frontend/content_sample/NewContentSamplePage"),
});

const TopicExtractorPromptListPage = PageComponentWrapper({
  component: require("../../../wordfactory/frontend/topic_extractor_prompt/TopicExtractorPromptListPage"),
});

const TopicExtractorPromptEditPage = PageComponentWrapper({
  component: require("../../../wordfactory/frontend/topic_extractor_prompt/TopicExtractorPromptEditPage"),
});

const NewTopicExtractorPromptPage = PageComponentWrapper({
  component: require("../../../wordfactory/frontend/topic_extractor_prompt/NewTopicExtractorPromptPage"),
});

const TranscriptProcessorConfigurationListPage = PageComponentWrapper({
  component: require("../../../wordfactory/frontend/transcript_processor_configuration/TranscriptProcessorConfigurationListPage"),
});

const TranscriptProcessorConfigurationEditPage = PageComponentWrapper({
  component: require("../../../wordfactory/frontend/transcript_processor_configuration/TranscriptProcessorConfigurationEditPage"),
});

const NewTranscriptProcessorConfigurationPage = PageComponentWrapper({
  component: require("../../../wordfactory/frontend/transcript_processor_configuration/NewTranscriptProcessorConfigurationPage"),
});

const ProcessTranscriptPage = PageComponentWrapper({
  component: require("../../../wordfactory/frontend/ProcessTranscriptPage"),
});

const GeneratedContentListPage = PageComponentWrapper({
  component: require("../../../wordfactory/frontend/generated_content/GeneratedContentListPage"),
});

const GeneratedContentViewPage = PageComponentWrapper({
  component: require("../../../wordfactory/frontend/generated_content/ViewGeneratedContentPage"),
});

const ImportedDocumentListPage = PageComponentWrapper({
  component: require("../../knowledge_base/frontend/imported_document/ImportedDocumentListPage"),
});

const ImportedDocumentEditPage = PageComponentWrapper({
  component: require("../../knowledge_base/frontend/imported_document/ImportedDocumentEditPage"),
});

const NewImportedDocumentPage = PageComponentWrapper({
  component: require("../../knowledge_base/frontend/imported_document/NewDocumentPage"),
});

const KnowledgeChunkListPage = PageComponentWrapper({
  component: require("../../knowledge_base/frontend/knowledge_chunk/KnowledgeChunkListPage"),
});

const KnowledgeChunkViewPage = PageComponentWrapper({
  component: require("../../knowledge_base/frontend/knowledge_chunk/KnowledgeChunkViewPage"),
});

const BulkDocumentUploadPage = PageComponentWrapper({
  component: require("../../knowledge_base/frontend/imported_document/BulkDocumentUploadPage"),
});

const DefaultOutboundRulesPage = PageComponentWrapper({
  component: require("../../outbound/frontend/DefaultOutboundRulesPage"),
});

const DocumentTemplateListPage = PageComponentWrapper({
  component: require("../../document_generator/frontend/document_template/DocumentTemplateListPage"),
});

const DocumentTemplateEditPage = PageComponentWrapper({
  component: require("../../document_generator/frontend/document_template/DocumentTemplateEditPage"),
});

const ImportedWebpageListPage = PageComponentWrapper({
  component: require("../../knowledge_base/frontend/imported_webpage/ImportedWebpageListPage"),
});

const ImportedWebpageEditPage = PageComponentWrapper({
  component: require("../../knowledge_base/frontend/imported_webpage/ImportedWebpageEditPage"),
});

const ImportWebpagePage = PageComponentWrapper({
  component: require("../../knowledge_base/frontend/imported_webpage/ImportWebpagePage"),
});

const GeneratedDocumentListPage = PageComponentWrapper({
  component: require("../../document_generator/frontend/generated_document/GeneratedDocumentListPage"),
});

const GeneratedDocumentEditPage = PageComponentWrapper({
  component: require("../../document_generator/frontend/generated_document/GeneratedDocumentEditPage"),
});

const IntakeStartPage = PageComponentWrapper({
  component: require("../../intake/frontend/IntakeStartPage"),
});

const IntakeListPage = PageComponentWrapper({
  component: require("../../intake/frontend/intakes/IntakeListPage"),
});

const IntakeEditorPage = PageComponentWrapper({
  component: require("../../intake/frontend/intakes/IntakeEditorPage"),
});

const FilledIntakeListPage = PageComponentWrapper({
  component: require("../../intake/frontend/filled_intakes/FilledIntakeListPage"),
});

const ViewFilledIntakePage = PageComponentWrapper({
  component: require("../../intake/frontend/filled_intakes/ViewFilledIntakePage"),
});

const DefaultIntakeConfigurationPage = PageComponentWrapper({
  component: require("../../intake/frontend/default_intake_configuration/DefaultIntakeConfigurationPage"),
});

const SmartChainListPage = PageComponentWrapper({
  component: require("../../smart_chains/frontend/smart_chain/SmartChainListPage"),
});

const SmartChainEditorPage = PageComponentWrapper({
  component: require("../../smart_chains/frontend/smart_chain/SmartChainEditPage"),
});

const NewSmartChainBindingPage = PageComponentWrapper({
  component: require("../../smart_chains/frontend/smart_chain_binding/NewSmartChainBindingPage"),
});

const SmartChainBindingListPage = PageComponentWrapper({
  component: require("../../smart_chains/frontend/smart_chain_binding/SmartChainBindingListPage"),
});

const CustomDataSchemaListPage = PageComponentWrapper({
  component: require("../../custom_data/frontend/custom_data_schema/CustomDataSchemaListPage"),
});

const NewCustomDataSchemaPage = PageComponentWrapper({
  component: require("../../custom_data/frontend/custom_data_schema/NewCustomDataSchemaPage"),
});

const CustomDataSchemaEditPage = PageComponentWrapper({
  component: require("../../custom_data/frontend/custom_data_schema/CustomDataSchemaEditPage"),
});

const CustomDataObjectListPage = PageComponentWrapper({
  component: require("../../custom_data/frontend/custom_data_object/CustomDataObjectListPage"),
});

const NewCustomDataObjectPage = PageComponentWrapper({
  component: require("../../custom_data/frontend/custom_data_object/NewCustomDataObjectPage"),
});

const CustomDataObjectEditPage = PageComponentWrapper({
  component: require("../../custom_data/frontend/custom_data_object/CustomDataObjectEditPage"),
});

const CustomFormListPage = PageComponentWrapper({
  component: require("../../custom_data/frontend/custom_form/CustomFormListPage"),
});

const NewCustomFormPage = PageComponentWrapper({
  component: require("../../custom_data/frontend/custom_form/NewCustomFormPage"),
});

const CustomFormEditPage = PageComponentWrapper({
  component: require("../../custom_data/frontend/custom_form/CustomFormEditPage"),
});

const UseCustomFormPage = PageComponentWrapper({
  component: require("../../custom_data/frontend/custom_form/UseCustomFormPage"),
});

const ImpersonatePage = PageComponentWrapper({
  component: require("../../frontend/pages/ImpersonatePage"),
});

function createRouter(config) {
  return createBrowserRouter(
    [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: "health",
        element: <SystemHealthPage />,
      },
      {
        path: "health_check",
        element: <SystemHealthPage />,
      },
      {
        path: "system_health",
        element: <SystemHealthPage />,
      },
      {
        path: "system",
        element: <AboutSystemPage />,
      },
      {
        path: "about",
        element: <AboutSystemPage />,
      },
      {
        path: "tenant_configuration",
        element: <TenantConfigurationsListPage />,
      },
      {
        path: "tenant_configuration/new",
        element: <NewTenantConfigurationsPage />,
      },
      {
        path: "tenant_configuration/:tenantConfigurationId",
        element: <TenantConfigurationsEditPage />,
      },
      {
        path: "conversations",
        element: <ConversationsListPage />,
      },
      {
        path: "conversations/:conversationId",
        element: <ConversationViewPage />,
      },
      {
        path: "business_info",
        element: <BusinessInfoPage />,
      },
      {
        path: "scheduling_rules",
        element: <SchedulingRulesPage />,
      },
      {
        path: "customers/new",
        element: <NewCustomerPage />,
      },
      {
        path: "customers",
        element: <CustomersListPage />,
      },
      {
        path: "customers/:customerId",
        element: <CustomerEditPage />,
      },
      {
        path: "chat",
        element: <ChatWithBotPage />,
      },
      {
        path: "voice_configuration",
        element: <VoiceConfigurationPage />,
      },
      {
        path: "wa_2chat_configuration",
        element: <WA2ChatConfigurationPage />,
      },
      {
        path: "bookings/new",
        element: <NewBookingPage />,
      },
      {
        path: "bookings/:bookingId",
        element: <EditBookingPage />,
      },
      {
        path: "bookings",
        element: <BookingsListPage />,
      },
      {
        path: "synthetic_user",
        element: <RunSyntheticUserPage />,
      },
      {
        path: "story_prompt_configuration",
        element: <EditStoryPromptConfigurationPage />,
      },
      {
        path: "generate_story",
        element: <GenerateNewStoryPage />,
      },
      {
        path: "calendars/new",
        element: <NewCalendarPage />,
      },
      {
        path: "calendars/:calendarId",
        element: <EditCalendarPage />,
      },
      {
        path: "calendars",
        element: <CalendarListPage />,
      },
      {
        path: "web_chat_configuration",
        element: <WebChatConfigurationPage />,
      },
      {
        path: "privacy_policy",
        element: <PrivacyPolicyPage />,
      },
      {
        path: "terms_and_conditions",
        element: <TermsAndConditionsPage />,
      },
      {
        path: "prompt_charts/:chartId",
        element: <ChartEditPage />,
      },
      {
        path: "prompt_charts/",
        element: <ChartListPage />,
      },
      {
        path: "insurance_agents/new",
        element: <NewInsuranceAgentPage />,
      },
      {
        path: "insurance_agents/",
        element: <InsuranceAgentsListPage />,
      },
      {
        path: "insurance_agents/:insuranceAgentId",
        element: <InsuranceAgentEditPage />,
      },
      {
        path: "subscription",
        element: <SubscriptionPage />,
      },
      {
        path: "contacts",
        element: <ContactsListPage />,
      },
      {
        path: "contacts/:contactId",
        element: <ContactEditPage />,
      },
      {
        path: "personal_info",
        element: <PersonalInfoPage />,
      },
      {
        path: "story",
        element: <StoryListPage />,
      },
      {
        path: "story/:storyId",
        element: <ViewStoryPage />,
      },
      {
        path: "single_chart_evaluation/:singleChartEvaluationId",
        element: <SingleChartEvaluationPage />,
      },
      {
        path: "content_samples",
        element: <ContentSampleListPage />,
      },
      {
        path: "content_samples/new",
        element: <NewContentSamplePage />,
      },
      {
        path: "content_samples/:contentSampleId",
        element: <ContentSampleEditPage />,
      },
      {
        path: "topic_extractor_prompts",
        element: <TopicExtractorPromptListPage />,
      },
      {
        path: "topic_extractor_prompts/new",
        element: <NewTopicExtractorPromptPage />,
      },
      {
        path: "topic_extractor_prompts/:topicExtractorPromptId",
        element: <TopicExtractorPromptEditPage />,
      },
      {
        path: "transcript_processor_configurations",
        element: <TranscriptProcessorConfigurationListPage />,
      },
      {
        path: "transcript_processor_configurations/new",
        element: <NewTranscriptProcessorConfigurationPage />,
      },
      {
        path: "transcript_processor_configurations/:transcriptProcessorConfigurationId/*",
        element: <TranscriptProcessorConfigurationEditPage />,
      },
      {
        path: "process_transcript",
        element: <ProcessTranscriptPage />,
      },
      {
        path: "generated_content",
        element: <GeneratedContentListPage />,
      },
      {
        path: "generated_content/:generatedContentId",
        element: <GeneratedContentViewPage />,
      },
      {
        path: "/imported_document/:documentId",
        element: <ImportedDocumentEditPage />,
      },
      {
        path: "/imported_documents/new",
        element: <NewImportedDocumentPage />,
      },
      {
        path: "/imported_documents/upload",
        element: <BulkDocumentUploadPage />,
      },
      {
        path: "/imported_documents",
        element: <ImportedDocumentListPage />,
      },
      {
        path: "/knowledge",
        element: <KnowledgeChunkListPage />,
      },
      {
        path: "/knowledge/:knowledgeChunkId",
        element: <KnowledgeChunkViewPage />,
      },
      {
        path: "/default_outbound_rules",
        element: <DefaultOutboundRulesPage />,
      },
      {
        path: "/document_template",
        element: <DocumentTemplateListPage />,
      },
      {
        path: "/document_template/:documentTemplateId",
        element: <DocumentTemplateEditPage />,
      },
      {
        path: "/generated_document",
        element: <GeneratedDocumentListPage />,
      },
      {
        path: "/generated_document/:generatedDocumentId",
        element: <GeneratedDocumentEditPage />,
      },
      {
        path: "/imported_webpages",
        element: <ImportedWebpageListPage />,
      },
      {
        path: "/imported_webpages/import",
        element: <ImportWebpagePage />,
      },
      {
        path: "/imported_webpages/:importedWebpageId",
        element: <ImportedWebpageEditPage />,
      },
      {
        path: "intakebot/start",
        element: <IntakeStartPage />,
      },
      {
        path: "intakes",
        element: <IntakeListPage />,
      },
      {
        path: "intakes/:intakeId/*",
        element: <IntakeEditorPage />,
      },
      {
        path: "intakes/config/:intakeToken/*",
        element: <WebChatConfigurationPage />,
      },
      {
        path: "intakes/chat/:intakeToken/*",
        element: <ChatWithBotPage />,
      },
      {
        path: "filled_intakes",
        element: <FilledIntakeListPage />,
      },
      {
        path: "filled_intakes/:filledIntakeId",
        element: <ViewFilledIntakePage />,
      },
      {
        path: "default_intake_configuration",
        element: <DefaultIntakeConfigurationPage />,
      },
      {
        path: "smart_chains",
        element: <SmartChainListPage />,
      },
      {
        path: "smart_chains/:smartChainId/*",
        element: <SmartChainEditorPage />,
      },
      {
        path: "smart_chain_binding/new",
        element: <NewSmartChainBindingPage />,
      },
      {
        path: "smart_chain_binding",
        element: <SmartChainBindingListPage />,
      },
      {
        path: "custom_data_schema",
        element: <CustomDataSchemaListPage />,
      },
      {
        path: "custom_data_schema/new",
        element: <NewCustomDataSchemaPage />,
      },
      {
        path: "custom_data_schema/:customDataSchemaId",
        element: <CustomDataSchemaEditPage />,
      },
      {
        path: "custom_form",
        element: <CustomFormListPage />,
      },
      {
        path: "custom_form/new",
        element: <NewCustomFormPage />,
      },
      {
        path: "custom_form/:customFormId",
        element: <CustomFormEditPage />,
      },
      {
        path: "c/:customDataSchemaId",
        element: <CustomDataObjectListPage />,
      },
      {
        path: "c/:customDataSchemaId/new",
        element: <NewCustomDataObjectPage />,
      },
      {
        path: "c/:customDataSchemaId/:customDataObjectId",
        element: <CustomDataObjectEditPage />,
      },
      {
        path: "cf/:customFormId",
        element: <UseCustomFormPage />,
      },
      {
        path: "impersonate",
        element: <ImpersonatePage />,
      },
    ],
    {
      basename: config.frontend.router_basename,
    }
  );
}

function MainFrontendApp() {
  const config = useFrontendConfiguration();
  document.title =
    config.frontend.environment_title || config.frontend.top_bar_title;
  globalTranslationManager.enabled =
    config.frontend.enable_automatic_frontend_translation;

  // Check if the user has accessed the root URL, without the basename in the path. If so, we redirect
  // them to the appropriate URL with the basename.
  useEffect(() => {
    if (
      window.location.pathname === "/" &&
      config?.frontend?.router_basename !== "/"
    ) {
      window.location.pathname = config.frontend.router_basename;
    }
  }, [config?.frontend?.router_basename]);

  let router = createRouter(config);

  // Decide what to show depending on whether we are authenticated or not
  let app = <RouterProvider router={router} />;

  // Wrap in provider which determines whether or not the user is an admin
  app = <IsAdminProvider>{app}</IsAdminProvider>;

  // Wrap in provider for the API itself
  app = <APIProvider>{app}</APIProvider>;

  // Wrap in provider for the server ping process, to ensure backend server is alive.
  // Notably this is done before api provider to ensure it happens before login,
  // so that the server can be waking up while the user finishes their login process
  app = <BackendPingWrapper>{app}</BackendPingWrapper>;

  // Wrap in Snackbar provider
  app = <SnackbarProvider>{app}</SnackbarProvider>;

  // Wrap in left menu state provider
  app = <LeftSideMenuStateProvider>{app}</LeftSideMenuStateProvider>;

  if (config.frontend.google_maps_api_key) {
    // Wrap in a provider for the Google Maps client API
    app = <GoogleMapsProvider>{app}</GoogleMapsProvider>;
  }

  // Wrap in Theme provider
  app = <ThemeProvider theme={theme}>{app}</ThemeProvider>;

  // Wrap in Localization provider
  app = (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {app}
    </LocalizationProvider>
  );

  // Wrap in DnD provider
  app = <DndProvider backend={HTML5Backend}>{app}</DndProvider>;

  // Wrap in ErrorBoundary
  app = <ErrorBoundary>{app}</ErrorBoundary>;

  // Wrap in the auth0 provider
  app = (
    <Auth0Provider
      domain={config.frontend.auth0_domain}
      clientId={config.frontend.auth0_client_id}
      authorizationParams={{
        redirect_uri: new URL(
          config.frontend.router_basename,
          window.location.href
        ).href,
        scope: "read:current_user update:current_user_metadata email profile",
        audience: config.frontend.auth0_audience,
      }}
      cacheLocation='localstorage'
    >
      {app}
    </Auth0Provider>
  );

  // Wrap in ErrorReportingWrapper
  app = <ErrorReportingWrapper>{app}</ErrorReportingWrapper>;

  // Wrap in the backend wakeup wrapper
  app = <BackendWakeupWrapper>{app}</BackendWakeupWrapper>;

  return app;
}

export default MainFrontendApp;
