import {PageWrapperWithMenu} from "../../../core/frontend/elements/PageWrapperWithMenu";
import React, {useCallback} from "react";
import {Card, CardContent} from "@mui/material";
import {useAPI} from "../../../core/frontend/components/APIProvider";
import {PageHeader} from "../../../core/frontend/elements/PageHeader";
import TranscriptProcessorConfigurationForm from "./TranscriptProcessorConfigurationForm";
import {useNavigate} from "react-router-dom";
import "./TranscriptProcessorConfigurationEditPage.scss";


export default function NewTranscriptProcessorConfigurationPage(props) {
    const api = useAPI();
    const navigate = useNavigate();
    const handleCreateTranscriptProcessorConfiguration = useCallback(async (transcriptProcessorConfigurationData) => {
        return await api.createNewTranscriptProcessorConfiguration(transcriptProcessorConfigurationData).then((newTranscriptProcessorConfiguration) => {
            navigate(`/transcript_processor_configurations/${newTranscriptProcessorConfiguration.id}`);
        });
    }, [api, navigate]);

    return <PageWrapperWithMenu>
        <PageHeader title={`New Transcript Processor Configuration`}/>
        <div className="transcript-processor-configuration-edit-page">
            <Card className="transcript-processor-configuration-edit-form-card">
                <CardContent>
                    <TranscriptProcessorConfigurationForm
                        initialTranscriptProcessorConfiguration={{}}
                        onTranscriptProcessorConfigurationSave={handleCreateTranscriptProcessorConfiguration}
                        saveButtonText={"Create New Transcript Processor Configuration"}
                    />
                </CardContent>
            </Card>
        </div>

    </PageWrapperWithMenu>
}