import React from "react";
import {
    formDataProvider,
    changeMultipleFormDataProvider,
    changeFormDataProvider,
    useFormData,
    useChangeFormData,
} from "./FormRoot";
import "./FormArrayItemRoot.scss";


export function FormSubFieldRoot({editable, field, children}) {
    editable = editable ?? true;

    const subFieldData = useFormData()[field] ?? [];

    const changeFormDataOriginal = useChangeFormData();

    const changeFormData = React.useCallback((key, newValue) => {
        if (editable) {
            const newSubFieldData = {
                ...subFieldData,
                [key]: newValue
            };

            changeFormDataOriginal(field, newSubFieldData);
        }
    }, [editable, subFieldData, changeFormDataOriginal]);

    const changeMultipleFormData = React.useCallback((changes) => {
        if (editable) {
            const newSubFieldData = {
                ...subFieldData,
                ...changes
            };

            changeFormDataOriginal(field, newSubFieldData);

        }
    }, [editable, subFieldData, changeFormDataOriginal, field]);

    return <formDataProvider.Provider value={subFieldData}>
        <changeFormDataProvider.Provider value={changeFormData}>
            <changeMultipleFormDataProvider.Provider value={changeMultipleFormData}>
                {children}
            </changeMultipleFormDataProvider.Provider>
        </changeFormDataProvider.Provider>
    </formDataProvider.Provider>;
}

export default FormSubFieldRoot;
