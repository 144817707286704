import React, { useCallback } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormGroup } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useChangeFormData, useFormData, useFormOptions } from "../FormRoot";
import "./CheckboxesInput.scss";
import Typography from "@mui/material/Typography";
import LocalTranslatedText from "../../../../translation/frontend/components/LocalTranslatedText";

export function CheckboxesInput({ label, options, fieldName, size }) {
  const formData = useFormData();
  const changeFormData = useChangeFormData();
  const formOptions = useFormOptions();

  const handleChange = useCallback(
    (event) => {
      changeFormData(event.target.name, event.target.checked);
    },
    [changeFormData]
  );

  return (
    <div className={"form-input checkboxes-input"}>
      {label ? (
        <Typography variant={"label"} className={"checkbox-label"}>
          <LocalTranslatedText language={"en"} text={label} />
        </Typography>
      ) : null}

      <FormGroup>
        {options.map((labelStr, labelIndex) => (
          <FormControlLabel
            key={labelStr}
            control={
              <Checkbox
                checked={formData[fieldName[labelIndex]] ?? false}
                onChange={handleChange}
                name={fieldName[labelIndex]}
                disabled={!formOptions.editable}
                size={size || "medium"}
              />
            }
            label={labelStr}
          />
        ))}
      </FormGroup>
    </div>
  );
}

export default CheckboxesInput;
