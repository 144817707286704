import * as React from 'react';
import {TableView} from "../../../frontend/elements/TableView";
import {useAPI} from "../../../frontend/components/APIProvider";
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

export function SmartChainTable() {
    const api = useAPI();
    const navigate = useNavigate();

    const handleSmartChainRowClicked = useCallback((smartChain) => {
        navigate(`/smart_chains/${smartChain.id}`);
    }, [navigate]);

    const handleDeleteClicked = useCallback((smartChain) => {
        return api.deleteSmartChain(smartChain.id);
    }, [api]);

    const handleCopyClicked = useCallback((smartChain) => {
        // First lets fetch the full document template object with all of its data
        return api.getSmartChain(smartChain.id).then((smartChain) => {
            const newSmartChain = {
                _id: null,
                chain_name: smartChain.chain_name + "_copy",
                ...smartChain,
            };

            return api.createNewSmartChain(newSmartChain);
        });
    }, [api]);

    const allowDeleteOnRow = useCallback((smartChain) => {
        return smartChain.user_id;
    }, []);

    return (
        <TableView
            schemaAPI={api.getSmartChainSchema}
            fetchAPI={api.getSmartChains}
            onRowClicked={handleSmartChainRowClicked}
            onDeleteClicked={handleDeleteClicked}
            onCopyClicked={handleCopyClicked}
            allowDeleteOnRow={allowDeleteOnRow}
        />
    );
}
