import React, {useCallback, useEffect} from "react";
import {PageWrapperWithMenu} from "../../core/frontend/elements/PageWrapperWithMenu";
import {PageHeader} from "../../core/frontend/elements/PageHeader";
import "./EditStoryPromptConfigurationPage.scss";
import {useAPI} from "../../core/frontend/components/APIProvider";
import {StoryPromptConfigurationForm} from "./StoryPromptConfigurationForm";
import {PageSection} from "../../core/frontend/elements/PageSection";


export default function EditStoryPromptConfigurationPage() {
    const api = useAPI();
    const [storyPromptConfiguration, setStoryPromptConfiguration] = React.useState(null);

    useEffect(() => {
        // Call the home endpoint, forcing server to start if it's off.
        // Helps reduce impact of cold boots.
        api.home();
    }, [api]);

    useEffect(() => {
        api.getStoryPromptConfiguration().then((storyPromptConfiguration) => {
            setStoryPromptConfiguration(storyPromptConfiguration);
        });
    }, [api]);

    const handleStoryPromptConfigurationChanged = useCallback((newStoryPromptConfiguration) => {
        setStoryPromptConfiguration(newStoryPromptConfiguration);
        return api.saveStoryPromptConfiguration(newStoryPromptConfiguration);
    }, [api, setStoryPromptConfiguration]);

    return <PageWrapperWithMenu>
        <PageHeader title={`Edit Story Prompt Configuration`}/>
        <div className={"edit-story-prompt-configuration-page"}>
            <PageSection
                title={"Prompts"}
            >
                {
                    storyPromptConfiguration ?
                        <div className={"prompt-config-wrapper"}>
                            <StoryPromptConfigurationForm
                                storyPromptConfiguration={storyPromptConfiguration}
                                onChange={handleStoryPromptConfigurationChanged}
                            />
                        </div>
                    : null
                }
            </PageSection>
        </div>
    </PageWrapperWithMenu>
}

