import {PageWrapperWithMenu} from "../../core/frontend/elements/PageWrapperWithMenu";
import React from "react";
import "../../core/frontend/pages/HomePage.scss";
import {StoryTable} from "./StoryTable";
import "./StoryListPage.scss";
import {PageHeader} from "../../core/frontend/elements/PageHeader";
import Paper from "@mui/material/Paper";


export default function StoryListPage() {
    return <PageWrapperWithMenu>
        <PageHeader title={"Storys"} />
        <div className={"story-list-page"}>
            <div className={"story-table-wrapper"}>
                <Paper className={"main-page-contents"}>
                    <StoryTable/>
                </Paper>
            </div>
        </div>
    </PageWrapperWithMenu>
}

