import React from "react";
import FormInput from "../../elements/form/FormInput";
import FormSection from "../../elements/form/FormSection";
import FormSubFieldRoot from "../../elements/form/FormSubFieldRoot";

export default function GroqConfigurationForm() {
    return (
        <FormSection
            title={"Groq Integration"}
            subtitle={"Configuration for the Groq API Integration for this tenant"}
            collapsable={true}
        >
            <FormSubFieldRoot field={"groq"}>
                <FormInput
                    type={"text"}
                    label={"API Key"}
                    fieldName={"api_key"}
                    placeholder={"The API key used to connect to their API"}
                />

                <FormSubFieldRoot field={"rate_limits_tokens_per_minute"}>
                    <FormInput
                        type={"number"}
                        label={"Rate Limit For: llama-3.1-70b-versatile"}
                        fieldName={"llama-3.1-70b-versatile"}
                        placeholder={"The rate limit applied to outbound requests made with the llama-3.1-70b-versatile model in terms of tokens per minute."}
                    />
                    <FormInput
                        type={"number"}
                        label={"Rate Limit For: llama-3.1-8b-instant"}
                        fieldName={"llama-3.1-8b-instant"}
                        placeholder={"The rate limit applied to outbound requests made with the llama-3.1-8b-instant model in terms of tokens per minute."}
                    />
                    <FormInput
                        type={"number"}
                        label={"Rate Limit For: gemma-7b-it"}
                        fieldName={"gemma-7b-it"}
                        placeholder={"The rate limit applied to outbound requests made with the gemma-7b-it model in terms of tokens per minute."}
                    />
                    <FormInput
                        type={"number"}
                        label={"Rate Limit For: gemma2-9b-it"}
                        fieldName={"gemma2-9b-it"}
                        placeholder={"The rate limit applied to outbound requests made with the gemma2-9b-it model in terms of tokens per minute."}
                    />
                    <FormInput
                        type={"number"}
                        label={"Rate Limit For: llama-guard-3-8b"}
                        fieldName={"llama-guard-3-8b"}
                        placeholder={"The rate limit applied to outbound requests made with the llama-guard-3-8b model in terms of tokens per minute."}
                    />
                    <FormInput
                        type={"number"}
                        label={"Rate Limit For: llama3-70b-8192"}
                        fieldName={"llama3-70b-8192"}
                        placeholder={"The rate limit applied to outbound requests made with the llama3-70b-8192 model in terms of tokens per minute."}
                    />
                    <FormInput
                        type={"number"}
                        label={"Rate Limit For: llama3-8b-8192"}
                        fieldName={"llama3-8b-8192"}
                        placeholder={"The rate limit applied to outbound requests made with the llama3-8b-8192 model in terms of tokens per minute."}
                    />
                    <FormInput
                        type={"number"}
                        label={"Rate Limit For: llama3-groq-70b-8192-tool-use-preview"}
                        fieldName={"llama3-groq-70b-8192-tool-use-preview"}
                        placeholder={"The rate limit applied to outbound requests made with the llama3-groq-70b-8192-tool-use-preview model in terms of tokens per minute."}
                    />
                    <FormInput
                        type={"number"}
                        label={"Rate Limit For: llama3-groq-8b-8192-tool-use-preview"}
                        fieldName={"llama3-groq-8b-8192-tool-use-preview"}
                        placeholder={"The rate limit applied to outbound requests made with the llama3-groq-8b-8192-tool-use-preview model in terms of tokens per minute."}
                    />
                    <FormInput
                        type={"number"}
                        label={"Rate Limit For: other"}
                        fieldName={"other"}
                        placeholder={"The rate limit applied to outbound requests made with any other models that didn't have a specific rate limit set, in terms of tokens per minute."}
                    />
                </FormSubFieldRoot>
            </FormSubFieldRoot>
        </FormSection>
    );
}