import React, { useCallback, useEffect, useState } from "react";
import { useAPI } from "../../../frontend/components/APIProvider";
import "./PublishedSmartChainVersionsList.scss";
import {
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VerifiedIcon from "@mui/icons-material/Verified";
import classnames from "classnames";
import ReverPublishedSmartChainDialog from "./ReverPublishedSmartChainDialog";
import _ from "lodash";

export default function PublishedSmartChainVersionsList({
  smartChain,
  setSmartChain,
}) {
  const api = useAPI();
  const [smartChainVersions, setSmartChainVersions] = useState([]);
  const [loadingSmartChainVersions, setLoadingSmartChainVersions] =
    useState(false);
  const [formattedVersions, setFormattedVersions] = useState([]);
  const [openSections, setOpenSections] = useState({});
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [openRevertDialog, setOpenRevertDialog] = useState(false);
  const [usedVersionId, setUsedVersionId] = useState([]);

  const getPublishedSmartChainVersions = useCallback(async () => {
    try {
      setLoadingSmartChainVersions(true);
      const versions = await api.getPublishedSmartChainVersions(
        smartChain.chain_name
      );
      setSmartChainVersions(versions);
      setLoadingSmartChainVersions(false);
    } catch (error) {
      setLoadingSmartChainVersions(false);
      console.error("Error getting published smart chain versions", error);
    } finally {
      setLoadingSmartChainVersions(false);
    }
  }, [api, smartChain]);

  const handleToggle = useCallback((date) => {
    setOpenSections((prev) => ({ ...prev, [date]: !prev[date] }));
  }, []);

  const handleRevertSmartChainVersion = useCallback(async () => {
    const copiedSmartChain = { ...smartChain };
    copiedSmartChain.steps = selectedVersion.steps;
    const savedSmartChain = await api.saveSmartChain(copiedSmartChain);
    setSmartChain(savedSmartChain);
    getPublishedSmartChainVersions();
    setOpenRevertDialog(false);
  }, [
    smartChain,
    selectedVersion,
    api,
    setSmartChain,
    getPublishedSmartChainVersions,
  ]);

  useEffect(() => {
    getPublishedSmartChainVersions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (smartChainVersions.length > 0) {
      setUsedVersionId(smartChainVersions[0]?.id);
      const formattedVersions = processAndGroupData(smartChainVersions);
      setFormattedVersions(formattedVersions);
    }
  }, [smartChainVersions]);

  return (
    <>
      <div className='published-smart-chain-versions-list-container'>
        {loadingSmartChainVersions ? (
          <div className='circular-loader-box'>
            <CircularProgress />
          </div>
        ) : smartChainVersions.length > 0 ? (
          formattedVersions.map((group, idx) => (
            <React.Fragment key={group.date}>
              <div
                className='published-smart-chain-versions-list-header'
                role='button'
                onClick={() => handleToggle(group.date)}
                tabIndex={0}
              >
                <Typography
                  variant='h6'
                  className={classnames(
                    "published-smart-chain-versions-list-header-text",
                    {
                      selected: idx === 0,
                    }
                  )}
                >
                  {dayjs(group?.date).format("MMMM D, YYYY")}
                </Typography>
                <IconButton>
                  {openSections[group.date] ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </div>
              <Collapse
                in={openSections[group.date]}
                timeout='auto'
                unmountOnExit
                sx={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <List disablePadding sx={{ paddingTop: 0, paddingBottom: 0 }}>
                  {group.items.map((item) => (
                    <ListItem key={item._id}>
                      <ListItemText
                        primary={
                          <div className='published-version-item-container'>
                            <div className='published-version-item'>
                              <Typography
                                variant='body2'
                                className={classnames(
                                  "published-version-item-text",
                                  {
                                    selected: item.id === usedVersionId,
                                  }
                                )}
                              >
                                {_.truncate(item.chain_name, { length: 20 })} -{" "}
                                {dayjs(item.created_at).format("h:mm A")}
                              </Typography>
                              {item.id === usedVersionId && <VerifiedIcon />}
                            </div>

                            <Button
                              size='small'
                              variant='outlined'
                              className='publish-btn'
                              color='success'
                              onClick={() => {
                                setOpenRevertDialog(true);
                                setSelectedVersion(item);
                              }}
                            >
                              Revert
                            </Button>
                          </div>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ))
        ) : (
          <div className='empty-box'>
            <Typography variant='body1' className='empty-text'>
              No published versions of this smart chain found.
            </Typography>
          </div>
        )}
      </div>
      <ReverPublishedSmartChainDialog
        handleClose={() => setOpenRevertDialog(false)}
        handleConfirm={handleRevertSmartChainVersion}
        open={openRevertDialog}
      />
    </>
  );
}

function processAndGroupData(data) {
  // Sort data by created_at
  const sortedData = data.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  // Group by date
  const groupedData = sortedData.reduce((acc, item) => {
    const date = item.created_at.split("T")[0]; // Extract date
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push({ ...item }); // Add time to the item
    return acc;
  }, {});

  // Format the result as an array of grouped dates
  return Object.entries(groupedData).map(([date, items]) => ({
    date,
    items,
  }));
}
