import { TextField } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useChangeFormData, useFormData, useFormOptions } from "../FormRoot";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import LocalTranslatedText from "../../../../translation/frontend/components/LocalTranslatedText";
import globalTranslationManager from "../../../../translation/frontend/components/translation_manager";

export function TextInput({
  label,
  example,
  fieldName,
  formatter,
  validator,
  errorHelperText,
  disabled,
  size,
  ...props
}) {
  const formOptions = useFormOptions();
  const formData = useFormData();
  const changeFormData = useChangeFormData();

  let initialValue = formData[fieldName];
  if (formatter) {
    initialValue = formatter(initialValue);
  }

  const [localValue, setLocalValue] = useState(initialValue);
  let isValid = true;
  if (validator && localValue) {
    isValid = validator(localValue);
  }

  let disabledLocal = !formOptions.editable || disabled;

  const onChangeFormDataDebounced = useMemo(
    () => _.debounce(changeFormData, 500),
    [changeFormData]
  );

  const handleInputChange = useCallback(
    (event) => {
      if (formOptions.editable) {
        setLocalValue(event.target.value);
        if (
          !validator ||
          !event.target.value ||
          validator(event.target.value)
        ) {
          onChangeFormDataDebounced(fieldName, event.target.value ?? "");
        }
      }
    },
    [validator, formOptions, fieldName, onChangeFormDataDebounced]
  );

  if (props.placeholder) {
    props.placeholder =
      globalTranslationManager.getTranslationIfAvailableAndEnabledSync(
        props.placeholder
      );
  }

  return (
    <div className={"form-input"}>
      <Typography variant={"label"}>
        <LocalTranslatedText language={"en"} text={label} />
      </Typography>

      <TextField
        size={size || "medium"}
        name={fieldName}
        placeholder={example}
        value={disabledLocal ? "" : localValue}
        onChange={handleInputChange}
        fullWidth
        disabled={disabledLocal}
        error={!isValid}
        helperText={!isValid ? errorHelperText : null}
        {...props}
      />
    </div>
  );
}

export default TextInput;
