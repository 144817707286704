import Avatar from "@mui/material/Avatar";
import React from "react";
import "./ConversationAvatar.scss";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import MemoryIcon from '@mui/icons-material/Memory';
import Icon from '@mui/material/Icon';

export function ConversationAvatar({avatarUrl, role, moduleId}) {
    let altText = null;
    let icon = null;

    if (role === "user") {
        altText = "User";
        icon = null;
    }
    else if (role === "assistant") {
        altText = "AI Bot";

        if (moduleId !== "user_interactor") {
            icon = <MemoryIcon />;
        } else {
            if (avatarUrl) {
                if (avatarUrl.startsWith("http")) {
                    icon = <img alt={altText} src={avatarUrl}/>;
                }
                else if (avatarUrl.startsWith("icon://")) {
                    icon = <Icon>{avatarUrl.substring(7)}</Icon>;
                }
            } else {
                icon = <SmartToyIcon/>;
            }
        }
    }

    return <Avatar
        alt={altText}
        className={"conversation-avatar"}
    >
        {icon}
    </Avatar>;
}
