import { PageWrapperWithMenu } from "../../../frontend/elements/PageWrapperWithMenu";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardContent } from "@mui/material";
import "./CustomDataObjectEditPage.scss";
import { useAPI } from "../../../frontend/components/APIProvider";
import { PageHeader } from "../../../frontend/elements/PageHeader";
import CustomDataObjectForm from "./CustomDataObjectForm";
import CircularProgress from "@mui/material/CircularProgress";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";

export default function CustomDataObjectEditPage(props) {
  const api = useAPI();
  const params = useParams();
  const navigate = useNavigate();
  const [customDataObject, setCustomDataObject] = useState(null);
  const [customDataSchema, setCustomDataSchema] = React.useState(null);

  useEffect(() => {
    api.getCustomDataSchema(params.customDataSchemaId).then((data) => {
      setCustomDataSchema(data);
    });
  }, [params.customDataSchemaId]);

  const handleCustomDataObjectSave = useCallback(
    async (newCustomDataObject) => {
      return await api.saveCustomDataObject(newCustomDataObject);
    },
    [api]
  );

  const handleDeleteClicked = useCallback(async () => {
    return await api.deleteCustomDataObject(customDataObject.id).then(() => {
      navigate(`/c/${params.customDataSchemaId}`);
    });
  }, [navigate, api, customDataObject?.id]);

  React.useEffect(() => {
    if (!customDataObject) {
      api
        .getCustomDataObject(params?.customDataObjectId)
        .then((customDataObject) => {
          setCustomDataObject(customDataObject);
        });
    }
  }, [
    handleCustomDataObjectSave,
    customDataObject,
    params?.customDataObjectId,
    api,
  ]);

  if (!customDataObject || !customDataSchema) {
    return (
      <PageWrapperWithMenu>
        <PageHeader
          title={<LocalTranslatedText language={"en"} text='Edit' />}
        />
        <div className='custom-data-object-edit-page'>
          <CircularProgress />
        </div>
      </PageWrapperWithMenu>
    );
  }

  return (
    <PageWrapperWithMenu>
      <PageHeader
        title={
          <LocalTranslatedText
            language={"en"}
            text={`Edit ${customDataSchema.title}`}
          />
        }
      />
      <div className='custom-data-object-edit-page'>
        <Card className='custom-data-object-edit-form-card'>
          <CardContent>
            <CustomDataObjectForm
              customDataSchema={customDataSchema}
              initialCustomDataObject={customDataObject}
              onCustomDataObjectSave={handleCustomDataObjectSave}
              onDeletedClicked={handleDeleteClicked}
            />
          </CardContent>
        </Card>
      </div>
    </PageWrapperWithMenu>
  );
}
