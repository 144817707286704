import "./PromptNode.scss";
import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import useChartStore from "../../ChartState";
import NodeTitleEditor from "../../components/NodeTitleEditor";
import NodeCustomPromptEditor from "../../components/NodeCustomPromptEditor";
import {NodeInputHandle, NodeOutputHandle} from "../../components/NodeHandle";
import CopyContentsActionButton from "../../components/CopyContentsActionButton";
import DeleteNodeActionButton from "../../components/DeleteNodeActionButton";
import Button from "@mui/material/Button";
import {TransparentNodeResizer} from "../../components/TransparentNodeResizer";
import EditNoteIcon from '@mui/icons-material/EditNote';
import SearchIcon from '@mui/icons-material/Search';
import List from '@mui/material/List';
import Slider from '@mui/material/Slider';
import ListItem from '@mui/material/ListItem';
import {ListItemButton, Switch} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as promptTemplates from "../../components/PromptTemplates";
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import {NodeTemplatedPromptEditor} from "../../components/NodeTemplatedPromptEditor";
import PromptWritingTipsActionButton from "../../components/PromptWritingTipsActionButton";
import BehaviorTracker from "../../../../../core/frontend/components/BehaviorTracker";
import FileUploadActionButton from "../../components/FileUploadActionButton";
import NodeFilePreview from "../../components/NodeFilePreview";
import NodeColorPickerWidget from "../../components/NodeColorPickerWidget";
import {useReactFlow} from "reactflow";
import BugReportIcon from '@mui/icons-material/BugReport';
import {CustomJSONView} from "../../../../../core/frontend/elements/CustomJSONView";
import {BottomTab, BottomTabs} from "../../../../../core/frontend/elements/BottomTabs";
import VisibilityIcon from '@mui/icons-material/Visibility';
import SettingsIcon from '@mui/icons-material/Settings';
import {useDelayedStateChange} from "../../../../../core/frontend/utils/delayed_state_change";
import ImageIcon from '@mui/icons-material/Image';
import {SplittingStrategySelector} from "../../components/SplittingStrategySelector";
import {LLMModelSelector} from "../../components/LLMModelSelector";
import ArticleIcon from '@mui/icons-material/Article';
import {PromptNodeIcon} from "../../components/icons";
import CleaningStrategySelector from "../../components/CleaningStrategySelector";
import {SpinnerButton} from "../../../../../core/frontend/elements/SpinnerButton";
import {useAPI} from "../../../../../core/frontend/components/APIProvider";
import {ensureMinimumPromiseResolveTime} from "../../../../../core/frontend/utils/time";
import CardHeader from "@mui/material/CardHeader";
import {chartMaxZoom, chartMinZoom} from "../../ChartConstants";


export function computeBackgroundColorStyleForNode(node) {
    const backgroundStyle = {
        backgroundColor: node?.data?.color ? node?.data?.color : "white",
    }
    return backgroundStyle;
}

export function useBackgroundColorStyleForNode(nodeId) {
    let {node} = useChartStore((state) => ({
        node: state.getNode(nodeId),
    }));
    const backgroundStyle = computeBackgroundColorStyleForNode(node);

    return backgroundStyle;
}

function PromptDebugViewMode({nodeId}) {
    let {node} = useChartStore((state) => {
        return {
            node: state.getNode(nodeId),
        }
    });

    return <CardContent className={"prompt-node-content debug-node-view nowheel nodrag"}>
        <CustomJSONView data={node}/>
    </CardContent>
}


function valuetext(value) {
    return `${value.toFixed(1)}`;
}

function PromptSettingsMode({nodeId}) {
    let {node, changeNodeData, recomputeChart} = useChartStore((state) => {
        return {
            node: state.getNode(nodeId),
            changeNodeData: state.changeNodeData,
            recomputeChart: state.recomputeChart,
        }
    });

    const handleEnableAiChanged = useCallback((evt) => {
        const newValue = evt.target.checked;

        if (newValue === false) {
            BehaviorTracker.trackInteraction({
                id: "disable-ai-on-prompt",
                mixpanel: null
            });
        }

        changeNodeData(nodeId, {
            enable_ai_completion: newValue,
            stale: true,
        });
    }, [nodeId, changeNodeData]);

    const temperatureMarks = [
        {
            value: 0,
            label: 'Predictable',
        },
        {
            value: 0.8,
            label: 'Balanced',
        },
        {
            value: 1.8,
            label: 'Chaotic',
        },
    ];

    const deduplicationThresholdMarks = [
        {
            value: 0.0,
            label: 'Weak',
        },
        {
            value: 0.7,
            label: 'Moderate',
        },
        {
            value: 1.5,
            label: 'Strong',
        },
    ];

    const handleTemperatureChanged = useCallback((evt, newValue) => {
        BehaviorTracker.trackInteraction({
            id: "change-temperature",
            mixpanel: null
        });

        changeNodeData(nodeId, {
            temperature: newValue,
            stale: true,
        });

        // Trigger the chart to start updating in the background.
        // recomputeChart();
    }, [nodeId, changeNodeData]);

    const handleDeduplicationThresholdChanged = useCallback((evt, newValue) => {
        BehaviorTracker.trackInteraction({
            id: "change-deduplication-threshold",
            mixpanel: null
        });

        changeNodeData(nodeId, {
            deduplication_threshold: newValue,
            stale: true,
        });

        // Trigger the chart to start updating in the background.
        // recomputeChart();
    }, [nodeId, changeNodeData]);

    const handleSplittingStrategyChanged = useCallback((newValue) => {
        changeNodeData(nodeId, {
            splitting_strategy: newValue,
            stale: true,
        });
    }, [nodeId, changeNodeData]);

    const handleLLMModelChange = useCallback((newValue) => {
        changeNodeData(nodeId, {
            llm_model: newValue,
            stale: true
        })
    }, [nodeId, changeNodeData]);

    const handleDeduplicationStrategyChanged = useCallback((evt) => {
        const newValue = evt.target.checked;

        if (newValue) {
            changeNodeData(nodeId, {
                deduplication_strategy: "combined",
                stale: true,
            });
        } else {
            changeNodeData(nodeId, {
                deduplication_strategy: null,
                stale: true,
            });
        }
    }, [nodeId, changeNodeData]);


    const handleMergeStrategyChanged = useCallback((evt) => {
        const newValue = evt.target.checked;

        if (newValue) {
            changeNodeData(nodeId, {
                merge_strategy: "concatenation",
                stale: true,
            });
        } else {
            changeNodeData(nodeId, {
                merge_strategy: null,
                stale: true,
            });
        }
    }, [nodeId, changeNodeData]);


    const handleMentionPermutationStrategyChanged = useCallback((evt) => {
        const newValue = evt.target.checked;

        if (newValue) {
            changeNodeData(nodeId, {
                mention_node_permutation_strategy: "all",
                stale: true,
            });
        } else {
            changeNodeData(nodeId, {
                mention_node_permutation_strategy: 'aligned',
                stale: true,
            });
        }
    }, [nodeId, changeNodeData]);

    const handleCleaningStrategiesChanged = useCallback((newValue) => {
        changeNodeData(nodeId, {
            cleaning_strategies: newValue,
            stale: true,
        });
    }, [nodeId, changeNodeData]);

    const handleCacheVariantsPerIdenticalPromptChanged = useCallback((evt, newValue) => {
        changeNodeData(nodeId, {
            cache_variants_per_identical_prompt: newValue,
            stale: true,
        });
    }, [nodeId, changeNodeData]);


    return <CardContent className={"prompt-node-content prompt-settings"}>
        <div className={"setting-row"}>
            <div className={"setting-label"}>
                AI?
            </div>
            <div className={"setting-input"}>
                <Switch
                    checked={node.data.enable_ai_completion}
                    onChange={handleEnableAiChanged}
                />
            </div>
        </div>
        <div className={"setting-row"}>
            <div className={"setting-label"}>
                Model
            </div>
            <div className={"setting-input"}>
                <LLMModelSelector
                    value={node.data.llm_model}
                    onChange={handleLLMModelChange}
                />
            </div>
        </div>
        <div className={"setting-row"}>
            <div className={"setting-label"}>
                Variety
            </div>
            <div className={"setting-input nodrag variety-input"}>
                <Slider
                    aria-label="Variety"
                    value={node.data.temperature}
                    valueLabelDisplay='auto'
                    getAriaValueText={valuetext}
                    onChange={handleTemperatureChanged}
                    step={0.1}
                    marks={temperatureMarks}
                    min={0.0}
                    max={1.8}
                />
            </div>
        </div>
        <div className={"setting-row"}>
            <div className={"setting-label"}>
                Cache Variants
            </div>
            <div className={"setting-input nodrag"}>
                <Slider
                    aria-label="Cache Variants"
                    value={node.data.cache_variants_per_identical_prompt}
                    valueLabelDisplay='auto'
                    getAriaValueText={valuetext}
                    onChange={handleCacheVariantsPerIdenticalPromptChanged}
                    step={1}
                    min={1}
                    max={10}
                    scale={(x) => x * x}
                />
            </div>
        </div>
        <div className={"setting-row"}>
            <div className={"setting-label"}>
                Cleaning
            </div>
            <div className={"setting-input"}>
                <CleaningStrategySelector
                    value={node.data.cleaning_strategies}
                    onChange={handleCleaningStrategiesChanged}
                />
            </div>
        </div>
        <div className={"setting-row"}>
            <div className={"setting-label"}>
                Splitting
            </div>
            <div className={"setting-input"}>
                <SplittingStrategySelector
                    value={node.data.splitting_strategy}
                    onChange={handleSplittingStrategyChanged}
                />
            </div>
        </div>
        <div className={"setting-row"}>
            <div className={"setting-label"}>
                Deduplication
            </div>
            <div className={"setting-input"}>
                <Switch
                    checked={node.data.deduplication_strategy === "combined"}
                    onChange={handleDeduplicationStrategyChanged}
                />
            </div>
        </div>
        {
            node.data.deduplication_strategy ?
                <div className={"setting-row"}>
                    <div className={"setting-label"}>
                        Deduplication Threshold
                    </div>
                    <div className={"setting-input nodrag"}>
                        <Slider
                            aria-label="Deduplication Threshold"
                            value={node.data.deduplication_threshold}
                            valueLabelDisplay='auto'
                            getAriaValueText={valuetext}
                            onChange={handleDeduplicationThresholdChanged}
                            step={0.05}
                            marks={deduplicationThresholdMarks}
                            min={0.0}
                            max={1.5}
                        />
                    </div>
                </div>
                : null
        }
        <div className={"setting-row"}>
            <div className={"setting-label"}>
                Merging
            </div>
            <div className={"setting-input"}>
                <Switch
                    checked={node.data.merge_strategy === "concatenation"}
                    onChange={handleMergeStrategyChanged}
                />
            </div>
        </div>
        <div className={"setting-row"}>
            <div className={"setting-label"}>
                All Permutations?
            </div>
            <div className={"setting-input"}>
                <Switch
                    checked={node.data.mention_node_permutation_strategy === "all"}
                    onChange={handleMentionPermutationStrategyChanged}
                />
            </div>
        </div>
    </CardContent>
}

function PromptCurrentValueMode({nodeId, onEditPrompt}) {
    let {node, isNodeBeingStreamed} = useChartStore((state) => {
        return {
            node: state.getNode(nodeId),
            isNodeBeingStreamed: state.isNodeBeingStreamed(nodeId),
        }
    });

    const reactFlow = useReactFlow();

    const [isMouseOverCurrentValue, setIsMouseOverCurrentValue] = useState(false);
    const currentValueScrollWrapperRef = React.useRef(null);
    // We have a state that were we don't actually need or use the value.
    // This is to help us force the view to rerender when the scroll position
    // changes
    const [, setScrollPosition] = useState(0);
    const currentValueContainerRef = React.useRef(null);
    const [isMouseOverEditPromptButton, setIsMouseOverEditPromptButton] = useState(false);

    const handleEditPromptClicked = () => {
        if (onEditPrompt) {
            onEditPrompt();
        }
    };

    let showTopBorder = false;
    let showBottomBorder = false;
    if (currentValueScrollWrapperRef.current) {
        // Check if the prompt node is currently scrolling
        const promptNodeContentHtmlElement = currentValueScrollWrapperRef.current;

        const isShowingScrollBar = promptNodeContentHtmlElement.scrollHeight > promptNodeContentHtmlElement.offsetHeight;

        const scrollBarTouchingAllowedOffset = 10;

        const isScrollBarTouchingBottom = (promptNodeContentHtmlElement.scrollTop + promptNodeContentHtmlElement.offsetHeight + scrollBarTouchingAllowedOffset) >= promptNodeContentHtmlElement.scrollHeight;
        const isScrollBarTouchingTop = promptNodeContentHtmlElement.scrollTop <= scrollBarTouchingAllowedOffset;

        showTopBorder = isShowingScrollBar && !isScrollBarTouchingTop
        showBottomBorder = isShowingScrollBar && !isScrollBarTouchingBottom
    }

    function projectWithViewport(viewport, point) {
        return {
            x: (point.x - viewport.x) / viewport.zoom,
            y: (point.y - viewport.y) / viewport.zoom,
        }
    }

    const handleWheel = useCallback((evt) => {
        const chartWrapperElement = document.getElementById("chart-editor");
        // Do nothing. This shouldn't really happen but sometimes occurs in gremlin tests
        if (chartWrapperElement === null) {
            return;
        }
        const chartCoords = chartWrapperElement.getBoundingClientRect();

        const beforeViewport = reactFlow.getViewport();
        const beforeProjection = reactFlow.project({
            x: evt.clientX - chartCoords.x,
            y: evt.clientY - chartCoords.y
        });


        function zoomOntoMouse(zoomChange) {
            const newZoom = Math.max(chartMinZoom, Math.min(beforeViewport.zoom * zoomChange, chartMaxZoom));

            const afterViewport = {
                x: beforeViewport.x * (newZoom / beforeViewport.zoom),
                y: beforeViewport.y * (newZoom / beforeViewport.zoom),
                zoom: newZoom,
            };

            const newProjected = projectWithViewport(afterViewport, {
                x: evt.clientX - chartCoords.x,
                y: evt.clientY - chartCoords.y
            });

            const diffX = newProjected.x - beforeProjection.x;
            const diffY = newProjected.y - beforeProjection.y;

            afterViewport.x += diffX * afterViewport.zoom;
            afterViewport.y += diffY * afterViewport.zoom;

            reactFlow.setViewport(afterViewport);
        }


        // TODO: This code zooms into the center of the screen rather then the current mouse position. We need to fix
        // TODO: the above commented out code so that we correctly zoom into the mouse. Basically we just need to write
        // our whole own zoom function and not use zoomIn() at all.
        if (evt.deltaY < 0 && !showTopBorder) {
            zoomOntoMouse(1.07);
        } else if (evt.deltaY > 0 && !showBottomBorder) {
            zoomOntoMouse(0.93);
        }

    }, [showTopBorder, showBottomBorder, reactFlow]);

    const useNoWheelClass = showBottomBorder || showTopBorder;

    const promptNodeContentClasses = "prompt-node-content  prompt-node-content-current-value-mode" +
        (showBottomBorder ? " bottom-border " : "") +
        (showTopBorder ? " top-border " : "") +
        (useNoWheelClass ? " nowheel " : "");

    const backgroundStyle = useBackgroundColorStyleForNode(nodeId);

    const hasCurrentValue = node.data.currentValues && node.data.currentValues.length > 0 && node.data.currentValues[0];

    const currentValueSpan = useMemo(() => {
        if (hasCurrentValue) {
            let tags = [];
            for (let valueIndex = 0; valueIndex < node.data.currentValues.length; valueIndex += 1) {
                const value = node.data.currentValues[valueIndex];

                if (node.data.currentValues.length > 1) {
                    tags.push(
                        <div className={"current-value-page-divider"} key={`divider-${valueIndex}`}>
                            <div className={"current-value-divider-dotted-part"}/>
                            <div className={"current-value-divider-page-number"}>#{valueIndex + 1}</div>
                            <div className={"current-value-divider-dotted-part"}/>
                        </div>
                    );
                }

                const valueLines = value.split("\n");

                const tagsForValue = valueLines.map((line, lineIndex) => {
                    return <span className={"current-value-line"} key={`${valueIndex}-${lineIndex}`}>
                            {line}
                        <br/>
                    </span>;
                })
                tags = tags.concat(tagsForValue);
            }

            return tags;
        } else {
            return <span className={"current-value-line no-data"}>No data...</span>;
        }
    }, [node.data.currentValues, hasCurrentValue])

    return <CardContent className={promptNodeContentClasses} style={backgroundStyle}>
        <div className={"current-value-scroll-wrapper "}
             id={"current-value-scroll-wrapper-" + nodeId}
             ref={currentValueScrollWrapperRef}
             onScroll={(event) => {
                 // Although this may appear to do nothing, this
                 // forces the element to rerender on scroll, which
                 // is important for everything to work reliably
                 setScrollPosition(event.target.scrollTop);
             }}
             onMouseEnter={() => setIsMouseOverCurrentValue(true)}
             onMouseLeave={() => setIsMouseOverCurrentValue(false)}
             onWheel={handleWheel}
             onClick={handleEditPromptClicked}
             title={"Edit or Drag Prompt"}
        >
            {
                /*
                 * If the node is being streamed, then we leave the current value blank. The streaming code will automatically
                 * insert span tags underneath this container, outside the react flow. This is done outside the reactflow
                 * purely for efficiencies sake, to reduce the number of full rerenders when streaming data.
                 */
                !isNodeBeingStreamed ?
                    <div
                        id={"current-value-container-" + nodeId}
                        className={"current-value-container " + ((!hasCurrentValue && !isNodeBeingStreamed) ? "current-value-container-empty" : "")}
                        ref={currentValueContainerRef}
                    >
                        {
                            currentValueSpan
                        }
                    </div> : null
            }
            {
                isNodeBeingStreamed ?
                    <div
                        id={"current-value-streaming-container-" + nodeId}
                        className={"current-value-container current-value-streaming-container"}
                        ref={currentValueContainerRef}
                    >

                    </div> : null
            }
        </div>
        {
            (isMouseOverCurrentValue || isMouseOverEditPromptButton) ?
                <div className={"current-value-overlay" + (useNoWheelClass ? " nowheel" : "")}
                     title={"Edit Prompt"}
                >
                    <IconButton aria-label="Edit"
                                className={"edit-button"}
                                onMouseEnter={() => setIsMouseOverEditPromptButton(true)}
                                onMouseLeave={() => setIsMouseOverEditPromptButton(false)}
                                onClick={handleEditPromptClicked}
                                title={"Edit Prompt"}
                    >
                        <EditIcon className={"edit-icon"}/>
                    </IconButton>
                </div>
                : null
        }
    </CardContent>
}

function PromptViewFilledPromptMode({nodeId}) {
    const {node} = useChartStore((state) => {
        return {
            node: state.getNode(nodeId)
        }
    });

    const backgroundStyle = useBackgroundColorStyleForNode(nodeId);

    return <CardContent className={"prompt-node-content"} style={backgroundStyle}>
        <div className={"prompt-node-filled-prompt"}>
            {
                (node.data.filled_prompts ?? []).map((filledPrompt, index) => {
                    return <div className={"filled-prompt"} key={index}>
                        <div className={"filled-prompt-title"}>Filled Prompt #{index + 1}</div>
                        <div className={"filled-prompt-content"}>{filledPrompt}</div>
                    </div>
                }, [])
            }
        </div>
    </CardContent>

}

export function PromptCustomEditorMode({handleSavePromptClicked, nodeId}) {
    const backgroundStyle = useBackgroundColorStyleForNode(nodeId);

    return <CardContent className={"prompt-node-content custom-prompt-editor"} style={backgroundStyle}>
        <NodeCustomPromptEditor
            nodeId={nodeId}
            savePrompt={handleSavePromptClicked}
        />
    </CardContent>
}

function PromptLoadingIndicatorMode({nodeId}) {
    const backgroundStyle = useBackgroundColorStyleForNode(nodeId);

    return <CardContent className={"prompt-node-content centered prompt-node-content-loading-indicator-mode"}
                        style={backgroundStyle}>
        <CircularProgress size={20}/>
    </CardContent>
}


function PromptMethodSelectorMode({nodeId, handleCreateFromScratchClicked, handleCreateFromTemplateClicked}) {
    const backgroundStyle = useBackgroundColorStyleForNode(nodeId);

    return <CardContent className={"prompt-node-content"} style={backgroundStyle}>
        <div className={"prompt-method-selector-mode"}>
            <Button
                variant={"outlined"}
                onClick={handleCreateFromScratchClicked}
            >
                <EditNoteIcon/>
                Create prompt from scratch
            </Button>
            <span>or</span>
            <Button
                variant={"outlined"}
                onClick={handleCreateFromTemplateClicked}
            >
                <SearchIcon/>
                Use a prompt template
            </Button>
        </div>
    </CardContent>
}


function PromptTemplateGroupSelector({nodeId, onSelectTemplateGroup, onGoBackSelected}) {
    const backgroundStyle = useBackgroundColorStyleForNode(nodeId);

    const listRef = React.useRef(null);

    let addNoWheelClass = false;
    if (listRef.current) {
        // Check if the prompt node is currently scrolling
        const listHtmlElement = listRef.current;

        const isShowingScrollBar = listHtmlElement.scrollHeight > listHtmlElement.offsetHeight;
        if (isShowingScrollBar) {
            addNoWheelClass = true;
        }
    }


    return <CardContent className={"prompt-node-content prompt-node-content-template-selector"} style={backgroundStyle}>
        <span className="select-template-header">Select Type</span>
        <List className={"template-list " + (addNoWheelClass ? "nowheel" : null)} ref={listRef}>
            {
                promptTemplates.templateGroups.map((templateGroup) => {
                    return <ListItem
                        disablePadding
                        className={"template-list-item"}
                        key={templateGroup.id}
                    >
                        <ListItemButton onClick={() => onSelectTemplateGroup(templateGroup.id)}>
                            <ListItemIcon>
                                {templateGroup.icon}
                            </ListItemIcon>
                            <ListItemText primary={templateGroup.title} secondary={templateGroup.explanation}/>
                        </ListItemButton>
                    </ListItem>
                })
            }

            <ListItem
                disablePadding
                className={"template-list-item go-back-item"}
            >
                <ListItemButton onClick={() => onGoBackSelected()}>
                    <ListItemIcon>
                        <KeyboardReturnIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Go Back"/>
                </ListItemButton>
            </ListItem>
        </List>
    </CardContent>;
}

function IndividualPromptTemplateSelector({nodeId, templateGroupId, onTemplateSelected, onGoBackSelected}) {
    const individualTemplates = promptTemplates.templatesByGroup[templateGroupId];
    const listRef = React.useRef(null);
    const backgroundStyle = useBackgroundColorStyleForNode(nodeId);

    return <CardContent className={"prompt-node-content prompt-node-content-template-selector"} style={backgroundStyle}>
        <span className="select-template-header">Select Template</span>
        <List className={"template-list"} ref={listRef}>
            {
                individualTemplates.map((template) => {
                    return <ListItem
                        disablePadding
                        className={"template-list-item"}
                        key={template.id}
                    >
                        <ListItemButton onClick={() => onTemplateSelected(template.id)}>
                            <ListItemIcon>
                                {template.icon}
                            </ListItemIcon>
                            <ListItemText primary={template.title}/>
                        </ListItemButton>
                    </ListItem>
                })
            }

            <ListItem
                disablePadding
                className={"template-list-item go-back-item"}
            >
                <ListItemButton onClick={() => onGoBackSelected()}>
                    <ListItemIcon>
                        <KeyboardReturnIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Go Back"/>
                </ListItemButton>
            </ListItem>
        </List>
    </CardContent>;
}


function PromptTemplateSelectorMode({nodeId, onTemplateSelected, onGoBackSelected}) {
    const [selectedTemplateGroupId, setSelectedTemplateGroupId] = useState(null);

    if (selectedTemplateGroupId === null) {
        return <PromptTemplateGroupSelector
            nodeId={nodeId}
            onSelectTemplateGroup={(templateGroupId) => setSelectedTemplateGroupId(templateGroupId)}
            onGoBackSelected={() => onGoBackSelected()}

        />
    } else {
        return <IndividualPromptTemplateSelector
            nodeId={nodeId}
            templateGroupId={selectedTemplateGroupId}
            onTemplateSelected={onTemplateSelected}
            onGoBackSelected={() => setSelectedTemplateGroupId(null)}
        />
    }
}

export function PromptTemplatedPromptEditorMode({handleSavePromptClicked, nodeId}) {
    const backgroundStyle = useBackgroundColorStyleForNode(nodeId);

    return <CardContent className={"prompt-node-content"} style={backgroundStyle}>
        <NodeTemplatedPromptEditor
            nodeId={nodeId}
            savePrompt={handleSavePromptClicked}
        />
    </CardContent>
}


function PromptFilePreviewMode({nodeId}) {
    const backgroundStyle = useBackgroundColorStyleForNode(nodeId);

    return <CardContent className={"prompt-node-content"} style={backgroundStyle}>
        <NodeFilePreview nodeId={nodeId}/>
    </CardContent>
}


function PromptGeneratedImagePreviewMode({nodeId}) {
    const backgroundStyle = useBackgroundColorStyleForNode(nodeId);
    const [fetchedImageUri, setFetchedImageUri] = useState("");
    const [generatedImagePrompt, setGeneratedImagePrompt] = useState("");
    const api = useAPI();

    let {node} = useChartStore((state) => {
        return {
            node: state.getNode(nodeId)
        }
    });

    const imagePrompt = node.data.currentValues[0];

    const handleGenerateImageClicked = useCallback(() => {
        return ensureMinimumPromiseResolveTime(api.generateImage(imagePrompt), 500).then((imageData) => {
            setFetchedImageUri(URL.createObjectURL(imageData));
            setGeneratedImagePrompt(imagePrompt);
        });
    }, [api, imagePrompt, setFetchedImageUri]);

    const showGenerateButton = imagePrompt !== generatedImagePrompt;

    return <CardContent className={"prompt-node-content generated-image-preview"} style={backgroundStyle}>
        {
            fetchedImageUri ?
                <div className={"prompt-node-generated-image"}
                    style={{"backgroundImage": `url(${fetchedImageUri})`}}
                     alt={"Preview of Generated Picture Based on Prompt Output"}
                /> : <div />
        }
        {
            showGenerateButton ? <SpinnerButton
            variant={"contained"}
            color={"primary"}
            className={"generate-image-button nodrag"}
            onClick={handleGenerateImageClicked}>Generate Image</SpinnerButton> : null
        }

    </CardContent>
}


function PromptNode({id: nodeId, isConnectable}) {
    let {
        node,
        recomputeChart,
        isUpdatingChart,
        changeNodeData,
        isNodeBeingStreamed,
        isFileUploadingForNode
    } = useChartStore((state) => {
        return {
            node: state.getNode(nodeId),
            changeNodeData: state.changeNodeData,
            recomputeChart: state.recomputeChart,
            isUpdatingChart: state.is_recomputing_chart,
            isNodeBeingStreamed: state.isNodeBeingStreamed(nodeId),
            isFileUploadingForNode: state.isFileUploadingForNode(nodeId),
        }
    });

    let [tabName, setTabName] = React.useState('current-value');
    let [isMouseOver, setIsMouseOver] = React.useState(false);

    const backgroundStyle = useBackgroundColorStyleForNode(nodeId);

    const {
        state: showBottomBar,
        lastStateChangeTime
    } = useDelayedStateChange(isMouseOver, (previousState, targetState) => {
        // This is the delay time in ms before showing the bottom bar.
        if (targetState) {
            return 250;
            // This is the delay time in ms before hiding the bottom bar.
        } else {
            return 5000;
        }
    });

    const handleSavePromptClicked = useCallback(() => {
        // Trigger the chart to start updating in the background.
        // recomputeChart();
    }, []);

    const handleTabChange = useCallback((newTabName) => {
        setTabName(newTabName)

        if (newTabName === "current-value" && node.data.stale) {
            recomputeChart();
        }

    }, [setTabName, node, recomputeChart]);

    const handleCreateFromScratchClicked = useCallback((evt) => {
        changeNodeData(nodeId, {
            method: "custom"
        });
    }, [changeNodeData, nodeId]);

    const handleCreateFromTemplateClicked = useCallback((evt) => {
        changeNodeData(nodeId, {
            method: "template"
        });
    }, [changeNodeData, nodeId]);

    const handleGoBackFromTemplateClicked = useCallback((evt) => {
        changeNodeData(nodeId, {
            method: null
        });
    }, [changeNodeData, nodeId]);

    const handleEditPromptClicked = useCallback((evt) => {
        setTabName('edit');
    }, []);

    const handleTemplateSelected = useCallback((templateId) => {
        // First get the list of insertion variables for this template
        const template = promptTemplates.findTemplateById(templateId);
        const insertions = template.getInsertionPoints();

        const templateInsertionValues = {};

        if (insertions.length > 0 && node.data.prompt) {
            templateInsertionValues[insertions[0]] = node?.data?.prompt;
        }

        changeNodeData(nodeId, {
            templateId: templateId,
            templateInsertionValues: templateInsertionValues,
        });
    }, [node, changeNodeData, nodeId]);

    const onMouseEnter = useCallback(() => setIsMouseOver(true), [setIsMouseOver]);
    const onMouseLeave = useCallback(() => setIsMouseOver(false), [setIsMouseOver]);
    const onKeyDown = useCallback((event) => {
        event.stopPropagation();
    }, []);

    if (!node) {
        return;
    }

    let currentViewState = null;
    if (isFileUploadingForNode) {
        currentViewState = "loading";
    } else if (!isUpdatingChart || !node.data.stale) {
        if (node.data.fileId) {
            currentViewState = "file-preview";
        } else {
            currentViewState = "current-value";
        }
    } else if (isNodeBeingStreamed) {
        currentViewState = "current-value";
    } else {
        currentViewState = "loading";
    }

    let currentEditState = null;
    if (!node.data.method) {
        currentEditState = "choose-method";
    } else if (node.data.method === 'custom') {
        currentEditState = "edit-custom-prompt";
    } else if (node.data.method === 'template') {
        if (node.data.templateId) {
            currentEditState = "edit-template-prompt";
        } else {
            currentEditState = "select-template";
        }
    }

    return (
        <div
            id={`prompt-node-${nodeId}`}
            className={`prompt-node`}
            onKeyDown={onKeyDown}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            title={"Prompt"}
            style={backgroundStyle}
        >
            <Card style={backgroundStyle}>
                <CardHeader
                    avatar={<PromptNodeIcon/>}
                    node={node}
                    title={<NodeTitleEditor nodeId={nodeId} placeholder={"New Prompt"}/>}
                />
                {
                    tabName === 'current-value' ?
                        <>
                            {
                                currentViewState === 'current-value' ?
                                    <PromptCurrentValueMode
                                        nodeId={nodeId}
                                        onEditPrompt={handleEditPromptClicked}
                                    />
                                    : null
                            }
                            {
                                currentViewState === 'file-preview' ?
                                    <PromptFilePreviewMode
                                        nodeId={nodeId}
                                    />
                                    : null
                            }
                            {
                                currentViewState === 'loading' ?
                                    <PromptLoadingIndicatorMode
                                        nodeId={nodeId}
                                    />
                                    : null
                            }
                        </>
                        : null
                }
                {
                    tabName === 'edit' ?
                        <>
                            {
                                currentEditState === 'choose-method' ?
                                    <PromptMethodSelectorMode
                                        nodeId={nodeId}
                                        handleCreateFromScratchClicked={handleCreateFromScratchClicked}
                                        handleCreateFromTemplateClicked={handleCreateFromTemplateClicked}
                                    />
                                    : null
                            }
                            {
                                currentEditState === 'edit-custom-prompt' ?
                                    <PromptCustomEditorMode
                                        nodeId={nodeId}
                                        handleSavePromptClicked={handleSavePromptClicked}
                                    />
                                    : null
                            }
                            {
                                currentEditState === 'select-template' ?
                                    <PromptTemplateSelectorMode
                                        nodeId={nodeId}
                                        onTemplateSelected={handleTemplateSelected}
                                        onGoBackSelected={handleGoBackFromTemplateClicked}
                                    />
                                    : null
                            }
                            {
                                currentEditState === 'edit-template-prompt' ?
                                    <PromptTemplatedPromptEditorMode
                                        nodeId={nodeId}
                                        handleSavePromptClicked={handleSavePromptClicked}
                                    />
                                    : null
                            }
                        </>
                        : null
                }
                {
                    tabName === 'view-filled-prompt' ?
                        <PromptViewFilledPromptMode
                            nodeId={nodeId}
                        />
                        : null
                }
                {
                    tabName === 'debug' ?
                        <PromptDebugViewMode
                            nodeId={nodeId}
                        />
                        : null
                }
                {
                    tabName === 'settings' ?
                        <PromptSettingsMode
                            nodeId={nodeId}
                        />
                        : null
                }
                {
                    tabName === 'generated-image' ?
                        <PromptGeneratedImagePreviewMode
                            nodeId={nodeId}
                        />
                        : null
                }

                {
                    // This section of the code is responsible for showing the bottom bar.
                    // It will completely stop rendering the bottom bar if it has been hidden
                    // for long enough, in order to reduce the number of elements on the page
                    // to reduce browser chugging when there are a lot of nodes.
                    // So this mechanism allows the animations on the bottom bar to still work
                    // smoothly, while also reducing the number of elements on the page when
                    // it isn't shown.
                    showBottomBar ||
                    (new Date().getTime() - lastStateChangeTime.getTime()) < 10000 ?

                        <CardActions disableSpacing
                                     className={`prompt-node-bottom-bar ${showBottomBar ? 'visible' : 'hidden'}`}>
                            <div className={"prompt-node-tabs"}>
                                <BottomTabs value={tabName} onChange={handleTabChange}>
                                    <BottomTab name={'current-value'}>
                                        <VisibilityIcon/>
                                    </BottomTab>
                                    {
                                        !node.data.fileId ?
                                            <BottomTab name={'edit'}>
                                                <EditIcon/>
                                            </BottomTab>
                                            : null
                                    }
                                    {
                                        !node.data.fileId ?
                                            <BottomTab name={'view-filled-prompt'}>
                                                <ArticleIcon/>
                                            </BottomTab> : null
                                    }
                                    <BottomTab name={'debug'}>
                                        <BugReportIcon/>
                                    </BottomTab>
                                    {
                                        !node.data.fileId ?
                                            <BottomTab name={'settings'}>
                                                <SettingsIcon/>
                                            </BottomTab> : null
                                    }
                                    {
                                        !node.data.fileId ?
                                            <BottomTab name={'generated-image'}>
                                                <ImageIcon/>
                                            </BottomTab> : null
                                    }
                                </BottomTabs>
                            </div>

                            <div className={"prompt-node-action-buttons"}>
                                {
                                    node.data.method === "custom" ?
                                        <PromptWritingTipsActionButton/>
                                        : null
                                }
                                {/*{*/}
                                {/*    (node.data.method === "template" && node.data.templateId) ?*/}
                                {/*        <CustomizeTemplatePromptActionButton nodeId={nodeId}/>*/}
                                {/*        : null*/}
                                {/*}*/}
                                <NodeColorPickerWidget nodeId={nodeId}/>
                                <FileUploadActionButton nodeId={nodeId}/>
                                <CopyContentsActionButton nodeId={nodeId}/>
                                <DeleteNodeActionButton nodeId={nodeId}/>
                            </div>

                        </CardActions>
                        : null
                }
            </Card>
            <TransparentNodeResizer nodeId={nodeId}/>
            <NodeInputHandle isConnectable={isConnectable} nodeId={nodeId}/>
            <NodeOutputHandle isConnectable={isConnectable} nodeId={nodeId}/>
        </div>
    );
}

export default PromptNode;
