import {PageWrapperWithMenu} from "../../../frontend/elements/PageWrapperWithMenu";
import React, {useCallback} from "react";
import {Card, CardContent} from "@mui/material";
import {useAPI} from "../../../frontend/components/APIProvider";
import {PageHeader} from "../../../frontend/elements/PageHeader";
import ImportedDocumentForm from "./ImportedDocumentForm";
import {useNavigate} from "react-router-dom";


export default function NewDocumentPage(props) {
    const api = useAPI();
    const navigate = useNavigate();
    const handleCreateDocument = useCallback(async (documentData) => {
        return await api.createNewImportedDocument(documentData).then((newDocument) => {
            navigate(`/imported_document/${newDocument.id}`);
        });
    }, [api, navigate]);

    return <PageWrapperWithMenu>
        <PageHeader title={`New Document`}/>
        <div className="document-edit-page">
            <Card className="document-edit-form-card">
                <CardContent>
                    <ImportedDocumentForm
                        initialDocument={{}}
                        onDocumentSave={handleCreateDocument}
                        saveButtonText={"Create New Document"}
                    />
                </CardContent>
            </Card>
        </div>

    </PageWrapperWithMenu>
}