import * as React from "react";
import Editor from "@monaco-editor/react";
import { loader } from "@monaco-editor/react";

// After a bunch of testing, it was found that the easiest way to get monaco fully working
// Was to use have it load from the CDN instead of from built-in webpack. But we don't
// want to load from any random CDN, so we uploaded the files to our own storage and
// use that to serve the files.
loader.config({
  paths: {
    vs: "https://storage.googleapis.com/prospera-labs-public-files/monaco/min/vs",
  },
});

export function CodeEditor(props) {
  return <Editor {...props} />;
}

export default CodeEditor;
