import axios from "axios";
import { createContext } from "react";
import { axiosETAGCache } from "axios-etag-cache";
import agentKernelApi from "../../../core/agent_kernel/frontend/apis";
import billingApi from "../../../core/billing/frontend/apis";
import bulkChartEvaluationApi from "../../../core/bulk_chart_evaluations/frontend/apis";
import businessInfoApi from "../../../shared/business_info/frontend/apis";
import conversationsApi from "../../../core/conversations/frontend/apis";
import customersApi from "../../../remindertap/customers/frontend/apis";
import customDataApi from "../../custom_data/frontend/apis";
import outboundsApi from "../../../core/outbound/frontend/apis";
import knowledgeBaseApi from "../../../core/knowledge_base/frontend/apis";
import mybookrobotApi from "../../../mybookrobot/frontend/apis";
import fileUploadAPI from "../../../core/file_uploads/frontend/apis";
import insuranceAgentsApi from "../../../remindertap/insurance_agents/frontend/apis";
import llmModelsApi from "../../../core/llm_model_providers/frontend/apis";
import networkingBotAPI from "../../../networkingbot/frontend/apis";
import onboardingApi from "../../../core/onboarding/frontend/apis";
import promptChartApi from "../../../core/prompt_chart/frontend/apis";
import promptTemplatesApi from "../../smart_chains/frontend/apis";
import schedulingApi from "../../../shared/scheduling/frontend/apis";
import syntheticUserApi from "../../../core/synthetic/frontend/apis";
import tenantApi from "../../../core/frontend/tenant_configuration/apis";
import translationApi from "../../../core/translation/frontend/apis";
import voiceApi from "../../../core/voice/frontend/apis";
import wa2ChatApi from "../../../core/wa_2chat/frontend/apis";
import webApi from "../../../core/web/frontend/apis";
import wordFactoryApi from "../../../wordfactory/frontend/apis";
import imageUploadApi from "../../../core/image_uploads/frontend/apis";
import documentGeneratorApi from "../../../core/document_generator/frontend/apis";
import axiosRetry from "axios-retry";
import intakeApi from "../../../core/intake/frontend/apis";

export const apiAccessTokenContext = createContext(null);

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);

let cachedUserDetails = null;

export const api = {
  async home() {
    const response = await axiosWithETAGCache.get(`/`);
    return response.data;
  },
  async frontendConfiguration() {
    const response = await axiosWithETAGCache.get(`/frontend_configuration`);
    return response.data;
  },
  async healthCheck() {
    // This may seem weird, but the we want calls to the health check endpoint to bypass the normal
    // error handling mechanisms that the system installs into the axios default. So instead we use
    // native javascript API here.
    const response = await fetch(axios.defaults.baseURL + "health_check");
    return response.json();
  },
  async isAdmin() {
    const response = await axiosWithETAGCache.get(`/is-admin`);
    return response.data;
  },
  async getUserDetails() {
    if (cachedUserDetails) {
      return cachedUserDetails;
    }

    const response = await axiosWithETAGCache.get(`/user/me`);
    cachedUserDetails = response.data;
    return response.data;
  },
  ...agentKernelApi,
  ...billingApi,
  ...bulkChartEvaluationApi,
  ...businessInfoApi,
  ...conversationsApi,
  ...customersApi,
  ...customDataApi,
  ...outboundsApi,
  ...knowledgeBaseApi,
  ...mybookrobotApi,
  ...networkingBotAPI,
  ...fileUploadAPI,
  ...insuranceAgentsApi,
  ...llmModelsApi,
  ...onboardingApi,
  ...promptChartApi,
  ...promptTemplatesApi,
  ...schedulingApi,
  ...syntheticUserApi,
  ...tenantApi,
  ...translationApi,
  ...voiceApi,
  ...wa2ChatApi,
  ...webApi,
  ...wordFactoryApi,
  ...imageUploadApi,
  ...documentGeneratorApi,
  ...intakeApi,
  resetCachedUserDetails() {
    cachedUserDetails = null;
  }
};

const SAFE_HTTP_METHODS = ["get", "head", "options"];
const IDEMPOTENT_HTTP_METHODS = SAFE_HTTP_METHODS.concat([
  "put",
  "delete",
  "patch",
]);

export function isIdempotentRequestError(error) {
  if (!error.config?.method) {
    // Cannot determine if the request can be retried
    return false;
  }
  return (
    axiosRetry.isRetryableError(error) &&
    IDEMPOTENT_HTTP_METHODS.indexOf(error.config.method) !== -1
  );
}

// Setup retries on API calls with backoff
axiosRetry(axios, {
  retries: 5,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    // We apply the default condition, except if the error is from the health check endpoint,
    // which has a normal behavior of returning the a bad status code if the health check fails,
    if (error.config.url === "/health_check") {
      return false;
    }

    return axiosRetry.isNetworkError(error) || isIdempotentRequestError(error);
  },
});

// TODO: FIX ME
if (process.env.REACT_APP_INITIAL_BACKEND_API_URL_ROOT) {
  axios.defaults.baseURL = process.env.REACT_APP_INITIAL_BACKEND_API_URL_ROOT;
} else {
  // Just assume the API url is the same as the page the code is loaded in.
  axios.defaults.baseURL = `https://${window.location.hostname}/api/`;
}

axios.defaults.headers['X-Client-Tenant-Host'] = window.location.hostname;

axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      // Auth0.logout();
      return Promise.reject(error);
    }

    if (process.env.REACT_APP_DEBUG === "true") {
      console.log(error.toString());
    } else {
      // Force reload the page. maybe this will help.
      // window.location.reload();
    }
    // Do something with response error
    return Promise.reject(error);
  }
);
