import React, { useCallback, useState } from "react";
import "./CustomerEditPage.scss";
import FormInput from "../../../core/frontend/elements/form/FormInput";
import FormRoot from "../../../core/frontend/elements/form/FormRoot";
import FormSection from "../../../core/frontend/elements/form/FormSection";
import { SpinnerButton } from "../../../core/frontend/elements/SpinnerButton";
// import FormDisplay from "../../../core/frontend/elements/form/FormDisplay";
import { PageSection } from "../../../core/frontend/elements/PageSection";
import OutboundStateViewer from "../../../core/outbound/frontend/OutboundStateViewer";
import "./CustomerForm.scss";

const getPrefillNextFollowUpDateForCustomer = (customer) => {
  if (!customer) {
    return null;
  }

  if (customer.policy_start_date && customer.follow_up_frequency) {
    const startDate = new Date(customer.policy_start_date);
    const nextFollowUpDate = new Date(startDate);

    nextFollowUpDate.setHours(12, 0, 0, 0);

    if (customer.follow_up_frequency === "monthly") {
      nextFollowUpDate.setMonth(nextFollowUpDate.getMonth() + 1);
    } else if (customer.follow_up_frequency === "quarterly") {
      nextFollowUpDate.setMonth(nextFollowUpDate.getMonth() + 3);
    } else if (customer.follow_up_frequency === "semiannually") {
      nextFollowUpDate.setMonth(nextFollowUpDate.getMonth() + 6);
    } else if (customer.follow_up_frequency === "annually") {
      nextFollowUpDate.setFullYear(nextFollowUpDate.getFullYear() + 1);
    } else {
      throw new Error(
        "Unknown follow up frequency: " + customer.follow_up_frequency
      );
    }

    return nextFollowUpDate;
  }
  return null;
};

export default function CustomerForm({
  initialCustomer,
  onCustomerSave,
  onDeletedClicked,
  onStartOptInConversationClicked,
  onFollowUpNowClicked,
  onViewConversationClicked,
  saveButtonText,
}) {
  const [customer, setCustomer] = useState(initialCustomer ?? null);

  const handleCustomerSaved = useCallback(
    (newCustomerData) => {
      if (onCustomerSave) {
        if (!newCustomerData.next_follow_up_date) {
          const next_follow_up_date =
            getPrefillNextFollowUpDateForCustomer(newCustomerData);
          if (next_follow_up_date) {
            newCustomerData.next_follow_up_date =
              next_follow_up_date.toISOString();
          }
        }
        return onCustomerSave(newCustomerData);
      }
    },
    [onCustomerSave]
  );

  const handleCustomerChanged = useCallback(
    (newCustomerData) => {
      setCustomer(newCustomerData);
    },
    [setCustomer]
  );

  const handleStartOptInConversationClicked = useCallback(() => {
    if (onStartOptInConversationClicked) {
      return onStartOptInConversationClicked(customer);
    }
  }, [customer, onStartOptInConversationClicked]);

  const handleFollowUpNowClicked = useCallback(() => {
    if (onFollowUpNowClicked) {
      return onFollowUpNowClicked(customer);
    }
  }, [customer, onFollowUpNowClicked]);

  const handleViewConversationClicked = useCallback(() => {
    if (onViewConversationClicked) {
      return onViewConversationClicked(customer);
    }
  }, [customer, onViewConversationClicked]);

  return (
    <div className={"customer-form"}>
      <FormRoot
        initialValue={initialCustomer}
        saveButtonText={saveButtonText ?? "Save Customer"}
        onChange={handleCustomerChanged}
        onSave={handleCustomerSaved}
        extraButtons={
          <>
            {onDeletedClicked ? (
              <SpinnerButton
                variant='contained'
                color='error'
                className={"delete-button"}
                onClick={onDeletedClicked}
              >
                Delete
              </SpinnerButton>
            ) : null}
            {onStartOptInConversationClicked ? (
              <SpinnerButton
                variant='contained'
                color='success'
                className={"follow-up-now-button"}
                onClick={handleStartOptInConversationClicked}
              >
                Start Opt-In Conversation
              </SpinnerButton>
            ) : null}
            {onFollowUpNowClicked ? (
              <SpinnerButton
                variant='contained'
                color='primary'
                className={"follow-up-now-button"}
                onClick={handleFollowUpNowClicked}
              >
                Follow Up Now
              </SpinnerButton>
            ) : null}
            {onViewConversationClicked &&
            customer?.follow_up_conversation_id ? (
              <SpinnerButton
                variant='contained'
                color='primary'
                className={"view-conversation-button"}
                onClick={handleViewConversationClicked}
              >
                View Conversation
              </SpinnerButton>
            ) : null}
          </>
        }
      >
        <FormSection
          title={"Customer Details"}
          subtitle={"General details about the customer"}
        >
          <FormInput
            type={"insurance_agent"}
            label={"Insurance Agent For Customer"}
            fieldName={"insurance_agent_id"}
          />

          <FormInput
            type={"full_name"}
            label={"Insured Name"}
            fieldName={"insured_name"}
          />

          <FormInput
            type={"company_name"}
            label={"Company Name"}
            fieldName={"company_name"}
          />

          <FormInput
            type={"phone"}
            label={"Cell Phone"}
            fieldName={"phone_number"}
          />

          <FormInput
            type={"email"}
            label={"Email"}
            fieldName={"email_address"}
          />
        </FormSection>
        <FormSection
          title={"Coverage Information"}
          subtitle={"Types of coverage that you want data tracked for"}
        >
          <FormInput
            type={"datetime"}
            label={"Policy Start Date"}
            fieldName={"policy_start_date"}
          />
          <FormInput
            type={"checkboxes"}
            label={"Coverage Types"}
            options={[
              "General Liability",
              "Subcontractors",
              "Commercial Vehicles",
              "Workers Compensation",
            ]}
            fieldName={[
              "has_general_liability_coverage",
              "has_subcontractors_coverage",
              "has_vehicle_coverage",
              "has_workers_compensation_coverage",
            ]}
          />
          {customer?.has_general_liability_coverage ? (
            <FormInput
              type={"radio"}
              label={"General Liability Coverage Type"}
              fieldName={"general_liability_coverage_type"}
              options={["gross_receipts", "payroll"]}
              optionLabels={["Gross Receipts", "Payroll"]}
            />
          ) : null}
        </FormSection>

        <FormSection
          title={"Main Data Points"}
          subtitle={
            "These are the data points that are automatically collected and kept up-to-date by the bot."
          }
        >
          {customer?.has_general_liability_coverage &&
          customer?.general_liability_coverage_type === "payroll" ? (
            <FormInput
              type={"currency"}
              label={"Annual Payroll for General Liability ($)"}
              fieldName={"annual_general_liability_payroll_amount_dollars"}
            />
          ) : null}
          {customer?.has_general_liability_coverage &&
          customer?.general_liability_coverage_type === "gross_receipts" ? (
            <FormInput
              type={"currency"}
              label={"Annual Gross Receipts ($)"}
              fieldName={"annual_gross_receipts_dollars"}
            />
          ) : null}
          {customer?.has_subcontractors_coverage ? (
            <FormInput
              type={"currency"}
              label={"Annual Sub Costs ($)"}
              fieldName={"annual_subcontractor_costs_amount_dollars"}
            />
          ) : null}
          {customer?.has_workers_compensation_coverage ? (
            <FormInput
              type={"currency"}
              label={"Annual Payroll for Workers Comp ($)"}
              fieldName={"annual_workers_comp_payroll_amount_dollars"}
            />
          ) : null}
          {customer?.has_workers_compensation_coverage ? (
            <FormInput
              type={"text"}
              label={"Type of Work Being Done"}
              fieldName={"type_of_work"}
              example={"Roofing, Carpentry, Plumbing, Electrical, etc...."}
            />
          ) : null}
          {customer?.has_vehicle_coverage ? (
            <FormInput
              type={"text"}
              label={"Commercial Vehicles Covered"}
              fieldName={"commercial_vehicle_count"}
              example={""}
            />
          ) : null}
        </FormSection>

        <FormSection
          title={"Custom Data Points"}
          subtitle={
            "Any additional data points you want the bot to ask about and track for this customer"
          }
        >
          <FormInput
            type={"string_list"}
            label={"Data Points"}
            fieldName={"custom_data_points"}
            example={"Employee Headcount..."}
          />
        </FormSection>

        {customer?.outbound_state ? (
          <PageSection title='Follow Ups' elevation={1}>
            <OutboundStateViewer
              outboundState={customer?.outbound_state ?? {}}
              outboundWord={"Follow Up"}
            />
          </PageSection>
        ) : null}

        {/*<FormSection title={"Follow Up Style"}>*/}
        {/*    <FormInput*/}
        {/*        type={"datetime"}*/}
        {/*        label={"Next Follow Up"}*/}
        {/*        fieldName={"next_follow_up_date"}*/}
        {/*        prefillValue={getPrefillNextFollowUpDateForCustomer(customer)}*/}
        {/*    />*/}
        {/*</FormSection>*/}
      </FormRoot>
    </div>
  );
}
