import React, { useCallback, useMemo, useState } from "react";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import { TextField } from "@mui/material";
import { useChangeFormData, useFormData, useFormOptions } from "../FormRoot";
import LocalTranslatedText from "../../../../translation/frontend/components/LocalTranslatedText";

function cleanCurrencyValue(value) {
  if (value) {
    return Number(value.replace(/[^0-9.]/g, ""));
  } else {
    return 0;
  }
}

function formatCurrencyValue(value) {
  if (!value) {
    return "$0";
  }

  if (value[0] !== "$") {
    value = "$" + value;
  }

  return value;
}

export function CurrencyInput({ label, example, fieldName, size }) {
  const formData = useFormData();
  const changeFormData = useChangeFormData();
  const formOptions = useFormOptions();
  const [localValue, setLocalValue] = useState(formData[fieldName]);

  const onChangeFormDataDebounced = useMemo(
    () => _.debounce(changeFormData, 500),
    [changeFormData]
  );

  const handleInputChange = useCallback(
    (event) => {
      const cleanedValue = cleanCurrencyValue(event.target.value);
      setLocalValue(cleanedValue);
      onChangeFormDataDebounced(fieldName, cleanedValue);
    },
    [fieldName, onChangeFormDataDebounced]
  );

  return (
    <div className={"form-input"}>
      <Typography variant={"label"}>
        <LocalTranslatedText language={"en"} text={label} />
      </Typography>

      <TextField
        name={fieldName}
        placeholder={example}
        value={formatCurrencyValue(localValue)}
        onChange={handleInputChange}
        disabled={!formOptions.editable}
        size={size || "medium"}
      />
    </div>
  );
}

export default CurrencyInput;
