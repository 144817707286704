import "./SmartChainWholeChainView.scss";
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {useCallback, useEffect, useMemo, useRef} from "react";
import {useTheme} from '@mui/material/styles';
import SmartChainWholeChainTestForm from "./SmartChainWholeChainTestForm";
import SmartChainStepTestSamples from "./SmartChainStepTestSamples";
import SmartChainErrorList from "./SmartChainErrorList";


function TabPanel({selectedTab, tabName, children, resetWhenTabOpens}) {
  const theme = useTheme();

  return (
    <div
      role="tabpanel"
      hidden={selectedTab !== tabName}
      id={`full-width-tabpanel-${tabName}`}
      aria-labelledby={`full-width-tab-${tabName}`}
      className={`editor-tab ${selectedTab === tabName ? "active" : "hidden"}`}
    >
      {
        !resetWhenTabOpens
          ? children
          : selectedTab === tabName
            ? children
            : null
      }
    </div>
  );
}

export default function SmartChainWholeChainView({
                                               smartChain,
                                               isDarkMode,
                                               tab,
                                               setTab
                                             }) {
  const handleTabChange = useCallback((event, newValue) => {
    setTab(newValue);
  }, [setTab]);

  useEffect(() => {
    if (tab !== "test_form" && tab !== "test_samples" && tab !== "recent_errors") {
      setTab("test_form");
    }
  }, [tab, setTab]);

  return (
    <div className={"smart-chain-whole-chain-view"}>
      <AppBar position="static" color={'transparent'}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="secondary"
          variant="fullWidth"
          aria-label="Smart Chain Whole Chain View"
        >
          <Tab label="Test Form" value={"test_form"}/>
          <Tab label="Test Samples" value={"test_samples"}/>
          <Tab label="Recent Errors" value={"recent_errors"}/>
        </Tabs>
      </AppBar>
      <TabPanel selectedTab={tab} tabName={"test_form"} resetWhenTabOpens={true}>
        <SmartChainWholeChainTestForm
          smartChain={smartChain}
          isDarkMode={isDarkMode}
        />
      </TabPanel>
      <TabPanel selectedTab={tab} tabName={"test_samples"} resetWhenTabOpens={true}>
        <SmartChainStepTestSamples
          smartChain={smartChain}
          selectedStepIndex={null}
          isDarkMode={isDarkMode}
        />
      </TabPanel>
      <TabPanel selectedTab={tab} tabName={"recent_errors"} resetWhenTabOpens={true}>
        <SmartChainErrorList
          smartChain={smartChain}
          selectedStepIndex={null}
          isDarkMode={isDarkMode}
        />
      </TabPanel>
    </div>
  );
}
