import React, { useCallback, useState } from "react";
import FormInput from "../../../frontend/elements/form/FormInput";
import FormRoot from "../../../frontend/elements/form/FormRoot";
import FormSection from "../../../frontend/elements/form/FormSection";
import { SpinnerButton } from "../../../frontend/elements/SpinnerButton";
import "./CustomDataSchemaForm.scss";
import LocalTranslatedText from "../../../translation/frontend/components/LocalTranslatedText";
import SchemaEditor from "../../../frontend/elements/schema-editor/SchemaEditor";
import IconSelector from "../../../frontend/elements/form/inputs/IconSelector";
import FormArraySection from "../../../frontend/elements/form/FormArraySection";
import TableElementLookupSelectorInput from "../../../frontend/elements/form/inputs/TableElementLookupSelectorInput";
import {useAPI} from "../../../frontend/components/APIProvider";
import SmartChainBindingSelector from "../../../smart_chains/frontend/smart_chain_binding/SmartChainBindingSelector";

export default function CustomDataSchemaForm({
  initialCustomDataSchema,
  onCustomDataSchemaSave,
  onDeletedClicked,
  saveButtonText,
}) {
  const api = useAPI();
  const [, setCustomDataSchema] = useState(initialCustomDataSchema);
  const [schemaData, setSchemaData] = useState(
    initialCustomDataSchema?.json_schema || {}
  );

  const handleCustomDataSchemaSaved = useCallback(
    (newCustomDataSchemaData) => {
      if (onCustomDataSchemaSave) {
        const payload = {
          ...newCustomDataSchemaData,
          json_schema: schemaData,
        };
        return onCustomDataSchemaSave(payload);
      }
    },
    [onCustomDataSchemaSave, schemaData]
  );

  const handleCustomDataSchemaChange = useCallback(
    (newCustomDataSchemaData) => {
      setCustomDataSchema(newCustomDataSchemaData);
    },
    []
  );

  const handleSchemaEditorChange = useCallback((schemaData) => {
    setSchemaData(schemaData);
  }, []);

  return (
    <div className={"custom-data-schema-form"}>
      <FormRoot
        initialValue={initialCustomDataSchema}
        saveButtonText={saveButtonText ?? "Save Custom Data Schema"}
        onSave={handleCustomDataSchemaSaved}
        onChange={handleCustomDataSchemaChange}
        extraButtons={
          <>
            {onDeletedClicked ? (
              <SpinnerButton
                variant='contained'
                color='error'
                className={"delete-button"}
                onClick={onDeletedClicked}
              >
                <LocalTranslatedText language={"en"} text='Delete' />
              </SpinnerButton>
            ) : null}
          </>
        }
      >
        <FormSection
          title={"General"}
          subtitle={"Metadata that about this custom data schema"}
        >
          <FormInput
            type={"text"}
            label={"Machine Name"}
            fieldName={"machine_name"}
            placeholder={
              "The machine name/id for the schema. This must be an all" +
              " lower-case string with only alphanumerics, underscores and hyphens" +
              " (url compatible)."
            }
            size='small'
          />

          <FormInput
            type={"text"}
            label={"Title"}
            fieldName={"title"}
            size='small'
          />

          <FormInput
            type={"text"}
            label={"Description"}
            fieldName={"description"}
            rows={4}
            multiline={true}
            size='small'
          />
        </FormSection>

        <SchemaEditor
          defaultLayout='schema'
          mode='full'
          initialSchemaData={schemaData}
          onChange={handleSchemaEditorChange}
          options={{
            padding: false,
            boxShadow: false,
            metadataComponent: false,
            margin: false,
          }}
        />

        <FormSection title={"Options"}>
          <FormInput
            type={"checkboxes"}
            options={["Show in Left Menu"]}
            fieldName={["show_in_left_menu"]}
            size='small'
          />

          <IconSelector fieldName={"left_menu_icon"} label={"Left Menu Icon"} />
        </FormSection>

        <FormSection title={"Custom Actions"}>
          <FormArraySection
            fieldName={"custom_actions"}
            defaultItemValue={{}}
            arrayFormItemComponent={({ item, index }) => (
              <>
                <FormSection title={`Custom Action ${index + 1}`}>
                  <FormInput
                    type={"text"}
                    label={"Action Name"}
                    fieldName={"action_name"}
                    size='small'
                  />

                  <FormInput
                    type={"text"}
                    label={"Title"}
                    fieldName={"title"}
                    size='small'
                  />

                  <SmartChainBindingSelector
                    label={"Smart chain binding"}
                    fieldName={"smart_chain_binding_name"}
                  />
                </FormSection>
              </>
            )}
          />
        </FormSection>
      </FormRoot>
    </div>
  );
}
