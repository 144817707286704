import "./BookingForm.scss";
import React, { useCallback } from "react";
import moment from "moment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { SpinnerButton } from "../../../../core/frontend/elements/SpinnerButton";
import { TextField } from "@mui/material";
import { CalendarSelector } from "../calendars/CalendarSelector";
import LocalTranslatedText from "../../../../core/translation/frontend/components/LocalTranslatedText";
import { PageSection } from "../../../../core/frontend/elements/PageSection";
import OutboundStateViewer from "../../../../core/outbound/frontend/OutboundStateViewer";
import { useFrontendConfiguration } from "../../../../core/frontend/components/FrontendConfigurationProvider";

export const BookingForm = ({
  booking,
  onChange,
  onSaveClicked,
  showDeleteButton,
  onDeleteClicked,
}) => {
  const config = useFrontendConfiguration();

  console.log("Config", config);

  const handleChange = useCallback(
    (e) => {
      const newBooking = {
        ...booking,
        [e.target.name]: e.target.value,
      };

      onChange(newBooking);
    },
    [booking, onChange]
  );

  const handleChangeTime = useCallback(
    (date) => {
      const newBooking = {
        ...booking,
        start_datetime_in_utc: date.toISOString(),
        user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      // See if we can parse the start time. If so, use it to update
      // the end-time automatically from duration minutes
      if (date.isValid()) {
        const endTime = date.clone().add(booking.duration_minutes, "minutes");
        newBooking.end_datetime_in_utc = endTime.toISOString();
      }

      onChange(newBooking);
    },
    [booking, onChange]
  );

  const handleChangeCalendar = useCallback(
    (calendar_id) => {
      const newBooking = {
        ...booking,
        calendar_id: calendar_id,
      };

      onChange(newBooking);
    },
    [booking, onChange]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      return onSaveClicked();
    },
    [onSaveClicked]
  );

  const handleDeleteClicked = useCallback(
    (e) => {
      e.preventDefault();
      return onDeleteClicked();
    },
    [onDeleteClicked]
  );

  return (
    <form className={"booking-form"}>
      <CalendarSelector
        value={booking.calendar_id}
        onCalendarSelected={handleChangeCalendar}
      />
      <TextField
        name='customer_name'
        label={<LocalTranslatedText language={"en"} text='Customer Name' />}
        value={booking.customer_name}
        onChange={handleChange}
        margin='normal'
        fullWidth
      />
      <TextField
        name='customer_email'
        label={<LocalTranslatedText language={"en"} text='Customer Email' />}
        value={booking.customer_email}
        onChange={handleChange}
        margin='normal'
        fullWidth
      />
      <TextField
        name='customer_phone'
        label={<LocalTranslatedText language={"en"} text='Customer Phone' />}
        value={booking.customer_phone}
        onChange={handleChange}
        margin='normal'
        fullWidth
      />
      <DateTimePicker
        name='start_datetime_in_utc'
        label={<LocalTranslatedText language={"en"} text='Date & Time' />}
        value={moment.utc(booking.start_datetime_in_utc).local()}
        onChange={handleChangeTime}
        margin='normal'
        fullWidth
      />
      <TextField
        name='duration_minutes'
        label={
          <LocalTranslatedText language={"en"} text='Duration in Minutes' />
        }
        value={booking.duration_minutes}
        onChange={handleChange}
        margin='normal'
        type='number'
        fullWidth
      />
      <TextField
        name='title'
        label={<LocalTranslatedText language={"en"} text='Title' />}
        value={booking.title}
        onChange={handleChange}
        margin='normal'
        fullWidth
      />
      <TextField
        name='notes'
        label={<LocalTranslatedText language={"en"} text='Notes' />}
        value={booking.notes}
        onChange={handleChange}
        margin='normal'
        fullWidth
        rows={8}
        multiline={true}
      />
      {config.modules.outbound ? (
        <PageSection title='Reminders' elevation={1}>
          <OutboundStateViewer
            outboundState={booking.outbound_state}
            outboundWord={"Reminder"}
          />
        </PageSection>
      ) : null}

      <div className={"booking-buttons-bar"}>
        <SpinnerButton
          variant='contained'
          color='primary'
          type='submit'
          onClick={handleSubmit}
        >
          <LocalTranslatedText language={"en"} text='Save' />
        </SpinnerButton>
        <SpinnerButton
          variant='contained'
          color='error'
          type='button'
          onClick={handleDeleteClicked}
        >
          <LocalTranslatedText language={"en"} text='Delete' />
        </SpinnerButton>
      </div>
    </form>
  );
};
