import React from "react";
import {DropdownInput} from "./DropdownInput";

import supportedLanguages from "../../../../backend/data/supported_languages.json";

// Filter for only the deepgram supported languages
supportedLanguages.languages = supportedLanguages.languages.filter(language => language.deepgram_language_code);

// Sort the standard list of languages
supportedLanguages.languages.sort((a, b) => a.name.localeCompare(b.name));

export function LanguageSelector({label, fieldName}) {
    const optionIds = supportedLanguages.languages.map(language => language.id);
    const optionLabels = supportedLanguages.languages.map(language => language.name);

    return <DropdownInput
        fieldName={fieldName}
        label={label}
        options={optionIds}
        optionLabels={optionLabels}
    />
}

export default LanguageSelector;

