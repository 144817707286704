import {PageWrapperWithMenu} from "../../../core/frontend/elements/PageWrapperWithMenu";
import React, {useCallback, useState} from "react";
import "../../../core/frontend/pages/HomePage.scss";
import {useNavigate, useParams} from "react-router-dom";
import {Card, CardContent} from "@mui/material";
import "./InsuranceAgentEditPage.scss";
import {useAPI} from "../../../core/frontend/components/APIProvider";
import {PageHeader} from "../../../core/frontend/elements/PageHeader";
import InsuranceAgentForm from "./InsuranceAgentForm";
import CircularProgress from "@mui/material/CircularProgress";


export default function InsuranceAgentEditPage(props) {
    const api = useAPI();
    const params = useParams();

    const navigate = useNavigate();
    const [insurance_agent, setInsuranceAgent] = useState(null);

    React.useEffect(() => {
        if (!insurance_agent) {
            api.getInsuranceAgent(params?.insuranceAgentId).then((insurance_agent) => {
                setInsuranceAgent(insurance_agent);
            });
        }
    }, [insurance_agent, params?.insuranceAgentId, api]);

    const handleInsuranceAgentSave = useCallback(async (newInsuranceAgent) => {
        return await api.saveInsuranceAgent(newInsuranceAgent);
    }, [api]);

    const handleDeleteClicked = useCallback(async () => {
        return await api.deleteInsuranceAgent(insurance_agent.id).then(() => {
            navigate(`/insurance_agents`);
        })
    }, [navigate, api, insurance_agent?.id]);

    if (!insurance_agent) {
        return <PageWrapperWithMenu>
            <PageHeader title={`Edit Insurance Agent`}/>
            <div className="insurance_agent-edit-page">
                <CircularProgress/>
            </div>
        </PageWrapperWithMenu>
    }

    return <PageWrapperWithMenu>
        <PageHeader title={`Edit Insurance Agent`}/>
        <div className="insurance_agent-edit-page">
            <Card className="insurance_agent-edit-form-card">
                <CardContent>
                    <InsuranceAgentForm
                        initialInsuranceAgent={insurance_agent}
                        onInsuranceAgentSave={handleInsuranceAgentSave}
                        onDeletedClicked={handleDeleteClicked}
                    />
                </CardContent>
            </Card>
        </div>

    </PageWrapperWithMenu>
}