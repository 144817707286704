import React, { useCallback } from "react";
import FormInput from "../elements/form/FormInput";
import FormRoot from "../elements/form/FormRoot";
import FormSection from "../elements/form/FormSection";
import "./TenantConfigurationForm.scss";
import MongoConfigurationForm from "./forms/MongoConfigurationForm";
import AMQPConfigurationForm from "./forms/AMQPConfigurationForm";
import APIConfigurationForm from "./forms/APIConfigurationForm";
import AnthropicConfigurationForm from "./forms/AnthropicConfigurationForm";
import Auth0ConfigurationForm from "./forms/Auth0ConfigurationForm";
import FrontendConfigurationForm from "./forms/FrontendConfigurationForm";
import SendGridConfigurationForm from "./forms/SendGridConfigurationForm";
import StripeConfigurationForm from "./forms/StripeConfigurationForm";
import TwilioConfigurationForm from "./forms/TwilioConfigurationForm";
import WA2ChatConfigurationForm from "./forms/WA2ChatConfigurationForm";
import CohereConfigurationForm from "./forms/CohereConfigurationForm";
import ElevenLabsConfigurationForm from "./forms/ElevenLabsConfigurationForm";
import GCSStorageConfigurationForm from "./forms/GCPStorageConfigurationForm";
import GoogleGeminiConfigurationForm from "./forms/GoogleGeminiConfigurationForm";
import GroqConfigurationForm from "./forms/GroqConfigurationForm";
import KnowledgeBaseConfigurationForm from "./forms/KnowledgeBaseConfigurationForm";
import MistralConfigurationForm from "./forms/MistralConfigurationForm";
import OpenAIConfigurationForm from "./forms/OpenAIConfigurationForm";
import DeepgramConfigurationForm from "./forms/DeepgramConfigurationForm";
import GoogleOAuthConfigurationForm from "./forms/GoogleOAuthConfigurationForm";
import ModulesConfigurationForm from "./forms/ModulesConfigurationForm";
import BotConfigurationForm from "./forms/BotConfigurationForm";
import HomeAPIServerConfigurationForm from "./forms/HomeAPIServerConfigurationForm";

export default function TenantConfigurationForm({
  initialTenantConfiguration,
  onTenantConfigurationSave,
  onDeletedClicked,
  saveButtonText,
}) {
  const [tenantConfiguration, setTenantConfiguration] = React.useState(
    initialTenantConfiguration
  );

  const handleTenantConfigurationSaved = useCallback(
    (newTenantConfigurationData) => {
      if (onTenantConfigurationSave) {
        return onTenantConfigurationSave(newTenantConfigurationData);
      }
    },
    [onTenantConfigurationSave]
  );

  const handleTenantConfigurationChange = useCallback(
    (newTenantConfigurationData) => {
      setTenantConfiguration(newTenantConfigurationData);
    },
    []
  );

  return (
    <div className={"tenant-configuration-form"}>
      <FormRoot
        initialValue={initialTenantConfiguration}
        saveButtonText={saveButtonText ?? "Save Tenant Configuration"}
        onSave={handleTenantConfigurationSaved}
        onChange={handleTenantConfigurationChange}
      >
        <FormSection
          title={"General"}
          subtitle={"Metadata that be used to identify this tenant"}
        >
          <FormInput type={"text"} label={"ID"} fieldName={"id"} />

          <FormInput type={"text"} label={"Title"} fieldName={"title"} />
        </FormSection>

        <ModulesConfigurationForm />
        <BotConfigurationForm />
        <HomeAPIServerConfigurationForm />
        <APIConfigurationForm />
        <FrontendConfigurationForm />

        <MongoConfigurationForm />
        <AMQPConfigurationForm />
        <GCSStorageConfigurationForm />

        <KnowledgeBaseConfigurationForm />

        <Auth0ConfigurationForm />
        <SendGridConfigurationForm />
        <StripeConfigurationForm />
        <TwilioConfigurationForm />
        <WA2ChatConfigurationForm />
        <GoogleOAuthConfigurationForm />

        <ElevenLabsConfigurationForm />
        <DeepgramConfigurationForm />

        <AnthropicConfigurationForm />
        <CohereConfigurationForm />
        <GoogleGeminiConfigurationForm />
        <GroqConfigurationForm />
        <MistralConfigurationForm />
        <OpenAIConfigurationForm />
      </FormRoot>
    </div>
  );
}
