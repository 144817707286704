import * as React from 'react';
import {TableView} from "../../../core/frontend/elements/TableView";
import {useAPI} from "../../../core/frontend/components/APIProvider";
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

export function ChartTable() {
    const api = useAPI();
    const navigate = useNavigate();

    const handleChartRowClicked = useCallback((chart) => {
        navigate(`/prompt_charts/${chart.id}`);
    }, [navigate]);

    const handleDeleteClicked = useCallback((chart) => {
        return api.deleteChart(chart.id);
    }, [api]);

    const handleCopyClicked = useCallback((chart) => {
        // First lets fetch the full chart object with all of its data
        return api.getChart(chart.id).then((chart) => {
            const newChart = {
                _id: null,
                title: chart.title + " (Copy)",
                ...chart,
            };

            return api.createNewChart(newChart);
        });
    }, [api]);

    return (
        <TableView
            schemaAPI={api.getPromptChartSchema}
            fetchAPI={api.getChartsTable}
            onRowClicked={handleChartRowClicked}
            onDeleteClicked={handleDeleteClicked}
            onCopyClicked={handleCopyClicked}
        />
    );
}
