import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import "./HealthCheckSummary.css";

export function HealthCheckListItem({checkData}) {
  return (
    <ListItem disablePadding>
      <ListItemIcon>
        {
          checkData.healthy ?
            <CheckCircleIcon
              color={'success'}
            />:
            <CancelIcon
              color={'error'}
            />
        }
      </ListItemIcon>
      <ListItemText primary={checkData.name} />
    </ListItem>
  );
}


export default function HealthCheckSummary({ healthCheckData }) {
  return (
    <div className={"health-check-summary"}>
        <List>
          <HealthCheckListItem
            checkData={{
              name: "Overall System Health",
              healthy: healthCheckData?.healthy ?? false
            }}
          />
          {
            Object.keys(healthCheckData?.checks ?? {}).map((checkName, index) => {
              return (
                <HealthCheckListItem
                  key={index}
                  checkData={healthCheckData.checks[checkName]}
                />
              )
            })
          }
        </List>
    </div>
  );
}