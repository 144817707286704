import * as React from 'react';
import {TableView} from "../../../frontend/elements/TableView";
import {useAPI} from "../../../frontend/components/APIProvider";
import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

export function KnowledgeChunkTable({knowledgeChunks}) {
    const api = useAPI();
    const navigate = useNavigate();

    const handleKnowledgeChunkRowClicked = useCallback((knowledgeChunk) => {
        navigate(`/knowledge/${knowledgeChunk.id}`);
    }, [navigate]);

    if (knowledgeChunks) {
        return <TableView
            key={"client_side"}
            schemaAPI={api.getKnowledgeChunkSchema}
            rowData={knowledgeChunks}
            onRowClicked={handleKnowledgeChunkRowClicked}
            rowModelType={'clientSide'}
        />;
    } else {
        return (
            <TableView
                key={"server_side"}
                schemaAPI={api.getKnowledgeChunkSchema}
                fetchAPI={api.getKnowledgeChunks}
                onRowClicked={handleKnowledgeChunkRowClicked}
                rowModelType={'serverSide'}
            />
        );
    }
}
