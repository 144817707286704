import {PageWrapperWithMenu} from "../../../core/frontend/elements/PageWrapperWithMenu";
import React, {useCallback} from "react";
import {Card, CardContent} from "@mui/material";
import {useAPI} from "../../../core/frontend/components/APIProvider";
import {PageHeader} from "../../../core/frontend/elements/PageHeader";
import TopicExtractorPromptForm from "./TopicExtractorPromptForm";
import {useNavigate} from "react-router-dom";
import "./TopicExtractorPromptEditPage.scss";


export default function NewTopicExtractorPromptPage(props) {
    const api = useAPI();
    const navigate = useNavigate();
    const handleCreateTopicExtractorPrompt = useCallback(async (topicExtractorPromptData) => {
        return await api.createNewTopicExtractorPrompt(topicExtractorPromptData).then((newTopicExtractorPrompt) => {
            navigate(`/topic_extractor_prompts/${newTopicExtractorPrompt.id}`);
        });
    }, [api, navigate]);

    return <PageWrapperWithMenu>
        <PageHeader title={`New Topic Extractor Prompt`}/>
        <div className="topic-extractor-prompt-edit-page">
            <Card className="topic-extractor-prompt-edit-form-card">
                <CardContent>
                    <TopicExtractorPromptForm
                        initialTopicExtractorPrompt={{}}
                        onTopicExtractorPromptSave={handleCreateTopicExtractorPrompt}
                        saveButtonText={"Create New Topic Extractor Prompt"}
                    />
                </CardContent>
            </Card>
        </div>

    </PageWrapperWithMenu>
}